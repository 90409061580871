import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  OnChanges,
  AfterViewInit,
} from "@angular/core";
import {
  DatatableComponent,
  RightRequest,
  APIService,
  ModalComponent,
  NotificationService,
  AuthService,
  Utilities,
  NgFlowchartCanvasDirective,
  NgFlowchart,
  NgFlowchartStepRegistry,
} from "@fourcomply-dashboard/shared";
import { environment } from "apps/fourcomply-dashboard/src/environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import {
  UploadOutput,
  UploaderOptions,
  UploadFile,
  UploadInput,
  UploadStatus,
  humanizeBytes,
} from "ngx-uploader";
import * as jwt_decode from "jwt-decode";
import { FormStepComponent } from "../right-request-configurations/form-step/form-step.component";

@Component({
  selector: "app-right-request-detail",
  templateUrl: "./right-request-detail.component.html",
  styleUrls: ["./right-request-detail.component.scss"],
})
export class RightRequestDetailComponent implements OnInit, AfterViewInit {
  @ViewChild("dtRightRequestDetail")
  dtRightRequestDetail: DatatableComponent;

  @ViewChild("modalUpload")
  public modalUpload: ModalComponent;

  @ViewChild("modalEndStep", { static: true })
  public modalEndStep: ModalComponent;

  @ViewChild("fakeUpload", { static: true })
  public fakeUploadInput;

  @ViewChild("fakeUpload2", { static: true })
  public fakeUploadInput2;

  @ViewChild(NgFlowchartCanvasDirective)
  canvas: NgFlowchartCanvasDirective;

  callbacks: NgFlowchart.Callbacks = {};
  initialZoom: number = 1;
  flowchartOptions: NgFlowchart.Options = {
    stepGap: 60,
    rootPosition: "FREE",
    centerOnResize: true,
    zoom: {
      mode: "MANUAL",
      defaultStep: 0.05,
    },
  };

  nextState: string;
  currentState: string;
  isChoiceStep = false;
  currentStepData: any = null;
  requestId: string;
  dataRightRequest: any;
  columnsRightRequestHistory: any;
  dataRightRequestConfigSteps: any[];
  selectionCompletionRequirement: string;
  completionRequirementMaps: any[] = [];
  completionEntryMaps: any;
  isIndeterminateStep: boolean;
  presignedUrls: any[] = [];
  uploadedData: any[] = [];
  options: UploaderOptions;
  requestData: any;
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  uploadFileStatus: string = "";
  headers: any;
  refresher: any;

  constructor(
    private api: APIService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private notification: NotificationService,
    private stepRegistry: NgFlowchartStepRegistry
  ) {
    this.options = { concurrency: 1, maxUploads: 100 };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
    this.callbacks.onDropError = () => {};
    this.callbacks.onDropStep = () => {};
    this.callbacks.onMoveError = () => {};
    this.callbacks.afterScale = this.afterScale.bind(this);
  }

  ngOnInit() {
    this.confirmEndStep = this.confirmEndStep.bind(this);
    this.requestId = this.route.snapshot.params["id"];
    this.columnsRightRequestHistory = [
      { name: "RIGHTS_DETAIL_CONFIG.STEP_NAME", prop: "step_name" },
      { name: "RIGHTS_DETAIL_CONFIG.TIMESTAMP", prop: "timestamp" },
      { name: "RIGHTS_DETAIL_CONFIG.OUTPUT_DATA", prop: "output_data" },
    ];
    this.fetchRightRequest();
    //this.startAutoRefresh();
    this.auth.token$.subscribe((token) => {
      const decodedToken = jwt_decode(token);
      let tenantId =
        decodedToken["https://" + environment.auth.AUDIENCE + "/tenant_id"] ||
        "";
      if (!tenantId) {
        for (const [key, value] of Object.entries(decodedToken)) {
          if (key.includes("tenant.name")) {
            tenantId = key.split("tenant.name/")[1];
          }
        }
      }
      this.headers = {
        Authorization: `Bearer ${token}`,
        tenant_id: tenantId,
      };
    });
  }

  ngAfterViewInit() {
    this.stepRegistry.registerStep("start", FormStepComponent as any);
    this.stepRegistry.registerStep("automatic", FormStepComponent as any);
    this.stepRegistry.registerStep("hitl", FormStepComponent as any);
    this.stepRegistry.registerStep("regular", FormStepComponent as any);
    this.stepRegistry.registerStep("indeterminate", FormStepComponent as any);
    this.stepRegistry.registerStep("fulfillment", FormStepComponent as any);
    this.stepRegistry.registerStep("choice", FormStepComponent as any);
    this.stepRegistry.registerStep("end", FormStepComponent as any);
    this.stepRegistry.registerStep("webhook", FormStepComponent as any);
  }

  startAutoRefresh() {
    this.refresher = setInterval(() => {
      this.fetchRightRequest();
    }, 3000);
  }

  afterScale(newScale) {
    console.log("calling scale", newScale);
    this.initialZoom = newScale;
    if (this.initialZoom < 0.5) {
      this.flowchartOptions = {
        ...this.flowchartOptions,
        stepGap: 70,
      };
    } else if (this.initialZoom >= 0.5) {
      this.flowchartOptions = {
        ...this.flowchartOptions,
        stepGap: 100,
      };
    }
    this.loadIntoWorkflow();
  }

  zoom(zoomIn: boolean) {
    if (zoomIn) {
      this.canvas.scaleUp();
    } else {
      if (this.initialZoom <= 0.5) return;
      this.canvas.scaleDown();
    }
  }

  loadIntoWorkflow() {
    let flow = {
      root: {
        id: 1,
        type: this.dataRightRequestConfigSteps[0].type,
        data: {
          ...this.dataRightRequestConfigSteps[0],
          name: this.dataRightRequestConfigSteps[0].name,
        },
        children: [],
      },
    };
    let a = this.dataRightRequestConfigSteps
      .map((step) => {
        console.log("STEP ORDER:", step.order);
        let directParentNoChoice = this.dataRightRequestConfigSteps.find(
          (a) => {
            console.log("NEXT:", a.next);
            return a.next === step.order;
          }
        );
        console.log("DIRECT PARENT NO CHOICE:", directParentNoChoice);
        let choiceParent = null;
        if (!directParentNoChoice) {
          // check for choice before
          let choiceParentList = this.dataRightRequestConfigSteps.filter(
            (a, index) => {
              //TEMP FIxa.type === "choice" &&
              return  a.order < step.order;
            }
          );
          // need the one with the highest order
          choiceParent = choiceParentList[choiceParentList.length - 1];
          console.log("CHOICE PARENT:", choiceParent);
        }
        return {
          ...step,
          activeMode: true,
          id: step.order,
          parent_id:
            step.order === 1 || step.order === 0
              ? null
              : choiceParent
              ? choiceParent.order
              : directParentNoChoice?.order,
        };
      })
      .slice(1, this.dataRightRequestConfigSteps.length);

    const nest = (items, id = null, link = "parent_id") => {
      console.log("nesting", items, id, link);
      return items
        .filter((item) => {
          return item[link] === id;
        })
        .map((item) => {
          console.log("getting children for", item);
          return {
            ...item,
            id: item.id,
            type: item.type,
            data: {
              ...item,
              name: item.name,
              scale: this.initialZoom,
            },
            children: nest(items, item.id),
          };
        });
    };
    console.log("A", a);
    flow.root.children = nest(a, 1);
    this.canvas.getFlow().upload(flow);
  }

  onDropError(error: NgFlowchart.DropError) {
    console.log(error);
  }

  onMoveError(error: NgFlowchart.MoveError) {
    console.log(error);
  }
  showFlowData() {
    let json = this.canvas.getFlow().toJSON(4);

    var x = window.open();
    x.document.open();
    x.document.write(
      "<html><head><title>Flowchart Json</title></head><body><pre>" +
        json +
        "</pre></body></html>"
    );
    x.document.close();
  }

  clearData() {
    this.canvas.getFlow().clear();
  }

  onGapChanged(event) {
    console.log(event);
    this.flowchartOptions = {
      ...this.flowchartOptions,
      stepGap: parseInt(event.target.value),
    };
  }

  onSequentialChange(event) {
    console.log(event);
    this.flowchartOptions = {
      ...this.flowchartOptions,
      isSequential: event.target.checked,
    };
  }

  closeUploadModal() {
    this.modalUpload.closeModal();
    this.uploadFileStatus = "";
    this.files = [];
    this.ngOnInit();
  }

  isLastStepLeftCompletedOrFailed() {
    // Get Slice
    let final2 = this.dataRightRequestConfigSteps.slice(
      this.dataRightRequestConfigSteps.length - 2,
      this.dataRightRequestConfigSteps.length
    );
    if (
      (final2[0] && (final2[0].isCompleted || final2[0].isFailedStep)) ||
      (final2[1] && (final2[1].isCompleted || final2[1].isFailedStep))
    ) {
      if (this.dataRightRequestConfigSteps.length % 2 === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isLastStepRightCompletedOrFailed() {
    // Get Slice
    let final2 = this.dataRightRequestConfigSteps.slice(
      this.dataRightRequestConfigSteps.length - 2,
      this.dataRightRequestConfigSteps.length
    );
    if (
      (final2[0] && (final2[0].isCompleted || final2[0].isFailedStep)) ||
      (final2[1] && (final2[1].isCompleted || final2[1].isFailedStep))
    ) {
      if (this.dataRightRequestConfigSteps.length % 2 !== 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  openEndStepModal() {
    this.modalEndStep.openModal();
  }

  openUploadModal(): void {
    this.modalUpload.openModal();
  }

  onUploadOutput(output: UploadOutput): void {
    console.log(this.selectionCompletionRequirement);
    if (output.type === "allAddedToQueue") {
      let url = this.api.rightRequests.getUploadFileUrl(
        this.requestId,
        this.currentState,
        this.selectionCompletionRequirement
      );
      console.log(url, this.headers);
      const event: UploadInput = {
        type: "uploadAll",
        url: url,
        headers: this.headers,
        method: "PUT",
      };
      this.uploadFileStatus = "uploading";
      this.uploadInput.emit(event);
    } else if (
      output.type === "addedToQueue" &&
      typeof output.file !== "undefined"
    ) {
      this.files.push(output.file);
    } else if (
      output.type === "uploading" &&
      typeof output.file !== "undefined"
    ) {
      const index = this.files.findIndex(
        (file) =>
          typeof output.file !== "undefined" && file.id === output.file.id
      );
      this.files[index] = output.file;
    } else if (output.type === "cancelled" || output.type === "removed") {
      this.files = this.files.filter(
        (file: UploadFile) => file !== output.file
      );
    } else if (output.type === "dragOver") {
      this.uploadFileStatus = "dragOver";
    } else if (output.type === "dragOut") {
      this.uploadFileStatus = "dragOver";
    } else if (output.type === "drop") {
      this.uploadFileStatus = "dragOver";
    } else if (output.type == "removedAll") {
      this.uploadFileStatus = "";
      this.files = [];
      this.formData = null;
      this.closeUploadModal();
    } else if (
      output.type === "rejected" &&
      typeof output.file !== "undefined"
    ) {
      //console.log(output.file.name + " rejected");
    } else if (output.type === "done") {
      this.notification.success("RIGHTS_REQUESTS.SUCCESSFUL_UPLOAD");
      this.uploadInput.emit({ type: "removeAll" });
      this.fetchRightRequest();
    }
  }

  isRightBeforeChoice(index) {
    let steps = this.dataRightRequestConfigSteps;
    let choiceIndexes = [];
    steps.forEach((s, ind) => {
      if (s.type == "choice") {
        choiceIndexes.push(ind);
      }
    });
    if (choiceIndexes.includes(index + 1)) {
      return true;
    } else {
      return false;
    }
  }

  confirmEndStep(): void {
    this.modalEndStep.closeModal();
    this.api.rightRequests
      .endCurrentStep(this.requestId, this.nextState)
      .subscribe((a) => {
        this.notification.success(
          "RIGHTS_REQUESTS.STEP_INDETERMINATE_ENDED_SUCCESS"
        );
        this.isIndeterminateStep = false;
        this.fetchRightRequest();
      });
  }

  getDescription(type) {
    switch (type) {
      case "automatic":
        return "RIGHTS_REQUESTS.TYPE_AUTOMATIC_DESC";
      case "end":
        return "RIGHTS_REQUESTS.TYPE_END_DESC";
      case "hitl":
        return "RIGHTS_REQUESTS.TYPE_HITL_DESC";
      case "indeterminate":
        return "RIGHTS_REQUESTS.TYPE_INDETERMINATE_DESC";
      case "webhook":
        return "RIGHTS_REQUESTS.TYPE_WEBHOOK_DESC";
      case "regular":
        return "RIGHTS_REQUESTS.TYPE_REGULAR_DESC";
      default:
        break;
    }
  }

  formatCompletionEntries() {
    if (!this.requestData || !this.requestData.completionEntries) return [];
    if (!this.currentStepData || !this.currentStepData.config)
      return this.requestData.completionEntries;
    let returner = this.requestData.completionEntries.map((e) => {
      this.completionRequirementMaps[0].forEach((c) => {
        if (
          e.workflow_step_completion_config_id ==
          c.workflow_step_completion_config_id
        ) {
          e.config = c;
        }
      });
      return {
        ...e,
        updated_at: Utilities.printShortDate(new Date(e.updated_at)),
      };
    });
    console.log(returner);
    return returner;
  }

  fetchRightRequest() {
    this.api.rightRequests.get(this.requestId).subscribe((data: any) => {
      this.requestData = data;
      this.nextState = data.nextState;
      this.currentState = data.currentState;
      data.parsedHistory = data.history; //JSON.parse(data.history);
      this.presignedUrls =
        data.presignedUrls &&
        data.presignedUrls.map((url) => {
          let urlArray = url.name.split("#"); // split step name
          let stepName = urlArray[0];
          let fileName = urlArray[1];
          return {
            stepName,
            fileName,
            ...url,
          };
        });
      // This should compare to the regulation name in regulation table, do not assume GDPRc

      this.api.rightRequestConfigs
        .get(data.configId)
        .subscribe((currentConfigData: any) => {
          console.log(currentConfigData);
          this.dataRightRequestConfigSteps = currentConfigData.steps;
          this.uploadedData = [];
          let mostRecentCompletedOrder = 0;
          this.dataRightRequestConfigSteps.forEach((step: any) => {
            step.isCompleted = false;
            step.message = "RIGHTS_REQUESTS.STEP_PENDING";
            if (step.type == "indeterminate") {
              this.completionRequirementMaps.push(
                currentConfigData.steps.find(
                  (s) => s.identifier === step.identifier
                )?.config?.completion_requirements
              );
            }
            // Check if it's the current state
            if (step.identifier === this.currentState) {
              step.isCurrentStep = true;
              this.currentStepData = currentConfigData.steps.find(
                (s) => s.identifier === step.identifier
              );
            }
            // Check if indeterminate
            if (
              step.identifier === data.currentState &&
              step.type === "indeterminate"
            ) {
              this.isIndeterminateStep = true;
            } else if (step.identifier === data.currentState) {
              this.isIndeterminateStep = false;
            }
            if (data.parsedHistory) {
              // Check History
              data.parsedHistory.forEach((history) => {
                if (history.step_name === step.identifier) {
                  if (history.error) {
                    step.isFailedStep = true;
                  } else {
                    step.isCompleted = true;
                    if (step.order > mostRecentCompletedOrder) {
                      mostRecentCompletedOrder = step.order;
                    }
                  }
                  step.isCurrentStep = false;
                  if (history.output_data) {
                    step.message = history.output_data.message;
                    if (history.output_data.data) {
                      step.data = history.output_data.data;
                      step.parsedData = JSON.parse(step.data);
                      this.uploadedData.push({
                        step: history.step_name,
                        data: step.data,
                        parsedData: step.parsedData,
                        keys: Object.keys(step.parsedData),
                      });
                    }
                  }
                }
              });
            }
          });
          // do choice steps last
          this.dataRightRequestConfigSteps.forEach((step: any) => {
            if (step.type === "choice") {
              step.isChoiceStep = true;
              if (
                data.currentState.indexOf("Completed") != -1 ||
                data.currentState.indexOf("Failed") != -1
              ) {
                step.isCompleted = true;
                step.message = "RIGHTS_REQUESTS.STEP_COMPLETED";
              }
              console.log("choice", step, mostRecentCompletedOrder);
              if (step.order <= mostRecentCompletedOrder) {
                step.isCompleted = true;
                step.message = "RIGHTS_REQUESTS.STEP_COMPLETED";
              }
            }
          });
          this.loadIntoWorkflow();
        });
    });
  }
}
