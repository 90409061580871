import { ClientFormFieldOption } from './client-form-field-option.model';

export class ClientFormField {
  type: string;
  name: string;
  identifier: string;
  description: string;
  validation: string;
  order: number;
  options?: ClientFormFieldOption[];
}
