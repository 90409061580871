import { BaseApiService } from "../base/base-api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from "../custom-error-handler.service";
import { ClientEmailTemplate } from '../../../models/client-email-templates/client-email-template.model';
import { ClientEmailTemplateSerializer } from '../../../models/client-email-templates/client-email-template-serializer.class';

/**
 * Service to communicate with API Client Users
 */

@Injectable()
export class ClientEmailTemplatesService extends BaseApiService<ClientEmailTemplate> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      null,
      "emailtemplates",
      new ClientEmailTemplateSerializer(),
      new CustomErrorHandlerService()
    );
  }

  // EXTEND/OVERRIDE THE SERVICE HERE IF NECESSARY
}
