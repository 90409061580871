import { ResourceSQL } from "../resource-sql/resource-sql.model";
import { Regulation } from "../regulation/regulation.model";

export class ForgottenUser extends ResourceSQL {
  emailAddress: string;
  userId: string;
  geo: string;
  consentCode: string;
  ttl: string;
  expiration: Date;
  activityId: string;
  standardState: string;
  standardCountry: string;
  processingPurpose: string;
  permissionCategory: string;
  permissionType: string;
  regulationName: string;
  regulationId: string;
  createdAt: Date;
  updatedAt: Date;
}
