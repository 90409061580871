import { BaseApiService } from "../base/base-api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from '../custom-error-handler.service';
import { ClientForm } from '../../../models/client-form/client-form.model';
import { ClientFormsSerializer } from '../../../models/client-form/client-form-serializer.class';

/**
 * Service to communicate with API Client Users
 */

@Injectable()
export class ClientFormsService extends BaseApiService<ClientForm> {
  constructor(httpClient: HttpClient) {
    super(
        httpClient, 
        null, 
        "forms", 
        new ClientFormsSerializer(), 
        new CustomErrorHandlerService());
  }

  // EXTEND/OVERRIDE THE SERVICE HERE IF NECESSARY
}
