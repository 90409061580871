import { Serializer } from "../resource/serializer.interface";
import { ForgottenUser } from "./forgotten-user.model";

export class ForgottenUserSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): ForgottenUser {
    const forgottenUser = new ForgottenUser();
    forgottenUser.id = json.id;
    forgottenUser.emailAddress = json.email_address;
    forgottenUser.userId = json.user_id;
    forgottenUser.systemId = json.system_id;
    forgottenUser.systemType = json.system_type;
    forgottenUser.system = json.system;
    forgottenUser.systemRecordId = json.system_record_id;
    forgottenUser.systemRecordType = json.system_record_type;
    forgottenUser.geo = json.geo;
    forgottenUser.consentCode = json.consent_code;
    forgottenUser.ttl = json.ttl;
    forgottenUser.expiration = json.expiration;
    forgottenUser.activityId = json.activity_id;
    forgottenUser.standardState = json.standard_state;
    forgottenUser.standardCountry = json.standard_country;
    forgottenUser.processingPurpose = json.processing_purpose;
    forgottenUser.permissionCategory = json.permission_category;
    forgottenUser.permissionType = json.permission_type;
    forgottenUser.regulationName = json.regulationName;
    forgottenUser.regulationId = json.regulation_id;
    forgottenUser.createdAt = json.created_at;
    forgottenUser.updatedAt = json.updated_at;

    return forgottenUser;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(forgottenUser: ForgottenUser): any {
    return {
      id: forgottenUser.id,
      email_address: forgottenUser.emailAddress,
      user_id: forgottenUser.userId,
      system_id: forgottenUser.systemId,
      system_type: forgottenUser.systemType,
      system: forgottenUser.system,
      system_record_id: forgottenUser.systemRecordId,
      system_record_type: forgottenUser.systemRecordType,
      consent_code: forgottenUser.consentCode,
      ttl: forgottenUser.ttl,
      expiration: forgottenUser.expiration,
      activity_id: forgottenUser.activityId,
      processing_purpose: forgottenUser.processingPurpose,
      permission_category: forgottenUser.permissionCategory,
      permission_type: forgottenUser.permissionType,
      regulation_name: forgottenUser.regulationName,
      regulation_id: forgottenUser.regulationId,
      created_at: forgottenUser.createdAt,
      updated_at: forgottenUser.updatedAt,
    };
  }
}
