<div class="datatable-super-upper-wrap">
  <div
    style="cursor:pointer;position:absolute;height:20px; top:20px;left:-15px;"
  >
    <!--<div style="height:20px;" (click)="toggleColumnSettings()">
      <i style="color:grey;" class="fas fa-cog"></i>
    </div>
    <div class="column-settings-wrapper" *ngIf="showColumnSettings">
      <div *ngFor="let col of columnMapping">
        <label class="form-checkbox">
          <input
            (click)="toggleColumn(col)"
            [(ngModel)]="!col.disabled"
            type="checkbox"
          />
          <i class="form-icon"></i> {{ col.name }}
        </label>
      </div>
    </div>-->
  </div>
  <div class="datatable-upper-wrap" *ngIf="showTable">
    <ngx-datatable
      #datatable
      class="material custom-ngx-dt"
      [rows]="rows"
      [loadingIndicator]="loadingIndicator"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="'auto'"
      (directiveDrop)="onDrop($event)"
      [trackByProp]="id"
      [classSelector]="'fas fa-grip-horizontal'"
      [dragulaName]="'bag'"
      [dragulaModel]="rows"
      [sorts]="sorts"
      (sort)="onSort($event)"
      [messages]="{ emptyMessage: 'TABLE.NO_DATA' | translate }"
    >
      <ngx-datatable-column [maxWidth]="25">
        <ng-template
          ngx-datatable-cell-template
          let-rowIndex="rowIndex"
          let-value="value"
          let-row="row"
        >
          <a class="grab-btn" *ngIf="editingItemCount == 0">
            <i class="fas fa-grip-horizontal"></i>
          </a>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        *ngFor="let col of columns"
        [name]="col.name | translate"
        [prop]="col.prop"
        [sortable]="col.sortable"
      >
        <ng-template
          ngx-datatable-cell-template
          let-rowIndex="rowIndex"
          let-value="value"
          let-row="row"
        >
          <div *ngIf="col.prop !== 'actions'">
            <mat-form-field
              *ngIf="isEditable[rowIndex] && col.prop !== 'priority'"
            >
              <input matInput [(ngModel)]="row[col.prop]" />
            </mat-form-field>
            <span *ngIf="!isEditable[rowIndex] || col.prop === 'priority'">{{
              value
            }}</span>
          </div>
          <div class="action-list-custom" *ngIf="col.prop === 'actions'">
            <div class="action-item" *ngFor="let a of actionItems">
              <div [ngSwitch]="a.toolTipText">
                <div *ngSwitchCase="'GENERIC.EDIT' | translate" style="position:absolute;">
                  <button
                    (click)="onEdit(rowIndex)"
                    class="btn btn-action btn-primary btn-sm s-circle tooltip tooltip-left"
                    [attr.data-tooltip]="a.toolTipText | translate"
                    *ngIf="!isEditable[rowIndex]"
                  >
                    <i class="{{ a.iconClass }}"></i>
                  </button>

                  <button
                    (click)="
                      saveData(row, rowIndex);
                      editingItemCount = editingItemCount - 1
                    "
                    class="btn btn-action btn-primary btn-sm s-circle tooltip tooltip-left"
                    [attr.data-tooltip]="'GENERIC.SAVE' | translate"
                    *ngIf="isEditable[rowIndex]"
                    style="position: absolute;right: 30px;top: 2px;"
                  >
                    <i class="fas fa-check"></i>
                  </button>
                  <button
                    (click)="onCancelEdit(rowIndex)"
                    class="btn btn-action btn-primary btn-sm s-circle tooltip tooltip-left"
                    [attr.data-tooltip]="'GENERIC.CANCEL' | translate"
                    *ngIf="isEditable[rowIndex]"
                  >
                    <i class="fas fa-times"></i>
                  </button>
                </div>

                <button
                  *ngSwitchDefault
                  (click)="onActionClick(a, row.id)"
                  class="btn btn-action btn-primary btn-sm s-circle tooltip tooltip-left"
                  [attr.data-tooltip]="a.toolTipText | translate"
                >
                  <i class="{{ a.iconClass }}"></i>
                </button>
              </div>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
