<div class="container">
  <div class="columns">
    <div class="colulmn col-7 login-bg"></div>
    <div class="colulmn col-5">
      <div class="login-section">
        <h1>ANGULAR DASHBOARD</h1>
        <div class="form-section">
            <div class="form-group">
                <input class="form-input" type="text" placeholder="Name">
                <!-- <p class="form-input-hint">Required</p> -->
              </div>
              <div class="form-group">
                  <input class="form-input" type="password" placeholder="Password">
                  <!-- <p class="form-input-hint">Required</p> -->
              </div>
        </div>
        <div class="input-form">
            <div class="form-group remember-section float-left">
                <label class="form-checkbox">
                  <input type="checkbox">
                  <i class="form-icon"></i> Remember me
                </label>
              </div>
              <a href="#" class="btn btn-primary btn-signin float-right" (click)="login()">Sign in</a>
        </div>
        <div class="clearfix"></div>
        <p class="help-text"><span style="color:red;">*</span> username: demo password: demodemo or just click on Sign in button</p>
      
        <hr class="dashed-hr" />
      
        <div class="social-btns">
          <a href="#" class="btn btn-primary btn-fb">Sign in with Facebook</a>
          <a href="#" class="btn btn-primary btn-gp">Sign in with Google Plus</a>
        </div>
      
        <div class="links">
          <a href="#">Forgot password</a>
          <a href="#">Create an Angular Dashboard account</a>
        </div>
      </div>
    </div>
  </div>
</div>