import { ClientUser } from "./client-user.model";
import { Serializer } from "../resource/serializer.interface";

export class ClientUserSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): ClientUser {
    const clientUser = new ClientUser();
    clientUser.id = json.id;
    clientUser.emailAddress = json.email_address;
    clientUser.firstName = json.first_name;
    clientUser.lastName = json.last_name;
    clientUser.userLevel = json.user_level;
    clientUser.externalId = json.external_id;
    clientUser.permissions = json.permissions;
    clientUser.createdAt = json.created_at;
    clientUser.updatedAt = json.updated_at;
    clientUser.isOriginalUser = json.is_original_user;

    return clientUser;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(clientUser: ClientUser): any {
    return {
      id: clientUser.id,
      first_name: clientUser.firstName,
      last_name: clientUser.lastName,
      email_address: clientUser.emailAddress,
      user_level: clientUser.userLevel,
      external_id: clientUser.externalId,
      permissions: clientUser.permissions,
      created_at: clientUser.createdAt,
      updated_at: clientUser.updatedAt,
      is_original_user: clientUser.isOriginalUser,
    };
  }
}
