import { Serializer } from "../resource/serializer.interface";
import { GeoPriority } from "./geo-priority.model";

export class GeoPrioritySerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): GeoPriority {
    const geoPriority = new GeoPriority();
    geoPriority.id = json.id;
    geoPriority.geo_method_name = json.geo_method_name;
    geoPriority.geo_type = json.geo_type;
    geoPriority.geo_key = json.geo_key;
    geoPriority.priority = json.priority;
    return geoPriority;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(geoPriority: GeoPriority): any {
    return {
      id: geoPriority.id,
      geo_method_name: geoPriority.geo_method_name,
      geo_type: geoPriority.geo_type,
      geo_key: geoPriority.geo_key,
      priority: geoPriority.priority,
    };
  }
}
