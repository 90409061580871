import { Resource } from "../resource/resource.model";

/**
 * Priority and level
 */

export class Regulation extends Resource {
  name: string;
  note: string;
  enabled: boolean
}
