import { Resource } from "../resource/resource.model";

/**
 * A Tenant in the 4Comply SaaS System
 * This will really only be used for the 4comply dashboard
 */

export class HitlTask extends Resource {
  id: string;
  status: string;
  assignedToId: string;
  citizenEmail: string;
  templateId: string;
  notes: string;
  history: string;
  lastEmailSent: Date;
  createdAt: Date;
  updatedAt: Date;
  source: string;
  workflowInstanceId: string;
}
