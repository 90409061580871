import { Resource } from "../resource/resource.model";
import { Regulation } from "../regulation/regulation.model";

/**
 * A Tenant in the 4Comply SaaS System
 * This will really only be used for the 4comply dashboard
 */

export class ClientRegulation extends Resource {
  name: string;
  priority: Regulation;
  note: string;
  longName?: string;
  enabled: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  sourceDuplicate?: string;
}
