import { Serializer } from "../resource/serializer.interface";
import { ComplianceInput } from "./compliance-input.model";

export class ComplianceInputSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): ComplianceInput {
    const complianceInput = new ComplianceInput();
    complianceInput.id = json.id;
    complianceInput.email = json.email_address;
    complianceInput.userId = json.user_id;
    complianceInput.geo = json.geo;
    complianceInput.consentCode = json.consent_code;
    complianceInput.ttl = json.ttl;
    complianceInput.activityId = json.activity_id;
    complianceInput.standardCountry = json.standard_country;
    complianceInput.standardState = json.standard_state;
    complianceInput.processingPurpose = json.processing_purpose;
    complianceInput.permissionCategory = json.permission_category;
    complianceInput.permissionType = json.permission_type;
    complianceInput.permissionSource = json.permission_source;
    complianceInput.regulationId = json.regulation_id;
    complianceInput.regulationName = json.regulation_name;
    complianceInput.createdAt = json.created_at;
    complianceInput.updatedAt = json.updated_at;
    complianceInput.businessUnit = json.business_unit;
    complianceInput.activity_date = json.activity_date;
    complianceInput.systemId = json.system_id;
    complianceInput.systemType = json.system_type;
    complianceInput.system = json.system;
    complianceInput.systemRecordId = json.system_record_id;
    complianceInput.systemRecordType = json.system_record_type;
    complianceInput.externalSource = json.external_source;
    complianceInput.means = json.means;
    complianceInput.consentLanguage = json.consent_language;
    complianceInput.privacyNoticeVersion = json.privacy_notice_version;
    complianceInput.customField1 = json.custom_field_1;
    complianceInput.customField2 = json.custom_field_2;
    complianceInput.customField3 = json.custom_field_3;
    complianceInput.customField4 = json.custom_field_4;
    complianceInput.customField5 = json.custom_field_5;
    complianceInput.customField6 = json.custom_field_6;
    complianceInput.customField7 = json.custom_field_7;
    return complianceInput;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(consent: ComplianceInput): any {
    return {
      id: consent.id,
      email_address: consent.email,
      user_id: consent.userId,
      geo: consent.geo,
      consent_code: consent.consentCode,
      ttl: consent.ttl,
      activity_id: consent.activityId,
      standard_country: consent.standardCountry,
      standard_state: consent.standardState,
      permission_source: consent.permissionSource,
      processing_purpose: consent.processingPurpose,
      permission_category: consent.permissionCategory,
      permission_type: consent.permissionType,
      regulation_name: consent.regulationName,
      regulation_id: consent.regulationId,
      business_unit: consent.businessUnit,
      created_at: consent.createdAt,
      updated_at: consent.updatedAt,
      system_id: consent.systemId,
      system_type: consent.systemType,
      system: consent.system,
      activity_date: consent.activity_date,
      system_record_id: consent.systemRecordId,
      system_record_type: consent.systemRecordType,
    };
  }
}
