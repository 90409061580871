import { BaseApiService } from "../base/base-api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from "../custom-error-handler.service";
import { ForgottenUser } from '../../../models/forgotten-users/forgotten-user.model';
import { ForgottenUserSerializer } from '../../../models/forgotten-users/forgotten-user-serializer.class';

/**
 * Service to communicate with API Client Users
 */

@Injectable()
export class ForgottenUsersService extends BaseApiService<ForgottenUser> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      null,
      "citizens/forgotten",
      new ForgottenUserSerializer(),
      new CustomErrorHandlerService()
    );
  }

  // EXTEND/OVERRIDE THE SERVICE HERE IF NECESSARY
}
