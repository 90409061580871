<div style="width:600px;height:100vh;overflow:scroll;">
  <ngx-panel-header>{{
    "RIGHTS_DETAIL_CONFIG.WORKFLOW_ACTIONS" | translate
  }}</ngx-panel-header>
  <div *ngIf="!requestData" style="padding:20px;text-align:center;">
    <span
      >{{ "GENERIC.LOADING" | translate }}
      <i class="fas fa-spinner fa-pulse"></i
    ></span>
  </div>

  <div *ngIf="requestData">
    <app-generic-card *ngIf="isIndeterminateStep">
      <button type="button" class="btn btn-primary" (click)="openUploadModal()">
        {{ "GENERIC.UPLOAD" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary ml-15"
        (click)="openEndStepModal()"
      >
        {{ "RIGHTS_DETAIL_CONFIG.COMPLETE_TASK" | translate }}
      </button>
    </app-generic-card>
    <app-generic-card *ngIf="uploadedData && uploadedData.length">
      <label>{{ "RIGHTS_DETAIL_CONFIG.UPLOADED_DATA" | translate }}</label>
      <ul class="file-list">
        <li *ngFor="let data of uploadedData; index as index">
          <div>
            <b>{{ data.step }}</b>
          </div>
          <div *ngFor="let key of data.keys">
            {{ key }} : {{ data.parsedData[key] }}
          </div>
        </li>
      </ul>
    </app-generic-card>
    <app-generic-card *ngIf="isIndeterminateStep && currentStepData.config">
      <label>{{
        "RIGHTS_DETAIL_CONFIG.REQUIRED_COMPLETION_ENTRIES" | translate
      }}</label>
      <ul class="file-list">
        <li
          *ngFor="
            let completion of currentStepData.config.completion_requirements
          "
        >
          <div>
            <b>{{ completion.name }}</b>
          </div>
        </li>
      </ul>
    </app-generic-card>
    <app-generic-card
      *ngIf="
        requestData.completionEntries && requestData.completionEntries.length
      "
    >
      <label>{{
        "RIGHTS_DETAIL_CONFIG.REQUIRED_COMPLETION_ENTRIES" | translate
      }}</label>
      <ul class="file-list">
        <li *ngFor="let completion of formatCompletionEntries()">
          <div *ngIf="completion && completion.config">
            <b>{{ completion.config.name }} - {{ completion.updated_at }}</b>
          </div>
        </li>
      </ul>
    </app-generic-card>
    <app-generic-card *ngIf="presignedUrls && presignedUrls.length > 0">
      <label *ngIf="presignedUrls.length == 1">{{
        "GENERIC.UPLOADED_FILE" | translate
      }}</label>
      <label *ngIf="presignedUrls.length > 1">{{
        "GENERIC.UPLOADED_FILES" | translate
      }}</label>
      <ul class="file-list">
        <li *ngFor="let url of presignedUrls; index as index">
          <i class="fas fa-external-link-square-alt"></i>
          <a [href]="url.presignedUrl" target="_blank"
            >{{ "GENERIC.UPLOADED_FILE" | translate }} #{{ index + 1 }} -
            {{ url && url.fileName }} - {{ url && url.stepName }}</a
          >
        </li>
      </ul>
    </app-generic-card>
    <div style="" *ngIf="dataAllPossibleConfigsSteps">
      <div
        [style.background]="step.isCompleted ? '#E5FFE5' : 'white'"
        style="margin-bottom:10px;padding:20px;border-bottom:1px solid #ddd;"
        *ngFor="
          let step of dataAllPossibleConfigsSteps;
          last as last;
          index as index;
          odd as odd;
          first as first
        "
      >
        <div class="step-status" *ngIf="step.isCompleted">
          <label>{{ "RIGHTS_DETAIL_CONFIG.COMPLETED" }}</label>
        </div>
        <div class="step-status" *ngIf="step.isCurrentStep">
          <label>{{ "RIGHTS_DETAIL_CONFIG.IN_PROGRESS" | translate }}</label>
        </div>
        <h5 [style.color]="step.isCompleted ? 'green' : 'black'">
          {{ step.name }}
        </h5>
        <div>
          <div>
            {{ getDescription(step.type) }}
          </div>
          <div
            style="margin-top:15px;"
            *ngIf="step.config && step.config.completion_requirements"
          >
            <div style="font-weight: bold;">
              {{ "RIGHTS_DETAIL_CONFIG.REQUIRED_COMPLETION_ENTRIES" }}:
            </div>
            <div *ngFor="let req of step.config.completion_requirements">
              - {{ req.workflow_step_completion_config_id }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modal
  #modalUpload
  title="{{
    requestData &&
    (requestData.requestType === 'RTA' || requestData.requestType === 'RTU')
      ? 'GENERIC.UPLOAD_JSON_FILE'
      : 'GENERIC.UPLOAD_ANY_FILE_TYPE'
  }}"
>
  <div class="upload-file-section">
    <div
      #fakeUpload
      class="drop-container"
      ngFileDrop
      [options]="options"
      (uploadOutput)="onUploadOutput($event)"
      [uploadInput]="uploadInput"
      [ngClass]="{ 'is-drop-over': uploadFileStatus === 'dragOver' }"
    >
      <h1 *ngIf="uploadFileStatus === ''">
        {{ "GENERIC.DRAG_AND_DROP" | translate }}
      </h1>
      <h1 *ngIf="uploadFileStatus === 'dragOver'">
        {{ "GENERIC.DROP_NOW" | translate }}
      </h1>
      <h1 *ngIf="uploadFileStatus === 'uploading'">
        {{ "GENERIC.UPLOADING" | translate }}
      </h1>
    </div>

    <label
      class="upload-button"
      [ngClass]="{ 'uploading-now': uploadFileStatus === 'uploading' }"
    >
      <input
        type="file"
        #fakeUpload2
        ngFileSelect
        [options]="options"
        (uploadOutput)="onUploadOutput($event)"
        [uploadInput]="uploadInput"
      />
      {{ "GENERIC.OR_CHOOSE_FILE" | translate }}
    </label>
    <div style="margin-top: 20px; text-align:center;">
      <div>
        {{ "RIGHTS_DETAIL_CONFIG.SELECT_WORKFLOW_COMPLETION" | translate }}
      </div>
      <div
        style="margin-top:5px;"
        *ngIf="currentStepData && currentStepData.config"
      >
        <select
          name="selectedCompletion"
          (ngModelChange)="onSelectionChange($event)"
          [(ngModel)]="selectionCompletionRequirement"
          required
        >
          <option default value="">
            {{ "GENERIC.NONE" | translate }}
          </option>
          <option
            value="{{ requirement.workflow_step_completion_config_id }}"
            *ngFor="
              let requirement of currentStepData.config.completion_requirements
            "
          >
            {{ requirement.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</app-modal>

<app-modal
  #modalEndStep
  title="RIGHTS_DETAIL_CONFIG.CONFIRM_END_STEP"
  bodyContent="{{ 'RIGHTS_DETAIL_CONFIG.ARE_YOU_SURE_STEP' | translate }}"
  [confirmCallback]="confirmEndStep"
></app-modal>
