<div class="container" [style.display]="outlet.isActivated ? 'none' : ''">
  <div class="columns">
    <div class="column col-6">
      <app-generic-card>
        <label>{{ "SETTINGS.CLIENT_NAME" | translate }}</label>
        <h3 class="section-title" *ngIf="client">{{ client?.name }}</h3>
        <p class="no-data" *ngIf="!client">
          {{ "SETTINGS.NO_NAME_FOUND" | translate }}
        </p>
        <div *ngIf="isNonProd">
          <button (click)="resetClient()">
            {{ "SETTINGS.RESET_ALL" | translate }}
          </button>
          <div style="margin-top: 20px">
            {{ "SETTINGS.RESET_EXPLANATION" | translate }}
          </div>
        </div>
      </app-generic-card>
      <app-generic-card>
        <label>{{ "SETTINGS.LOGO" | translate }}</label>
        <div
          *ngIf="clientLogo"
          style="
            width: 250px;
            margin-top: 20px;
            margin-bottom: 20px;
            max-width: 100%;
          "
        >
          <img src="{{ clientLogo }}" style="max-width: 100%" />
        </div>
        <p class="no-data" *ngIf="client && !clientLogo">
          {{ "SETTINGS.NO_LOGO_FOUND" | translate }}
        </p>
        <button
          class="btn btn-primary"
          type="input"
          (click)="modalUploadLogo.openModal()"
          *ngIf="client && !clientLogo"
        >
          {{ "SETTINGS.ADD_LOGO" | translate }}
        </button>
        <button
          class="btn btn-primary"
          type="input"
          (click)="modalUploadLogo.openModal()"
          *ngIf="client && clientLogo"
        >
          {{ "SETTINGS.UPDATE_LOGO" | translate }}
        </button>
      </app-generic-card>
      <app-generic-card>
        <label>{{ "SETTINGS.FAVICON" | translate }}</label>
        <div
          *ngIf="clientFavicon"
          style="
            width: 250px;
            margin-top: 20px;
            margin-bottom: 20px;
            max-width: 100%;
          "
        >
          <img src="{{ clientFavicon }}" style="max-width: 100%" />
        </div>
        <p class="no-data" *ngIf="client && !clientFavicon">
          {{ "SETTINGS.NO_LOGO_FOUND" | translate }}
        </p>
        <button
          class="btn btn-primary"
          type="input"
          (click)="modalUploadFavicon.openModal()"
          *ngIf="client && !clientFavicon"
        >
          {{ "SETTINGS.ADD_FAVICON" | translate }}
        </button>
        <button
          class="btn btn-primary"
          type="input"
          (click)="modalUploadFavicon.openModal()"
          *ngIf="client && clientFavicon"
        >
          {{ "SETTINGS.UPDATE_FAVICON" | translate }}
        </button>
      </app-generic-card>
      <app-generic-card>
        <label>{{ "SETTINGS.ALIAS" | translate }}</label>
        <div class="">
          <div class="form-group">
            <mat-form-field>
              <input matInput [(ngModel)]="alias" />
            </mat-form-field>
          </div>
          <button type="submit" class="btn btn-primary" (click)="updateAlias()">
            {{ "GENERIC.SAVE" | translate }}
          </button>
        </div>
      </app-generic-card>
      <app-generic-card>
        <label>{{ "SETTINGS.DEFAULT_NOTIFICATION_EMAIL" | translate }}</label>
        <div class="">
          <div class="form-group">
            <mat-form-field>
              <input matInput [(ngModel)]="defaultEmailAddress" />
            </mat-form-field>
          </div>
          <button
            type="submit"
            class="btn btn-primary"
            (click)="updateDefaultEmailAddress()"
          >
            {{ "GENERIC.SAVE" | translate }}
          </button>
        </div>
      </app-generic-card>
      <app-generic-card>
        <label>{{
          "SETTINGS.SECONDARY_NOTIFICATION_EMAILS" | translate
        }}</label>
        <div class="">
          <div class="form-group">
            <mat-form-field>
              <input matInput [(ngModel)]="secondaryEmailAddresses" />
            </mat-form-field>
          </div>
          <button
            type="submit"
            class="btn btn-primary"
            (click)="updateSecondaryEmailAddresses()"
          >
            {{ "GENERIC.SAVE" | translate }}
          </button>
        </div>
      </app-generic-card>
      <app-generic-card>
        <label>{{ "SETTINGS.PERMISSION_UPDATE_LOGIC" | translate }}</label>
        <div class="">
          <div class="form-group">
            <mat-select [(value)]="permissionUpdateLogic">
              <mat-option [value]="'regular'">
                {{ "SETTINGS.PERMISSION_UPDATE_LOGIC_REGULAR" | translate }}
              </mat-option>
              <mat-option [value]="'only_on_boolean_change'">
                {{
                  "SETTINGS.PERMISSION_UPDATE_LOGIC_ONLY_ON_CHANGE" | translate
                }}
              </mat-option>
            </mat-select>
          </div>
          <button
            type="submit"
            class="btn btn-primary"
            (click)="updatePermissionUpdateLogic()"
          >
            {{ "GENERIC.SAVE" | translate }}
          </button>
        </div>
      </app-generic-card>
      <app-generic-card *ngIf="userLevel.includes('admin')">
        <a
          class="btn btn-link floating-btn tooltip tooltip-bottom"
          data-tooltip="Add Domain"
          (click)="domainEditMood = true"
          *ngIf="
            !domainEditMood &&
            (!client ||
              !client.configId ||
              !client.configId.custom_email_domain)
          "
        >
          <span><i class="fas fa-plus"></i></span
        ></a>
        <a
          class="btn btn-link floating-btn tooltip tooltip-bottom"
          data-tooltip="Edit Domain"
          (click)="
            domainEditMood = true;
            customDomain = client.configId.custom_email_domain
          "
          *ngIf="
            !domainEditMood &&
            client &&
            client.configId &&
            client.configId.custom_email_domain
          "
        >
          <span><i class="fas fa-pen"></i></span
        ></a>
        <label>{{ "SETTINGS.EMAIL_DOMAIN" | translate }}</label>
        <div *ngIf="!domainEditMood">
          <b
            ><p
              *ngIf="
                client && client.configId && client.configId.custom_email_domain
              "
            >
              {{ client.configId.custom_email_domain }}
            </p></b
          >
          <p
            class="no-data"
            *ngIf="
              !client ||
              !client.configId ||
              !client.configId.custom_email_domain
            "
          >
            {{ "SETTINGS.NO_DOMAIN_FOUND" | translate }}
          </p>
        </div>

        <div class="" *ngIf="domainEditMood">
          <div class="form-group">
            <mat-form-field>
              <input matInput [(ngModel)]="customDomain" />
              <mat-error *ngIf="domainErrorMsg == 'Domain cannot be empty'">{{
                "SETTINGS.DOMAIN_CANNOT_BE_EMPTY" | translate
              }}</mat-error>
              <mat-error *ngIf="domainErrorMsg == 'Invalid domain format'">{{
                "SETTINGS.INVALID_DOMAIN_FORMAT" | translate
              }}</mat-error>
            </mat-form-field>
          </div>
          <button
            type="submit"
            class="btn btn-primary"
            (click)="saveCustomDomain()"
          >
            {{ "GENERIC.SAVE" | translate }}
          </button>
          <a
            class="btn btn-default"
            (click)="domainEditMood = false; customDomain = ''"
            style="margin-left: 10px"
            >{{ "GENERIC.CLOSE" | translate }}</a
          >
        </div>
      </app-generic-card>
      <app-generic-card>
        <label>{{ "SETTINGS.TENANT_ID" | translate }}</label>
        <h6 class="secret-key">{{ userid }}</h6>
      </app-generic-card>
      <app-generic-card *ngIf="!!token">
        <label>{{ "SETTINGS.SECRET_KEY" | translate }}</label>
        <h6 class="secret-key">{{ token | json }}</h6>
        <button
          class="btn btn-primary"
          type="input"
          (click)="modalUpdateToken.openModal()"
        >
          {{ "SETTINGS.UPDATE_TOKEN" | translate }}
        </button>
      </app-generic-card>
      <app-generic-card *ngIf="cNameInfo">
        <div *ngIf="cNameInfo">
          <table class="table" style="max-width: 100%; table-layout: fixed">
            <thead>
              <tr>
                <th colspan="2">
                  {{ "CUSTOM_DOMAIN_SETUP_EXPLANATION" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let info of cNameInfo">
                <td>{{ info.key }}</td>
                <td style="word-break: break-word">{{ info.value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </app-generic-card>
      <app-generic-card>
        <label>{{ "SETTINGS.CUSTOM_FIELDS" | translate }}</label>
        <div class="custom-field-config">
          <div>
            <label>{{ "SETTINGS.CUSTOM_FIELD" | translate }} 1</label>
            <div class="form-group">
              <mat-form-field>
                <input
                  matInput
                  [(ngModel)]="customFieldNames.customFieldName1"
                />
              </mat-form-field>
            </div>
          </div>
          <div>
            <label>{{ "SETTINGS.CUSTOM_FIELD" | translate }} 2</label>
            <div class="form-group">
              <mat-form-field>
                <input
                  matInput
                  [(ngModel)]="customFieldNames.customFieldName2"
                />
              </mat-form-field>
            </div>
          </div>
          <div>
            <label>{{ "SETTINGS.CUSTOM_FIELD" | translate }} 3</label>
            <div class="form-group">
              <mat-form-field>
                <input
                  matInput
                  [(ngModel)]="customFieldNames.customFieldName3"
                />
              </mat-form-field>
            </div>
          </div>
          <div>
            <label>{{ "SETTINGS.CUSTOM_FIELD" | translate }} 4</label>
            <div class="form-group">
              <mat-form-field>
                <input
                  matInput
                  [(ngModel)]="customFieldNames.customFieldName4"
                />
              </mat-form-field>
            </div>
          </div>
          <div>
            <label>{{ "SETTINGS.CUSTOM_FIELD" | translate }} 5</label>
            <div class="form-group">
              <mat-form-field>
                <input
                  matInput
                  [(ngModel)]="customFieldNames.customFieldName5"
                />
              </mat-form-field>
            </div>
          </div>
          <div>
            <label>{{ "SETTINGS.CUSTOM_FIELD" | translate }} 6</label>
            <div class="form-group">
              <mat-form-field>
                <input
                  matInput
                  [(ngModel)]="customFieldNames.customFieldName6"
                />
              </mat-form-field>
            </div>
          </div>
          <div>
            <label>{{ "SETTINGS.CUSTOM_FIELD" | translate }} 7</label>
            <div class="form-group">
              <mat-form-field>
                <input
                  matInput
                  [(ngModel)]="customFieldNames.customFieldName7"
                />
              </mat-form-field>
            </div>
          </div>
          <button
            type="submit"
            class="btn btn-primary"
            (click)="saveCustomFields()"
          >
            {{ "GENERIC.SAVE" | translate }}
          </button>
        </div>
      </app-generic-card>
    </div>
    <!--<div class="column col-8">
      <app-generic-card>
        <app-actionbar title="Email Templates"></app-actionbar>
        <app-datatable
          #dtEmailTemplates
          tableIdentifier="emailTemplates"
          [data]="dataEmailTemplates"
          [columns]="columnsEmailTemplates"
          [actions]="actionsEmailTemplates"
        ></app-datatable>
      </app-generic-card>
    </div>-->
  </div>
</div>
<router-outlet #outlet="outlet"></router-outlet>
<app-modal
  #modalUpdateToken
  title="GENERIC.CONFIRM_UPDATE"
  bodyContent="{{ 'SETTINGS.ARE_YOU_SURE_SECRET' | translate }}"
  [confirmCallback]="updateToken"
></app-modal>
<app-modal #modalUploadLogo title="SETTINGS.UPDATE_LOGO">
  <span>
    {{ "SETTINGS.LOGO_UPLOAD_RECOMMENDED_1" | translate }} <br />{{
      "SETTINGS.LOGO_UPLOAD_RECOMMENDED_2" | translate
    }}
  </span>
  <div class="upload-file-section">
    <div
      class="drop-container"
      ngFileDrop
      [options]="options"
      (uploadOutput)="onUploadOutput($event, 'logo')"
      [uploadInput]="uploadInput"
      [ngClass]="{ 'is-drop-over': uploadFileStatus === 'dragOver' }"
    >
      <h1 *ngIf="uploadFileStatus === ''">
        {{ "GENERIC.DRAG_AND_DROP" | translate }}
      </h1>
      <h1 *ngIf="uploadFileStatus === 'dragOver'">
        {{ "GENERIC.DROP_NOW" | translate }}
      </h1>
      <h1 *ngIf="uploadFileStatus === 'uploading'">
        {{ "GENERIC.UPLOADING" | translate }}
      </h1>
    </div>

    <label
      class="upload-button"
      [ngClass]="{ 'uploading-now': uploadFileStatus === 'uploading' }"
    >
      <input
        type="file"
        ngFileSelect
        [options]="options"
        (uploadOutput)="onUploadOutput($event, 'logo')"
        [uploadInput]="uploadInput"
      />
      {{ "GENERIC.OR_CHOOSE_FILE" | translate }}
    </label>
  </div>
</app-modal>

<app-modal #modalUploadFavicon title="SETTINGS.UPDATE_FAVICON">
  <span>
    {{ "SETTINGS.FAVICON_UPLOAD_RECOMMENDED_1" | translate }}
  </span>
  <div class="upload-file-section">
    <div
      class="drop-container"
      ngFileDrop
      [options]="options"
      (uploadOutput)="onUploadOutput($event, 'favicon')"
      [uploadInput]="uploadInput"
      [ngClass]="{ 'is-drop-over': uploadFileStatus === 'dragOver' }"
    >
      <h1 *ngIf="uploadFileStatus === ''">
        {{ "GENERIC.DRAG_AND_DROP" | translate }}
      </h1>
      <h1 *ngIf="uploadFileStatus === 'dragOver'">
        {{ "GENERIC.DROP_NOW" | translate }}
      </h1>
      <h1 *ngIf="uploadFileStatus === 'uploading'">
        {{ "GENERIC.UPLOADING" | translate }}
      </h1>
    </div>

    <label
      class="upload-button"
      [ngClass]="{ 'uploading-now': uploadFileStatus === 'uploading' }"
    >
      <input
        type="file"
        ngFileSelect
        [options]="options"
        (uploadOutput)="onUploadOutput($event, 'favicon')"
        [uploadInput]="uploadInput"
      />
      {{ "GENERIC.OR_CHOOSE_FILE" | translate }}
    </label>
  </div>
</app-modal>
