import {
  Component,
  Input,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import { DatalistActionItem } from "../../models/datalist-action-item.interface";
import { Router } from "@angular/router";
import { LocalStorageService } from "../../services/local-storage.service";
import { TranslateService } from "@ngx-translate/core";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { version as versionApp } from "../../../../../../package.json";

@Component({
  selector: "app-datatable",
  templateUrl: "./datatable.component.html",
  styleUrls: ["./datatable.component.scss"],
})
export class DatatableComponent implements OnInit, OnChanges {
  rows = [];
  columnList = [];
  columnMapping = [];
  loadingIndicator = true;
  isEditable = {};
  editedItemOriginalData: any[] = [];
  showColumnSettings: boolean = false;
  sorts = [];

  ColumnMode = ColumnMode;

  @Input() enableExternalSorting: boolean = true;
  @Input() enableSearch: boolean = false;
  @Input() columns: [];
  @Input() tableIdentifier: string = null;
  @Input() data: [];
  @Input() actions: DatalistActionItem[];
  @Input() buttonTitle;
  @Input() placeholder;
  @Input() disabled;
  @Output() search: EventEmitter<any> = new EventEmitter<any>();
  public searchText: string;
  @Output() callbackOnUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() openCustomFieldsCallback: EventEmitter<any> = new EventEmitter<
    any
  >();
  @Output() callbackEmit = new EventEmitter();
  @Output() sortEvent = new EventEmitter();

  constructor(
    public router: Router,
    public localStorage: LocalStorageService,
    public translate: TranslateService
  ) {}

  version = versionApp;

  ngOnInit() {
    this.rows = this.data;
    console.log(this.actions);
    this.setupColumns();
    this.setupSort();
    console.log(this.enableExternalSorting);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.columnMapping,
      event.previousIndex,
      event.currentIndex
    );
    this.columnList = this.columnMapping.filter((c) => !c.disabled);
    this.localStorage.set(
      "tablecolumns_" + this.version + this.tableIdentifier,
      JSON.stringify(this.columnMapping)
    );
  }

  searcher(ev) {
    this.search.emit(ev);
  }

  public noopComparator(): number {
    return 0;
  }

  ngOnChanges(changes) {
    this.setupColumns();
  }

  toggleColumnSettings() {
    this.showColumnSettings = !this.showColumnSettings;
  }

  setupColumns() {
    let columnMapping;
    if (!this.tableIdentifier) {
      this.columnList = this.columns;
      this.columnMapping = this.columns;
    }
    if (!this.columns) {
      return;
    }
    let columnMappingString = this.localStorage.get(
      "tablecolumns_" + this.version + this.tableIdentifier
    );
    let oldColumns: any = this.columns;
    if (!columnMappingString) {
      columnMapping = this.columns;
      this.localStorage.set(
        "tablecolumns_" + this.version + this.tableIdentifier,
        JSON.stringify(columnMapping)
      );
    } else {
      columnMapping = JSON.parse(columnMappingString);
    }
    console.log(
      "SETUP COLUMN MAPPING",
      columnMapping,
      oldColumns,
      this.columns
    );
    columnMapping = columnMapping.map((m) => {
      return {
        ...m,
        name: oldColumns.find((f: any) => f.prop === m.prop)?.name,
        width: oldColumns.find((f: any) => f.prop === m.prop)?.width,
      };
    });
    this.columnList = columnMapping.filter((c) => !c.disabled);
    this.columnMapping = columnMapping;
  }

  toggleColumn(col) {
    let columnMappingString = this.localStorage.get(
      "tablecolumns_" + this.version + this.tableIdentifier
    );
    let columnMapping = JSON.parse(columnMappingString);
    columnMapping.forEach((c) => {
      if (c.name == col.name) {
        if (c.disabled) {
          c.disabled = false;
        } else {
          c.disabled = true;
        }
      }
    });
    this.columnMapping = columnMapping;
    this.columnList = columnMapping.filter((c) => !c.disabled);
    this.localStorage.set(
      "tablecolumns_" + this.version + this.tableIdentifier,
      JSON.stringify(columnMapping)
    );
  }

  setupSort() {
    let sort = this.localStorage.get(
      "tablesort_" + this.version + this.tableIdentifier
    );
    console.log("SETTING UP SORT for " + this.tableIdentifier + " : " + sort);
    if (sort) {
      this.sortEvent.emit({ sorts: JSON.parse(sort) });
      this.sorts = JSON.parse(sort);
    }
  }

  onSort(event) {
    this.sortEvent.emit(event);
    console.log("SETTING SORT LOCALSTORAGE", event.sorts);
    this.localStorage.set(
      "tablesort_" + this.version + this.tableIdentifier,
      JSON.stringify(event.sorts)
    );
    this.sorts = event.sorts;
  }

  onEdit(index: number): void {
    this.isEditable[index] = true;
    this.editedItemOriginalData[index] = { ...this.rows[index] };
  }

  onCancelEdit(index: number): void {
    this.isEditable[index] = false;
    this.rows[index] = this.editedItemOriginalData[index];
    this.rows = [...this.rows];
  }

  loaderVisibility(value: boolean) {
    this.loadingIndicator = value;
  }

  onCustomFields(val) {
    this.openCustomFieldsCallback.emit(val);
  }

  updateRows(data) {
    this.rows = data;
  }

  onActionClick(item: DatalistActionItem, id) {
    if (item.route) {
      this.router.navigate([item.route]);
    } else if (item.callback) {
      this.callbackEmit.emit(item.callback(id));
    }
  }

  saveData(row, rowIndex) {
    this.callbackOnUpdate.emit(row);
    this.isEditable[rowIndex] = false;
  }
}
