import { BaseApiService } from "../base/base-api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from "../custom-error-handler.service";
import { HitlTask } from "../../../models/hitl-tasks/hitl-task.model";
import { HitlTaskSerializer } from "../../../models/hitl-tasks/hitl-task-serializer.class";
import { Observable } from "rxjs";

/**
 * Service to communicate with API Client Users
 */

@Injectable()
export class HitlTasksService extends BaseApiService<HitlTask> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      null,
      "hitltask",
      new HitlTaskSerializer(),
      new CustomErrorHandlerService()
    );
  }
}
