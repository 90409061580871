import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { SideNavItem } from "../../models/sidenav-item.interface";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-sidenav-item",
  templateUrl: "./sidenav-item.component.html",
  styleUrls: ["./sidenav-item.component.scss"],
  animations: [
    trigger("indicatorRotate", [
      state("collapsed", style({ transform: "rotate(0deg)" })),
      state("expanded", style({ transform: "rotate(180deg)" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4,0.0,0.2,1)")
      ),
    ]),
  ],
})
export class SideNavItemComponent implements OnInit {
  expanded: boolean;

  @Input() item: SideNavItem;
  @Input() depth: number;

  constructor(public router: Router, private activatedRoute: ActivatedRoute) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
    // this.navService.currentUrl.subscribe((url: string) => {
    //   if (this.item.route && url) {
    //     this.expanded = url.indexOf(`/${this.item.route}`) === 0;
    //     this.ariaExpanded = this.expanded;
    //   }
    // });
  }

  isItemActive(item): boolean {
    if (item.route && this.router.isActive(item.route, true)) {
      return true;
    } else if (item.route && window.location.href.indexOf(item.route) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  onItemSelected(item: SideNavItem) {
    if (item.route && item.route.includes("http")) {
      window.open(item.route, "_blank");
    } else {
      if (!item.children || !item.children.length) {
        this.router.navigate([item.route]);
      }
      if (item.children && item.children.length) {
        this.expanded = !this.expanded;
      }
    }
  }
}
