import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { AuthService } from "./auth.service";
import { Observable, throwError } from "rxjs";
import { filter, mergeMap, catchError } from "rxjs/operators";
import { CustomErrorHandlerService } from "../api/custom-error-handler.service";
import * as jwt_decode from "jwt-decode";
import { environment } from "../../../environments/environment";

@Injectable()
export class UserInterceptorService implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private errorHandler: CustomErrorHandlerService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // @NOTE: If you have some endpoints that are public
    // and do not need Authorization header, implement logic
    // here to accommodate that and conditionally let public
    // requests pass through based on your requirements
    return this.auth.userProfile$.pipe(
      mergeMap((prof) => {
        if (req.url.includes(".s3.")) {
          return next.handle(req);
        }
        const tenantId = prof.currentTenant.tenant_id;
        let tokenReq;
        if (tenantId) {
          tokenReq = req.clone({
            setHeaders: {
              tenant_id: tenantId,
            },
          });
        } else {
          tokenReq = req.clone();
        }
        return next.handle(tokenReq);
      }),
      catchError((error) => throwError(this.errorHandler.tryParseError(error)))
    );
  }
}
