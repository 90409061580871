import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  EventEmitter,
} from "@angular/core";
import {
  APIService,
  AuthService,
  Client,
  DatatableComponent,
  ClientEmailTemplate,
  DatalistActionItem,
  KeyValuePair,
  NotificationService,
  ModalComponent,
} from "@fourcomply-dashboard/shared";
import { NavigationEnd, Router, RoutesRecognized } from "@angular/router";
import { templateJitUrl } from "@angular/compiler";
import { Subscription } from "rxjs";
import { filter, pairwise } from "rxjs/operators";
import { EditClientEmailTemplateComponent } from "../edit-client-email-template/edit-client-email-template.component";

@Component({
  selector: "app-client-email-template", //'fourcomply-dashboard-client-email-template',
  templateUrl: "./client-email-template.component.html",
  styleUrls: ["./client-email-template.component.css"],
})
export class ClientEmailTemplateComponent implements OnInit {
  @ViewChild("dtEmailTemplates")
  dtEmailTemplates: DatatableComponent;

  @ViewChild("modalEmailTemplatePreview")
  modalEmailTemplatePreview: ModalComponent;

  dataEmailTemplates: ClientEmailTemplate[];
  columnsEmailTemplates: any[];
  actionsEmailTemplates: DatalistActionItem[];
  templateForPreview: any = null;

  navigationSubscription: Subscription;

  constructor(private api: APIService, private router: Router) {
    this.navigationSubscription = this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        if (events[0].urlAfterRedirects.includes("/email-templates/")) {
          this.fetchEmailTemplates();
        }
      });
  }

  ngOnInit() {
    this.goToEmailTemplateEdit = this.goToEmailTemplateEdit.bind(this);
    this.previewEmailTemplate = this.previewEmailTemplate.bind(this);
    this.fetchEmailTemplates();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  previewEmailTemplate(id) {
    console.log(id);
    console.log(this.dataEmailTemplates);
    this.templateForPreview = this.dataEmailTemplates.find((t) => t.id === id);
    this.modalEmailTemplatePreview.openModal();
  }

  fetchEmailTemplates() {
    this.actionsEmailTemplates = [
      {
        iconClass: "fas fa-pen",
        toolTipText: "GENERIC.CUSTOMIZE",
        callback: this.goToEmailTemplateEdit,
      },
      {
        iconClass: "fas fa-link",
        toolTipText: "GENERIC.PREVIEW",
        callback: this.previewEmailTemplate,
      },
    ];

    this.columnsEmailTemplates = [
      { name: "GENERIC.TYPE", prop: "type", sortable: false },
      { name: "GENERIC.EMAIL_SUBJECT", prop: "subject", sortable: false },
      { name: "GENERIC.ACTIONS", prop: "actions", sortable: false },
    ];

    this.api.clientEmailTemplates.list().subscribe((a) => {
      this.dataEmailTemplates = a;
      this.dtEmailTemplates.updateRows(this.dataEmailTemplates);
      this.dtEmailTemplates.loaderVisibility(false);
    });
  }

  goToEmailTemplateEdit(id) {
    this.router.navigate(["/email-templates", id]);
  }
}
