import { Component, OnInit, ViewChild } from "@angular/core";
import {
  ForgottenUser,
  APIService,
  DatatableComponent,
  ActionBarItem,
  LocalStorageService,
} from "@fourcomply-dashboard/shared";
import { QueryOptions } from "libs/shared/src/lib/models/query-options/query-options.class";

@Component({
  selector: "fourcomply-dashboard-forgotten-users",
  templateUrl: "./forgotten-users.component.html",
  styleUrls: ["./forgotten-users.component.scss"],
})
export class ForgottenUsersComponent implements OnInit {
  @ViewChild("dtForgottenUsers", { static: true })
  dtForgottenUsers: DatatableComponent;
  dataForgottenUsers: ForgottenUser[];
  columnsForgottenUsers: any[];
  pages: number;
  sort: string;
  isEstimate: boolean = false;
  searchText: string;
  currentPage: number = 1;
  total: number;
  pageSize: number = 10;
  actionBarItemsForgottenUsers: ActionBarItem[];

  constructor(
    private api: APIService,
    private localStorage: LocalStorageService
  ) {}

  ngOnInit() {
    this.getStoredPageSize();
    this.fetchForgottenUsers();
  }

  getStoredPageSize() {
    this.pageSize = this.localStorage.get("LIST_PAGE_SIZE")
      ? Number(this.localStorage.get("LIST_PAGE_SIZE"))
      : 10;
  }

  setCurrentPage(ev) {
    this.dtForgottenUsers.loaderVisibility(true);
    this.currentPage = ev;
    this.fetchForgottenUsers();
  }

  setPageSize(ev) {
    this.dtForgottenUsers.loaderVisibility(true);
    this.pageSize = ev;
    this.localStorage.set("LIST_PAGE_SIZE", this.pageSize.toString());
    this.currentPage = 1;
    this.fetchForgottenUsers();
  }

  onSort(ev) {
    if (!ev.sorts || !ev.sorts.length) {
      return;
    }
    this.sort =
      (ev.sorts[0].dir == "desc" ? "-" : "") +
      this.sortMapper(ev.sorts[0].prop);
    this.fetchForgottenUsers();
  }

  sortMapper(field) {
    switch (field) {
      case "createdAt":
        return "created_at";
      case "email":
        return "email_address";
      case "permissionCategory":
        return "permission_category";
      case "processingPurpose":
        return "processing_purpose";
      case "permissionType":
        return "permission_type";
      case "standardCountry":
        return "standard_country";
      case "standardState":
        return "standard_state";
      case "systemId":
        return "system_id";
      case "system":
        return "system";
      case "systemType":
        return "system_type";
      case "activityId":
        return "activity_id";
      default:
        return field;
    }
  }

  fetchForgottenUsers() {
    this.actionBarItemsForgottenUsers = [];

    this.columnsForgottenUsers = [
      { name: "GENERIC.EMAIL_ADDRESS", prop: "emailAddress" },
      { name: "FORGOTTEN_VAULT.SYSTEM_ID_FIELD", prop: "systemId" },
      { name: "FORGOTTEN_VAULT.SYSTEM_TYPE_FIELD", prop: "systemType" },
      { name: "BASIC_ENTITIES_SINGULAR.SYSTEM", prop: "system" },
      { name: "GEO.STATE", prop: "standardState" },
      { name: "GEO.COUNTRY", prop: "standardCountry" },
      { name: "FORGOTTEN_VAULT.ACTIVITY_REASON", prop: "activityId" },
      { name: "FORGOTTEN_VAULT.FORGOTTEN_AT_FIELD", prop: "expiration" },
    ];

    let qo = new QueryOptions();
    qo.page = this.currentPage;
    qo.pageSize = this.pageSize;
    qo.sorts = this.sort;

    this.api.forgottenUsers.listWithPaging(qo).subscribe((data) => {
      this.dataForgottenUsers = data.list;
      this.pages = data.pages;
      this.currentPage = data.currentpage;
      this.total =
        this.searchText || Number(data.total) < 1000
          ? Number(data.total)
          : data.totalunfiltered
          ? Number(data.totalunfiltered)
          : Number(data.total);
      this.isEstimate =
        this.searchText || Number(data.total) < 1000 ? false : true;
      this.pageSize = data.pagesize;
      this.dtForgottenUsers.updateRows(this.dataForgottenUsers);
      this.dtForgottenUsers.loaderVisibility(false);
    });
  }
}
