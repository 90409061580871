import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { map, tap, catchError } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Serializer } from "../../../models/resource/serializer.interface";
import { QueryOptions } from "../../../models/query-options/query-options.class";
import { Resource } from "../../../models/resource/resource.model";
import { CustomErrorHandlerService } from "../custom-error-handler.service";

/**
 * This Service provides a base for all other API services
 */

@Injectable()
export class BaseApiService<T extends Resource> {
  constructor(
    protected httpClient: HttpClient,
    public url: string, // Base Url that can be overriden
    private endpoint: string,
    public serializer: Serializer,
    public errorHandler: CustomErrorHandlerService,
    private alternateSerializer?: Serializer
  ) {
    // this.token = myToken;
    this.url = environment.api.API_ENDPOINT;
  }

  /**
   * Create - Base Function
   * @param item
   */
  public create(item: T): Observable<T> {
    return this.httpClient
      .post<T>(`${this.url}/${this.endpoint}`, this.serializer.toJson(item))
      .pipe(
        map(data => this.serializer.fromJson(data) as T),
        catchError(error => throwError(this.errorHandler.tryParseError(error)))
      );
  }

  /**
   * Update - Base Function
   * @param item
   */
  public update(item: T): Observable<T> {
    return this.httpClient
      .put<T>(
        `${this.url}/${this.endpoint}/${item.id}`,
        this.serializer.toJson(item)
      )
      .pipe(
        map(data => this.serializer.fromJson(data) as T),
        catchError(error => throwError(this.errorHandler.tryParseError(error)))
      );
  }

  /**
   * Get - Base Function
   * @param id
   */
  get(id: any): Observable<T> {
    return this.httpClient.get(`${this.url}/${this.endpoint}/${id}`).pipe(
      map((data: any) => this.serializer.fromJson(data) as T),
      catchError(error => throwError(this.errorHandler.tryParseError(error)))
    );
  }

  /**
   * List - Base Function
   * @param queryOptions : optional
   */
  list(queryOptions?: QueryOptions): Observable<T[]> {
    return this.httpClient
      .get(this.formatUrl(this.url, this.endpoint, queryOptions))
      .pipe(
        map(a => this.convertData(a)),
        catchError(error => throwError(this.errorHandler.tryParseError(error)))
      );
  }

  /**
   * List with Paging Functionality - different return type
   * @param queryOptions
   */
  listWithPaging(queryOptions?: QueryOptions): Observable<any> {
    return this.httpClient
      .get(this.formatUrl(this.url, this.endpoint, queryOptions), {
        observe: "response"
      })
      .pipe(
        map((resp: any) => {
          const base = {
            list: this.convertData(resp.body),
            total: resp.headers.get("total"),
            totalunfiltered: resp.headers.get("totalunfiltered"),
            pages: resp.headers.get("pages"),
            pagesize: resp.headers.get("pagesize"),
            currentpage: resp.headers.get("currentpage")
          };
          return base;
        }),
        catchError(error => throwError(this.errorHandler.tryParseError(error)))
      );
  }

  /**
   * Delete - Base Function
   * @param id
   */
  delete(id: any) {
    return this.httpClient.delete(`${this.url}/${this.endpoint}/${id}`);
  }

  protected convertData(data: any): T[] {
    return data.map(item => this.serializer.fromJson(item));
  }

  protected convertDataAlternate(data: any): T[] {
    return data.map(item => this.alternateSerializer.fromJson(item));
  }

  public formatUrl(path, endpoint, queryOptions: QueryOptions) {
    const p = `${path}/${endpoint}${
      queryOptions ? queryOptions.toQueryString() : ""
    }`;
    return p;
  }

  public handleError(err) {
    if (err.error_description) {
      throwError(err.error_description);
    } else {
      throwError(err);
    }
  }
}
