import { BaseApiService } from "../base/base-api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from "../custom-error-handler.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RightRequestConfig } from "../../../models/right-request-configs/right-request-config.model";
import { RightRequestConfigMap } from "../../../models/right-request-config-maps/right-request-config-map.model";
import { RightRequestConfigSerializer } from "../../../models/right-request-configs/right-request-config-serializer.class";
import { RightRequestConfigMapSerializer } from "../../../models/right-request-config-maps/right-request-config-map-serializer.class";

/**
 * Service to communicate with API Client Users
 */

@Injectable()
export class RightRequestConfigsService extends BaseApiService<
  RightRequestConfig
> {
  public alternateConverter;
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      null,
      "rightsrequestconfig",
      new RightRequestConfigSerializer(),
      new CustomErrorHandlerService(),
      new RightRequestConfigMapSerializer()
    );
  }

  // EXTEND/OVERRIDE THE SERVICE HERE IF NECESSARY

  getByRegulationId(regulationId: string): Observable<RightRequestConfig[]> {
    return this.httpClient
      .get(`${this.url}/rightsrequestconfig/regulation/${regulationId}`)
      .pipe(map((data: any) => this.convertData(data)));
  }

  getCompletionConfigs(): Observable<any[]> {
    return this.httpClient
      .get(`${this.url}/rightsrequestconfig/completionconfigs`)
      .pipe(map((data: any) => data));
  }

  getAllPossible(): Observable<RightRequestConfigMap[]> {
    return this.httpClient
      .get(`${this.url}/rightsrequestconfig/allpossible`)
      .pipe(map((data: any) => this.convertDataAlternate(data)));
  }
}
