import { Component, OnInit, ViewChild, OnChanges } from "@angular/core";
import {
  DatatableComponent,
  ClientForm,
  DatalistActionItem,
  APIService,
  NotificationService,
  ActionBarItem,
} from "@fourcomply-dashboard/shared";
import { Router } from "@angular/router";
import { environment } from "apps/fourcomply-dashboard/src/environments/environment";
import { ClipboardService } from "ngx-clipboard";

@Component({
  selector: "app-client-forms",
  templateUrl: "./client-forms.component.html",
  styleUrls: ["./client-forms.component.scss"],
})
export class ClientFormsComponent implements OnInit {
  @ViewChild("dtClientForms", { static: true })
  dtClientForms: DatatableComponent;

  dataClientForms: any[];
  columnsClientForms: any[];
  actionsClientForms: DatalistActionItem[];
  actionBarItemsForms: ActionBarItem[];

  constructor(
    private api: APIService,
    private router: Router,
    private _clipboardService: ClipboardService,
    private notification: NotificationService
  ) {}

  ngOnInit() {
    this.routeToEdit = this.routeToEdit.bind(this);
    this.copyEmbed = this.copyEmbed.bind(this);
    this.fetchClientForms();
  }

  onDeactivate(event): void {
    this.fetchClientForms();
  }

  fetchClientForms() {
    this.actionBarItemsForms = [
      {
        btnText: "GENERIC.ADD_NEW",
        iconClass: "fas fa-plus",
        route: "forms/add",
      },
    ];

    this.actionsClientForms = [
      {
        iconClass: "fas fa-cog",
        toolTipText: "GENERIC.CUSTOMIZE",
        callback: this.routeToEdit,
      },
      {
        iconClass: "fas fa-box",
        toolTipText: "GENERIC.COPY_EMBED",
        callback: this.copyEmbed,
      },
    ];

    this.columnsClientForms = [
      { name: "GENERIC.NAME", prop: "type" },
      { name: "CLIENT_FORM.TITLE", prop: "title" },
      { name: "CLIENT_FORM.SUBHEADER", prop: "subHeader" },
      { name: "GENERIC.DESCRIPTION", prop: "description" },
      { name: "CLIENT_FORM.LINK", prop: "link", type: "link" },
      { name: "CLIENT_FORM.SUBMIT_ACTION", prop: "submitAction" },
      { name: "GENERIC.ACTIONS", prop: "actions" },
    ];

    this.api.clientForms.list().subscribe((a: any) => {
      this.dataClientForms = a.map((form) => {
        form.link = environment.auth.FORMS_URL + "/forms/" + form.id;
        return form;
      });
      if (!this.dtClientForms.updateRows) {
        return;
      }
      this.dtClientForms.updateRows(this.dataClientForms);
      this.dtClientForms.loaderVisibility(false);
    });
  }

  copyEmbed(id) {
    let embedString = "";
    let form = this.dataClientForms.filter((a) => a.id == id)[0];
    embedString =
      `<iframe width="800" height="800" src="` +
      form.link +
      `" frameborder="0" allowfullscreen></iframe>`;
    this._clipboardService.copyFromContent(embedString);
    this.notification.success("CLIENT_FORM.IFRAME_SNIPPET_MESSAGE");
  }

  routeToEdit(id) {
    this.router.navigate(["/forms", id]);
  }
}
