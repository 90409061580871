import { Component, OnInit, ViewChild } from "@angular/core";
import { FormlyFieldConfig } from "@ngx-formly/core";
import {
  NotificationService,
  APIService,
  FormComponent,
  ModalComponent,
  DatalistActionItem,
  DraggableTableComponent,
} from "@fourcomply-dashboard/shared";
import { ActivatedRoute, Router } from "@angular/router";
import { BreadcrumbService } from "xng-breadcrumb";

@Component({
  selector: "app-client-form-edit",
  templateUrl: "./client-form-edit.component.html",
  styleUrls: ["./client-form-edit.component.scss"],
})
export class ClientFormEditComponent implements OnInit {
  @ViewChild("formClient", { static: true })
  formClient: FormComponent;
  @ViewChild("formFieldClient", { static: true })
  formFieldClient: FormComponent;

  @ViewChild("modalClient", { static: true })
  public modalClient: ModalComponent;
  @ViewChild("modalDeleteFormField", { static: true })
  public modalDeleteFormField: ModalComponent;

  @ViewChild("dtClientFormFields", { static: true })
  dtClientFormFields: DraggableTableComponent;

  actualData: any = {
    fields: [],
    submitAction: null,
    description: null,
    subHeader: null,
    title: null,
    type: null,
    tenantId: null,
    id: null,
    formattedType: null,
  };
  dataClientFormFields: any;
  columnsClientFormFields: any[];
  fieldValues: any[];
  additionalButtons: any[] = [];
  actionsClientFormFields: DatalistActionItem[];
  formFieldIdToDelete: number;
  loadingDone: boolean = false;

  fields: FormlyFieldConfig[] = [];
  formSubmitted = false;
  model: any = {};
  modalTitle: string;

  fieldsFormFields: FormlyFieldConfig[] = [];
  modelFormFields: any = {};
  isEdit = false;
  typeMapping = {
    select: "Dropdown list",
    input: "Input",
    text: "Input",
    radio: "Radio Button",
    longtext: "Long Text",
    hidden: "Value (hidden)",
    geoSelect: "Geo Select",
    hidden_dependency: "Hidden Dependency Select",
  };

  constructor(
    public api: APIService,
    public router: Router,
    private route: ActivatedRoute,
    private notification: NotificationService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this.submitFormFields = this.submitFormFields.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.deleteClientFormField = this.deleteClientFormField.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.submitForms = this.submitForms.bind(this);

    this.initFormsForm();
    this.initFormFieldTable();
    this.initFormFieldModalForm();
  }

  initFormFieldModalForm() {
    this.additionalButtons = [];
    this.fieldsFormFields = [
      {
        key: "type",
        type: "select",
        templateOptions: {
          required: true,
          label: "CLIENT_FORM.INPUT_TYPE",
          change: (val) => {
            if (
              val.key === "type" &&
              (this.modelFormFields[val.key] === "select" ||
                this.modelFormFields[val.key] === "hidden_dependency")
            ) {
              let newestOption = 0;
              this.fieldsFormFields.push({
                key: "option_" + newestOption + "_name",
                type: "input",
                templateOptions: {
                  label: "Option " + (newestOption + 1) + " Name",
                },
              });
              this.fieldsFormFields.push({
                key: "option_" + newestOption + "_value",
                type: "input",
                templateOptions: {
                  label: "Option " + (newestOption + 1) + " Value",
                },
              });
              this.modelFormFields = {
                ...this.modelFormFields,
                ["option_" + newestOption + "_name"]: "",
                ["option_" + newestOption + "_value"]: "",
              };
              this.additionalButtons = [
                {
                  name: "CLIENT_FORM.ADD_DROPDOWN_VALUE",
                  action: this.addDropdownOption.bind(this),
                },
                {
                  name: "CLIENT_FORM.REMOVE_DROPDOWN_VALUE",
                  action: this.removeDropdownOption.bind(this),
                },
              ];
              this.formFieldClient.updateFields(this.fieldsFormFields);
              this.formFieldClient.updateModel(this.modelFormFields);
            } else {
              // remove all additional option fields
              this.initFormFieldModalForm();
            }
          },
          options: [
            { value: "text", label: "Text Input" },
            { value: "select", label: "Drop down list" },
            { value: "radio", label: "Radio button" },
            { value: "longtext", label: "Long text" },
            { value: "geoSelect", label: "Geo Select" },
            { value: "hidden", label: "Value (hidden)" },
            { value: "hidden_dependency", label: "Hidden Dependency Select" },
          ],
        },
      },
      {
        key: "name",
        type: "input",
        templateOptions: {
          required: true,
          label: "GENERIC.NAME",
        },
      },
      {
        key: "identifier",
        type: "input",
        templateOptions: {
          required: true,
          label: "GENERIC.IDENTIFIER",
        },
      },
      {
        key: "description",
        type: "input",
        templateOptions: {
          required: true,
          label: "GENERIC.DESCRIPTION",
        },
      },
      {
        key: "validation",
        type: "select",
        templateOptions: {
          label: "GENERIC.VALIDATION",
          options: [
            { value: null, label: "GENERIC.NONE" },
            { value: "ip", label: "GEO.IP_RANGE" },
            { value: "email", label: "GENERIC.EMAIL_ADDRESS" },
          ],
        },
      },
    ];
    this.formFieldClient.updateFields(this.fieldsFormFields);
  }

  initFormFieldTable() {
    this.actionsClientFormFields = [
      {
        iconClass: "fas fa-cog",
        toolTipText: "GENERIC.CUSTOMIZE",
        callback: this.openEditModal,
      },
      {
        iconClass: "fas fa-trash",
        toolTipText: "GENERIC.DELETE",
        callback: this.openDeleteModal,
      },
    ];

    this.columnsClientFormFields = [
      { name: "GENERIC.TYPE", prop: "formattedType", sortable: true },
      { name: "GENERIC.NAME", prop: "name", sortable: true },
      { name: "GENERIC.DESCRIPTION", prop: "description", sortable: true },
      { name: "GENERIC.IDENTIFIER", prop: "identifier", sortable: true },
      { name: "GENERIC.VALIDATION", prop: "validation", sortable: true },
      { name: "GENERIC.ORDER", prop: "order", sortable: true },
      { name: "GENERIC.ACTIONS", prop: "actions", sortable: true },
    ];
    let sorts = [{ dir: "asc", prop: "order" }];
    this.dtClientFormFields.sorts = sorts;
    this.dtClientFormFields.onColumnSort({ sorts });
    this.dtClientFormFields.updateRows([]);
  }

  initFormsForm() {
    this.fields = [
      {
        key: "type",
        type: "input",
        templateOptions: {
          required: true,
          label: "CLIENT_FORM.FORM_NAME",
        },
      },
      {
        key: "title",
        type: "input",
        templateOptions: {
          required: true,
          label: "CLIENT_FORM.TITLE",
        },
      },
      {
        key: "subHeader",
        type: "input",
        templateOptions: {
          required: false,
          label: "CLIENT_FORM.SUBHEADER",
        },
      },
      {
        key: "description",
        type: "input",
        templateOptions: {
          required: false,
          label: "GENERIC.DESCRIPTION",
        },
      },
      {
        key: "submitAction",
        type: "input",
        defaultValue: "POST /rightsrequests",
        templateOptions: {
          required: true,
          label: "CLIENT_FORM.SUBMIT_ACTION",
        },
      },
    ];
    const formId = this.route.snapshot.params["id"];
    if (formId) {
      this.isEdit = true;
      this.api.clientForms.get(formId).subscribe((a) => {
        this.actualData = a;
        this.model = { ...a };
        this.dtClientFormFields.reset();
        this.formFieldClient.resetForm();
        this.formClient.updateModel(this.model);
        this.fetchClientFormFieldsTable(this.model.fields);
        this.breadcrumbService.set("@formTitle", this.actualData.title);
      });
    }
  }

  submitForms() {
    this.actualData.title = this.model.title;
    this.actualData.submitAction = this.model.submitAction;
    this.actualData.description = this.model.description;
    this.actualData.subHeader = this.model.subHeader;
    this.actualData.type = this.model.type;
    if (this.isEdit) {
      this.updateActualData("CLIENT_FORM.FORM_EDITED_SUCCESSFUL");
    } else {
      this.addNewData();
    }
  }

  fetchClientFormFieldsTable(fields) {
    if (!this.dtClientFormFields) {
      return;
    }
    this.dtClientFormFields.loaderVisibility(true);
    this.dataClientFormFields = fields;
    var count = 1;
    this.dataClientFormFields.forEach((a) => {
      a.id = count++;
      a.formattedType = this.typeMapping[a.type];
      return a;
    });
    this.dtClientFormFields.loaderVisibility(false);
    this.dtClientFormFields.ngOnInit();
    this.dtClientFormFields.updateRows(this.dataClientFormFields);
    let sorts = [{ dir: "asc", prop: "order" }];
    this.dtClientFormFields.sorts = sorts;
    this.dtClientFormFields.onColumnSort({ sorts });
  }

  addNewFormField() {
    this.modalTitle = "CLIENT_FORM.ADD_NEW_FORM_FIELD";
    this.initFormFieldModalForm();
    this.modelFormFields = {};
    this.formFieldClient.updateFields(this.fieldsFormFields);
    this.formFieldClient.updateModel(this.modelFormFields);
    this.modalClient.openModal();
  }

  openEditModal(id) {
    this.modalTitle = "CLIENT_FORM.EDIT_FORM_FIELD";
    this.initFormFieldModalForm();
    this.dataClientFormFields.forEach((a) => {
      if (a.id === id) {
        if (a.type === "select" || a.type === "hidden_dependency") {
          let map = a.options
            ? this.getFormFieldsAndValuesFromOptions(a.options)
            : { fields: [], values: {} };
          this.fieldsFormFields = this.fieldsFormFields.concat(map.fields);
          a = Object.assign(a, map.values);
          this.additionalButtons = [
            {
              name: "CLIENT_FORM.ADD_DROPDOWN_VALUE",
              action: this.addDropdownOption.bind(this),
            },
            {
              name: "CLIENT_FORM.REMOVE_DROPDOWN_VALUE",
              action: this.removeDropdownOption.bind(this),
            },
          ];
        }
        this.modelFormFields = Object.assign({}, a);
      }
    });
    this.formFieldClient.updateFields(this.fieldsFormFields);
    this.formFieldClient.updateModel(this.modelFormFields);
    this.modalClient.openModal();
  }

  removeDropdownOption() {
    // See how many there are already
    let newestOption = -1;
    if (!this.fieldsFormFields || !this.fieldsFormFields.length) {
      return;
    }
    this.fieldsFormFields.forEach((obj, ind) => {
      if (obj.key.toString().includes("option_")) {
        // It is an option
        // get the number of the index
        let index = ind;
        if (index > newestOption) {
          newestOption = ind;
        }
      }
    });
    let toDelete = [newestOption, newestOption - 1];
    delete this.modelFormFields[this.fieldsFormFields[newestOption].key as string];
    delete this.modelFormFields[this.fieldsFormFields[newestOption - 1].key as string];
    let newFields = [];
    this.fieldsFormFields.forEach((field, index) => {
      if (toDelete.includes(index)) {
        //
      } else {
        newFields.push(field);
      }
    });
    this.fieldsFormFields = newFields;
    this.formFieldClient.updateFields(this.fieldsFormFields);
    this.formFieldClient.updateModel(this.modelFormFields);
  }

  addDropdownOption() {
    // See how many there are already
    let newestOption = -1;
    if (!this.fieldsFormFields || !this.fieldsFormFields.length) {
      return;
    }
    this.fieldsFormFields.forEach((obj, ind) => {
      if (obj.key.toString().includes("option_")) {
        // It is an option
        // get the number of the index
        let pieces = obj.key.toString().split("_");
        let index = Number(pieces[1]);
        if (index > newestOption) {
          newestOption = index;
        }
      }
    });
    newestOption++;
    this.fieldsFormFields.push({
      key: "option_" + newestOption + "_name",
      type: "input",
      templateOptions: {
        label: "Option " + (newestOption + 1) + " Nickname",
      },
    });
    this.fieldsFormFields.push({
      key: "option_" + newestOption + "_value",
      type: "input",
      templateOptions: {
        label: "Option " + (newestOption + 1) + " Value",
      },
    });
    this.modelFormFields = {
      ...this.modelFormFields,
      ["option_" + newestOption + "_name"]: "",
      ["option_" + newestOption + "_value"]: "",
    };
    this.formFieldClient.updateFields(this.fieldsFormFields);
    this.formFieldClient.updateModel(this.modelFormFields);
  }

  closeModal() {
    this.actualData = null;
    this.loadingDone = true;
    this.formFieldClient.options.resetModel();
    this.initFormsForm();
    this.initFormFieldModalForm();
  }

  getFormFieldsAndValuesFromOptions(options) {
    let formfields = [];
    let values = {};
    options.forEach((o, index) => {
      formfields.push({
        key: "option_" + index + "_name",
        type: "input",
        templateOptions: {
          label: "Option " + (index + 1) + " Nickname",
        },
      });
      formfields.push({
        key: "option_" + index + "_value",
        type: "input",
        templateOptions: {
          label: "Option " + (index + 1) + " Value",
        },
      });
      values = {
        ...values,
        ["option_" + index + "_name"]: o.label,
        ["option_" + index + "_value"]: o.value,
      };
    });
    return { fields: formfields, values };
  }

  getActualDataFromFormField() {
    let toAddData = {};
    if (
      this.modelFormFields.type === "select" ||
      this.modelFormFields.type === "hidden_dependency"
    ) {
      // Check for new dropdown options
      let dropdownOptions = [];
      Object.keys(this.modelFormFields).forEach((key, index) => {
        if (key.toString().includes("option_") && key.toString().includes("_name")) {
          // Check if null
          if (
            !this.modelFormFields[key] ||
            !this.modelFormFields[key.replace("_name", "_value")] ||
            this.modelFormFields[key] == "" ||
            this.modelFormFields[key.replace("_name", "_value")] == ""
          ) {
            return;
          }
          // Its the first one
          dropdownOptions.push({
            label: this.modelFormFields[key],
            value: this.modelFormFields[key.replace("_name", "_value")],
          });
        } else if (!key.toString().includes("option_")) {
          toAddData[key] = this.modelFormFields[key];
        }
      });
      toAddData = { ...toAddData, options: dropdownOptions };
    } else {
      toAddData = this.modelFormFields;
    }
    return toAddData;
  }

  submitFormFields() {
    this.loadingDone = false;
    let totalObj = this.dataClientFormFields
      ? this.dataClientFormFields.length
      : 0;

    if (!this.modelFormFields.id) {
      this.modelFormFields.order = totalObj + 1;
      const toAddData = this.getActualDataFromFormField();
      this.actualData.fields.push(toAddData);
    } else {
      let index = 0;
      this.dataClientFormFields.forEach((a) => {
        if (a.id === this.modelFormFields.id) {
          const toAddData = this.getActualDataFromFormField();
          this.dataClientFormFields[index] = Object.assign({}, toAddData);
          this.fetchClientFormFieldsTable(this.dataClientFormFields);
          this.actualData.fields = Object.assign([], this.dataClientFormFields);
        }
        index++;
      });
    }
    this.formFieldClient.updateFields(this.fieldsFormFields);
    this.modelFormFields = {};
    this.updateActualData("CLIENT_FORM.FORM_EDITED_SUCCESSFUL");
  }

  openDeleteModal(id) {
    this.formFieldIdToDelete = id;
    this.modalDeleteFormField.openModal();
  }

  deleteClientFormField() {
    let index = 0;
    this.dataClientFormFields.forEach((a) => {
      if (a.id === this.formFieldIdToDelete) {
        this.actualData.fields.splice(index, 1);
        this.updateActualData("CLIENT_FORM.FORM_FIELD_DELETED_SUCCESSFUL");
      }
      index++;
    });
  }

  onDropFields(draggable) {
    if (draggable.dragIndex !== draggable.dropIndex) {
      let count = 1;
      this.actualData.fields.forEach((a) => {
        a.order = count++;
        return a;
      });
      this.dtClientFormFields.loaderVisibility(true);
      this.updateActualData("CLIENT_FORM.FORM_EDITED_SUCCESSFUL");
    }
  }

  addNewData() {
    this.api.clientForms.create(this.actualData).subscribe((a) => {
      this.actualData = a;
      this.notification.success("GENERIC.SUCCESSFUL_DATA_ADD");
      this.router.navigate(["/forms", a.id]);
    });
  }

  updateActualData(notificationMsg) {
    this.api.clientForms.update(this.actualData).subscribe((a) => {
      this.actualData = null;
      this.dtClientFormFields.loaderVisibility(false);
      this.formFieldClient.loaderVisibility(false);
      this.modalDeleteFormField.closeModal();
      this.modalClient.closeModal();
      this.loadingDone = true;
      this.notification.success(notificationMsg);
      this.initFormFieldTable();
      this.initFormFieldModalForm();
      this.initFormsForm();
    });
  }
}
