<div
  class="menu-list-item-divider"
  [ngClass]="{ first: item.first }"
  *ngIf="item.divider"
>
  {{ item.divider }}
</div>

<a
  mat-list-item
  *ngIf="!item.divider"
  (click)="onItemSelected(item)"
  [ngClass]="{
    active: isItemActive(item),
    expanded: expanded
  }"
  class="menu-list-item"
>
  <span class="routeIcon"><i class="fa {{ item.iconClass }}"></i></span>
  {{ item.displayName | translate}}
  <span *ngIf="item.children && item.children.length">
    <span [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'">
      expand_more
    </span>
  </span>
</a>
<div *ngIf="expanded">
  <app-sidenav-item
    *ngFor="let child of item.children"
    [item]="child"
    [depth]="depth + 1"
  >
  </app-sidenav-item>
</div>
