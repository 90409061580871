import { Serializer } from "../resource/serializer.interface";
import { ClientForm } from "./client-form.model";

export class ClientFormsSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): ClientForm {
    const clientForm = new ClientForm();
    clientForm.id = json.id;
    clientForm.type = json.type;
    clientForm.title = json.title;
    clientForm.description=json.description;
    clientForm.subHeader=json.subHeader;
    clientForm.submitAction = json.submit_action;
    clientForm.fields = json.fields;
    clientForm.tenantId = json.tenant_id;
    clientForm.createdAt = json.create_at;
    clientForm.updatedAt = json.updated_at;
    return clientForm;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(clientForm: ClientForm): any {
    return {
      id: clientForm.id,
      type: clientForm.type,
      title: clientForm.title,
      submit_action: clientForm.submitAction,
      subHeader: clientForm.subHeader,
      description: clientForm.description,
      fields: clientForm.fields,
      create_at: clientForm.createdAt,
      update_at: clientForm.updatedAt,
      tenant_id: clientForm.tenantId
    };
  }
}
