<header class="navbar">
  <section
    class="navbar-section"
    style="flex-direction: column;align-items: flex-start; margin-bottom: 10px;"
  >
    <h3 style="margin-bottom: 5px" class="section-title">
      {{ title | translate }}
    </h3>
    <div class="subtitle-holder" *ngIf="subtitle != null && subtitle != ''">
      {{ subtitle | translate }}
    </div>
  </section>
  <section class="navbar-section">
    <a
      (click)="onItemClicked(item)"
      class="btn btn-link"
      *ngFor="let item of actionbarItemsThis"
    >
      <span *ngIf="item.iconClass"><i class="{{ item.iconClass }}"></i></span>
      {{ item.btnText | translate }}
    </a>
  </section>
</header>
