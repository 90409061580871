<div class="container" [style.display]="outlet.isActivated ? 'none' : ''">
  <div class="column col-12">
    <app-generic-card>
      <app-actionbar
        title="{{ 'BASIC_ENTITIES_PLURAL.BUSINESS_UNITS' | translate }}"
        [actionBarItems]="actionBarItemsForms"
      ></app-actionbar>
      <app-datatable
        #dtBusinessUnits
        tableIdentifier="businessUnits"
        [data]="dataBusinessUnits"
        [columns]="columnsBusinessUnits"
        [actions]="actionsBusinessUnits"
        (callbackOnUpdate)="updateBusinessUnit($event)"
      ></app-datatable>
    </app-generic-card>
  </div>
</div>
<router-outlet
  #outlet="outlet"
  (deactivate)="onDeactivate($event)"
></router-outlet>
