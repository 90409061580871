import { Serializer } from "../resource/serializer.interface";
import { GeoSummary } from "@fourcomply-dashboard/shared";

export class GeoSummarySerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): GeoSummary {
    const geoSummary = new GeoSummary();
    geoSummary.total_number = json.total_number;
    geoSummary.data = json.data;

    return geoSummary;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(geoSummary: GeoSummary): any {
    return {
      total_number: geoSummary.total_number,
      data: geoSummary.data,
    };
  }
}
