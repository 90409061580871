import { Component, OnInit, ViewChild } from "@angular/core";
import { FormlyFieldConfig } from "@ngx-formly/core";
import {
  APIService,
  NotificationService,
  ClientSystem,
  FormComponent,
} from "@fourcomply-dashboard/shared";
import { Router, ActivatedRoute } from "@angular/router";
import { BreadcrumbService } from "xng-breadcrumb";
import { WebUrlValidator } from "../../app.module";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-add-client-system",
  templateUrl: "./add-client-system.component.html",
  styleUrls: ["./add-client-system.component.scss"],
})
export class AddClientSystemComponent implements OnInit {
  @ViewChild("formSystem", { static: true })
  formSystem: FormComponent;
  isEdit = false;
  fields: FormlyFieldConfig[] = [];
  formSubmitted = false;
  triggers: string[];
  actualData: ClientSystem;
  loadingDone = false;
  translate: TranslateService;
  model: ClientSystem = {
    name: "",
    id: undefined,
    type: "",
    priority: undefined,
  };

  constructor(
    public api: APIService,
    public router: Router,
    private notification: NotificationService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.submitSystemsForm = this.submitSystemsForm.bind(this);
    this.translate = this.translateService;
    this.initSystemsForm();
  }

  initSystemsForm() {
    this.fields = [
      {
        key: "name",
        type: "input",
        templateOptions: {
          required: true,
          label: "SYSTEMS.SYSTEM_NAME_FIELD",
        },
      },
      {
        key: "type",
        type: "input",
        templateOptions: {
          required: true,
          label: "SYSTEMS.SYSTEM_TYPE_FIELD",
        },
      },
      {
        key: "priority",
        type: "input",
        templateOptions: {
          type: "number",
          required: true,
          label: "SYSTEMS.SYSTEM_PRIORITY_FIELD",
        },
      },
    ];

    const systemId = this.route.snapshot.params["id"];
    if (systemId) {
      this.isEdit = true;
      this.api.clientSystems.get(systemId).subscribe((a) => {
        this.actualData = a;
        this.model = { ...a };
        this.formSystem.updateModel(this.model);
        this.breadcrumbService.set("@systemName", this.actualData.name);
      });
    }
  }

  submitSystemsForm() {
    this.formSubmitted = true;
    this.loadingDone = false;
    if (this.model.name.trim() === "" || this.model.type.trim() === "") {
      this.loadingDone = true;
      this.formSystem.loaderVisibility(false);
      this.notification.error("SYSTEMS.NAME_TYPE_MISSING_ERROR");
      return;
    } else {
      if (this.model.priority <= 0) {
        this.loadingDone = true;
        this.formSystem.loaderVisibility(false);
        this.notification.error("SYSTEMS.PRIORITY_TYPE_ERROR");
      }
    }
    const systemId = this.route.snapshot.params["id"];
    if (systemId) {
      this.api.clientSystems.update(this.model).subscribe((a) => {
        this.loadingDone = true;
        this.formSystem.loaderVisibility(false);
        this.notification.success("SYSTEMS.SUCCESSFUL_UPDATE");
        this.router.navigate(["systems"]);
      });
    } else {
      this.api.clientSystems.create(this.model).subscribe((a) => {
        this.loadingDone = true;
        this.formSystem.loaderVisibility(false);
        this.notification.success("SYSTEMS.SUCCESSFUL_CREATION");
        this.router.navigate(["systems"]);
      });
    }
  }
}
