import { Component, OnInit, ViewChild } from "@angular/core";
import { FormlyFieldConfig } from "@ngx-formly/core";
import {
  APIService,
  NotificationService,
  FormComponent,
} from "@fourcomply-dashboard/shared";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "fourcomply-dashboard-add-edit-geo-ip-exception",
  templateUrl: "./add-edit-geo-ip-exception.component.html",
  styleUrls: ["./add-edit-geo-ip-exception.component.scss"],
})
export class AddEditGeoIpExceptionComponent implements OnInit {
  @ViewChild("ipExceptionForm", { static: true })
  ipExceptionForm: FormComponent;

  fields: FormlyFieldConfig[] = [];
  formSubmitted = false;
  model: any = {};
  isNew = true;
  constructor(
    public api: APIService,
    public router: Router,
    private notification: NotificationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.submitGetIpExceptionForm = this.submitGetIpExceptionForm.bind(this);
    this.initGeoIpExceptionForm();
  }

  initGeoIpExceptionForm() {
    const id = this.route.snapshot.params["id"];
    if (id) {
      this.api.geoIpExceptions.get(id).subscribe((a) => {
        this.isNew = false;
        this.model = { ...a };
        if (this.model.ip_range && this.model.ip_range.indexOf("-") != -1) {
          let ipAdds = this.model.ip_range.split("-");
          this.model.firstIP = ipAdds[0].trim();
          this.model.lastIP = ipAdds[1].trim();
        }

        this.ipExceptionForm.updateModel(this.model);
      });
    } else {
      this.model = {};
    }

    this.fields = [
      {
        key: "firstIP",
        type: "input",
        templateOptions: {
          required: true,
          label: "GEO.BEGIN_IP",
        },
        validators: {
          validation: ["ip"],
        },
      },
      {
        key: "lastIP",
        type: "input",
        templateOptions: {
          required: true,
          label: "GEO.END_IP",
        },
        validators: {
          validation: ["ip"],
        },
      },
      {
        key: "geo_type",
        type: "input",
        templateOptions: {
          required: true,
          label: "GEO.GEO_TYPE",
        },
      },
      {
        key: "value",
        type: "input",
        templateOptions: {
          label: "GENERIC.VALUE",
          required: true,
        },
      },
    ];
  }

  submitGetIpExceptionForm() {
    this.model.ip_range = this.model.firstIP + "-" + this.model.lastIP;
    this.formSubmitted = true;
    if (this.isNew) {
      this.api.geoIpExceptions.create(this.model).subscribe(
        (a) => {
          this.notification.success("GENERIC.SUCCESSFUL_DATA_ADD");
          this.router.navigate(["geo"]);
        },
        (error) => {
          this.ipExceptionForm.loaderVisibility(false);
        }
      );
    } else {
      this.api.geoIpExceptions.update(this.model).subscribe(
        (a) => {
          this.notification.success("GENERIC.SUCCESSFUL_DATA_UPDATE");
          this.router.navigate(["geo"]);
        },
        (error) => {
          this.ipExceptionForm.loaderVisibility(false);
        }
      );
    }
  }
}
