import { Component, OnInit, ViewChild } from "@angular/core";
import {
  DatatableComponent,
  APIService,
  ClientRegulationConfiguration,
  RightRequestConfig,
  ModalComponent,
  NotificationService,
  ClientLawConfiguration,
  ClientLawConfigurationType,
  ClientLawConfigurationPurpose,
  AuthService,
} from "@fourcomply-dashboard/shared";
import { ActivatedRoute, Router } from "@angular/router";
import { pipe } from "rxjs";
import { DatalistActionItem } from "libs/shared/src/lib/models/datalist-action-item.interface";
import { RightRequestStep } from "libs/shared/src/lib/models/right-request-steps/right-request-step.model";
import { BreadcrumbService } from "xng-breadcrumb";
import { environment } from "libs/shared/src/environments/environment";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-client-regulation-configurations",
  templateUrl: "./client-regulation-configurations.component.html",
  styleUrls: ["./client-regulation-configurations.component.scss"],
})
export class ClientRegulationConfigurationsComponent implements OnInit {
  @ViewChild("dtClientRegulationConfigurations", { static: true })
  dtClientRegulationConfigurations: DatatableComponent;
  @ViewChild("dtRightRequestConfigs", { static: true })
  dtRightRequestConfigs: DatatableComponent;
  @ViewChild("dtRightRequestSteps", { static: true })
  dtRightRequestSteps: DatatableComponent;

  @ViewChild("modalBasesEdit")
  modalBasesEdit: ModalComponent;
  @ViewChild("modalConfigurationEdit")
  modalConfigurationEdit: ModalComponent;

  @ViewChild("modalTypeEdit")
  modalTypeEdit: ModalComponent;

  @ViewChild("modalDeletePerm")
  modalDeletePerm: ModalComponent;

  @ViewChild("modalDeletePurp")
  modalDeletePurp: ModalComponent;

  @ViewChild("f")
  f: NgForm;

  @ViewChild("g")
  g: NgForm;

  dataClientRegulationConfiguration: ClientRegulationConfiguration;
  columnsClientRegulationConfigurations: any[];

  dataRightRequestConfigs: RightRequestConfig[];
  columnsRightRequestConfigs: any[];
  actionsRightRequestConfigs: DatalistActionItem[];

  dataRightRequestSteps: any[];
  columnsRightRequestSteps: any[];

  regulationId: number;
  derivedPermToDelete: number;
  explicitPermToDeleteFrom: number;
  purpToDelete: number;
  userId: string;
  explicitPermissionTypes: any;
  allExistingPermissionCategories: any;

  modalTitleBasesEdit: string;
  processingForEdit: ClientLawConfigurationPurpose;
  processingForEditIndex: number;

  explicitPermissionTypeForEdit: any;
  explicitPermissionTypeForEditIndex: number;

  derivedPermissionTypeForEdit: any;
  derivedPermissionTypeForEditIndex: number;

  basicConfigForEdit: ClientLawConfiguration;
  regulationName: string;
  isProcessingRequired = false;
  constructor(
    private api: APIService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private notification: NotificationService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this.regulationId = this.route.snapshot.params["id"];
    this.getRegulation();
    this.auth.userProfile$.subscribe(
      pipe((res: any) => {
        this.userId = res.currentTenant.tenant_id;
        this.getClient();
      })
    );
    this.deletePermissionType = this.deletePermissionType.bind(this);
    this.deleteProcessingPurpose = this.deleteProcessingPurpose.bind(this);
  }

  getClient() {
    this.api.clients.getPermissionTypes(this.userId).subscribe((a) => {
      this.explicitPermissionTypes = a;
      this.explicitPermissionTypes.forEach((eType) => {
        
        if(!eType.business_unit)
        {
          eType.name_aux= eType.name;
        }else{
          eType.name_aux= eType.name+ ' ('+ eType.business_unit+')';
        }
    
      });
      this.fetchClientRegulationConfigurations();
    });
  }

  getRegulation() {
    this.api.regulations.get(this.regulationId).subscribe((data) => {
      this.regulationName = data.name;
      this.breadcrumbService.set("@clientRegulationTitle", this.regulationName);
    });
  }

  fetchClientRegulationConfigurations() {
    this.api.clientRegulationConfigurations
      .getByRegulationId(this.regulationId)
      .subscribe((a) => {
        this.dataClientRegulationConfiguration = a;
        this.allExistingPermissionCategories = [];
        // Add existing permission categories
        this.api.clients.getPermissionCategories(this.userId).subscribe((a) => {
          this.allExistingPermissionCategories = a;
          this.explicitPermissionTypes.forEach((eType) => {
            eType.derived_permission_types = [];
            this.dataClientRegulationConfiguration.config.derived_permission_types.forEach(
              (type) => {
                if (type.explicit_permission_types.includes(eType.name)) {
                  type.name_aux= type.name;
                  eType.derived_permission_types.push(type);
                }
              }
            );
          });
        });
      });
  }

  closeModal() {
    this.isProcessingRequired = false;
    this.processingForEditIndex = null;
    this.processingForEdit = null;
  }

  closePermissionModal() {
    this.f.resetForm();
    this.derivedPermissionTypeForEdit = null;
    this.derivedPermissionTypeForEditIndex = null;
    this.explicitPermissionTypeForEdit = null;
    this.explicitPermissionTypeForEditIndex = null;
  }

  openTypeModal(dIndex: number, eIndex: number): void {
    this.derivedPermissionTypeForEdit = null;
    this.derivedPermissionTypeForEditIndex = dIndex;
    this.explicitPermissionTypeForEditIndex = eIndex;
    this.explicitPermissionTypeForEdit = this.explicitPermissionTypes[eIndex];
    let dTypeName = this.explicitPermissionTypeForEdit.derived_permission_types[
      dIndex
    ]
      ? this.explicitPermissionTypeForEdit.derived_permission_types[dIndex].name
      : "";

    this.dataClientRegulationConfiguration.config.derived_permission_types.forEach(
      (dType) => {
        if(!dType.business_unit)
        {
          dType.name_aux= dType.name;
        }else{
          dType.name_aux= dType.name+ ' ('+ dType.business_unit+')';
        }
        if (dType.name == dTypeName) {
          let copyObj = Object.assign({}, dType);
          this.derivedPermissionTypeForEdit = copyObj;
        }
      }
    );
    if (!this.derivedPermissionTypeForEdit) {
      this.derivedPermissionTypeForEdit = new ClientLawConfigurationType();
    }

    this.modalTypeEdit.openModal();
  }

  changeDerivedTypeInModal(ev) {
    let type = ev.value;
    // autofill existing stuff
    this.dataClientRegulationConfiguration.config.derived_permission_types.forEach(
      (dType) => {
        if(!dType.business_unit)
        {
          dType.name_aux= dType.name;
        }else{
          dType.name_aux= dType.name+ ' ('+ dType.business_unit+')';
        }
        if (type && dType.name == type) {
          this.derivedPermissionTypeForEdit = dType;
        }
      }
    );
    if (!this.derivedPermissionTypeForEdit.name) {
      this.derivedPermissionTypeForEdit.name = type;
    }
  }

  changeDerivedValueInModal(ev, key) {
    let val = ev.value;
    if (val) {
      if (key === "expiry_behavior") {
        this.derivedPermissionTypeForEdit.expiry_behavior = val;
      } else if (key == "default_permission_value") {
        this.derivedPermissionTypeForEdit.default_permission_value = val;
      }
    }
  }

  sortProcessingPurposesByName() {
    return this.dataClientRegulationConfiguration.config
    .processing_purposes.sort(function(a, b) {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }

  openProcessingModal(index: number): void {
    this.processingForEditIndex = index;
    this.processingForEdit = this.dataClientRegulationConfiguration.config
      .processing_purposes[index]
      ? JSON.parse(
          JSON.stringify(
            this.dataClientRegulationConfiguration.config.processing_purposes[
              index
            ]
          )
        )
      : new ClientLawConfigurationPurpose();
    this.modalBasesEdit.openModal();
  }

  openDeleteProcessingPurposeModal(ind) {
    this.purpToDelete = ind;
    this.modalDeletePurp.openModal();
  }

  openDeletePermissionTypeModal(explicitIndex, derivedIndex) {
    this.derivedPermToDelete = derivedIndex;
    this.explicitPermToDeleteFrom = explicitIndex;
    this.modalDeletePerm.openModal();
  }

  onChangeBasisProcessing() {
    this.isProcessingRequired = false;
  }

  deletePermissionType(): void {
    let index = this.derivedPermToDelete;
    let explicitIndex = this.explicitPermToDeleteFrom;
    this.derivedPermToDelete = null;
    this.explicitPermToDeleteFrom = null;

    let backup: any;

    let explicitPerm = this.explicitPermissionTypes[explicitIndex];
    let derivedWeAreDeleting = explicitPerm.derived_permission_types[index];
   // Change Permission
    let old = this.dataClientRegulationConfiguration.config
      .derived_permission_types;

    let derivedToDelete=null;
    old.forEach((dType) => {
      if (dType.name == derivedWeAreDeleting.name) {
        dType.explicit_permission_types = dType.explicit_permission_types.filter(
          (eType) => eType != explicitPerm.name
        );
        if (dType.explicit_permission_types.length==0)
        {
         derivedToDelete= dType;
        }
      }
    });
    

    if (derivedToDelete!= null)
    {
     let idxDerived=  old.indexOf(derivedToDelete)
      old.splice(idxDerived,1);
    }

    this.dataClientRegulationConfiguration.config.derived_permission_types = old;
    this.api.clientRegulationConfigurations
      .updateByRegulationId(
        this.regulationId,
        this.dataClientRegulationConfiguration
      )
      .subscribe(
        (x) => {
          this.notification.success("GENERIC.SUCCESSFUL_DATA_UPDATE");
          this.fetchClientRegulationConfigurations();
          this.modalBasesEdit.closeModal();
          this.modalDeletePerm.closeModal();
        },
        (error) => {
          this.fetchClientRegulationConfigurations();
          this.modalBasesEdit.closeModal();
          this.modalDeletePerm.closeModal();
        }
      );
  }

  deleteProcessingPurpose(): void {
    let index = this.purpToDelete;
    this.purpToDelete = null;
    let backup: any;
    // Change Processing Purposes
    let old = this.dataClientRegulationConfiguration.config.processing_purposes;
    old.splice(index, 1);
    this.dataClientRegulationConfiguration.config.processing_purposes = old;
    this.api.clientRegulationConfigurations
      .updateByRegulationId(
        this.regulationId,
        this.dataClientRegulationConfiguration
      )
      .subscribe(
        (x) => {
          this.notification.success("GENERIC.SUCCESSFUL_DATA_UPDATE");
          this.fetchClientRegulationConfigurations();
          this.modalBasesEdit.closeModal();
          this.modalDeletePurp.closeModal();
        },
        (error) => {
          this.fetchClientRegulationConfigurations();
          this.modalBasesEdit.closeModal();
          this.modalDeletePurp.closeModal();
        }
      );
  }

  submitPermissionTypeCustomization(f): void {
    if (!f.valid) {
      return;
    }

    // Validations
    let isExplicit = false;
    this.derivedPermissionTypeForEdit.name = this.derivedPermissionTypeForEdit.name.trim();
    this.explicitPermissionTypes.forEach((a) => {
      if (a.name == this.derivedPermissionTypeForEdit.name) {
        isExplicit = true;
      }
    });

    if (!isExplicit) {
      this.notification.error(
        "CLIENT_REGULATIONS.PERMISSION_TYPE_CORRESPOND_ERROR"
      );
      this.fetchClientRegulationConfigurations();
      return;
    }

    // Add new Derived Type to the configuration, overridding the old one
    // Also, if it wasnt there already, add the new explicit type it was added from
    let found = false;
    this.dataClientRegulationConfiguration.config.derived_permission_types.forEach(
      (oldDType, index) => {
        if (oldDType.name == this.derivedPermissionTypeForEdit.name) {
          let newType;
          newType = this.derivedPermissionTypeForEdit;
          found = true;
          // add in new explicit
          if (
            !newType.explicit_permission_types.includes(
              this.explicitPermissionTypeForEdit.name
            )
          ) {
            newType.explicit_permission_types.push(
              this.explicitPermissionTypeForEdit.name
            );
          }
          // Save
          this.dataClientRegulationConfiguration.config.derived_permission_types[
            index
          ] = newType;
        }
      }
    );

    // Wasn't found
    if (!found) {
      let toAdd = this.derivedPermissionTypeForEdit;
      toAdd.explicit_permission_types = [
        this.explicitPermissionTypeForEdit.name,
      ];
      this.dataClientRegulationConfiguration.config.derived_permission_types
        ? this.dataClientRegulationConfiguration.config.derived_permission_types.push(
            toAdd
          )
        : [toAdd];
    }

    console.log(this.dataClientRegulationConfiguration);

    this.api.clientRegulationConfigurations
      .updateByRegulationId(
        this.regulationId,
        this.dataClientRegulationConfiguration
      )
      .subscribe(
        (x) => {
          this.notification.success("GENERIC.SUCCESSFUL_DATA_UPDATE");
          this.modalTypeEdit.closeModal();
          this.fetchClientRegulationConfigurations();
        },
        (error) => {
          this.fetchClientRegulationConfigurations();
        }
      );
  }

  changeConsentOverrideBehavior(value): void {
    if (value.checked === true) {
      value = "yes";
    } else {
      value = "no";
    }
    this.dataClientRegulationConfiguration.config.consent_override_behavior = value;
    this.api.clientRegulationConfigurations
      .updateByRegulationId(
        this.regulationId,
        this.dataClientRegulationConfiguration
      )
      .subscribe(
        (x) => {
          this.notification.success("GENERIC.SUCCESSFUL_DATA_UPDATE");
          this.fetchClientRegulationConfigurations();
          this.modalBasesEdit.closeModal();
          this.modalDeletePurp.closeModal();
        },
        (error) => {
          this.fetchClientRegulationConfigurations();
          this.modalBasesEdit.closeModal();
          this.modalDeletePurp.closeModal();
        }
      );
  }

  submitProcessingCustomization(f): void {
    console.log(f);
    if (!f.valid) {
      return;
    }

    let backup: any;

    if(this.processingForEdit.privacy_policy_justification != null)
    {
      this.processingForEdit.privacy_policy_justification = this.processingForEdit.privacy_policy_justification.trim();
    }    
    
    if (this.processingForEditIndex >= 0) 
    {
      backup = this.dataClientRegulationConfiguration.config.processing_purposes[this.processingForEditIndex];
      this.dataClientRegulationConfiguration.config.processing_purposes[this.processingForEditIndex] = this.processingForEdit;
    } 
    else 
    {
      if (this.dataClientRegulationConfiguration.config.processing_purposes.length == 0) 
      {
        this.dataClientRegulationConfiguration.config.processing_purposes = [];
      }

      this.dataClientRegulationConfiguration.config.processing_purposes.unshift(this.processingForEdit);
    }

    if (this.processingForEdit && this.processingForEdit.name) 
    {
      this.processingForEdit.name = this.processingForEdit.name.trim();
    }

    this.api.clientRegulationConfigurations
      .updateByRegulationId(
        this.regulationId,
        this.dataClientRegulationConfiguration
      )
      .subscribe(
        (x) => {
          this.notification.success("GENERIC.SUCCESSFUL_DATA_UPDATE");
          this.g.resetForm();
          this.fetchClientRegulationConfigurations();
          this.modalBasesEdit.closeModal();
        },
        (error) => {
          this.fetchClientRegulationConfigurations();
        }
      );
  }
}
