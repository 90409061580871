<div title="" class="modal" [ngClass]="{'active': isActiveThis}">
    <a href="javascript:void(0);" class="modal-overlay" aria-label="Close" (click)="closeModal()"></a>
    <div title="" class="modal-container">
        <div class="modal-header">
            <a href="javascript:void(0);" class="btn btn-clear float-right" aria-label="Close"
                (click)="closeModal()"></a>
            <h5 class="modal-title h5">
                {{titleThis | translate}}
            </h5>
        </div>
        <div title="" class="modal-body">
            <div class="content" *ngIf="bodyThis" [innerHtml]="bodyThis"></div>
            <div class="content" *ngIf="!bodyThis">
                <ng-content></ng-content>
            </div>
        </div>
        <div class="modal-footer" *ngIf="confirmCallback">
            <button class="btn btn-primary" (click)="confirmClick()">{{'GENERIC.CONFIRM' | translate}}</button>
            <a class="btn btn-link" (click)="closeModal()">{{'GENERIC.NO' | translate}}</a>
        </div>
    </div>
</div>