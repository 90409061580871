<mat-sidenav-container>
  <app-header [appTitle]="'DASHBOARD.APP_TITLE' | translate"></app-header>
  <xng-breadcrumb [separator]="iconTemplate" *ngIf="showBreadCrumb">
    <ng-container
      *xngBreadcrumbItem="let breadcrumb; let info = info; let first = first"
    >
      <ng-container *ngIf="!first">
        <div *ngIf="first">Home</div>
        <ng-container>{{ breadcrumb }}</ng-container>
      </ng-container>
    </ng-container>
  </xng-breadcrumb>
  <ng-template #iconTemplate>
    <i class="fas fa-chevron-right"></i>
  </ng-template>

  <mat-sidenav
    mode="side"
    [opened]="auth.navOpen"
    fixedInViewport="true"
    *ngIf="auth.loggedIn"
  >
    <app-sidenav [navItems]="navItems"></app-sidenav>
  </mat-sidenav>
  <router-outlet></router-outlet>
</mat-sidenav-container>
<ngx-panel-container></ngx-panel-container>
