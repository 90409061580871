import { Component, OnInit, ViewChild } from "@angular/core";
import { DatalistActionItem } from "libs/shared/src/lib/models/datalist-action-item.interface";
import {
  APIService,
  RightRequest,
  NotificationService,
  ActionBarItem,
  ClientWebhook,
  DatatableComponent,
  LegalActivity,
  ConsentPermission,
  ModalComponent,
  AuthService,
} from "@fourcomply-dashboard/shared";
import { Router } from "@angular/router";
import { QueryOptions } from "libs/shared/src/lib/models/query-options/query-options.class";
import { DatePipe } from "@angular/common";
import { pipe } from "rxjs";

const cleanData = (d: any) => {
  if (typeof d === "undefined" || !d) {
    return "";
  } else {
    return d;
  }
};

@Component({
  selector: "app-citizen-history",
  templateUrl: "./citizen-history.component.html",
  styleUrls: ["./citizen-history.component.scss"],
})
export class CitizenHistoryComponent implements OnInit {
  parentFlagValue: boolean;
  searchText: string;
  actionBarItemsReporting: ActionBarItem[];
  actionsWebhooks: DatalistActionItem[];
  idForDeleteWebhooks: number;
  constructor(
    private api: APIService,
    private notification: NotificationService,
    private router: Router,
    private auth: AuthService
  ) {}

  /* RIGHT REQUEST DATA */
  @ViewChild("dtRightRequest")
  dtRightRequest: DatatableComponent;
  // Permission
  permPages: number;
  permCurrentPage: number = 1;
  permTotal: number;
  permPageSize: number = 10;
  // Consent
  consPages: number;
  consCurrentPage: number = 1;
  consTotal: number;
  consPageSize: number = 10;
  // Legal
  legalPages: number;
  legalCurrentPage: number = 1;
  legalTotal: number;
  legalPageSize: number = 10;
  // Rights
  rightPages: number;
  rightCurrentPage: number = 1;
  rightTotal: number;
  rightPageSize: number = 10;
  dataRightRequest: RightRequest[];
  actionsRightRequest: DatalistActionItem[];
  /* RIGHT REQUEST DATA */

  /* LEGAL ACTIVITIES DATA */
  @ViewChild("dtLegalActivities", { static: true })
  dtLegalActivities: DatatableComponent;
  dataLegalActivities: LegalActivity[];
  /* LEGAL ACTIVITIES DATA */

  /* PERMISSIONS DATA */
  @ViewChild("dtPermissions")
  dtPermissions: DatatableComponent;
  dataPermissions: ConsentPermission[];

  /* CONSENT DATA */
  @ViewChild("dtConsent")
  dtConsent: DatatableComponent;
  dataConsent: ConsentPermission[];

  @ViewChild("modalCustomFields")
  modalCustomFields: ModalComponent;

  @ViewChild("modalCustomFields2")
  modalCustomFields2: ModalComponent;

  client: any = null;
  userid: string;
  selectedPermission: any = null;
  selectedConsent: any = null;
  customFieldNames: any = {
    customFieldName1: "",
    customFieldName2: "",
    customFieldName3: "",
    customFieldName4: "",
    customFieldName5: "",
    customFieldName6: "",
    customFieldName7: "",
  };
  sortLegal: string;
  sortRight: string;
  sortPerm: string;
  sortComp: string;

  ngOnInit() {
    //this.fetchWebhooks();
    this.openCustomFieldsModal = this.openCustomFieldsModal.bind(this);
    this.openCustomFieldsModal2 = this.openCustomFieldsModal2.bind(this);
    this.auth.userProfile$.subscribe(
      pipe((res: any) => {
        this.userid = res.currentTenant.tenant_id;
        this.getClient();
      })
    );
  }

  getClient() {
    this.api.clients.get(this.userid).subscribe((a) => {
      this.client = a;
      if (a.configId.custom_field_config) {
        this.customFieldNames = {
          customFieldName1:
            a.configId.custom_field_config.custom_field_1_name || "",
          customFieldName2:
            a.configId.custom_field_config.custom_field_2_name || "",
          customFieldName3:
            a.configId.custom_field_config.custom_field_3_name || "",
          customFieldName4:
            a.configId.custom_field_config.custom_field_4_name || "",
          customFieldName5:
            a.configId.custom_field_config.custom_field_5_name || "",
          customFieldName6:
            a.configId.custom_field_config.custom_field_6_name || "",
          customFieldName7:
            a.configId.custom_field_config.custom_field_7_name || "",
        };
      }
    });
  }

  onOpenCustomFields(ev) {
    this.selectedPermission = ev;
    this.openCustomFieldsModal();
  }

  openCustomFieldsModal(): void {
    this.modalCustomFields.openModal();
  }

  onOpenCustomFields2(ev) {
    this.selectedConsent = ev;
    this.openCustomFieldsModal2();
  }

  openCustomFieldsModal2(): void {
    this.modalCustomFields2.openModal();
  }

  columnsLegalActivities: any = [
    { name: "GENERIC.EMAIL_ADDRESS", prop: "emailAddress" },
    { name: "CITIZEN_HISTORY.ACTIVITY_TYPE_FIELD", prop: "activityType" },
    { name: "GENERIC.ACTIVITY_DATE", prop: "activityAtAux", width: 120 },
    { name: "GEO.STATE", prop: "standardState" },
    { name: "GEO.COUNTRY", prop: "standardCountry" },
    { name: "GEO.INPUT_STATE", prop: "inputState" },
    { name: "GEO.INPUT_COUNTRY", prop: "inputCountry" },
    { name: "BASIC_ENTITIES_SINGULAR.CONSENT_CODE", prop: "consentCode" },
    { name: "BASIC_ENTITIES_SINGULAR.REGULATION", prop: "regulationName" },
    { name: "CITIZEN_HISTORY.RIGHT_WORKFLOW_ID", prop: "rightWorkflowId" },
    {
      name: "CITIZEN_HISTORY.RIGHT_WORKFLOW_STEP_NAME",
      prop: "rightWorkflowStepName",
    },
    
    { name: "BASIC_ENTITIES_SINGULAR.SYSTEM", prop: "system", width: 120 },
    { name: "BASIC_ENTITIES_SINGULAR.PERMISSION_TYPE", prop: "permission_type", width: 120 },
    { name: "BASIC_ENTITIES_SINGULAR.BUSINESS_UNIT", prop: "business_unit", width: 120 },
    { name: "GENERIC.EXTERNAL_SOURCE", prop: "external_source", width: 120 },
    { name: "BASIC_ENTITIES_SINGULAR.PROCESSING_PURPOSE", prop: "processing_purpose", width: 120 },

  ];

  columnsRightRequests: any = [
    { name: "GENERIC.ID", prop: "id", type: "localLink" },
    { name: "GENERIC.EMAIL_ADDRESS", prop: "email" },
    { name: "GENERIC.STATUS", prop: "status" },
    { name: "RIGHTS_REQUESTS.CURRENT_RIGHTS_STATE", prop: "currentState" },
    { name: "RIGHTS_REQUESTS.NEXT_STATE", prop: "nextState" },
    { name: "RIGHTS_REQUESTS.REQUEST_TYPE", prop: "requestType" },
    { name: "GEO.STATE", prop: "standardState" },
    { name: "GEO.COUNTRY", prop: "standardCountry" },
    { name: "RIGHTS_REQUESTS.REGULATION_NAME", prop: "regulationName" },
  ];

  columnsPermissions: any = [
    { name: "GENERIC.ID", prop: "id" },
    { name: "GENERIC.EMAIL_ADDRESS", prop: "email", scss: "width:200px;" },
    { name: "GENERIC.EXPIRATION", prop: "expirationAux" },
    { name: "GENERIC.ACTIVITY_DATE", prop: "activity_dateAux" },
    {
      name: "BASIC_ENTITIES_SINGULAR.PERMISSION_CATEGORY",
      prop: "permissionCategory",
    },
    {
      name: "BASIC_ENTITIES_SINGULAR.PROCESSING_PURPOSE",
      prop: "processingPurpose",
    },
    { name: "BASIC_ENTITIES_SINGULAR.PERMISSION_TYPE", prop: "permissionType" },
    {
      name: "BASIC_ENTITIES_SINGULAR.PERMISSION_SOURCE",
      prop: "permissionSource",
    },
    { name: "GEO.STATE", prop: "standardState" },
    { name: "GEO.COUNTRY", prop: "standardCountry" },
    { name: "BASIC_ENTITIES_SINGULAR.REGULATION", prop: "regulationName" },
    { name: "BASIC_ENTITIES_SINGULAR.SYSTEM", prop: "system" },
    { name: "GENERIC.CREATED_AT", prop: "createdAtAux" },
    { name: "SETTINGS.CUSTOM_FIELDS", prop: "customFields" },
    { name: "GENERIC.EXTERNAL_SOURCE", prop: "externalSource" },
    { name: "GENERIC.MEANS", prop: "means" },
    { name: "GENERIC.CONSENT_LANGUAGE", prop: "consentLanguage" },
    { name: "GENERIC.PRIVACY_NOTICE_VERSION", prop: "privacyNoticeVersion" },
  ];

  columnsConsent: any = [
    { name: "GENERIC.ID", prop: "id" },
    { name: "GENERIC.EMAIL_ADDRESS", prop: "email", scss: "width:200px;" },
    { name: "GEO.STATE", prop: "standardState" },
    { name: "GEO.COUNTRY", prop: "standardCountry" },
    { name: "BASIC_ENTITIES_SINGULAR.REGULATION", prop: "regulationName" },
    { name: "BASIC_ENTITIES_SINGULAR.CONSENT_CODE", prop: "consentCode" },
    {
      name: "BASIC_ENTITIES_SINGULAR.PERMISSION_SOURCE",
      prop: "permissionSource",
    },
    { name: "BASIC_ENTITIES_SINGULAR.PERMISSION_TYPE", prop: "permissionType" },
    { name: "BASIC_ENTITIES_SINGULAR.SYSTEM", prop: "system" },
    { name: "GENERIC.ACTIVITY_DATE", prop: "activity_dateAux" },
    { name: "GENERIC.CREATED_AT", prop: "createdAtAux" },
    { name: "SETTINGS.CUSTOM_FIELDS", prop: "customFields" },
    { name: "GENERIC.EXTERNAL_SOURCE", prop: "externalSource" },
    { name: "GENERIC.MEANS", prop: "means" },
    { name: "GENERIC.CONSENT_LANGUAGE", prop: "consentLanguage" },
    { name: "GENERIC.PRIVACY_NOTICE_VERSION", prop: "privacyNoticeVersion" },
  ];
  /* PERMISSIONS DATA */

  onDeactivate(event): void {
    //this.fetchWebhooks();
  }

  downloadPermissionCsv() {
    // Get Columns
    let array = [
      "id",
      "email",
      "expiration",
      "permissionCategory",
      "permissionType",
      "processingPurpose",
      "regulationName",
      "standardCountry",
      "standardState",
      "consentSource",
      "createdAt",
      "updatedAt",
      "externalSource",
      "means",
      "system",
      "consentLanguage",
      "privacyNoticeVersion",
      "customField1",
      "customField2",
      "customField3",
      "customField4",
      "customField5",
      "customField6",
      "customField7",
    ];
    let displayArray = [
      "id",
      "email_address",
      "expiration",
      "permission_category",
      "permission_type",
      "processing_purpose",
      "regulation_name",
      "standard_country",
      "standard_state",
      "consent_source",
      "created_at",
      "updated_at",
      "external_source",
      "means",
      "system",
      "consent_language",
      "privacy_notice_version",
      "custom_field_1",
      "custom_field_2",
      "custom_field_3",
      "custom_field_4",
      "custom_field_5",
      "custom_field_6",
      "custom_field_7",
    ];
    let csv = "";
    displayArray.forEach((a) => (csv += a + ","));
    csv += "\n";

    let qo = new QueryOptions();
    qo.page = 1;
    qo.pageSize = 10000;
    qo.filter = "email_address==" + encodeURIComponent(this.searchText) + "";
    this.api.consentPermissions.listWithPaging(qo).subscribe((data) => {
      data.list.forEach(function(row) {
        // Add row items
        array.forEach((a) => (csv += cleanData(row[a]) + ","));
        // Add newline
        csv += "\n";
      });
      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      hiddenElement.target = "_blank";
      hiddenElement.download = "permissions.csv";
      hiddenElement.click();
    });
  }

  downloadConsentCsv() {
    // Get Columns
    let array = [
      "id",
      "email",
      "standardCountry",
      "standardState",
      "processingPurpose",
      "regulationName",
      "consentCode",
      "consentSource",
      "consentType",
      "system",
      "createdAt",
      "updatedAt",
      "activity_date",
      "externalSource",
      "means",
      "consentLanguage",
      "privacyNoticeVersion",
      "customField1",
      "customField2",
      "customField3",
      "customField4",
      "customField5",
      "customField6",
      "customField7",
    ];
    let displayArray = [
      "id",
      "email_address",
      "standard_country",
      "standard_state",
      "processing_purpose",
      "regulation_name",
      "consent_code",
      "consent_source",
      "consent_type",
      "system",
      "created_at",
      "updated_at",
      "activity_date",
      "external_source",
      "means",
      "consent_language",
      "privacy_notice_version",
      "custom_field_1",
      "custom_field_2",
      "custom_field_3",
      "custom_field_4",
      "custom_field_5",
      "custom_field_6",
      "custom_field_7",
    ];
    let csv = "";
    displayArray.forEach((a) => (csv += a + ","));
    csv += "\n";

    let qo = new QueryOptions();
    qo.page = 1;
    qo.pageSize = 10000;
    qo.filter = "email_address==" + encodeURIComponent(this.searchText) + "";
    this.api.complianceInputs.listWithPaging(qo).subscribe((data) => {
      data.list.forEach(function(row) {
        // Add row items
        array.forEach((a) => (csv += cleanData(row[a]) + ","));
        // Add newline
        csv += "\n";
      });
      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      hiddenElement.target = "_blank";
      hiddenElement.download = "consent.csv";
      hiddenElement.click();
    });
  }
  downloadLegalActivityCsv() {
    // Get Columns
    let array = [
      "id",
      "emailAddress",
      "activityType",
      "standardCountry",
      "standardState",
      "inputCountry",
      "inputState",
      "consentCode",
      "regulationName",
      "rightWorkflowId",
      "rightWorkflowStepName",
      "createdAt",
      "updatedAt",
      "system",
      "activityAt",
      "permission_type",
      "processing_purpose",
      "external_source",
      
    ];
    let displayArray = [
      "id",
      "email_address",
      "activity_type",
      "standard_country",
      "standard_state",
      "input_country",
      "input_state",
      "consent_code",
      "regulation_name",
      "right_workflow_id",
      "right_workflow_step_name",
      "created_at",
      "updated_at",
      "system",
      "activityAt",
      "permission_type",
      "processing_purpose",
      "external_source",
    ];
    let csv = "";
    displayArray.forEach((a) => (csv += a + ","));
    csv += "\n";

    let qo = new QueryOptions();
    qo.page = 1;
    qo.pageSize = 10000;
    qo.filter = "email_address==" + this.searchText + "";
    this.api.legalActivities.listWithPaging(qo).subscribe((data) => {
      data.list.forEach(function(row) {
        // Add row items
        array.forEach((a) => (csv += cleanData(row[a]) + ","));
        // Add newline
        csv += "\n";
      });
      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      hiddenElement.target = "_blank";
      hiddenElement.download = "legalactivities.csv";
      hiddenElement.click();
    });
  }

  downloadRightRequestCsv() {
    // Get Columns
    let array = [
      "id",
      "email",
      "status",
      "currentState",
      "nextState",
      "requestType",
      "standardCountry",
      "standardState",
      "inputCountry",
      "inputState",
      "regulationName",
      "createdAt",
      "updatedAt",
    ];
    let displayArray = [
      "id",
      "email_address",
      "status",
      "current_state",
      "next_state",
      "request_type",
      "standard_country",
      "standard_state",
      "input_country",
      "input_state",
      "regulation_name",
      "created_at",
      "updated_at",
    ];
    let csv = "";
    displayArray.forEach((a) => (csv += a + ","));
    csv += "\n";

    let qo = new QueryOptions();
    qo.page = 1;
    qo.pageSize = 10000;
    qo.filter = "email==" + encodeURIComponent(this.searchText) + "";
    this.api.rightRequests.listWithPaging(qo).subscribe((data) => {
      data.list.forEach(function(row) {
        // Add row items
        array.forEach((a) => (csv += cleanData(row[a]) + ","));
        // Add newline
        csv += "\n";
      });
      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      hiddenElement.target = "_blank";
      hiddenElement.download = "rightrequests.csv";
      hiddenElement.click();
    });
  }

  downloadExcel() {
    if (!this.searchText) {
      //
      return;
    }
    this.api.complianceInputs
      .createExcelCitizenHistory(this.searchText)
      .subscribe((data) => {
        console.log(data);
        const blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        var downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = "report.xlsx";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
  }

  fetchReport() {
    this.actionBarItemsReporting = [
      {
        btnText: "Search",
        iconClass: "fas fa-edit",
        route: "citizen-history/search",
      },
    ];

    if (this.searchText == undefined || this.searchText == "") {
      return;
      // dont search
    }

    let qo = new QueryOptions();
    qo.page = this.rightCurrentPage;
    qo.pageSize = this.rightPageSize;
    qo.sorts = this.sortRight;
    qo.filter = "email==" + encodeURIComponent(this.searchText) + "";

    this.api.rightRequests.listWithPaging(qo).subscribe((data) => {
      this.dataRightRequest = data.list.map((rightRequest: any) => {
        rightRequest.linkValue = "/right-requests/" + rightRequest.id;
        return rightRequest;
      });
      this.rightPages = data.pages;
      this.rightCurrentPage = data.currentpage;
      this.rightTotal = data.total;
      this.rightPageSize = data.pagesize;
      this.dtRightRequest.columnList = this.dtRightRequest.columns;
      this.dtRightRequest.updateRows(this.dataRightRequest);
      this.dtRightRequest.loaderVisibility(false);
    });

    qo = new QueryOptions();
    qo.page = this.legalCurrentPage;
    qo.pageSize = this.legalPageSize;
    qo.sorts = this.sortLegal;
    qo.filter = "email_address==" + encodeURIComponent(this.searchText) + "";

    this.api.legalActivities.listWithPaging(qo).subscribe((a) => {
      this.dataLegalActivities = a.list;
      this.legalPages = a.pages;
      this.legalCurrentPage = a.currentpage;
      this.legalTotal = a.total;
      this.legalPageSize = a.pagesize;

     
      this.dtLegalActivities.columnList = this.dtLegalActivities.columns;
      this.dataLegalActivities.forEach(function(value) {
        value.activityAtAux = new DatePipe("en-US").transform(
          value.activityAt,
          "MM/dd/yyyy hh:mm zzz"
        );
      }
      );
      this.dtLegalActivities.updateRows(this.dataLegalActivities);
      this.dtLegalActivities.loaderVisibility(false);
    });

    qo = new QueryOptions();
    qo.page = this.permCurrentPage;
    qo.pageSize = this.permPageSize;
    qo.sorts = this.sortPerm;
    qo.filter = "email_address==" + encodeURIComponent(this.searchText) + "";

    this.api.consentPermissions.listWithPaging(qo).subscribe((data) => {
      this.dataPermissions = data.list;
      this.permPages = data.pages;
      this.permCurrentPage = data.currentpage;
      this.permTotal = data.total;
      this.permPageSize = data.pagesize;
      this.dtPermissions.columnList = this.dtPermissions.columns;
      this.dataPermissions.forEach(function(value) {
        value.expirationAux = new DatePipe("en-US").transform(
          value.expiration,
          "MM/dd/yyyy hh:mm zzz"
        );
        value.activity_dateAux = new DatePipe("en-US").transform(
          value.activity_date,
          "MM/dd/yyyy hh:mm zzz"
        );
      });
      this.dtPermissions.updateRows(this.dataPermissions);
      this.dtPermissions.loaderVisibility(false);
    });

    qo = new QueryOptions();
    qo.page = this.permCurrentPage;
    qo.pageSize = this.permPageSize;
    qo.sorts = this.sortComp;
    qo.filter = "email_address==" + encodeURIComponent(this.searchText) + "";

    this.api.complianceInputs.listWithPaging(qo).subscribe((data) => {
      this.dataConsent = data.list;
      this.consPages = data.pages;
      this.consCurrentPage = data.currentpage;
      this.consTotal = data.total;
      this.consPageSize = data.pagesize;
      this.dtConsent.columnList = this.dtConsent.columns;
      this.dataConsent.forEach(function(value) {
        value.createdAtAux = new DatePipe("en-US").transform(
          value.createdAt,
          "MM/dd/yyyy hh:mm zzz"
        );
        value.activity_dateAux = new DatePipe("en-US").transform(
          value.activity_date,
          "MM/dd/yyyy hh:mm zzz"
        );
      });
      this.dtConsent.updateRows(this.dataConsent);
      this.dtConsent.loaderVisibility(false);
    });
  }

  setPermCurrentPage(ev) {
    this.permCurrentPage = ev;
    this.fetchReport();
  }

  setPermPageSize(ev) {
    this.permPageSize = ev;
    this.permCurrentPage = 1;
    this.fetchReport();
  }

  setLegalCurrentPage(ev) {
    this.legalCurrentPage = ev;
    this.fetchReport();
  }

  setLegalPageSize(ev) {
    this.legalPageSize = ev;
    this.legalCurrentPage = 1;
    this.fetchReport();
  }

  setRightCurrentPage(ev) {
    this.rightCurrentPage = ev;
    this.fetchReport();
  }

  setRightPageSize(ev) {
    this.rightPageSize = ev;
    this.rightCurrentPage = 1;
    this.fetchReport();
  }

  searchWithEmail() {
    this.parentFlagValue = false;
    this.dtRightRequest.updateRows([]);
    this.dtLegalActivities.updateRows([]);
    this.dtPermissions.updateRows([]);
    this.fetchReport();
    // this.searchButton = false;
  }

  setSearchText(selectedEmail: string) {
    this.searchText = selectedEmail;
  }

  sortMapperLegal(field) {
    switch (field) {
      case "createdAtAux":
        return "created_at";

        case "activityAtAux":
          return "activity_at";
      case "emailAddress":
        return "email_address";
      case "activityType":
        return "activity_type";
      case "standardCountry":
        return "standard_country";
      case "standardState":
        return "standard_state";
      case "consentCode":
        return "consent_code";
      case "regulationName":
        return "regulation_name";
      case "rightWorkflowId":
        return "right_workflow_id";
      case "rightWorkflowStepName":
        return "right_workflow_step_name";
      case "inputState":
        return "input_state";
      case "inputCountry":
        return "input_country";
      default:
        return field;
    }
  }

  onSortLegal(ev) {
    if (!ev.sorts || !ev.sorts.length) {
      return;
    }
    this.sortLegal =
      (ev.sorts[0].dir == "desc" ? "-" : "") +
      this.sortMapperLegal(ev.sorts[0].prop);
    console.log(this.sortLegal);
    this.fetchReport();
  }

  sortMapperRight(field) {
    switch (field) {
      case "createdAtAux":
        return "created_at";
      case "email":
        return "email_address";
      case "permissionCategory":
        return "permission_category";
      case "processingPurpose":
        return "processing_purpose";
      case "permissionType":
        return "permission_type";
      case "regulationName":
        return "regulation_name";
      case "expirationAux":
        return "expiration";
      case "activity_dateAux":
        return "activity_date";
      case "consentSource":
        return "consent_source";
      case "standardState":
        return "standard_country";
      case "standardCountry":
        return "standard_country";
      case "createdAtAux":
        return "created_at";
      case "externalSource":
        return "external_source";
      case "consentLanguage":
        return "consent_language";
      case "privacyNoticeVersion":
        return "privacy_notice_version";
      case "requestType":
        return "request_type";
      case "regulationName":
        return "regulation_name";
      case "currentState":
        return "current_state";
      default:
        return field;
    }
  }

  onSortRight(ev) {
    if (!ev.sorts || !ev.sorts.length) {
      return;
    }
    this.sortRight =
      (ev.sorts[0].dir == "desc" ? "-" : "") +
      this.sortMapperRight(ev.sorts[0].prop);
    console.log(this.sortRight);
    this.fetchReport();
  }

  sortMapperPerm(field) {
    switch (field) {
      case "createdAtAux":
        return "created_at";
      case "email":
        return "email_address";
      case "permissionCategory":
        return "permission_category";
      case "processingPurpose":
        return "processing_purpose";
      case "permissionType":
        return "permission_type";
      case "regulationName":
        return "regulation_name";
      case "expirationAux":
        return "expiration";
      case "activity_dateAux":
        return "activity_date";
      case "consentSource":
        return "consent_source";
      case "standardState":
        return "standard_country";
      case "standardCountry":
        return "standard_country";
      case "createdAtAux":
        return "created_at";
      case "externalSource":
        return "external_source";
      case "consentLanguage":
        return "consent_language";
      case "privacyNoticeVersion":
        return "privacy_notice_version";
      default:
        return field;
    }
  }

  onSortPerm(ev) {
    if (!ev.sorts || !ev.sorts.length) {
      return;
    }
    this.sortPerm =
      (ev.sorts[0].dir == "desc" ? "-" : "") +
      this.sortMapperPerm(ev.sorts[0].prop);
    console.log(this.sortPerm);
    this.fetchReport();
  }

  sortMapperComp(field) {
    switch (field) {
      case "createdAtAux":
        return "created_at";
      case "email":
        return "email_address";
      case "regulationName":
        return "regulation_name";
      case "activity_dateAux":
        return "activity_date";
      case "consentCode":
        return "consent_code";
      case "consentSource":
        return "consent_source";
      case "consentType":
        return "consent_type";
      case "standardState":
        return "standard_country";
      case "standardCountry":
        return "standard_country";
      case "externalSource":
        return "external_source";
      case "consentLanguage":
        return "consent_language";
      case "privacyNoticeVersion":
        return "privacy_notice_version";
      default:
        return field;
    }
  }

  onSortComp(ev) {
    if (!ev.sorts || !ev.sorts.length) {
      return;
    }
    this.sortComp =
      (ev.sorts[0].dir == "desc" ? "-" : "") +
      this.sortMapperComp(ev.sorts[0].prop);
    console.log(this.sortComp);
    this.fetchReport();
  }
}
