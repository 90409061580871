import { BaseApiService } from "../base/base-api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from "../custom-error-handler.service";
import { LegalActivity } from '../../../models/legal-activities/legal-activity.model';
import { LegalActivitySerializer } from '../../../models/legal-activities/legal-activity-serializer.class';


@Injectable()
export class LegalActivitiesService extends BaseApiService<LegalActivity> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      null,
      "legalactivities",
      new LegalActivitySerializer(),
      new CustomErrorHandlerService()
    );
  }

  // EXTEND/OVERRIDE THE SERVICE HERE IF NECESSARY
}
