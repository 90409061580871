import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {
  AuthService,
  ClientsService,
  LocalStorageService,
} from "@fourcomply-dashboard/shared";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { pipe } from "rxjs";
import { APIService } from "@fourcomply-dashboard/shared";
import { TranslateService } from "@ngx-translate/core";
import { PanelRef } from "@verizon/ngx-panels";

@Component({
  selector: "hitl-detail-panel",
  templateUrl: "./hitl-detail.component.html",
})
export class HitlDetailPanelComponent implements OnInit {
  public selectedTask: any;
  public goToWorkflow: any;
  public submitDeleteTask: any;
  public submitEditTask: any;
  public submitArchiveTask: any;
  public users: any;
  constructor(
    private auth: AuthService,
    public api: APIService,
    public translate: TranslateService,
    private localStorage: LocalStorageService,
    private router: Router,
    private readonly panelRef: PanelRef<any>
  ) {}
  ngOnInit() {
    console.log(this.panelRef);
    if (this.panelRef && this.panelRef.data) {
      this.selectedTask = this.panelRef.data.selectedTask;
      this.goToWorkflow = this.panelRef.data.goToWorkflow;
      this.submitDeleteTask = this.panelRef.data.submitDeleteTask;
      this.submitEditTask = this.panelRef.data.submitEditTask;
      this.users = this.panelRef.data.users;
      this.submitArchiveTask = this.panelRef.data.submitArchiveTask;
    }
  }
}
