<div class="container page" [style.display]="outlet.isActivated ? 'none' : ''">
  <div class="columns">
    <div class="column col-12">
      <app-generic-card>
        <app-actionbar
          title="{{ 'SIDEMENU.LANDING_PAGES' | translate }}"
        ></app-actionbar>
        <app-datatable
          #dtLandingPages
          tableIdentifier="landingPages"
          [data]="dataLandingPages"
          [columns]="columnsLandingPages"
          [actions]="actionsLandingPages"
        ></app-datatable>
      </app-generic-card>
    </div>
  </div>
</div>
<router-outlet #outlet="outlet"></router-outlet>

<app-modal #modalLandingPagePreview title="SIDEMENU.LANDING_PAGES">
  <div *ngIf="templateForPreview">
    <div
      style="max-width:600px;position: relative;"
      [innerHTML]="templateForPreview.html | safeHtml"
    ></div>
  </div>
</app-modal>
