<div class="container" [style.display]="outlet.isActivated ? 'none' : ''">
  <div class="column col-12">
    <app-generic-card>
      <app-actionbar
        title="{{ 'USER.USERS_TITLE' | translate }}"
        [actionBarItems]="actionBarItemsUsers"
      ></app-actionbar>
      <app-datatable
        tableIdentifier="users"
        #dtClientUsers
        [data]="dataClientUsers"
        [actions]="actionsUsers"
        [columns]="columnsClientUsers"
      ></app-datatable>
    </app-generic-card>
  </div>
</div>
<router-outlet
  #outlet="outlet"
  (deactivate)="onDeactivate($event)"
></router-outlet>
<app-modal
  #modalDeleteUser
  title="{{ 'MODAL_GENERIC.CONFIRM_DELETE' | translate }}"
  bodyContent="{{ 'USER.MODAL_DELETE_USER_CONFIRM' | translate }}"
  [confirmCallback]="deleteUser"
></app-modal>

<app-modal
  #modalResetPassword
  title="{{ 'MODAL_GENERIC.CONFIRM_RESET' | translate }}"
  bodyContent="{{ 'USER.MODAL_RESET_PASSWORD_CONFIRM' | translate }}"
  [confirmCallback]="resetPassword"
></app-modal>
