import { Resource } from "../resource/resource.model";

/**
 * Priority and level
 */

export class GeoIpException extends Resource {
  regulation_id: string;
  ip_range: string;
  geo_type: string;
  value: string;
}
