<div class="container">
  <div class="column col-5">
    <app-generic-card>
      <h3 class="section-title">
        {{ (isNew ? "USER.ADD_NEW" : "USER.EDIT") | translate }}
      </h3>
      <app-form
        #formAddUser
        [fields]="fields"
        [model]="model"
        [callback]="submitAddUser"
        closeRoute="users"
      ></app-form>
    </app-generic-card>
  </div>
</div>
