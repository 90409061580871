import {
  Component,
  OnInit,
  NgZone,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  AuthService,
  APIService,
  DatatableComponent,
  NotificationService,
  ModalComponent,
  FormComponent,
} from "@fourcomply-dashboard/shared";
import { HitlTask } from "libs/shared/src/lib/models/hitl-tasks/hitl-task.model";
import { HitlTemplate } from "libs/shared/src/lib/models/hitl-templates/hitl-template.model";
import { QueryOptions } from "libs/shared/src/lib/models/query-options/query-options.class";
import { pipe } from "rxjs";

@Component({
  selector: "app-hitl-configuration",
  templateUrl: "./hitl-configuration.component.html",
  styleUrls: ["./hitl-configuration.component.scss"],
})
export class HitlConfigurationComponent implements OnInit {
  private userId: string;
  @ViewChild("dtHitlTemplates", { static: true })
  dtHitlTemplates: DatatableComponent;
  @ViewChild("modalHitlConfig", { static: true })
  public modalHitlConfig: ModalComponent;
  @ViewChild("modalCreateTask", { static: true })
  public modalCreateTask: ModalComponent;
  pages: number;
  currentPage: number = 1;
  total: number;
  sort: string;
  pageSize: number = 10;
  dataHitlTemplates: HitlTemplate[];
  idForDeleteHitlConfig: any;
  modelCreateTask: any = {};
  fieldsCreateTask: any;
  loadingDone: boolean = false;
  workflows: any[];

  public columnsHitlTemplates = [
    { name: "HITL_TEMPLATE.TEMPLATE_NAME", prop: "name" },
    { name: "GENERIC.SHORT_DESCRIPTION", prop: "shortDescription" },
    { name: "GENERIC.DESCRIPTION", prop: "description" },
    { name: "HITL_TEMPLATE.TRIGGERING_ACTIONS", prop: "triggers" },
    { name: "HITL_TEMPLATE.USED_IN_WORKFLOWS", prop: "workflows" },
    { name: "HITL_TEMPLATE.TIMEOUT_BEHAVIOR", prop: "timeoutBehavior" },
    { name: "HITL_TEMPLATE.DECLINE_BEHAVIOR", prop: "declineBehavior" },
    { name: "GENERIC.ACTIONS", prop: "actions" },
  ];

  actionBarItemsHitl = [
    {
      btnText: "GENERIC.ADD_NEW",
      iconClass: "fas fa-plus",
      route: "add-hitl-config",
    },
  ];

  actionsHitl = [
    {
      iconClass: "fas fa-pen",
      toolTipText: "GENERIC.EDIT",
      callback: this.editHitlConfig.bind(this),
    },
    {
      iconClass: "fas fa-trash-alt",
      toolTipText: "GENERIC.DELETE",
      callback: this.deleteHitlConfig.bind(this),
    },
    {
      iconClass: "fas fa-plus",
      toolTipText: "GENERIC.CREATE_TASK",
      callback: this.createFromConfig.bind(this),
    },
  ];

  constructor(
    private auth: AuthService,
    private api: APIService,
    private zone: NgZone,
    private notification: NotificationService,
    private router: Router
  ) {
    this.submitCreateTask = this.submitCreateTask.bind(this);
    this.deleteHitlConfigConfirm = this.deleteHitlConfigConfirm.bind(this);
  }

  ngOnInit() {
    this.auth.userProfile$.subscribe(
      pipe((res: any) => {
        this.userId = res.currentTenant.tenant_id;
        this.getWorkflows();
      })
    );
  }

  getWorkflows() {
    return this.api.rightRequestConfigs.listWithPaging().subscribe((a) => {
      this.workflows = a.list;
      this.getTemplates();
    });
  }

  editHitlConfig(id) {
    this.router.navigate(["/add-hitl-config", id]);
  }

  deleteHitlConfig(id) {
    this.idForDeleteHitlConfig = id;
    this.modalHitlConfig.openModal();
  }

  createFromConfig(id) {
    this.modelCreateTask = {};
    this.modelCreateTask.templateId = id;
    this.modalCreateTask.openModal();
  }

  submitCreateTask() {
    console.log(this.modelCreateTask);
    let task: any = {};
    task.templateId = this.modelCreateTask.templateId;
    task.citizenEmail = this.modelCreateTask.citizenEmail;
    task.notes = this.modelCreateTask.notes;
    task.source = "manual";
    task.status = "created";
    console.log(task);
    this.api.hitlTasks.create(task).subscribe((a) => {
      this.modelCreateTask = {};
      this.notification.success("GENERIC.SUCCESSFUL_DATA_ADD");
      this.modalCreateTask.closeModal();
    });
  }

  closeModal() {
    this.modalCreateTask.closeModal();
  }

  deleteHitlConfigConfirm() {
    this.api.hitlTemplates.delete(this.idForDeleteHitlConfig).subscribe(
      (a) => {
        console.log(a);
        this.getTemplates();
        this.modalHitlConfig.closeModal();
        this.notification.success("GENERIC.SUCCESSFUL_DATA_DELETE");
      },
      (error) => {
        console.log(error);
        this.modalHitlConfig.closeModal();
      }
    );
  }

  getTemplates() {
    let qo = new QueryOptions();
    qo.page = this.currentPage;
    qo.pageSize = this.pageSize;
    qo.sorts = this.sort;
    this.api.hitlTemplates.listWithPaging(qo).subscribe((a) => {
      this.dataHitlTemplates = a.list.map((r) => {
        return {
          ...r,
          triggers: r.triggeringActions
            .filter((a) => !a.workflow_id)
            .map((a) => a.type),
          workflows: r.triggeringActions
            .filter((a) => a.workflow_id)
            .map((a) => {
              let strin;
              this.workflows.forEach((w) => {
                console.log(a, w);
                if (a.workflow_id === w.id) {
                  strin = a.name + " - " + w.requestType;
                }
              });
              return strin;
            }),
        };
      });
      this.pages = a.pages;
      this.currentPage = a.currentpage;
      this.total = a.total;
      this.pageSize = a.pagesize;
      this.dtHitlTemplates.updateRows(this.dataHitlTemplates);
      this.dtHitlTemplates.loaderVisibility(false);
    });
  }
}
