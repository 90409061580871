import { Client } from "./client.model";
import { Serializer } from "../resource/serializer.interface";

export class ClientSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): Client {
    const client = new Client();
    client.id = json.id;
    client.configId = json.config_id;
    client.name = json.name;
    client.metering = json.metering;
    client.tenantSuffix = json.tenant_suffix;
    client.createdAt = json.created_at;
    return client;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(client: Client): any {
    return {
      id: client.id,
      name: client.name,
      config_id: client.configId,
      metering: client.metering,
      tenant_suffix: client.tenantSuffix,
    };
  }
}
