import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { AuthGuard } from "@fourcomply-dashboard/shared";
import { TokenInterceptorService } from "@fourcomply-dashboard/shared";
import { UserInterceptorService } from "@fourcomply-dashboard/shared";

import { CallbackComponent } from "@fourcomply-dashboard/shared";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { AnalyticsComponent } from "./components/analytics/analytics.component";
import { UsersComponent } from "./components/users/users.component";
import { GeoComponent } from "./components/geo/geo.component";
import { AddEditGeoIpExceptionComponent } from "./components/add-edit-geo-ip-exception/add-edit-geo-ip-exception.component";
import { ConsentPermissionsComponent } from "./components/consent-permissions/consent-permissions.component";
import { ComplianceInputComponent } from "./components/compliance-inputs/compliance-inputs.component";
import { WebhooksComponent } from "./components/webhooks/webhooks.component";
import { SystemsComponent } from "./components/systems/systems.component";
import { BusinessUnitComponent } from "./components/business-unit/business-unit.component";
import { AddBusinessUnitComponent } from "./components/add-business-unit/add-business-unit.component";
import { CitizenHistoryComponent } from "./components/citizen-history/citizen-history.component";
import { AddClientWebhookComponent } from "./components/add-client-webhook/add-client-webhook.component";
import { AddClientSystemComponent } from "./components/add-client-system/add-client-system.component";
import { LegalActivityErrorsComponent } from "./components/legal-activity-errors/legal-activity-errors.component";
import { LegalActivitiesComponent } from "./components/legal-activities/legal-activities.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { ClientRegulationsComponent } from "./components/client-regulations/client-regulations.component";
import { ClientRegulationConfigurationsComponent } from "./components/client-regulation-configurations/client-regulation-configurations.component";
import { EditClientEmailTemplateComponent } from "./components/edit-client-email-template/edit-client-email-template.component";
import { RightRequestConfigurationComponent } from "./components/right-request-configurations/right-request-configurations.component";
import { ClientFormsComponent } from "./components/client-forms/client-forms.component";
import { ClientFormEditComponent } from "./components/client-form-edit/client-form-edit.component";
import { AddUserComponent } from "./components/add-user/add-user.component";
import { AddGeoMapComponent } from "./components/add-geo-map/add-geo-map.component";
import { ForgottenUsersComponent } from "./components/forgotten-users/forgotten-users.component";
import { RightRequestComponent } from "./components/right-request/right-request.component";
import { RightRequestDetailComponent } from "./components/right-request-detail/right-request-detail.component";
import { DashboardRightsComponent } from "./components/dashboard-rights/dashboard-rights.component";
import { HitlTasksComponent } from "./components/hitl-tasks/hitl-tasks.component";
import { HitlConfigurationComponent } from "./components/hitl-configuration/hitl-configuration.component";
import { AddHitlTemplateComponent } from "./components/add-hitl-template/add-hitl-template.component";
import { ClientEmailTemplateComponent } from "./components/client-email-template/client-email-template.component";
import { LandingPagesComponent } from "./components/landing-pages/landing-pages.component";
import { EditClientLandingPageTemplateComponent } from "./components/edit-client-landing-page-template/edit-client-landing-page-template.component";

@NgModule({
  imports: [
    RouterModule.forRoot([
    {
        path: "",
        redirectTo: "dashboard-consent",
        pathMatch: "full",
    },
    { path: "callback", component: CallbackComponent, data: { title: "" } },
    { path: "dashboard", redirectTo: "dashboard-consent", pathMatch: "full" },
    {
        path: "dashboard-consent",
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: {
            title: "4Comply",
            breadcrumb: {
                label: "Dashboard",
            },
        },
    },
    {
        path: "dashboard-rights",
        component: DashboardRightsComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Rights Dashboard",
            breadcrumb: {
                label: "Rights Dashboard",
            },
        },
    },
    {
        path: "hitl-tasks",
        component: HitlTasksComponent,
        canActivate: [AuthGuard],
        data: {
            title: "HITL Tasks",
            breadcrumb: {
                label: "HITL Tasks",
            },
        },
    },
    {
        path: "hitl_tasks",
        component: HitlTasksComponent,
        canActivate: [AuthGuard],
        data: {
            title: "HITL Tasks",
            breadcrumb: {
                label: "HITL Tasks",
            },
        },
    },
    {
        path: "hitl-config",
        component: HitlConfigurationComponent,
        canActivate: [AuthGuard],
        data: {
            title: "HITL Configuration",
            breadcrumb: {
                label: "HITL Configuration",
            },
        },
    },
    {
        path: "add-hitl-config",
        component: AddHitlTemplateComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Add New Hitl Template",
            breadcrumb: {
                label: "Add New Hitl Templates",
            },
        },
    },
    {
        path: "add-hitl-config/:id",
        component: AddHitlTemplateComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Edit Hitl Template",
            breadcrumb: {
                label: "Edit Hitl Template",
            },
        },
    },
    {
        path: "rights-flows",
        component: RightRequestConfigurationComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Rights Config",
            breadcrumb: {
                label: "Rights Config",
            },
        },
    },
    {
        path: "users",
        component: UsersComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Users",
            breadcrumb: {
                label: "Users",
            },
        },
        children: [
            {
                path: "add",
                component: AddUserComponent,
                canActivate: [AuthGuard],
                data: {
                    title: "Add User",
                    breadcrumb: {
                        label: "Add New User",
                    },
                },
            },
            {
                path: ":id",
                component: AddUserComponent,
                canActivate: [AuthGuard],
                data: {
                    title: "Edit User",
                    breadcrumb: {
                        alias: "webhookName",
                        label: "Edit User",
                    },
                },
            },
        ],
    },
    {
        path: "geo",
        component: GeoComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Geo",
            breadcrumb: {
                label: "Geo",
            },
        },
        children: [
            {
                path: "add-ip-exception",
                component: AddEditGeoIpExceptionComponent,
                canActivate: [AuthGuard],
                data: {
                    title: "Add New Geo IP Exception",
                    breadcrumb: {
                        label: "Add new geo IP exception",
                    },
                },
            },
            {
                path: "ip-exception/:id",
                component: AddEditGeoIpExceptionComponent,
                canActivate: [AuthGuard],
                data: {
                    title: "Edit Geo IP Exception",
                    breadcrumb: {
                        label: "Edit geo IP exception",
                    },
                },
            },
            {
                path: "add-map",
                component: AddGeoMapComponent,
                canActivate: [AuthGuard],
                data: {
                    title: "Add New Geo Map",
                    breadcrumb: {
                        label: "Add new geo map",
                    },
                },
            },
            {
                path: ":id",
                component: AddGeoMapComponent,
                canActivate: [AuthGuard],
                data: {
                    title: "Edit Geo Map",
                    breadcrumb: {
                        label: "Edit Geo Map",
                    },
                },
            },
        ],
    },
    {
        path: "analytics",
        component: AnalyticsComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Analytics",
            breadcrumb: {
                label: "Analytics",
            },
        },
    },
    {
        path: "consent-permissions",
        component: ConsentPermissionsComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Permissions",
            breadcrumb: {
                label: "Permissions",
            },
        },
    },
    {
        path: "compliance-inputs",
        component: ComplianceInputComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Compliance Inputs",
            breadcrumb: {
                label: "Compliance Inputs",
            },
        },
    },
    {
        path: "webhooks",
        component: WebhooksComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Webhooks",
            breadcrumb: {
                label: "Webhooks",
            },
        },
        children: [
            {
                path: "add",
                component: AddClientWebhookComponent,
                canActivate: [AuthGuard],
                data: {
                    title: "Add New Webhook",
                    breadcrumb: {
                        label: "Add New Webhooks",
                    },
                },
            },
            {
                path: ":id",
                component: AddClientWebhookComponent,
                canActivate: [AuthGuard],
                data: {
                    title: "Edit Webhook",
                    breadcrumb: {
                        alias: "webhookName",
                        label: "Edit Webhook",
                    },
                },
            },
        ],
    },
    {
        path: "business-units",
        component: BusinessUnitComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Business-Units",
            breadcrumb: {
                label: "Business Units",
            },
        },
        children: [
            {
                path: "add",
                component: AddBusinessUnitComponent,
                canActivate: [AuthGuard],
                data: {
                    title: "Add New Business Unit",
                    breadcrumb: {
                        label: "Add New Business Unit",
                    },
                },
            },
            {
                path: "edit/:id",
                component: AddBusinessUnitComponent,
                canActivate: [AuthGuard],
                data: {
                    title: "Edit Business Unit",
                    breadcrumb: {
                        label: "Edit Business Unit",
                    },
                },
            },
        ],
    },
    {
        path: "systems",
        component: SystemsComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Systems",
            breadcrumb: {
                label: "Systems",
            },
        },
        children: [
            {
                path: "add",
                component: AddClientSystemComponent,
                canActivate: [AuthGuard],
                data: {
                    title: "Add New System",
                    breadcrumb: {
                        label: "Add New Systems",
                    },
                },
            },
            {
                path: "edit/:id",
                component: AddClientSystemComponent,
                canActivate: [AuthGuard],
                data: {
                    title: "Edit System",
                    breadcrumb: {
                        label: "Edit Systems",
                    },
                },
            },
        ],
    },
    {
        path: "citizen-history",
        component: CitizenHistoryComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Citizen History",
            breadcrumb: {
                label: "Citizen History",
            },
        },
    },
    {
        path: "legal-activity-errors",
        component: LegalActivityErrorsComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Legal Activity Errors",
            breadcrumb: {
                label: "Legal Activity Errors",
            },
        },
    },
    {
        path: "legal-activities",
        component: LegalActivitiesComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Legal Activities",
            breadcrumb: {
                label: "Legal Activities",
            },
        },
    },
    {
        path: "settings",
        component: SettingsComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Settings",
            breadcrumb: {
                label: "Settings",
            },
        },
    },
    {
        path: "client-regulations",
        component: ClientRegulationsComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Client Regulations",
            breadcrumb: {
                label: "Client Regulations",
            },
        },
        children: [
            {
                path: "configuration/:id",
                component: ClientRegulationConfigurationsComponent,
                canActivate: [AuthGuard],
                data: {
                    title: "Client Regulation Configurations",
                    breadcrumb: {
                        alias: "clientRegulationTitle",
                        label: "Client Regulation Configurations",
                    },
                },
                children: [
                    {
                        path: ":type",
                        component: RightRequestConfigurationComponent,
                        canActivate: [AuthGuard],
                        data: { title: "clientRegulationConfigurationTitle" },
                    },
                ],
            },
        ],
    },
    {
        path: "forms",
        component: ClientFormsComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Client Forms",
            breadcrumb: {
                label: "Client Forms",
            },
        },
        children: [
            {
                path: "add",
                component: ClientFormEditComponent,
                canActivate: [AuthGuard],
                data: {
                    title: "Add New Client Form",
                    breadcrumb: {
                        label: "Add New Client Form",
                    },
                },
            },
            {
                path: ":id",
                component: ClientFormEditComponent,
                canActivate: [AuthGuard],
                data: {
                    title: "Edit Client Form",
                    breadcrumb: {
                        alias: "formTitle",
                        label: "Edit Client Form",
                    },
                },
            },
        ],
    },
    {
        path: "forgotten-users",
        component: ForgottenUsersComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Forgotten Citizens",
            breadcrumb: {
                label: "Forgotten Citizens",
            },
        },
    },
    {
        path: "right-requests",
        component: RightRequestComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Right Request",
            breadcrumb: {
                label: "Right Requests",
            },
        },
        children: [
            {
                path: ":id",
                component: RightRequestDetailComponent,
                canActivate: [AuthGuard],
                data: {
                    title: "Right Request Detail",
                    breadcrumb: {
                        label: "Edit Right Request",
                    },
                },
            },
        ],
    },
    {
        path: "edit-landing-pages/:type",
        component: EditClientLandingPageTemplateComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Edit Landing Page Template",
            breadcrumb: {
                label: "Edit Landing Page Template",
            },
        },
    },
    {
        path: "landing-pages",
        component: LandingPagesComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Landing Pages",
            breadcrumb: {
                label: "Landing Pages",
            },
        },
    },
    {
        path: "email-templates",
        component: ClientEmailTemplateComponent,
        canActivate: [AuthGuard],
        data: {
            title: "Email Templates",
            breadcrumb: {
                label: "Email Templates",
            },
        },
        children: [
            {
                path: ":id",
                component: EditClientEmailTemplateComponent,
                canActivate: [AuthGuard],
                data: {
                    title: "Edit Email Template",
                    breadcrumb: {
                        label: "Edit Email Template",
                    },
                },
            },
        ],
    },
], { relativeLinkResolution: 'legacy' }),
  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserInterceptorService,
      multi: true,
    },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
