<div class="container page" [style.display]="outlet.isActivated ? 'none' : ''">
  <div class="column col-12">
    <app-generic-card>
      <app-actionbar
        title="{{ 'BASIC_ENTITIES_PLURAL.RIGHT_REQUESTS' | translate }}"
        [actionBarItems]="[]"
        [subtitle]="
          total != null && total != undefined
            ? isEstimate
              ? 'around ' + total + ' items'
              : total + ' total items'
            : null
        "
      ></app-actionbar>
      <app-datatable
        #dtRightRequest
        [data]="dataRightRequest"
        (sortEvent)="onSort($event)"
        tableIdentifier="rightRequests"
        [columns]="columnsRightRequest"
        [actions]="actionsRightRequest"
        enableSearch="true"
        placeholder="{{ 'GENERIC.ENTER_VALUE_SEARCH' | translate }}"
        [buttonTitle]="'GENERIC.SEARCH' | translate"
        [disabled]="disabled"
        (search)="searchWithEmail($event)"
      ></app-datatable>
    </app-generic-card>
    <app-pagination
      *ngIf="pages"
      [pages]="pages"
      [currentPageSize]="pageSize"
      [currentPage]="currentPage"
      (setPageSize)="setPageSize($event)"
      (setCurrentPage)="setCurrentPage($event)"
    ></app-pagination>
  </div>
</div>
<router-outlet #outlet="outlet"></router-outlet>
