import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class NotificationService {
  private translate: TranslateService;
  constructor(
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) {
    this.translate = this.translateService;
  }

  success(msg: string) {
    this.toastrService.success(this.translate.instant(msg), "", {
      positionClass: "toast-bottom-left",
      extendedTimeOut: 5000,
      timeOut: 7000,
      progressBar: true,
    });
  }

  error(msg: string) {
    this.toastrService.error(this.translate.instant(msg), "", {
      positionClass: "toast-bottom-left",
      extendedTimeOut: 5000,
      timeOut: 7000,
      progressBar: true,
    });
  }
}
