import { Component, OnInit, ViewChild } from "@angular/core";
import {
  DatatableComponent,
  LegalActivityError,
  APIService,
  LocalStorageService,
} from "@fourcomply-dashboard/shared";
import { QueryOptions } from "libs/shared/src/lib/models/query-options/query-options.class";
import { DatePipe } from "@angular/common";

@Component({
  selector: "fourcomply-dashboard-legal-activity-errors",
  templateUrl: "./legal-activity-errors.component.html",
  styleUrls: ["./legal-activity-errors.component.scss"],
})
export class LegalActivityErrorsComponent implements OnInit {
  @ViewChild("dtLegalActivityErrors", { static: true })
  dtLegalActivityErrors: DatatableComponent;
  pages: number;
  currentPage: number = 1;
  total: number;
  isEstimate: boolean = false;
  searchText: string;
  disabled: boolean = false;
  sort: string;
  pageSize: number = 10;
  dataLegalActivityErrors: LegalActivityError[];
  columnsLegalActivityErrors: any[];

  constructor(
    private api: APIService,
    private localStorage: LocalStorageService
  ) {}

  ngOnInit() {
    this.getStoredPageSize();
    this.fetchLegalActivityErrors();
  }

  getStoredPageSize() {
    this.pageSize = this.localStorage.get("LIST_PAGE_SIZE")
      ? Number(this.localStorage.get("LIST_PAGE_SIZE"))
      : 10;
  }

  setCurrentPage(ev) {
    this.dtLegalActivityErrors.loaderVisibility(true);
    this.currentPage = ev;
    this.fetchLegalActivityErrors();
  }

  setPageSize(ev) {
    this.dtLegalActivityErrors.loaderVisibility(true);
    this.pageSize = ev;
    this.localStorage.set("LIST_PAGE_SIZE", this.pageSize.toString());
    this.currentPage = 1;
    this.fetchLegalActivityErrors();
  }

  sortMapper(field) {
    switch (field) {
      case "createdAtAux":
        return "created_at";
      case "activityAtAux":
        return "activity_at";
      case "emailAddress":
        return "email_address";
      case "activityType":
        return "activity_type";
      case "standardCountry":
        return "standard_country";
      case "standardState":
        return "standard_state";
      case "consentCode":
        return "consent_code";
      case "regulationName":
        return "regulation_name";
      case "rightWorkflowId":
        return "right_workflow_id";
      case "rightWorkflowStepName":
        return "right_workflow_step_name";
      case "rejectReason":
        return "reject_reason";
      case "inputState":
        return "input_state";
      case "inputCountry":
        return "input_country";
      default:
        return field;
    }
  }

  onSort(ev) {
    if (!ev.sorts || !ev.sorts.length) {
      return;
    }
    this.sort =
      (ev.sorts[0].dir == "desc" ? "-" : "") +
      this.sortMapper(ev.sorts[0].prop);
    this.fetchLegalActivityErrors();
  }

  fetchLegalActivityErrors() {
    this.columnsLegalActivityErrors = [
      { name: "GENERIC.EMAIL_ADDRESS", prop: "emailAddress", width: 120 },
      {
        name: "LEGAL_ACTIVITIES.ACTIVITY_TYPE_FIELD",
        prop: "activityType",
        width: 120,
      },
      { name: "GENERIC.ACTIVITY_DATE", prop: "activityAtAux", width: 120 },
      { name: "GENERIC.CREATED_AT", prop: "createdAtAux", width: 120 },
      { name: "GEO.STANDARD_STATE", prop: "standardState", width: 120 },
      { name: "GEO.STANDARD_COUNTRY", prop: "standardCountry", width: 120 },
      { name: "GEO.INPUT_STATE", prop: "inputState", width: 120 },
      { name: "GEO.INPUT_COUNTRY", prop: "inputCountry", width: 120 },
      {
        name: "BASIC_ENTITIES_SINGULAR.CONSENT_CODE",
        prop: "consentCode",
        width: 120,
      },
      {
        name: "RIGHTS_REQUESTS.REGULATION_NAME",
        prop: "regulationName",
        width: 120,
      },
      {
        name: "CITIZEN_HISTORY.RIGHT_WORKFLOW_ID",
        prop: "rightWorkflowId",
        width: 120,
      },
      {
        name: "CITIZEN_HISTORY.RIGHT_WORKFLOW_STEP_NAME",
        prop: "rightWorkflowStepName",
        width: 120,
      },
      {
        name: "LEGAL_ACTIVITIES.REJECT_REASON",
        prop: "rejectReason",
        width: 120,
      },
      { name: "BASIC_ENTITIES_SINGULAR.SYSTEM", prop: "system", width: 120 },
      {
        name: "BASIC_ENTITIES_SINGULAR.PERMISSION_TYPE",
        prop: "permission_type",
        width: 120,
      },
      {
        name: "BASIC_ENTITIES_SINGULAR.BUSINESS_UNIT",
        prop: "business_unit",
        width: 120,
      },
      { name: "GENERIC.EXTERNAL_SOURCE", prop: "external_source", width: 120 },
      {
        name: "BASIC_ENTITIES_SINGULAR.PROCESSING_PURPOSE",
        prop: "processing_purpose",
        width: 120,
      },
    ];

    let qo = new QueryOptions();
    qo.page = this.currentPage;
    qo.pageSize = this.pageSize;
    qo.sorts = this.sort;

    if (this.searchText) {
      qo.filter = "email_address@=" + encodeURIComponent(this.searchText) + "";
    }

    this.api.legalActivityErrors.listWithPaging(qo).subscribe((a) => {
      this.dataLegalActivityErrors = a.list;
      this.pages = a.pages;
      this.currentPage = a.currentpage;
      this.total =
        this.searchText || Number(a.total) < 1000
          ? Number(a.total)
          : a.totalunfiltered
          ? Number(a.totalunfiltered)
          : Number(a.total);
      this.isEstimate =
        this.searchText || Number(a.total) < 1000 ? false : true;
      this.pageSize = a.pagesize;
      this.dataLegalActivityErrors.forEach(function (value) {
        value.createdAtAux = new DatePipe("en-US").transform(
          value.createdAt,
          "MM/dd/yyyy hh:mm zzz"
        );
        value.activityAtAux = new DatePipe("en-US").transform(
          value.activityAt,
          "MM/dd/yyyy hh:mm zzz"
        );
      });
      this.dtLegalActivityErrors.updateRows(this.dataLegalActivityErrors);
      this.dtLegalActivityErrors.loaderVisibility(false);
    });
  }

  searchWithEmail(ev) {
    this.searchText = ev;
    this.currentPage = 1;
    this.dtLegalActivityErrors.updateRows([]);
    this.fetchLegalActivityErrors();
  }
}
