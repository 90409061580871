import { Component, OnInit, ViewChild } from "@angular/core";
import { FormlyFieldConfig } from "@ngx-formly/core";
import {
  ClientUser,
  APIService,
  NotificationService,
  FormComponent,
} from "@fourcomply-dashboard/shared";
import { ActivatedRoute, Router } from "@angular/router";
import { map, tap, catchError, finalize } from "rxjs/operators";
import { Observable } from "rxjs";

@Component({
  selector: "fourcomply-dashboard-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit {
  @ViewChild("formAddUser", { static: true }) formAddUser: FormComponent;
  fields: FormlyFieldConfig[] = [];
  formSubmitted = false;
  isNew = true;
  model: any = {
    createdAt: new Date(),
    emailAddress: "",
    externalId: "",
    firstName: "",
    id: null,
    lastName: "",
    permissions: null,
    updatedAt: new Date(),
    userLevel: "",
  };

  constructor(
    public api: APIService,
    public router: Router,
    private route: ActivatedRoute,
    public notification: NotificationService
  ) {
    this.submitAddUser = this.submitAddUser.bind(this);
  }

  ngOnInit() {
    this.initUser();
  }

  initUser() {
    const id = this.route.snapshot.params["id"];
    console.log(id);
    if (id) {
      this.isNew = false;
      this.api.clientUsers.get(id).subscribe((a) => {
        console.log(a);
        this.model = { ...a };
        console.log(this.model);
        this.fields = [
          {
            key: "firstName",
            type: "input",
            templateOptions: {
              required: true,
              label: "USER.FIRST_NAME_FIELD",
            },
          },
          {
            key: "lastName",
            type: "input",
            templateOptions: {
              required: true,
              label: "USER.LAST_NAME_FIELD",
            },
          },
          {
            key: "emailAddress",
            type: "input",
            templateOptions: {
              disabled: true,
              type: "email",
              required: true,
              label: "GENERIC.EMAIL_ADDRESS",
            },
          },
          {
            key: "userLevel",
            type: "select",
            templateOptions: {
              label: "USER.USER_LEVEL",
              options: [
                { key: "admin", name: "USER.ADMIN_LEVEL" },
                { key: "basic", name: "USER.BASIC_LEVEL" },
                { key: "config", name: "USER.CONFIG_LEVEL" },
                { key: "legal", name: "USER.LEGAL_LEVEL" },
              ],
              valueProp: "key",
              labelProp: "name",
              required: true,
              placeholder: "GENERIC.SELECT_ONE",
            },
          },
        ];
        this.formAddUser.updateModel(this.model);
      });
    } else {
      this.model = {};
      this.fields = [
        {
          key: "firstName",
          type: "input",
          templateOptions: {
            required: true,
            label: "USER.FIRST_NAME_FIELD",
          },
        },
        {
          key: "lastName",
          type: "input",
          templateOptions: {
            required: true,
            label: "USER.LAST_NAME_FIELD",
          },
        },
        {
          key: "emailAddress",
          type: "input",
          templateOptions: {
            type: "email",
            required: true,
            label: "GENERIC.EMAIL_ADDRESS",
          },
        },
        {
          key: "userLevel",
          type: "select",
          templateOptions: {
            label: "USER.USER_LEVEL",
            options: [
              { key: "admin", name: "USER.ADMIN_LEVEL" },
              { key: "basic", name: "USER.BASIC_LEVEL" },
              { key: "config", name: "USER.CONFIG_LEVEL" },
              { key: "legal", name: "USER.LEGAL_LEVEL" },
            ],
            valueProp: "key",
            labelProp: "name",
            required: true,
            placeholder: "GENERIC.SELECT_ONE",
          },
        },
      ];
    }
  }

  submitAddUser() {
    const id = this.route.snapshot.params["id"];
    if (id) {
      this.api.clientUsers.update(this.model).subscribe(
        (a) => {
          this.notification.success("GENERIC.SUCCESSFUL_DATA_UPDATE");
          this.router.navigate(["users"]);
        },
        (err) => {
          this.formAddUser.loaderVisibility(false);
        }
      );
    } else {
      this.api.clientUsers.create(this.model).subscribe(
        (a) => {
          this.notification.success("GENERIC.SUCCESSFUL_DATA_ADD");
          this.router.navigate(["users"]);
        },
        (err) => {
          this.formAddUser.loaderVisibility(false);
        }
      );
    }
  }
}
