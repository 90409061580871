import { Resource } from "../resource/resource.model";
/**
 * The Base API Resource
 * This is extensible to cover all other API resources
 */

export class ResourceSQL extends Resource {
  system: string;
  systemId: string;
  systemType: string;
  systemRecordType: string;
  systemRecordId: string;
  createdAt: Date;
  updatedAt: Date;
}
