import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  EventEmitter,
} from "@angular/core";
import {
  APIService,
  AuthService,
  Client,
  DatatableComponent,
  ClientEmailTemplate,
  DatalistActionItem,
  KeyValuePair,
  NotificationService,
  ModalComponent,
} from "@fourcomply-dashboard/shared";
import { NavigationEnd, Router, RoutesRecognized } from "@angular/router";
import { templateJitUrl } from "@angular/compiler";
import { pipe, Subscription } from "rxjs";
import { filter, pairwise } from "rxjs/operators";

@Component({
  selector: "app-landing-pages", //'fourcomply-dashboard-landing-pages',
  templateUrl: "./landing-pages.component.html",
})
export class LandingPagesComponent implements OnInit {
  @ViewChild("dtLandingPages")
  dtLandingPages: DatatableComponent;

  @ViewChild("modalLandingPagePreview")
  modalLandingPagePreview: ModalComponent;

  dataLandingPages: any[];
  columnsLandingPages: any[];
  actionsLandingPages: DatalistActionItem[];
  templateForPreview: any = null;
  public userid: string;

  navigationSubscription: Subscription;

  constructor(
    private api: APIService,
    private router: Router,
    private auth: AuthService
  ) {}

  ngOnInit() {
    //this.goToEmailTemplateEdit = this.goToEmailTemplateEdit.bind(this);
    this.previewLandingPage = this.previewLandingPage.bind(this);

    this.auth.userProfile$.subscribe(
      pipe((res: any) => {
        this.userid = res.currentTenant.tenant_id;
        this.fetchLandingPages();
      })
    );
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  previewLandingPage(id) {
    console.log(id);
    console.log(this.dataLandingPages);
    let t = this.dataLandingPages.find((t) => t.id === id);
    this.templateForPreview = {
      ...t,
      html: unescape(t.hydrated),
    };
    this.modalLandingPagePreview.openModal();
  }

  fetchLandingPages() {
    this.actionsLandingPages = [
      /* {
        iconClass: "fas fa-pen",
        toolTipText: "GENERIC.CUSTOMIZE",
        callback: this.goToEmailTemplateEdit,
      },*/
      {
        iconClass: "fas fa-link",
        toolTipText: "GENERIC.PREVIEW",
        callback: this.previewLandingPage,
      },
      {
        iconClass: "fas fa-pen",
        toolTipText: "GENERIC.CUSTOMIZE",
        callback: (type) => {
          this.router.navigate(["/edit-landing-pages/" + type]);
        },
      },
    ];

    this.columnsLandingPages = [
      { name: "GENERIC.NAME", prop: "type", sortable: false },
      { name: "GENERIC.ACTIONS", prop: "actions", sortable: false },
    ];

    this.api.clients.getLandingPages(this.userid).subscribe((a) => {
      console.log(a);
      this.dataLandingPages = a.data.pages.map((p) => {
        return {
          ...p,
          id: p.type,
        };
      });
      this.dtLandingPages.updateRows(this.dataLandingPages);
      this.dtLandingPages.loaderVisibility(false);
    });
  }
}
