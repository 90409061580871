import { Injectable } from "@angular/core";
import {
  from,
  of,
  Observable,
  combineLatest,
  throwError,
  BehaviorSubject,
} from "rxjs";
import createAuth0Client from "@auth0/auth0-spa-js";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import { tap, catchError, concatMap, shareReplay } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class AuthService {
  auth0Client$ = (from(
    createAuth0Client({
      domain: environment.auth.CLIENT_DOMAIN,
      client_id: environment.auth.CLIENT_ID,
      audience: environment.auth.AUDIENCE,
      scope: "openid profile email",
      redirect_uri: `${window.location.origin}/callback`,
    })
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError((err) => throwError(err))
  );

  token$ = new BehaviorSubject<string>(null);
  claims$ = new BehaviorSubject<string>(null);
  navOpen = true;
  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated()))
  );
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );
  // Create subject and public observable of user profile data
  userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  // Create a local property for login status
  loggedIn: boolean = null;

  getTokenSilently$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.getTokenSilently()))
  );

  getIdClaims$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.getIdTokenClaims()))
  );

  constructor(private router: Router) {}

  // getUser$() is a method because options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options)))
    );
  }

  localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user data
          return combineLatest(
            this.getUser$(),
            this.getTokenSilently$,
            this.getIdClaims$
          );
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );
    const checkAuthSub = checkAuth$.subscribe(
      (response: { [key: string]: any } | boolean) => {
        // If authenticated, response will be user object
        // If not authenticated, response will be 'false'
        // Set subjects appropriately
        if (response) {
          const user = response[0];
          const token = response[1];
          const claims = response[2];
          //console.log(token, claims);
          // Add Current Tenant ID
          user.tenants = this.getTenantsFromUser(user);
          let tenantForce = new URL(location.href).searchParams.get("tenant");
          if (tenantForce) {
            user.currentTenant = user.tenants.find(
              (t) => t.tenant_id === tenantForce
            );
            localStorage.setItem("currentTenantId", tenantForce);
          } else if (localStorage.getItem("currentTenantId")) {
            user.currentTenant = user.tenants.find(
              (t) => t.tenant_id === localStorage.getItem("currentTenantId")
            );
          } else {
            user.currentTenant = user.tenants[0];
          }

          this.userProfileSubject$.next(user);
          this.token$.next(token);
          this.claims$.next(claims);
          this.loggedIn = true;
        } else {
          this.loggedIn = false;
        }
        // Clean up subscription
        checkAuthSub.unsubscribe();
      }
    );
  }

  changeTenant(tenant): Observable<any> {
    return this.userProfileSubject$.pipe(
      tap((res) => {
        res.tenants.forEach((t) => {
          console.log(tenant, t);
          if (t.tenant_id === tenant.tenant_id) {
            res.currentTenant = t;
            localStorage.setItem("currentTenantId", t.tenant_id);
            location.replace(location.toString().split("/")[0]); // back to dashboard
          }
        });
      })
    );
  }

  getTenantsFromUser(user) {
    const tenantMaps = [];
    for (const keyl in user) {
      if (
        keyl.includes(
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/roles/"
        )
      ) {
        const tenant_id = keyl.replace(
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/roles/",
          ""
        );
        tenantMaps.push({
          tenant_id: tenant_id,
          roles: user[keyl],
          name: user["http://tenant.name/" + tenant_id],
        });
      }
    }
    return tenantMaps;
  }

  login(redirectPath: string = "/") {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: `${window.location.origin}/callback`,
        appState: { target: redirectPath },
      });
    });
  }

  getLoggedInUserEmail() {
    let email;
    this.userProfileSubject$.pipe(
      tap((res) => {
        console.log(email);
        email = res.email;
      })
    );
    return email;
  }

  handleAuthCallback() {
    // Only the callback component should call this method
    // Call when app reloads after user logs in with Auth0
    let targetRoute: string; // Path to redirect to after login processsed
    // Ensure Auth0 client instance exists
    const authComplete$ = this.auth0Client$.pipe(
      // Have client, now call method to handle auth callback redirect
      concatMap(() => this.handleRedirectCallback$),
      tap((cbRes) => {
        console.log(cbRes);
        // Get and set target redirect route from callback results
        targetRoute =
          cbRes.appState && cbRes.appState.target ? cbRes.appState.target : "/";
      }),
      concatMap(() => {
        // Redirect callback complete; create stream
        // returning user data and authentication status
        return combineLatest(
          this.getUser$(),
          this.getTokenSilently$,
          this.getIdClaims$,
          this.isAuthenticated$
        );
      })
    );
    // Subscribe to authentication completion observable
    // Response will be an array of user and login status
    authComplete$.subscribe(([user, token, claims, loggedIn]) => {
      //console.log(token);
      // Update subjects and loggedIn property
      const claims2: any = claims;
      //console.log(token, claims);
      // Add Current Tenant ID
      user.tenants = this.getTenantsFromUser(user);
      user.currentTenant = user.tenants[0];
      localStorage.setItem("currentTenantId", user.currentTenant.tenant_id);
      this.userProfileSubject$.next(user);
      this.token$.next(token);
      this.claims$.next(claims2);
      this.loggedIn = loggedIn;
      // Redirect to target route after callback processing
      this.router.navigate([targetRoute]);
    });
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        client_id: environment.auth.CLIENT_ID,
        returnTo: `${window.location.origin}`,
      });
    });
  }
}
