import { Component, OnInit, ViewChild, NgZone } from "@angular/core";
import { FormlyFieldConfig } from "@ngx-formly/core";
import {
  AuthService,
  APIService,
  ModalComponent,
  ComplianceInput,
  GaugeOptions,
  PieOptions,
  ConsentSummaryByCountry,
  GeoSummary,
  PermissionSummaryByCountry,
  DatatableComponent,
} from "@fourcomply-dashboard/shared";
import { pipe } from "rxjs";
import { ClientUser } from "@fourcomply-dashboard/shared";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldHigh from "@amcharts/amcharts4-geodata/worldHigh";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { ChartOptions, ChartType, ChartDataSets } from "chart.js";
import { Label, Color } from "ng2-charts";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  @ViewChild("dtGeoEntries")
  dtGeoEntries: DatatableComponent;
  geoEntries: any = [];
  columnsGeo: any[] = [
    { name: "BASIC_ENTITIES_SINGULAR.GEO", prop: "geo_name" },
    { name: "GENERIC.COUNT", prop: "number" },
  ];
  geoType: string = "Country";
  total: number = 0;
  indeterminateAmounts: boolean = false;
  public chartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
      },
    },
  };

  public mapType: string = "consent";

  public chartLabels: Label[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  public chartType: ChartType = "bar";
  public chartLegend = true;

  public chartRTRData: ChartDataSets[] = [
    { data: [], label: "Right to Review" },
  ];
  chartRTRLabel: Label[] = [];

  public chartRTRColors: Color[] = [
    {
      backgroundColor: "#A5D6A7", //green
    },
  ];

  public chartRTUData: ChartDataSets[] = [
    { data: [], label: "Right to Update" },
  ];
  chartRTULabel: Label[] = [];
  public chartRTUColors: Color[] = [
    {
      backgroundColor: "#4FC3F7", //blue
    },
  ];

  public chartRTPData: ChartDataSets[] = [
    { data: [], label: "Right to Portability" },
  ];
  chartRTPLabel: Label[] = [];
  public chartRTPColors: Color[] = [
    {
      backgroundColor: "#FFF59D", //yellow
    },
  ];

  public chartRTBFData: ChartDataSets[] = [
    { data: [], label: "Right to be Forgotten" },
  ];
  chartRTBFLabel: Label[] = [];
  public chartRTBFColors: Color[] = [
    {
      backgroundColor: "#FF8A65", //red
    },
  ];

  public chartTotalLabels: Label[] = [
    "Jan 19",
    "Feb 19",
    "Mar 19",
    "Apr 19",
    "May 19",
    "Jun 19",
  ];
  public chartTotalData: ChartDataSets[] = [
    { data: [12, 8, 25, 35, 11, 21], label: "Right to Review" },
    { data: [4, 8, 1, 2, 1, 3], label: "Right to Update" },
    { data: [1, 2, 1, 2, 1, 1], label: "Right to Portability" },
    { data: [1, 0, 0, 0, 1, 1], label: "Right to be Forgotten" },
  ];
  public chartTotalColors: Color[] = [
    {
      backgroundColor: "#A5D6A7", //green
    },
    {
      backgroundColor: "#4FC3F7", //blue
    },
    {
      backgroundColor: "#FFF59D", //yellow
    },
    {
      backgroundColor: "#FF8A65", //red
    },
  ];

  gaugeData: any[];
  gaugeOptions: GaugeOptions;
  pieData: any[];
  pieOptions: PieOptions;

  userName = "";
  data = [];
  model: any = {};
  columns = [];
  selectedRegulation: any = 0;
  fields: FormlyFieldConfig[] = [];
  formSubmitted = false;
  submitUserName = "";
  regulations: any[] = [];
  permissionType: string;
  public clientUsers: ClientUser[];
  public complianceInputs: ComplianceInput[];
  rtaTotal = 0;
  rtuTotal = 0;
  rtpTotal = 0;
  rtbfTotal = 0;
  userId: string;
  explicitPermissionTypes: any[] = [];
  selectedPermissionType: string;
  sort: string;
  translate: TranslateService;
  public loading: boolean;

  @ViewChild(ModalComponent, { static: true })
  public childModal: ModalComponent;

  constructor(
    private auth: AuthService,
    private api: APIService,
    private zone: NgZone,
    private translateService: TranslateService
  ) {
    this.translate = this.translateService;
  }

  ngOnInit() {
    this.columns = [
      { name: "GENERIC.NAME", prop: "name" },
      { name: "GENERIC.ID", prop: "id" },
    ];
    this.fetchClientUsers();
    this.getRegulations();
    this.auth.userProfile$.subscribe(
      pipe((res: any) => {
        this.userId = res.currentTenant.tenant_id;
        this.getClient();
        setTimeout(() => {
          this.loadAnyMapDataGeo();
        }, 300);
      })
    );
  }

  getClient() {
    this.api.clients.getPermissionTypes(this.userId).subscribe((a) => {
      a.unshift({
        name: "All",
      });
      this.explicitPermissionTypes = a;
      this.selectedPermissionType = a[0].name;
    });
  }

  getMapData(id: number): void {
    this.api.complianceInputs
      .getConsentCountCountryWise(
        id,
        this.selectedPermissionType === "All"
          ? null
          : this.selectedPermissionType
      )
      .subscribe((a) => {
        this.geoEntries = a.data;
        this.loading = false;
        this.dtGeoEntries.updateRows(this.geoEntries);
        this.dtGeoEntries.loaderVisibility(false);
        if (this.geoType.toLowerCase() == "country") {
          this.loadMap(a);
        }
      });
  }

  getMapDataGeo(id: number): void {
    this.api.geoSummaries
      .getGeoSummary(
        id,
        this.selectedPermissionType === "All"
          ? null
          : this.selectedPermissionType,
        this.sort,
        this.mapType
      )
      .subscribe((a) => {
        this.geoEntries = a.data;
        let total = 0;
        this.geoEntries.forEach((g) => {
          if (g.number == 1000) {
            this.indeterminateAmounts = true;
            total += g.number;
            g.number = "1000+";
          } else {
            total += g.number;
          }
        });
        this.total = total;
        this.loading = false;
        this.dtGeoEntries.updateRows(this.geoEntries);
        this.dtGeoEntries.loaderVisibility(false);
        if (this.geoType.toLowerCase() == "country") {
          this.loadMapGeo(a);
        }
      });
  }

  getPermMapData(id: number): void {
    this.api.geoSummaries
      .getGeoSummary(
        id,
        this.selectedPermissionType === "All"
          ? null
          : this.selectedPermissionType,
        this.sort,
        this.mapType
      )
      .subscribe((a) => {
        this.geoEntries = a.data;
        let total = 0;
        this.geoEntries.forEach((g) => {
          if (g.number == 1000) {
            this.indeterminateAmounts = true;
            total += g.number;
            g.number = "1000+";
          } else {
            total += g.number;
          }
        });
        this.total = total;

        this.loading = false;
        this.dtGeoEntries.updateRows(this.geoEntries);
        this.dtGeoEntries.loaderVisibility(false);
        if (this.geoType.toLowerCase() == "country") {
          this.loadMapGeo(a);
        }
      });
  }

  loadAnyMapData() {
    let index = 0;
    this.regulations.forEach((r, ind) => {
      if (r.id === this.selectedRegulation) {
        index = ind;
      }
    });
    if (this.mapType == "consent") {
      this.getMapData(index ? this.regulations[index].id : 0);
    } else {
      this.getPermMapData(index ? this.regulations[index].id : 0);
    }
  }

  loadAnyMapDataGeo() {
    this.loading = true;
    this.dtGeoEntries.loaderVisibility(true);
    let index = 0;
    this.regulations.forEach((r, ind) => {
      if (r.id === this.selectedRegulation) {
        index = ind;
      }
    });

    this.getMapDataGeo(index ? this.regulations[index].id : 0);
  }

  changeMapType() {
    this.indeterminateAmounts = false;
    this.loadAnyMapDataGeo();
  }

  changePermissionType() {
    this.indeterminateAmounts = false;
    this.loadAnyMapDataGeo();
  }

  changeGeoType() {
    this.indeterminateAmounts = false;
    this.loadAnyMapDataGeo();
  }

  changeRegulation(): void {
    this.indeterminateAmounts = false;
    this.loadAnyMapDataGeo();
  }

  getRegulations(): void {
    this.api.regulations.list().subscribe((data) => {
      this.regulations.push({
        name: "All",
        id: 0,
        isActive: true,
      });
      data.forEach((d) => {
        this.regulations.push({
          name: d.name,
          id: d.id,
          isActive: false,
        });
      });
    });
  }

  getColorByRelativeNumber(allCountries, number) {
    if (number == "1000+") {
      number = 1000;
    } else {
      number = Number(number);
    }

    // Make a map of all the countries numbers
    let allCountriesNumbersMap = allCountries.map((data) => {
      if (data.number == "1000+") {
        return 1000;
      } else {
        return Number(data.number);
      }
    });
    //Segment into ranges
    let max = 0,
      min = 0,
      avg;
    let allCountriesTotal = 0;
    allCountriesNumbersMap.forEach((a) => {
      allCountriesTotal = allCountriesTotal + a;
      if (!min) min = a;
      if (!max) max = a;
      if (min > a) min = a;
      if (max < a) max = a;
    });
    avg = allCountriesTotal / allCountriesNumbersMap.length;
    // Divide into 3 ranges
    if (number >= min && number < avg / 2) {
      return "#d8ffd8";
    } else if (number > avg / 2 && number < avg) {
      return "#89ff89";
    } else {
      return "#14ff14";
    }
  }

  loadPermMap(mapData: PermissionSummaryByCountry) {
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create("divMap", am4maps.MapChart);
    // Add UK
    let chartGeo = am4geodata_worldHigh;

    chart.geodata = chartGeo;
    chart.projection = new am4maps.projections.Mercator();
    chart.zoomControl = new am4maps.ZoomControl();

    let homeButton = new am4core.Button();
    homeButton.events.on("hit", function() {
      chart.goHome();
    });

    homeButton.icon = new am4core.Sprite();
    homeButton.padding(7, 5, 7, 5);
    homeButton.width = 30;
    homeButton.icon.path =
      "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";
    homeButton.marginBottom = 10;
    homeButton.parent = chart.zoomControl;
    homeButton.insertBefore(chart.zoomControl.plusButton);

    // Center on the groups by default
    chart.homeZoomLevel = 3.5;
    chart.homeGeoPoint = { longitude: 10, latitude: 52 };
    let groupData = [];
    mapData.data.forEach((data, index) => {
      groupData.push({
        name: data.standard_country + " - " + data.number,
        color: this.getColorByRelativeNumber(mapData.data, data.number),
        data: [],
      });
      let temp = {
        id: data.standard_country,
        customData: data.number,
      };
      groupData[index].data.push(temp);
    });

    // This array will be populated with country IDs to exclude from the world series
    //let excludedCountries = ["AQ"];

    // Create a series for each group, and populate the above array
    groupData.forEach(function(group) {
      let series = chart.series.push(new am4maps.MapPolygonSeries());
      series.name = group.name;
      series.useGeodata = true;
      let includedCountries = [];
      group.data.forEach(function(country) {
        includedCountries.push(country.id);
      });
      series.include = includedCountries;

      series.fill = am4core.color(group.color);

      // By creating a hover state and setting setStateOnChildren to true, when we
      // hover over the series itself, it will trigger the hover SpriteState of all
      // its countries (provided those countries have a hover SpriteState, too!).
      series.calculateVisualCenter = true;
      // Country shape properties & behaviors
      let mapPolygonTemplate = series.mapPolygons.template;
      // Instead of our custom title, we could also use {name} which comes from geodata
      mapPolygonTemplate.fill = am4core.color(group.color);
      mapPolygonTemplate.fillOpacity = 1;
      mapPolygonTemplate.nonScalingStroke = true;

      series.data = JSON.parse(JSON.stringify(group.data));
    });

    // The rest of the world.
    let worldSeries = chart.series.push(new am4maps.MapPolygonSeries());
    let worldSeriesName = "world";
    worldSeries.name = worldSeriesName;
    worldSeries.useGeodata = true;
    worldSeries.fillOpacity = 0.3;
    worldSeries.hiddenInLegend = true;
    worldSeries.mapPolygons.template.nonScalingStroke = true;

    let countryTemplate = worldSeries.mapPolygons.template;
    countryTemplate.applyOnClones = true;
    countryTemplate.fill = am4core.color("#a791b4");
    countryTemplate.fillOpacity = 0.3; // see continents underneath, however, country shapes are more detailed than continents.
    countryTemplate.strokeOpacity = 0.5;
    countryTemplate.nonScalingStroke = true;
    // get the number for this country
    worldSeries.mapPolygons.template.adapter.add(
      "tooltipText",
      (text, target, key) => {
        var country: any = target.dataItem.dataContext;
        var data;
        // find country data
        groupData.forEach((g) => {
          g.data.forEach((c) => {
            if (c.id === country.id) {
              data = c;
            }
          });
        });

        return (
          country.name +
          " - " +
          this.translate.instant("GENERIC.COUNT") +
          " : " +
          (data ? data.customData : "0")
        );
      }
    );
    countryTemplate.events.on("hit", function(event) {
      chart.zoomToMapObject(event.target);
    });

    let countryHover = countryTemplate.states.create("hover");
    countryHover.properties.fill = am4core.color("#a791b4");
    countryHover.properties.fillOpacity = 0.8; // Reduce conflict with back to continents map label
    countryHover.properties.stroke = am4core.color("#a791b4");
    countryHover.properties.strokeOpacity = 1;

    // Legend items
    //chart.legend.itemContainers.template.interactionsEnabled = false;
  }

  loadMap(mapData: ConsentSummaryByCountry) {
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create("divMap", am4maps.MapChart);
    // Add UK
    let chartGeo = am4geodata_worldHigh;
    console.log(am4geodata_worldHigh);
    chart.geodata = chartGeo;
    chart.projection = new am4maps.projections.Mercator();
    chart.zoomControl = new am4maps.ZoomControl();

    let homeButton = new am4core.Button();
    homeButton.events.on("hit", function() {
      chart.goHome();
    });

    homeButton.icon = new am4core.Sprite();
    homeButton.padding(7, 5, 7, 5);
    homeButton.width = 30;
    homeButton.icon.path =
      "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";
    homeButton.marginBottom = 10;
    homeButton.parent = chart.zoomControl;
    homeButton.insertBefore(chart.zoomControl.plusButton);

    // Center on the groups by default
    chart.homeZoomLevel = 3.5;
    chart.homeGeoPoint = { longitude: 10, latitude: 52 };
    let groupData = [];

    mapData.data.forEach((data, index) => {
      groupData.push({
        name: data.standard_country + " - " + data.number,
        color: this.getColorByRelativeNumber(mapData.data, data.number),
        data: [],
      });
      let temp = {
        id: data.standard_country,
        customData: data.number,
      };
      groupData[index].data.push(temp);
    });

    // This array will be populated with country IDs to exclude from the world series
    //let excludedCountries = ["AQ"];
    // Create a series for each group, and populate the above array
    groupData.forEach(function(group) {
      let series = chart.series.push(new am4maps.MapPolygonSeries());
      series.name = group.name;
      series.useGeodata = true;
      let includedCountries = [];
      group.data.forEach(function(country) {
        includedCountries.push(country.id);
      });
      series.include = includedCountries;

      series.fill = am4core.color(group.color);

      // By creating a hover state and setting setStateOnChildren to true, when we
      // hover over the series itself, it will trigger the hover SpriteState of all
      // its countries (provided those countries have a hover SpriteState, too!).
      series.calculateVisualCenter = true;
      // Country shape properties & behaviors
      let mapPolygonTemplate = series.mapPolygons.template;
      // Instead of our custom title, we could also use {name} which comes from geodata
      mapPolygonTemplate.fill = am4core.color(group.color);
      mapPolygonTemplate.fillOpacity = 1;
      mapPolygonTemplate.nonScalingStroke = true;

      series.data = JSON.parse(JSON.stringify(group.data));
    });

    // The rest of the world.
    let worldSeries = chart.series.push(new am4maps.MapPolygonSeries());
    let worldSeriesName = "world";
    worldSeries.name = worldSeriesName;
    worldSeries.useGeodata = true;
    worldSeries.fillOpacity = 0.3;
    worldSeries.hiddenInLegend = true;
    worldSeries.mapPolygons.template.nonScalingStroke = true;

    let countryTemplate = worldSeries.mapPolygons.template;
    countryTemplate.applyOnClones = true;
    countryTemplate.fill = am4core.color("#a791b4");
    countryTemplate.fillOpacity = 0.3; // see continents underneath, however, country shapes are more detailed than continents.
    countryTemplate.strokeOpacity = 0.5;
    countryTemplate.nonScalingStroke = true;
    // get the number for this country
    worldSeries.mapPolygons.template.adapter.add(
      "tooltipText",
      (text, target, key) => {
        var country: any = target.dataItem.dataContext;
        var data;
        // find country data
        groupData.forEach((g) => {
          g.data.forEach((c) => {
            if (c.id === country.id) {
              data = c;
            }
          });
        });

        return (
          country.name +
          " - " +
          this.translate.instant("GENERIC.COUNT") +
          " : " +
          (data ? data.customData : "0")
        );
      }
    );
    countryTemplate.events.on("hit", function(event) {
      chart.zoomToMapObject(event.target);
    });

    let countryHover = countryTemplate.states.create("hover");
    countryHover.properties.fill = am4core.color("#a791b4");
    countryHover.properties.fillOpacity = 0.8; // Reduce conflict with back to continents map label
    countryHover.properties.stroke = am4core.color("#a791b4");
    countryHover.properties.strokeOpacity = 1;

    // Legend items
    //chart.legend.itemContainers.template.interactionsEnabled = false;
  }

  loadMapGeo(mapData: GeoSummary) {
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create("divMap", am4maps.MapChart);
    // Add UK
    let chartGeo = am4geodata_worldHigh;
    console.log(am4geodata_worldHigh);
    chart.geodata = chartGeo;
    chart.projection = new am4maps.projections.Mercator();
    chart.zoomControl = new am4maps.ZoomControl();

    let homeButton = new am4core.Button();
    homeButton.events.on("hit", function() {
      chart.goHome();
    });

    homeButton.icon = new am4core.Sprite();
    homeButton.padding(7, 5, 7, 5);
    homeButton.width = 30;
    homeButton.icon.path =
      "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";
    homeButton.marginBottom = 10;
    homeButton.parent = chart.zoomControl;
    homeButton.insertBefore(chart.zoomControl.plusButton);

    // Center on the groups by default
    chart.homeZoomLevel = 3.5;
    chart.homeGeoPoint = { longitude: 10, latitude: 52 };
    let groupData = [];

    mapData.data.forEach((data, index) => {
      groupData.push({
        name: data.geo_name + " - " + data.number,
        color: this.getColorByRelativeNumber(mapData.data, data.number),
        data: [],
      });

      let temp = {
        id: data.geo,
        customData: data.number,
      };
      groupData[index].data.push(temp);
    });

    // This array will be populated with country IDs to exclude from the world series
    //let excludedCountries = ["AQ"];
    // Create a series for each group, and populate the above array
    groupData.forEach(function(group) {
      let series = chart.series.push(new am4maps.MapPolygonSeries());
      series.name = group.name;
      series.useGeodata = true;
      let includedCountries = [];
      group.data.forEach(function(country) {
        includedCountries.push(country.id);
      });
      series.include = includedCountries;

      series.fill = am4core.color(group.color);

      // By creating a hover state and setting setStateOnChildren to true, when we
      // hover over the series itself, it will trigger the hover SpriteState of all
      // its countries (provided those countries have a hover SpriteState, too!).
      series.calculateVisualCenter = true;
      // Country shape properties & behaviors
      let mapPolygonTemplate = series.mapPolygons.template;
      // Instead of our custom title, we could also use {name} which comes from geodata
      mapPolygonTemplate.fill = am4core.color(group.color);
      mapPolygonTemplate.fillOpacity = 1;
      mapPolygonTemplate.nonScalingStroke = true;

      series.data = JSON.parse(JSON.stringify(group.data));
    });

    // The rest of the world.
    let worldSeries = chart.series.push(new am4maps.MapPolygonSeries());
    let worldSeriesName = "world";
    worldSeries.name = worldSeriesName;
    worldSeries.useGeodata = true;
    //worldSeries.exclude = excludedCountries;
    worldSeries.fillOpacity = 0.3;
    worldSeries.hiddenInLegend = true;
    worldSeries.mapPolygons.template.nonScalingStroke = true;

    let countryTemplate = worldSeries.mapPolygons.template;
    countryTemplate.applyOnClones = true;
    countryTemplate.fill = am4core.color("#a791b4");
    countryTemplate.fillOpacity = 0.3; // see continents underneath, however, country shapes are more detailed than continents.
    countryTemplate.strokeOpacity = 0.5;
    countryTemplate.nonScalingStroke = true;
    // get the number for this country
    worldSeries.mapPolygons.template.adapter.add(
      "tooltipText",
      (text, target, key) => {
        var country: any = target.dataItem.dataContext;
        var data;
        // find country data
        groupData.forEach((g) => {
          g.data.forEach((c) => {
            if (c.id === country.id) {
              data = c;
            }
          });
        });

        return (
          country.name +
          " - " +
          this.translate.instant("GENERIC.COUNT") +
          " : " +
          (data ? data.customData : "0")
        );
      }
    );
    countryTemplate.events.on("hit", function(event) {
      chart.zoomToMapObject(event.target);
    });

    let countryHover = countryTemplate.states.create("hover");
    countryHover.properties.fill = am4core.color("#a791b4");
    countryHover.properties.fillOpacity = 0.8; // Reduce conflict with back to continents map label
    countryHover.properties.stroke = am4core.color("#a791b4");
    countryHover.properties.strokeOpacity = 1;

    // Legend items
    //chart.legend.itemContainers.template.interactionsEnabled = false;
  }

  fetchClientUsers() {
    this.api.clientUsers.list().subscribe((a) => {
      this.clientUsers = a;
    });
  }

  getDummyGuageData() {
    this.gaugeData = [["Risk", 37]];

    this.gaugeOptions = {
      greenFrom: 0,
      greenTo: 25,
      yellowFrom: 25,
      yellowTo: 75,
      redFrom: 75,
      redTo: 100,
    };
  }

  getDummyPieData() {
    this.pieData = [
      ["Yes", 80],
      ["No", 20],
    ];

    this.pieOptions = {
      legend: {
        position: "top",
      },
      pieHole: 0.4,
      sliceVisibilityThreshold: 0,
    };
  }

  onSort(ev) {
    //
  }
}
