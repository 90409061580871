import { Component, OnInit } from "@angular/core";
import { AuthService } from "@fourcomply-dashboard/shared";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit() {
    if (this.auth.loggedIn) {
      this.router.navigate(["dashboard"]);
    } else {
      this.auth.login();
    }
  }

  login() {
    this.auth.login();
  }
}
