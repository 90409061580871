import { Component, OnInit, Input, Output } from "@angular/core";
import { ActionBarItem } from "../../models/actionbar-item.interface";
import { Router } from "@angular/router";
import { EventEmitter } from "events";

@Component({
  selector: "app-actionbar",
  templateUrl: "./actionbar.component.html",
  styleUrls: ["./actionbar.component.scss"],
})
export class ActionBarComponent implements OnInit {
  actionbarItemsThis: ActionBarItem[];
  titleThis: string = "";
  subtitleThis: string = "";

  @Input() title: string;
  @Input() actionBarItems: ActionBarItem[];
  @Input() subtitle: string;
  @Output() callbackEmit = new EventEmitter();

  constructor(public router: Router) {}

  ngOnInit() {
    this.actionbarItemsThis = this.actionBarItems;
    if (this.title !== undefined && this.title?.length > 0) {
      this.titleThis = this.title;
    }
    if (this.subtitle !== undefined && this.subtitle?.length > 0) {
      this.subtitleThis = this.subtitle;
    }
  }

  onItemClicked(item: ActionBarItem) {
    if (item.route) {
      this.router.navigate([item.route]);
    } else if (item.callback) {
      this.callbackEmit.emit(item.callback());
    }
  }
}
