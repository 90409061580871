<div style="display: flex;flex-direction:row;">
  <div class="pagesize-wrapper">
    <label>
      <select (change)="setPagerSize($event.target.value)">
        <option
          [selected]="currentPageSize == option"
          *ngFor="let option of options"
          value="{{ option }}"
        >
          {{ option }}
        </option>
      </select>
      {{ "GENERIC.PAGE_SIZE" | translate }}</label
    >
  </div>
  <div style="flex:1"></div>
  <div
    class="pagination-wrapper"
    style="display: flex;flex-direction:row;align-items:center;justify-content:center;"
  >
    <div style="margin-right:30px">{{ "GENERIC.PAGES" | translate }}:</div>
    <div *ngIf="lessPage" (click)="decrementAll()" class="page-item">
      <<
    </div>
    <div *ngIf="lessPage" (click)="decrement()" class="page-item">
      <
    </div>
    <div *ngIf="lessPage" (click)="decrement()" class="page-item">
      {{ lessPage }}
    </div>
    <select (change)="setCurrentPager($event.target.value)">
      <option
        [selected]="currentPage == page"
        *ngFor="let page of pageList"
        value="{{ page }}"
      >
        {{ page }}
      </option>
    </select>
    <div *ngIf="morePage && morePage < pages" (click)="increment()" class="page-item">
      {{ morePage }}
    </div>
    <div *ngIf="morePage && morePage < pages" (click)="increment()" class="page-item">
      >
    </div>
    <div *ngIf="morePage && morePage < pages" (click)="incrementAll()" class="page-item">
      >>
    </div>
  </div>
</div>
