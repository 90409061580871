import { BaseApiService } from "../base/base-api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from "../custom-error-handler.service";
import { ConsentPermission } from "../../../models/consent-permissions/consent-permission.model";
import { ConsentPermissionSerializer } from "../../../models/consent-permissions/consent-permission-serializer.class";
import { Observable } from "rxjs";
import { map, tap, catchError } from "rxjs/operators";
import { PermissionSummaryByCountry } from "../../../models/permission-summary-by-country/permission-summary-by-country.model";
import { PermissionSummaryByCountrySerializer } from "../../../models/permission-summary-by-country/permission-summary-by-country-serializer.class";

/**
 * Service to communicate with API Client Users
 */

@Injectable()
export class ConsentPermissionsService extends BaseApiService<
  ConsentPermission
> {
  permissionSummarySerializer = new PermissionSummaryByCountrySerializer();
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      null,
      "citizens/permissions",
      new ConsentPermissionSerializer(),
      new CustomErrorHandlerService()
    );
  }

  // EXTEND/OVERRIDE THE SERVICE HERE IF NECESSARY

  getPermissionCountCountryWise(
    id: number,
    permissionType: string
  ): Observable<PermissionSummaryByCountry> {
    let url = `${this.url}/citizens/permissions/bycountry`;
    if (id !== 0) {
      url += `/${id}`;
    }
    url += permissionType ? "?permission_type=" + permissionType : "";
    return this.httpClient
      .get(url)
      .pipe(
        map(
          (data: any) =>
            this.permissionSummarySerializer.fromJson(
              data
            ) as PermissionSummaryByCountry
        )
      );
  }

  exportPermissions(): Observable<any> {
    return this.httpClient.post<any>(
      `${this.url}/bulkrequest/citizens/permission/download`,
      {}
    );
  }

  uploadPermissions(): Observable<any> {
    return this.httpClient.post<any>(
      `${this.url}/bulkrequest/citizens/permission/upload`,
      {}
    );
  }

  uploadConsents(): Observable<any> {
    return this.httpClient.post<any>(
      `${this.url}/bulkrequest/citizens/complianceinput/upload`,
      {}
    );
  }
}
