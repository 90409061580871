<div class="container page">
  <div class="column col-12">
    <app-generic-card>
      <app-actionbar
        title="{{ 'BASIC_ENTITIES_PLURAL.LEGAL_ACTIVITIES' | translate }}"
        [subtitle]="
          total != null && total != undefined
            ? isEstimate
              ? 'around ' + total + ' items'
              : total + ' total items'
            : null
        "
      ></app-actionbar>
      <app-datatable
        #dtLegalActivities
        [data]="dataLegalActivities"
        tableIdentifier="legalActivities"
        [columns]="columnsLegalActivities"
        (sortEvent)="onSort($event)"
        placeholder="{{ 'GENERIC.ENTER_VALUE_SEARCH' | translate }}"
        buttonTitle="{{ 'GENERIC.SEARCH' | translate }}"
        [disabled]="disabled"
        enableSearch="true"
        (search)="searchWithEmail($event)"
      ></app-datatable>
    </app-generic-card>
    <app-pagination
      *ngIf="pages"
      [pages]="pages"
      [currentPageSize]="pageSize"
      [currentPage]="currentPage"
      (setPageSize)="setPageSize($event)"
      (setCurrentPage)="setCurrentPage($event)"
    ></app-pagination>
  </div>
</div>
