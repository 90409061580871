<div class="container">
  <div class="column col-7">
    <app-generic-card>
      <h3 class="section-title">
        {{
          (isEdit ? "HITL_TEMPLATE.EDIT" : "HITL_TEMPLATE.ADD_NEW") | translate
        }}
      </h3>
      <div class="columns">
        <div class="column col-6">
          <app-form
            #hitlTemplateForm
            [fields]="fields"
            [model]="model"
            [callback]="submitHitlTemplateForm"
            closeRoute="hitl-config"
          ></app-form>
        </div>
        <div class="column col-6">
          <h6>{{ "HITL_TEMPLATE.ADD_TRIGGER_ACTIONS" | translate }}</h6>
          <div class="form-group">
            <mat-form-field
              class="example-full-width"
              *ngFor="
                let ta of triggeringActions;
                let i = index;
                trackBy: trackByFn
              "
            >
              <mat-label
                >{{ "GENERIC.ACTION" | translate }} {{ i + 1 }}</mat-label
              >
              <!-- <input matInput placeholder="Action {{i + 1}}" [(ngModel)]="triggeringActions[i]"> -->
              <mat-select
                [(ngModel)]="triggeringActions[i]"
                (selectionChange)="onSelectionChanged()"
              >
                <mat-option
                  *ngFor="let trigger of triggers"
                  [value]="trigger.value"
                >
                  {{ trigger.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <label class="error-text" *ngIf="isDuplicateTriggerSelected">{{
              "HITL_TEMPLATE.SAME_ACTION_ERROR" | translate
            }}</label>
          </div>
          <button
            type="submit"
            class="btn btn-primary"
            (click)="triggeringActions.push('')"
          >
            {{ "HITL_TEMPLATE.ADD_ACTION" | translate }}
          </button>
          <button
            style="margin-left: 10px;"
            type="submit"
            class="btn btn-default"
            (click)="
              triggeringActions.splice(triggeringActions.length - 1, 1);
              onSelectionChanged()
            "
            *ngIf="triggeringActions.length > 0"
          >
            {{ "HITL_TEMPLATE.REMOVE_LAST" | translate }}
          </button>
        </div>
      </div>
    </app-generic-card>
  </div>
</div>
