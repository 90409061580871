import { Component, OnInit, ViewChild } from "@angular/core";
import {
  DatatableComponent,
  ClientRegulation,
  APIService,
  Client,
  AuthService,
  NotificationService,
  ModalComponent,
  BusinessUnit,
} from "@fourcomply-dashboard/shared";
import { pipe } from "rxjs";
import { DatalistActionItem } from "libs/shared/src/lib/models/datalist-action-item.interface";
import { Router } from "@angular/router";
import { environment } from "libs/shared/src/environments/environment";
import { BusinessUnitComponent } from "../business-unit/business-unit.component";
import { GeoRegulationMap } from "libs/shared/src/lib/models/geo-regulation-map/geo-regulation-map.model";

@Component({
  selector: "fourcomply-dashboard-client-regulations",
  templateUrl: "./client-regulations.component.html",
  styleUrls: ["./client-regulations.component.scss"],
})
export class ClientRegulationsComponent implements OnInit {
  @ViewChild("dtClientRegulations", { static: true })
  dtClientRegulations: DatatableComponent;
  dataClientRegulations: ClientRegulation[];

  @ViewChild("dtClientRegulationsUnused", { static: true })
  dtClientRegulationsUnused: DatatableComponent;
  dataClientRegulationsUnused: ClientRegulation[];

  @ViewChild("modalAddRegulation")
  modalAddRegulation: ModalComponent;

  @ViewChild("modalDuplicateRegulation")
  modalDuplicateRegulation: ModalComponent;

  @ViewChild("dtClientPermissionTypes", { static: true })
  dtClientPermissionTypes: DatatableComponent;

  @ViewChild("dtClientPermissionCategories", { static: true })
  dtClientPermissionCategories: DatatableComponent;

  @ViewChild("modalCategoryEdit")
  modalCategoryEdit: ModalComponent;

  @ViewChild("modalMasterPermissionEdit")
  modalMasterPermissionEdit: ModalComponent;

  explicitPermissionTypes: any[];
  client: Client;
  userId: string;
  explicitPermissionTypeColumns: any = [];
  columnsClientRegulations: any[];
  columnsClientRegulationsUnused: any[];
  columnsPermissionCategories: any[];
  dataPermissionCategories: any[];
  dataMasterPermissions: any[];
  actionsClientRegulations: DatalistActionItem[];
  actionsClientRegulationsUnused: DatalistActionItem[];
  actionsPermissionTypes: DatalistActionItem[];
  actionsPermissionCategories: DatalistActionItem[];
  actionBarItemsPermission: any[];
  actionBarItemsMasterPermission: any[];
  actionBarItemsRegulations: any[];
  actionBarItemsRegulationsUnused: any[];
  toEditCategoryIndex: number;
  toEditRegulationIndex: number;
  toEditCategory: string;
  toEditCategoryType: string;
  toEditMasterPermissionIndex: number;
  toEditMasterPermissionName: string;
  toEditMasterPermissionDefault: string;
  toEditMasterPermissionDescription: string;
  toEditMasterPermissionBusinessUnit: string;
  toEditRegulationName: string;
  toEditRegulationNotes: string;
  toEditRegulationSourceDuplicateId: string;
  toEditRegulationSourceDuplicateName: string;
  explicitPermissionTypesAux: any[];
  businessUnits: BusinessUnit[];
  complianceInputTypes: string[];
  newCR: ClientRegulation;
  dataGeoRegulationMaps: any[];
  constructor(
    private api: APIService,
    private router: Router,
    private auth: AuthService,
    private notification: NotificationService
  ) {}

  ngOnInit() {
    this.getConfigurations = this.getConfigurations.bind(this);
    this.deleteRegulation = this.deleteRegulation.bind(this);
    this.duplicateRegulation = this.duplicateRegulation.bind(this);
    this.auth.userProfile$.subscribe(
      pipe((res: any) => {
        console.log(res);
        this.userId = res.currentTenant.tenant_id;
        this.getClient();
      })
    );
    this.fetchGeoRegulationMaps();

    this.actionBarItemsRegulations = [
      {
        btnText: "GENERIC.ADD_NEW",
        iconClass: "fas fa-plus",
        callback: this.openAddEditModalRegulations.bind(this),
      },
    ];

    this.actionBarItemsRegulationsUnused = [
      {
        btnText: "GENERIC.ADD_NEW",
        iconClass: "fas fa-plus",
        callback: this.openAddEditModalRegulations.bind(this),
      },
    ];
  }

  fetchGeoRegulationMaps() {
    this.api.geoRegulationMaps.list().subscribe((a) => {
      this.dataGeoRegulationMaps = a;
      this.fetchClientRegulations();
    });
  }

  getClient() {
    this.actionsPermissionTypes = [
      {
        iconClass: "fas fa-pen",
        toolTipText: "GENERIC.UPDATE",
        callback: this.editPermissionType.bind(this),
      },
      {
        iconClass: "fas fa-trash",
        toolTipText: "GENERIC.DELETE",
        callback: this.deletePermissionType.bind(this),
      },
    ];

    this.actionBarItemsMasterPermission = [
      {
        btnText: "GENERIC.ADD_NEW",
        iconClass: "fas fa-plus",
        callback: this.openAddEditMasterModal.bind(this),
      },
    ];

    this.explicitPermissionTypeColumns = [
      { name: "GENERIC.NAME", prop: "name", sortable: true },
      {
        name: "CLIENT_REGULATIONS.DEFAULT_PERMISSION_VALUE",
        prop: "default_permission_value",
        sortable: true,
      },
      { name: "GENERIC.DESCRIPTION", prop: "description", sortable: true },
      {
        name: "BASIC_ENTITIES_SINGULAR.BUSINESS_UNIT",
        prop: "business_unit",
        sortable: true,
      },
      { name: "GENERIC.ACTIONS", prop: "actions" },
    ];
    this.api.clients.getPermissionTypes(this.userId).subscribe((a) => {
      this.explicitPermissionTypes = a.map((c, index) => {
        return {
          name: c["name"],
          default_permission_value: c["default_permission_value"],
          description: c["description"],
          business_unit: c["business_unit"],
          id: index,
        };
      });
      this.dtClientPermissionTypes.updateRows(this.explicitPermissionTypes);
      this.dtClientPermissionTypes.loaderVisibility(false);
    });

    this.actionsPermissionCategories = [
      {
        iconClass: "fas fa-pen",
        toolTipText: "GENERIC.UPDATE",
        callback: this.editCategory.bind(this),
      },
      {
        iconClass: "fas fa-trash",
        toolTipText: "GENERIC.DELETE",
        callback: this.deleteCategory.bind(this),
      },
    ];
    this.columnsPermissionCategories = [
      { name: "GENERIC.NAME", prop: "name", sortable: true },
      {
        name: "BASIC_ENTITIES_SINGULAR.COMPLIANCE_INPUT_TYPE",
        prop: "compliance_input_type",
        sortable: true,
      },
      { name: "GENERIC.ACTIONS", prop: "actions" },
    ];

    this.api.clients.getPermissionCategories(this.userId).subscribe((a) => {
      if (a != null) {
        this.dataPermissionCategories = a.map((c, index) => {
          return {
            name: c.name,
            id: index,
            compliance_input_type: c.compliance_input_type,
          };
        });
        this.dtClientPermissionCategories.updateRows(
          this.dataPermissionCategories
        );
      }
      this.dtClientPermissionCategories.loaderVisibility(false);
    });

    this.actionBarItemsPermission = [
      {
        btnText: "GENERIC.ADD_NEW",
        iconClass: "fas fa-plus",
        callback: this.openAddEditModal.bind(this),
      },
    ];
  }

  editCategory(e) {
    if (e !== undefined) {
      this.openAddEditModal(e);
    }
  }

  editPermissionType(e) {
    if (e !== undefined) {
      this.openAddEditMasterModal(e);
    }
  }

  openAddEditMasterModal(existing) {
    if (existing || existing === 0) {
      this.toEditMasterPermissionIndex = existing;
      this.toEditMasterPermissionName = this.explicitPermissionTypes[
        existing
      ].name;
      this.toEditMasterPermissionDefault = this.explicitPermissionTypes[
        existing
      ].default_permission_value;
      this.toEditMasterPermissionDescription = this.explicitPermissionTypes[
        existing
      ].description;
      this.toEditMasterPermissionBusinessUnit = this.explicitPermissionTypes[
        existing
      ].business_unit;
    } else {
      this.toEditMasterPermissionName = "";
      this.toEditMasterPermissionDefault = "";
      this.toEditMasterPermissionDescription = "";
      this.toEditMasterPermissionBusinessUnit = "";
    }
    this.modalMasterPermissionEdit.openModal();
  }

  deletePermissionType(e) {
    if (e !== undefined) {
      if (e || e === 0) {
        // existing
        this.explicitPermissionTypes.splice(e, 1);
      }

      this.explicitPermissionTypesAux = [];
      this.explicitPermissionTypes.forEach((a) => {
        this.explicitPermissionTypesAux.push({
          name: a.name,
          default_permission_value: a.default_permission_value,
          description: a.description,
          business_unit: a.business_unit,
        });
      });

      this.api.clients
        .updatePermissionTypes(this.userId, this.explicitPermissionTypesAux)
        .subscribe(() => {
          this.modalMasterPermissionEdit.closeModal();
          this.notification.success(
            "CLIENT_REGULATIONS.SUCCESSFULLY_DELETED_MASTER_PERMISSION_TYPE"
          );
          this.toEditMasterPermissionIndex = null;
          this.getClient();
        });
    }
  }

  submitMasterPermission() {
    this.dtClientPermissionTypes.loaderVisibility(true);
    if (
      this.toEditMasterPermissionIndex ||
      this.toEditMasterPermissionIndex === 0
    ) {
      // existing
      this.explicitPermissionTypes[this.toEditMasterPermissionIndex] = {
        name: this.toEditMasterPermissionName,
        default_permission_value: this.toEditMasterPermissionDefault,
        description: this.toEditMasterPermissionDescription,
        business_unit: this.toEditMasterPermissionBusinessUnit,
      };
    } else {
      this.explicitPermissionTypes.push({
        name: this.toEditMasterPermissionName,
        default_permission_value: this.toEditMasterPermissionDefault,
        description: this.toEditMasterPermissionDescription,
        business_unit: this.toEditMasterPermissionBusinessUnit,
      });
    }

    this.explicitPermissionTypesAux = [];
    this.explicitPermissionTypes.forEach((a) => {
      this.explicitPermissionTypesAux.push({
        name: a.name,
        default_permission_value: a.default_permission_value,
        description: a.description,
        business_unit: a.business_unit,
      });
    });

    this.api.clients
      .updatePermissionTypes(this.userId, this.explicitPermissionTypesAux)
      .subscribe(() => {
        this.modalMasterPermissionEdit.closeModal();
        this.notification.success(
          "CLIENT_REGULATIONS.SUCCESSFULLY_UPDATED_MASTER_PERMISSION_TYPES"
        );
        this.toEditMasterPermissionIndex = null;
        this.getClient();
      });
  }

  deleteCategory(e) {
    if (e !== undefined) {
      if (e || e === 0) {
        // existing
        this.dataPermissionCategories.splice(e, 1);
      }
      this.api.clients
        .updatePermissionCategories(
          this.userId,
          this.dataPermissionCategories.map((a) => a.name)
        )
        .subscribe(() => {
          this.modalCategoryEdit.closeModal();
          this.notification.success(
            "CLIENT_REGULATIONS.SUCCESSFULLY_DELETED_CATEGORY"
          );
          this.toEditCategoryIndex = null;
          this.getClient();
        });
    }
  }

  changeCategory(ev) {
    this.toEditCategory = ev.value;
    this.toEditCategoryType = ev.compliance_input_type;
  }

  closeAddEditModal() {
    this.modalCategoryEdit.closeModal();
  }

  closeDuplicateRegModal() {
    this.modalDuplicateRegulation.closeModal();
  }

  openAddEditModal(existing) {
    if (existing || existing === 0) {
      this.toEditCategoryIndex = existing;
      this.toEditCategory = this.dataPermissionCategories[existing].name;
      this.toEditCategoryType = this.dataPermissionCategories[
        existing
      ].compliance_input_type;
    } else {
      this.toEditCategory = "";
    }
    this.modalCategoryEdit.openModal();
  }

  openAddEditModalRegulations(existing) {
    if (existing || existing === 0) {
      this.toEditCategoryIndex = existing;
      this.toEditCategory = this.dataClientRegulations[existing].name;
    } else {
      this.toEditCategory = "";
      this.toEditCategoryType = "";
    }
    this.modalAddRegulation.openModal();
  }

  submitPermissionCategory() {
    this.dtClientPermissionCategories.loaderVisibility(true);
    if (this.toEditCategoryIndex || this.toEditCategoryIndex === 0) {
      // existing
      this.dataPermissionCategories[this.toEditCategoryIndex] = {
        name: this.toEditCategory,
        compliance_input_type: this.toEditCategoryType,
      };
    } else {
      this.dataPermissionCategories.push({
        name: this.toEditCategory,
        compliance_input_type: this.toEditCategoryType,
      });
    }

    this.api.clients
      .updatePermissionCategories(
        this.userId,
        this.dataPermissionCategories
        //  this.dataPermissionCategories.map((a) => name: a.name, compliance_input_type:this.toEditCategoryType )
      )
      .subscribe(() => {
        this.modalCategoryEdit.closeModal();
        this.notification.success(
          "CLIENT_REGULATIONS.SUCCESSFULLY_UPDATED_CATEGORIES"
        );
        this.toEditCategoryIndex = null;
        this.getClient();
      });
  }

  submitRegulation() {
    this.newCR = {
      name: this.toEditRegulationName,
      priority: null,
      note: this.toEditRegulationNotes,
      enabled: true,
      id: null,
    };
    this.dtClientRegulations.loaderVisibility(true);
    this.dtClientRegulationsUnused.loaderVisibility(true);
    this.api.clientRegulations.list().subscribe((a) => {
      this.dataClientRegulations = a;
    });
    var existingCR = this.dataClientRegulations.find(
      (a) => a.name == this.newCR.name
    );
    if (existingCR === undefined) {
      this.api.clientRegulations.create(this.newCR).subscribe((x) => {
        this.dtClientRegulations.loaderVisibility(false);
        this.dtClientRegulationsUnused.loaderVisibility(false);
        this.modalAddRegulation.closeModal();
        this.notification.success(
          "CLIENT_REGULATIONS.SUCCESSFULLY_CREATED_REGULATION"
        );
        this.toEditRegulationIndex = null;
        this.fetchClientRegulations();
      });
    } else {
      this.notification.error(
        "CLIENT_REGULATIONS.REGULATION_ALREADY_EXISTS_ERROR"
      );
    }
  }

  duplicateReg() {
    this.newCR = {
      name: this.toEditRegulationName,
      priority: null,
      note: this.toEditRegulationNotes,
      sourceDuplicate: this.toEditRegulationSourceDuplicateId,
      enabled: true,
      id: null,
    };
    this.dtClientRegulations.loaderVisibility(true);
    this.dtClientRegulationsUnused.loaderVisibility(true);
    this.api.clientRegulations.list().subscribe((a) => {
      this.dataClientRegulations = a;
    });
    var existingCR = this.dataClientRegulations.find(
      (a) => a.name == this.newCR.name
    );
    if (existingCR === undefined) {
      this.api.clientRegulations.create(this.newCR).subscribe((x) => {
        this.dtClientRegulations.loaderVisibility(false);
        this.dtClientRegulationsUnused.loaderVisibility(false);
        this.modalDuplicateRegulation.closeModal();
        this.notification.success(
          "CLIENT_REGULATIONS.SUCCESSFULLY_CREATED_REGULATION"
        );
        this.toEditRegulationIndex = null;
        this.fetchClientRegulations();
      });
    } else {
      this.notification.error(
        "CLIENT_REGULATIONS.REGULATION_ALREADY_EXISTS_ERROR"
      );
    }
  }

  deleteRegulation(id) {
    this.api.clientRegulations.delete(id).subscribe(
      () => {
        this.notification.success(
          "CLIENT_REGULATIONS.SUCCESSFULLY_DELETED_REGULATION"
        );
        this.fetchClientRegulations();
      },
      (err) => {
        this.notification.error("CLIENT_REGULATIONS.RECORDS_STILL_EXIST_ERROR");
      }
    );
  }

  duplicateRegulation(id) {
    this.toEditRegulationSourceDuplicateId = id;
    this.toEditRegulationSourceDuplicateName = this.dataClientRegulations.find(
      (i) => i.id === id
    ).name;
    if (!this.toEditRegulationSourceDuplicateName) {
      this.toEditRegulationSourceDuplicateName = this.dataClientRegulationsUnused.find(
        (i) => i.id === id
      ).name;
    }
    this.modalDuplicateRegulation.openModal();
  }

  fetchClientRegulations() {
    this.actionsClientRegulations = [
      {
        iconClass: "fas fa-edit",
        toolTipText: "GENERIC.VIEW_EDIT_CONFIGURATION",
        callback: this.getConfigurations,
      },
      {
        iconClass: "fas fa-pen",
        toolTipText: "GENERIC.EDIT",
      },
      {
        iconClass: "fas fa-copy",
        toolTipText: "GENERIC.DUPLICATE",
        callback: this.duplicateRegulation,
      },
    ];

    this.actionsClientRegulationsUnused = [
      {
        iconClass: "fas fa-edit",
        toolTipText: "GENERIC.VIEW_EDIT_CONFIGURATION",
        callback: this.getConfigurations,
      },
      {
        iconClass: "fas fa-pen",
        toolTipText: "GENERIC.EDIT",
      },
      {
        iconClass: "fas fa-copy",
        toolTipText: "GENERIC.DUPLICATE",
        callback: this.duplicateRegulation,
      },
      {
        iconClass: "fas fa-trash",
        toolTipText: "GENERIC.DELETE",
        callback: this.deleteRegulation,
      },
    ];

    this.columnsClientRegulations = [
      {
        name: "CLIENT_REGULATIONS.SHORT_NAME_FIELD",
        prop: "name",
        width: 200,
        sortable: true,
      },
      { name: "GENERIC.DESCRIPTION", prop: "note", width: 500, sortable: true },
      { name: "GENERIC.ACTIONS", prop: "actions", width: 500 },
    ];

    this.columnsClientRegulationsUnused = [
      {
        name: "CLIENT_REGULATIONS.SHORT_NAME_FIELD",
        prop: "name",
        width: 200,
        sortable: true,
      },
      { name: "GENERIC.DESCRIPTION", prop: "note", width: 500, sortable: true },
      { name: "GENERIC.ACTIONS", prop: "actions", width: 500 },
    ];

    this.api.clientRegulations.list().subscribe((a) => {
      const usedRegulations = [];
      const unusedRegulations = [];

      a.forEach((reg: any) => {
        let toAdd = false;
        this.dataGeoRegulationMaps.forEach((geo: GeoRegulationMap) => {
          if (geo.regulationId === reg.id) {
            toAdd = true;
          }
        });
        if (toAdd) {
          usedRegulations.push(reg);
        } else {
          unusedRegulations.push(reg);
        }
      });

      this.dataClientRegulations = usedRegulations;
      this.dataClientRegulationsUnused = unusedRegulations;
      this.dtClientRegulations.updateRows(this.dataClientRegulations);
      this.dtClientRegulations.loaderVisibility(false);
      this.dtClientRegulationsUnused.updateRows(
        this.dataClientRegulationsUnused
      );
      this.dtClientRegulationsUnused.loaderVisibility(false);
    });
    this.api.businessUnits.list().subscribe((bu: BusinessUnit[]) => {
      this.businessUnits = bu;
    });

    this.api.clients.getComplianceInputTypes(this.userId).subscribe((a) => {
      this.complianceInputTypes = a.map((c, index) => {
        return { name: c };
      });
    });
  }

  getConfigurations(id) {
    this.router.navigate(["/client-regulations/configuration", id]);
  }

  updateRegulation(clientRegulation) {
    this.dtClientRegulations.loaderVisibility(true);
    this.dtClientRegulationsUnused.loaderVisibility(true);
    this.api.clientRegulations.update(clientRegulation).subscribe((x) => {
      this.dtClientRegulations.loaderVisibility(false);
      this.dtClientRegulationsUnused.loaderVisibility(false);
      this.notification.success("GENERIC.SUCCESSFUL_DATA_UPDATE");
    });
  }
}
