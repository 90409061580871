import { BaseApiService } from "../base/base-api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GeoSummary } from "@fourcomply-dashboard/shared";
import { GeoSummarySerializer } from "../../../models/geo-summary/geo-summary-serializer.class";
import { CustomErrorHandlerService } from "../custom-error-handler.service";
import { Observable, throwError } from "rxjs";
import { map, tap, catchError } from "rxjs/operators";

@Injectable()
export class GeoSummaryService extends BaseApiService<GeoSummary> {

  geoSummarySerializer = new GeoSummarySerializer();

  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      null,
      "citizens",
      new GeoSummarySerializer(),
      new CustomErrorHandlerService()
    );
  }

getGeoSummary(
    id: number,
    permissionType: string,
    sortBy : string = "",
    mapType : string
  ): Observable<GeoSummary> {
    console.log("GETTING MAP TYPE", mapType);
    let url = "";
    if(mapType == "consent"){
      url = `${this.url}/citizens/complianceinput/summarygeo`;
    }else{
      url = `${this.url}/citizens/permissions/summarygeo`;
    }
    
    let filters : string = "";
    let sorts : string = "";

    if (id !== 0) {
      filters += "regulation_id=="+id+",";
    }

    if(permissionType){
      filters += "permission_type=="+permissionType
    }

    console.log(filters);

    url += "?page=1&pagesize=1"+(filters ? "&filters="+filters : "")+(sortBy ? "&sorts="+sortBy : "");

    return this.httpClient
      .get(url)
      .pipe(
        map(
          (data: any) =>
            this.geoSummarySerializer.fromJson(
              data
            ) as GeoSummary
        )
      );
    }
}