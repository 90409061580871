<div
  class="container regulation-configuration"
  *ngIf="dataClientRegulationConfiguration"
>
  <div class="column col-12">
    <app-generic-card>
      <h3>
        <app-back-btn></app-back-btn>
        {{ "BASIC_ENTITIES_SINGULAR.REGULATION_CONFIGURATION" | translate }} -
        {{ regulationName }}
      </h3>
      <div class="columns">
        <div class="column col-8">
          <div class="columns">
            <div
              *ngIf="
                dataClientRegulationConfiguration &&
                dataClientRegulationConfiguration.config
              "
              class="column col-12"
              style="margin-bottom:20px;"
            >
              <h5>
                {{
                  "BASIC_ENTITIES_SINGULAR.CONSENT_OVERRIDE_BEHAVIOR"
                    | translate
                }}
              </h5>
              <div style="margin-bottom:10px;">
                {{
                  "CLIENT_REGULATIONS.THIS_TOGGLE_CONSENT_OVERRIDE" | translate
                }}
              </div>
              <mat-slide-toggle
                [checked]="
                  dataClientRegulationConfiguration.config
                    .consent_override_behavior == 'yes'
                "
                (change)="changeConsentOverrideBehavior($event)"
              >
                <b>{{
                  "BASIC_ENTITIES_SINGULAR.CONSENT_OVERRIDE_BEHAVIOR"
                    | translate
                }}</b></mat-slide-toggle
              >
            </div>
            <div class="column col-12" style="margin-bottom:20px;">
              <h5>
                {{
                  "BASIC_ENTITIES_SINGULAR.PERMISSION_TYPE_MAPPING" | translate
                }}
              </h5>
              <span *ngFor="let type of explicitPermissionTypes; index as i">
                <div
                  class="column col-12 processing-base-section"
                  *ngIf="type.derived_permission_types?.length > 0"
                >
                  <h6>
                    {{ "BASIC_ENTITIES_SINGULAR.EXPLICIT_TYPE" | translate }} -
                    {{ type.name_aux }}
                  </h6>
                  <div style="padding-left:20px;">
                    <div style="font-size:16px;font-weight:bold;">
                      {{ "BASIC_ENTITIES_PLURAL.DERIVED_TYPES" | translate }}:

                      <button
                        class="btn btn-action btn-primary btn-sm s-circle tooltip"
                        matTooltip="{{ 'GENERIC.ADD' | translate }}"
                        (click)="openTypeModal(null, i)"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                    <div
                      style="padding-left:15px;margin-top:15px;"
                      *ngFor="
                        let dperm of type.derived_permission_types;
                        index as ind
                      "
                    >
                      <b
                        >{{ dperm.name_aux }}
                        <button
                          class="btn btn-action btn-primary btn-sm s-circle tooltip"
                          matTooltip="{{ 'GENERIC.EDIT' | translate }}"
                          (click)="openTypeModal(ind, i)"
                        >
                          <i class="fas fa-pen"></i>
                        </button>
                        <button
                          class="btn btn-action btn-primary btn-sm s-circle tooltip"
                          matTooltip="{{ 'GENERIC.DELETE' | translate }}"
                          (click)="openDeletePermissionTypeModal(i, ind)"
                        >
                          <i class="fas fa-trash"></i></button
                      ></b>
                      <div style="padding-left:15px;">
                        {{
                          "BASIC_ENTITIES_SINGULAR.EXPIRY_BEHAVIOR" | translate
                        }}
                        - {{ dperm.expiry_behavior }}
                      </div>
                      <div style="padding-left:15px;">
                        {{
                          "BASIC_ENTITIES_SINGULAR.DEFAULT_PERMISSION_VALUE"
                            | translate
                        }}: -
                        {{ dperm.default_permission_value }}
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            </div>

            <mat-accordion style="width:100%; margin-bottom:30px;">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h5 style="margin:0;">
                      {{ "GENERIC.UNSET" | translate }}
                      {{
                        "BASIC_ENTITIES_SINGULAR.PERMISSION_TYPE_MAPPING"
                          | translate
                      }}
                    </h5>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <span *ngFor="let type of explicitPermissionTypes; index as i">
                  <div
                    class="column col-12 processing-base-section"
                    *ngIf="type.derived_permission_types?.length == 0"
                  >
                    <h6>
                      {{ "BASIC_ENTITIES_SINGULAR.EXPLICIT_TYPE" | translate }}
                      - {{ type.name_aux }}
                    </h6>
                    <div style="padding-left:20px;">
                      <div style="font-size:16px;font-weight:bold;">
                        {{ "BASIC_ENTITIES_PLURAL.DERIVED_TYPES" | translate }}:

                        <button
                          class="btn btn-action btn-primary btn-sm s-circle tooltip"
                          matTooltip="{{ 'GENERIC.ADD' | translate }}"
                          (click)="openTypeModal(null, i)"
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                      </div>
                      <div
                        style="padding-left:15px;margin-top:15px;"
                        *ngFor="
                          let dperm of type.derived_permission_types;
                          index as ind
                        "
                      >
                        <b
                          >{{ dperm.name_aux }}
                          <button
                            class="btn btn-action btn-primary btn-sm s-circle tooltip"
                            matTooltip="{{ 'GENERIC.EDIT' | translate }}"
                            (click)="openTypeModal(ind, i)"
                          >
                            <i class="fas fa-pen"></i>
                          </button>
                          <button
                            class="btn btn-action btn-primary btn-sm s-circle tooltip"
                            matTooltip="{{ 'GENERIC.DELETE' | translate }}"
                            (click)="openDeletePermissionTypeModal(i, ind)"
                          >
                            <i class="fas fa-trash"></i></button
                        ></b>
                        <div style="padding-left:15px;">
                          {{
                            "BASIC_ENTITIES_SINGULAR.EXPIRY_BEHAVIOR"
                              | translate
                          }}
                          - {{ dperm.expiry_behavior }}
                        </div>
                        <div style="padding-left:15px;">
                          {{
                            "BASIC_ENTITIES_SINGULAR.DEFAULT_PERMISSION_VALUE"
                              | translate
                          }}: -
                          {{ dperm.default_permission_value }}
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
              </mat-expansion-panel>
            </mat-accordion>

            <div class="column col-12">
              <h5>
                {{
                  dataClientRegulationConfiguration.config
                    .processing_purpose_translation &&
                  dataClientRegulationConfiguration.config
                    .processing_purpose_translation !== ""
                    ? dataClientRegulationConfiguration.config
                        .processing_purpose_translation + "s"
                    : "Processing Purposes"
                }}
                <button
                  class="btn btn-action btn-primary btn-sm s-circle tooltip"
                  matTooltip="Add"
                  (click)="openProcessingModal()"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </h5>
            </div>

            <div class="column col-12">
              <div
                class="processing-base-section"
                *ngFor="
                  let purpose of sortProcessingPurposesByName(
                    dataClientRegulationConfiguration.config.processing_purposes
                  );
                  index as i
                "
              >
                <mat-accordion
                  style="width:100%; margin-bottom:30px; border-top: 0px"
                >
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <span style="margin:0;">{{ purpose.name }}</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div style="margin-bottom:20px;" class="purposes">
                      <div>
                        <strong>{{ "GENERIC.NAME" | translate }}:</strong>
                        {{ purpose.name }}
                        <button
                          class="btn btn-action btn-primary btn-sm s-circle tooltip"
                          matTooltip="{{ 'GENERIC.EDIT' | translate }}"
                          (click)="openProcessingModal(i)"
                        >
                          <i class="fas fa-pen"></i>
                        </button>
                        <button
                          class="btn btn-action btn-primary btn-sm s-circle tooltip"
                          matTooltip="{{ 'GENERIC.DELETE' | translate }}"
                          (click)="openDeleteProcessingPurposeModal(i)"
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                      <div>
                        <strong
                          >{{
                            "BASIC_ENTITIES_SINGULAR.TTL_MONTHS" | translate
                          }}:</strong
                        >
                        {{ purpose.ttl_months }}
                        {{ "BASIC_ENTITIES_PLURAL.MONTHS" | translate }}
                      </div>
                      <div>
                        <strong
                          >{{
                            "BASIC_ENTITIES_SINGULAR.CONSENT_REQUIREMENT"
                              | translate
                          }}:</strong
                        >
                        {{ purpose.consent_requirement }}
                      </div>
                      <div>
                        <strong
                          >{{
                            "BASIC_ENTITIES_SINGULAR.PERMISSION_CATEGORY"
                              | translate
                          }}:</strong
                        >
                        {{ purpose.permission_category }}
                      </div>
                      <div>
                        <strong
                          >{{
                            "BASIC_ENTITIES_SINGULAR.RETURNED_CONTACT"
                              | translate
                          }}:</strong
                        >
                        {{ purpose.returned_contact }}
                      </div>
                      <div>
                        <strong
                          >{{
                            "BASIC_ENTITIES_SINGULAR.PRIVACY_POLICY_JUSTIFICATION"
                              | translate
                          }}:</strong
                        >
                        {{ purpose.privacy_policy_justification }}
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-generic-card>
  </div>
</div>

<app-modal
  #modalBasesEdit
  (closer)="closeModal()"
  title="BASIC_ENTITIES_SINGULAR.PROCESSING_PURPOSE"
>
  <form #g="ngForm" (ngSubmit)="submitProcessingCustomization(g)">
    <div class="purposes-edit-section" *ngIf="processingForEdit">
      <div class="form-group">
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'GENERIC.NAME' | translate }}"
            name="name"
            [(ngModel)]="processingForEdit.name"
            required
          />
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <input
            matInput
            type="number"
            name="ttl"
            placeholder="{{ 'BASIC_ENTITIES_SINGULAR.TTL_MONTHS' }}"
            [(ngModel)]="processingForEdit.ttl_months"
            required
          />
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-select
            matInput
            name="category"
            placeholder="{{
              'BASIC_ENTITIES_SINGULAR.PERMISSION_CATEGORY' | translate
            }}"
            [(ngModel)]="processingForEdit.permission_category"
            required
          >
            <mat-option
              *ngFor="let type of allExistingPermissionCategories"
              value="{{ type.name }}"
              >{{ type.name }}</mat-option
            >
          </mat-select>
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-select
            matInput
            name="consent"
            placeholder="{{
              'CLIENT_REGULATIONS.CONSENT_REQUIRED' | translate
            }}"
            [(ngModel)]="processingForEdit.consent_requirement"
            required
          >
            <mat-option [value]="true">{{
              "GENERIC.TRUE" | translate
            }}</mat-option>
            <mat-option [value]="false">{{
              "GENERIC.FALSE" | translate
            }}</mat-option>
          </mat-select>
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-select
            matInput
            name="returned"
            placeholder="{{
              'CLIENT_REGULATIONS.RETURNED_CONTACT' | translate
            }}"
            [(ngModel)]="processingForEdit.returned_contact"
            required
          >
            <mat-option value="Delete">{{
              "GENERIC.DELETE" | translate
            }}</mat-option>
            <mat-option value="Retain">{{
              "GENERIC.RETAIN" | translate
            }}</mat-option>
          </mat-select>
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <textarea
            rows="6"
            matInput
            type="string"
            name="justification"
            placeholder="{{
              'CLIENT_REGULATIONS.PRIVACY_POLICY_JUSTIFICATION' | translate
            }}"
            [(ngModel)]="processingForEdit.privacy_policy_justification"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="form-group" *ngIf="isProcessingRequired">
        <p class="required-style">
          {{ "GENERIC.ALL_FIELDS_REQUIRED" | translate }}
        </p>
      </div>
      <div class="form-group main-submit">
        <button type="submit" class="btn btn-primary">
          {{ "GENERIC.SAVE" | translate }}
        </button>
      </div>
    </div>
  </form>
</app-modal>

<app-modal
  #modalTypeEdit
  (closer)="closePermissionModal()"
  title="CLIENT_REGULATIONS.ADD_EDIT_DERIVED_TYPE"
>
  <form #f="ngForm" (ngSubmit)="submitPermissionTypeCustomization(f)">
    <div class="purposes-edit-section" *ngIf="derivedPermissionTypeForEdit">
      <div class="form-group">
        <mat-form-field>
          <mat-select
            matInput
            name="type"
            placeholder="{{
              'BASIC_ENTITIES_SINGULAR.PERMISSION_TYPE' | translate
            }}"
            (selectionChange)="changeDerivedTypeInModal($event)"
            [(ngModel)]="derivedPermissionTypeForEdit.name"
            required
          >
            <mat-option
              *ngFor="let eType of explicitPermissionTypes"
              value="{{ eType.name }}"
              >{{ eType.name_aux }}</mat-option
            >
          </mat-select>
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-select
            matInput
            name="expiry_behavior"
            [(ngModel)]="derivedPermissionTypeForEdit.expiry_behavior"
            placeholder="{{
              'BASIC_ENTITIES_SINGULAR.PERMISSION_TYPE' | translate
            }} {{ 'BASIC_ENTITIES_SINGULAR.EXPIRY_BEHAVIOR' | translate }}"
            (selectionChange)="changeDerivedValueInModal($event, 'expiry')"
            required
          >
            <mat-option value="delete">{{
              "GENERIC.DELETE" | translate
            }}</mat-option>
            <mat-option value="retain">{{
              "GENERIC.RETAIN" | translate
            }}</mat-option>
          </mat-select>
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-select
            matInput
            name="default_permission_value"
            placeholder="{{
              'BASIC_ENTITIES_SINGULAR.DEFAULT_PERMISSION_VALUE' | translate
            }}"
            [(ngModel)]="derivedPermissionTypeForEdit.default_permission_value"
            (selectionChange)="changeDerivedValueInModal($event, 'default')"
            required
          >
            <mat-option value="yes">{{ "GENERIC.YES" | translate }}</mat-option>
            <mat-option value="no">{{ "GENERIC.NO" | translate }}</mat-option>
            <mat-option value="indeterminate">{{
              "GENERIC.INDETERMINATE" | translate
            }}</mat-option>
          </mat-select>
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group main-submit">
        <button type="submit" class="btn btn-primary">
          {{ "GENERIC.SAVE" | translate }}
        </button>
      </div>
    </div>
  </form>
</app-modal>

<app-modal
  #modalDeletePurp
  title="MODAL_GENERIC.CONFIRM_DELETE"
  bodyContent="{{
    'CLIENT_REGULATIONS.ARE_YOU_SURE_DELETE_PURPOSE' | translate
  }}"
  [confirmCallback]="deleteProcessingPurpose"
></app-modal>

<app-modal
  #modalDeletePerm
  title="MODAL_GENERIC.CONFIRM_DELETE"
  bodyContent="{{
    'CLIENT_REGULATIONS.ARE_YOU_SURE_DELETE_DERIVED' | translate
  }}"
  [confirmCallback]="deletePermissionType"
></app-modal>
