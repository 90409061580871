import { BaseApiService } from "../base/base-api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from '../custom-error-handler.service';
import { BusinessUnit } from '../../../models/business-unit/business-unit.model';
import { BusinessUnitSerializer } from '../../../models/business-unit/business-unit-serializer.class';

/**
 * Service to communicate with API Client Users
 */

@Injectable()
export class BusinessUnitsService extends BaseApiService<BusinessUnit> {
  constructor(httpClient: HttpClient) {
    super(
        httpClient, 
        null, 
        "businessunits", 
        new BusinessUnitSerializer(), 
        new CustomErrorHandlerService());
  }

  // EXTEND/OVERRIDE THE SERVICE HERE IF NECESSARY
}
