import { ConsentCountry } from "./consent-country.model";
import { Resource } from "../resource/resource.model";

/**
 * Users WITHIN a tenant in 4Comply.  This will be used in both applications
 */

export class ConsentSummaryByCountry extends Resource {
  total_number: string;
  data: ConsentCountry[];
}
