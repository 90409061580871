import { BaseApiService } from "../base/base-api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from '../custom-error-handler.service';
import { AdminUser } from '../../../models/admin-users/admin-user.model';
import { AdminUserSerializer } from '../../../models/admin-users/admin-user-serializer.class';

/**
 * Service to communicate with API Client Users
 */

@Injectable()
export class AdminUsersService extends BaseApiService<AdminUser> {
  constructor(httpClient: HttpClient) {
    super(httpClient, null, "AdminUser", new AdminUserSerializer(), new CustomErrorHandlerService());
  }

  // EXTEND/OVERRIDE THE SERVICE HERE IF NECESSARY
}
