import { Resource } from "../resource/resource.model";
import { ClientUserPermission } from "../client-user-permissions/client-user-permission.model";

/**
 * Users WITHIN a tenant in 4Comply.  This will be used in both applications
 */

export class ClientUser extends Resource {
  firstName: string;
  lastName: string;
  emailAddress: string;
  userLevel: string;
  externalId: string;
  permissions: ClientUserPermission;
  createdAt: Date;
  updatedAt: Date;
  isOriginalUser: string;
}
