/**
 * This is a basic class to hold our query options and transformations
 */

export class QueryOptions {
  public pageSize: number;
  public page: number;
  public filter: string;
  public sorts: string;

  public toQueryString() {
    let string = "?PageSize=" + this.pageSize + "&Page=" + this.page;
    if (this.filter) {
      string += "&filters=" + this.filter;
    }

    if (this.sorts) {
      string += "&Sorts=" + this.sorts;
    }
    return string;
  }
}
