import { Serializer } from "../resource/serializer.interface";
import { LegalActivityError } from "./legal-activity-error.model";

export class LegalActivityErrorSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): LegalActivityError {
    const legalActivityError = new LegalActivityError();
    legalActivityError.id = json.id;
    legalActivityError.consentId = json.consent_id;
    legalActivityError.emailAddress = json.email_address;
    legalActivityError.regulationId = json.regulation_id;
    legalActivityError.regulationName = json.regulation_name;
    legalActivityError.createdAt = new Date(json.created_at);
    legalActivityError.updatedAt = new Date(json.updated_at);
    legalActivityError.activityAt = new Date(json.activity_at);
    legalActivityError.activityType = json.activity_type;
    legalActivityError.standardState = json.standard_state;
    legalActivityError.standardCountry = json.standard_country;
    legalActivityError.inputState = json.input_state;
    legalActivityError.inputCountry = json.input_country;
    legalActivityError.consentCode = json.consent_code;
    legalActivityError.rightWorkflowId = json.right_workflow_id;
    legalActivityError.rightWorkflowStepName = json.right_workflow_step_name;
    legalActivityError.rejectCode = json.reject_code;
    legalActivityError.rejectReason = json.reject_reason;
    legalActivityError.systemId = json.system_id;
    legalActivityError.systemType = json.system_type;
    legalActivityError.system = json.system;
    legalActivityError.systemRecordId = json.system_record_id;
    legalActivityError.systemRecordType = json.system_record_type;

    legalActivityError.system=json.system;
    legalActivityError.permission_type=json.permission_type;
    legalActivityError.business_unit=json.business_unit;
    legalActivityError.external_source=json.external_source;
    legalActivityError.processing_purpose=json.processing_purpose;
    return legalActivityError;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(legalActivityError: LegalActivityError): any {
    return {
      id: legalActivityError.id,
      consent_id: legalActivityError.consentId,
      email_address: legalActivityError.emailAddress,
      regulation_id: legalActivityError.regulationId,
      created_at: legalActivityError.createdAt,
      updated_at: legalActivityError.updatedAt,
      activity_at: legalActivityError.activityAt,
      activity_type: legalActivityError.activityType,
      consent_code: legalActivityError.consentCode,
      right_workflow_id: legalActivityError.rightWorkflowId,
      right_workflow_step_name: legalActivityError.rightWorkflowStepName,
      reject_code: legalActivityError.rejectCode,
      reject_reason: legalActivityError.rejectReason,
      system_id: legalActivityError.systemId,
      system_type: legalActivityError.systemType,
      system: legalActivityError.system,
      system_record_id: legalActivityError.systemRecordId,
      system_record_type: legalActivityError.systemRecordType,
    };
  }
}
