import {
  Component,
  OnInit,
  NgZone,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  AuthService,
  APIService,
  NotificationService,
  ModalComponent,
} from "@fourcomply-dashboard/shared";
import { ActivatedRoute, Router } from "@angular/router";
import { pipe } from "rxjs";
import { QueryOptions } from "libs/shared/src/lib/models/query-options/query-options.class";
import { HitlTask } from "libs/shared/src/lib/models/hitl-tasks/hitl-task.model";
import { HitlTemplate } from "libs/shared/src/lib/models/hitl-templates/hitl-template.model";
import { DatePipe } from "@angular/common";
import {
  CardSettingsModel,
  DialogEventArgs,
  DragEventArgs,
} from "@syncfusion/ej2-kanban";
import { KanbanComponent } from "@syncfusion/ej2-angular-kanban";
import { PanelService } from "@verizon/ngx-panels";
import { HitlDetailPanelComponent } from "./hitl-detail-panel.component";
import { WorkflowDetailPanelComponent } from "../right-request-detail/workflow-detail-panel.component";

@Component({
  selector: "app-hitl-tasks",
  templateUrl: "./hitl-tasks.component.html",
  styleUrls: ["./hitl-tasks.component.scss"],
})
export class HitlTasksComponent implements OnInit {
  private userId: string;
  private userEmail: string;

  constructor(
    private auth: AuthService,
    private api: APIService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private zone: NgZone,
    private readonly panelService: PanelService
  ) {
    this.openEditTaskModal = this.openEditTaskModal.bind(this);
    this.goToWorkflow = this.goToWorkflow.bind(this);
    this.submitEditTask = this.submitEditTask.bind(this);
    this.submitDeleteTask = this.submitDeleteTask.bind(this);
    this.submitArchiveTask = this.submitArchiveTask.bind(this);
  }

  public kanbanData: any[] = [];
  public cardSettings: CardSettingsModel = {
    contentField: "Summary",
    headerField: "Title",
  };

  pages: number;
  currentPage: number = 1;
  total: number;
  sort: string;
  pageSize: number = 10;
  dataHitlTasks: HitlTask[];
  isMoving: boolean = false;
  dataHitlTemplates: HitlTemplate[];
  selectedTask: any = null;
  sourceFilter: any;
  userFilter: any;
  templateFilter: any;
  searchText: string;
  users: any;
  triggers: any;
  popupId: string;

  @ViewChild("KanbanObj")
  public kanbanObj: KanbanComponent;

  fields: any = [
    { name: "status", map: "state", type: "string" },
    { name: "text", map: "label", type: "string" },
    { name: "tags", type: "string" },
    { name: "color", map: "hex", type: "string" },
    { name: "resourceId", type: "string" },
  ];

  getWidth(): any {
    if (document.body.offsetWidth < 850) {
      return "90%";
    }

    return 850;
  }

  ngOnInit() {
    this.popupId = this.route.snapshot.queryParams["popup_id"];
    this.auth.userProfile$.subscribe(
      pipe((res: any) => {
        this.userEmail = res.email;
        this.getTemplates();
        this.getTriggers();
        this.getUsers();
      })
    );
    console.log(this.popupId, this.route.snapshot.queryParams);
    if (this.popupId) {
      this.openPopupDelayed();
    }
  }

  openPopupDelayed() {
    setTimeout(() => {
      if (
        this.dataHitlTasks &&
        this.dataHitlTasks.length > 0 &&
        this.dataHitlTemplates &&
        this.dataHitlTemplates.length > 0
      ) {
        this.openEditTaskModal(this.popupId);
      } else {
        this.openPopupDelayed();
      }
    }, 3000);
  }

  getUsers() {
    this.api.clientUsers.list().subscribe((a) => {
      this.users = a;
      let matchingUser = a.find((u) => u.emailAddress == this.userEmail);
      if (matchingUser) {
        this.userFilter = matchingUser.id;
      }
      if (this.popupId) {
        this.removeFilters();
      } else {
        this.getTasks();
      }
    });
  }

  getTriggers() {
    this.api.hitlTemplates.getTriggers().subscribe((a) => {
      console.log(a);
      this.triggers = a.map((a) => {
        return { value: a, label: a };
      });
    });
  }

  dialogOpen(args: DialogEventArgs): void {
    args.cancel = true;
    console.log(args);
    this.openEditTaskModal(args.data.Id);
  }

  searchWithEmail() {
    this.getTasks();
  }

  getTemplates() {
    this.api.hitlTemplates.list().subscribe((d) => {
      this.dataHitlTemplates = d;
    });
  }

  changeSourceFilter() {
    this.getTasks();
  }

  changeUserFilter() {
    this.getTasks();
  }

  changeTemplateFilter() {
    this.getTasks();
  }

  onKanbanDragStop(args: DragEventArgs) {
    console.log(args);

    let data = args.data[0];
    const newColumn = data.Status;
    let oldColumn;
    let taskToUpdate = null;
    this.dataHitlTasks.forEach((a) => {
      console.log(a, data);
      if (a.id === data.Id) {
        taskToUpdate = a;
        oldColumn = a.status;
      }
    });
    console.log(newColumn, oldColumn);
    if (oldColumn !== newColumn) {
      this.updateTaskStatus(taskToUpdate, newColumn, oldColumn, data);
    }
  }

  revertTaskBack(taskId, oldStatus) {
    this.kanbanObj.updateCard({ Id: taskId, Status: oldStatus });
  }

  updateTaskStatus(task: HitlTask, newStatus, oldColumn, itemData) {
    console.log(newStatus, oldColumn, itemData);
    this.api.hitlTasks.update({ ...task, status: newStatus }).subscribe(
      (a) => {
        this.notificationService.success("HITL_TASK.STATUS_UPDATED");
      },
      (err) => {
        this.revertTaskBack(itemData.Id, oldColumn);
      }
    );
  }

  submitDeleteTask() {
    let task = this.selectedTask;
    this.api.hitlTasks.delete(task.id).subscribe((a) => {
      this.selectedTask = null;
      this.panelService.closeAll();
      this.notificationService.success("HITL_TASK.DELETED");
      this.getTasks();
    });
  }

  submitArchiveTask() {
    this.updateTaskStatus(this.selectedTask, "archived", null, null);
    this.selectedTask = null;
    this.panelService.closeAll();
    this.getTasks();
  }

  openEditTaskModal(taskId) {
    this.dataHitlTasks.forEach((a) => {
      if (a.id === taskId) {
        this.selectedTask = a;
      }
    });
    this.dataHitlTemplates.forEach((b) => {
      console.log(b, this.selectedTask);
      if (b.id === this.selectedTask.templateId) {
        this.selectedTask.template = b;
      }
    });
    console.log(this.selectedTask);
    this.panelService.open(HitlDetailPanelComponent, {
      selectedTask: this.selectedTask,
      goToWorkflow: this.goToWorkflow,
      submitDeleteTask: this.submitDeleteTask,
      submitArchiveTask: this.submitArchiveTask,
      submitEditTask: this.submitEditTask,
      users: this.users,
    });
  }

  goToWorkflow(selectedTask) {
    this.panelService.open(WorkflowDetailPanelComponent, {
      workflowInstanceId: selectedTask.workflowInstanceId,
    });
  }

  submitEditTask(selectedTask) {
    console.log("HEY", selectedTask);
    let a = { ...selectedTask };
    let oldTask = this.dataHitlTasks.find((a) => a.id === selectedTask.id);
    delete a.template;
    this.api.hitlTasks.update(a).subscribe((a) => {
      this.panelService.closeAll();
      this.notificationService.success("HITL_TASK.STATUS_UPDATED");
      this.selectedTask = null;
      this.getTasks();
    });
  }

  removeFilters() {
    this.searchText = null;
    this.sourceFilter = null;
    this.templateFilter = null;
    this.userFilter = null;
    this.getTasks();
  }

  getTasks() {
    let qo = new QueryOptions();
    qo.page = this.currentPage;
    qo.pageSize = this.pageSize;
    qo.sorts = this.sort;
    qo.filter = "";
    if (this.searchText != null) {
      qo.filter = "citizen_email@=" + this.searchText + "&";
    }

    if (this.sourceFilter != null) {
      qo.filter = "source==" + this.sourceFilter + "&";
    }

    if (this.templateFilter != null) {
      qo.filter = "template_id==" + this.templateFilter + "&";
    }

    if (this.userFilter != null) {
      qo.filter = "assigned_to_id==" + this.userFilter + "&";
    }

    this.api.hitlTasks.listWithPaging(qo).subscribe((a) => {
      this.dataHitlTasks = a.list;
      this.pages = a.pages;
      this.currentPage = a.currentpage;
      this.total = a.total;
      this.pageSize = a.pagesize;
      this.dataHitlTasks = this.dataHitlTasks.sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });
      console.log(this.dataHitlTasks);
      this.kanbanData = this.dataHitlTasks.map((task, index) => {
        let template: HitlTemplate = null;
        this.dataHitlTemplates.forEach((d) => {
          if (d.id === task.templateId) {
            template = d;
          }
        });
        let userAssigned;
        if (task.assignedToId != null) {
          this.users.forEach((user) => {
            if (user.id === task.assignedToId) {
              userAssigned = user;
            }
          });
        }
        return {
          Status: task.status,
          Title: template.shortDescription,
          Summary:
            task.citizenEmail +
            " | " +
            new DatePipe("en-US").transform(task.updatedAt, "mediumDate"),
          Tags: `Template-${template.name}, Source-${task.source} ,${
            task.assignedToId ? "Assigned To-" + userAssigned.emailAddress : ""
          }`,
          hex: "#6bbd49",
          Id: task.id,
        };
      });
    });
  }
}
