<mat-nav-list style="margin-top: 48px !important;width: 280px;"
*ngIf="map">
<div style="height: 15px;background: white;"></div>
<mat-accordion style="width:100%; margin-bottom:0px; border-top: 0px"
*ngFor="let mapItem of map | keyvalue: unOrderedPipe; let indexOfElement=index">
  <mat-expansion-panel
  *ngIf="indexOfElement <= 2" [expanded]="true">
    <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px" multi>
      <mat-panel-title>
          <h6 style="margin:0; font-weight: bold; min-width:350px;">{{ mapItem.key | translate }}</h6>
      </mat-panel-title>
    </mat-expansion-panel-header>

      <app-sidenav-item
        *ngFor="let item of mapItem.value"
        [item]="item"
      ></app-sidenav-item>

</mat-expansion-panel >

<mat-expansion-panel
*ngIf="indexOfElement > 2" [expanded]="false">
  <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
    <mat-panel-title>
        <h6 style="margin:0; font-weight: bold;">{{ mapItem.key | translate }}</h6>
    </mat-panel-title>
  </mat-expansion-panel-header>

    <app-sidenav-item
      *ngFor="let item of mapItem.value"
      [item]="item"
    ></app-sidenav-item>

</mat-expansion-panel >

</mat-accordion>
</mat-nav-list>



<mat-nav-list
*ngIf="!map">
  <app-sidenav-item
    *ngFor="let item of navItemsThis"
    [item]="item"
  ></app-sidenav-item>
</mat-nav-list>
