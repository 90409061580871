import { Component, OnInit, ViewChild } from "@angular/core";
import { FormlyFieldConfig } from "@ngx-formly/core";
import {
  APIService,
  NotificationService,
  BusinessUnit,
  FormComponent,
} from "@fourcomply-dashboard/shared";
import { Router, ActivatedRoute } from "@angular/router";
import { BreadcrumbService } from "xng-breadcrumb";
import { WebUrlValidator } from "../../app.module";

@Component({
  selector: "app-add-business-unit",
  templateUrl: "./add-business-unit.component.html",
  styleUrls: ["./add-business-unit.component.scss"],
})
export class AddBusinessUnitComponent implements OnInit {
  @ViewChild("formSystem", { static: true })
  formSystem: FormComponent;
  isEdit = false;
  fields: FormlyFieldConfig[] = [];
  formSubmitted = false;
  triggers: string[];
  actualData: BusinessUnit;
  loadingDone = false;
  model: BusinessUnit = {
    name: "",
    id: undefined,
    tenantId: "",
  };

  constructor(
    public api: APIService,
    public router: Router,
    private notification: NotificationService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this.submitBusinessUnitForm = this.submitBusinessUnitForm.bind(this);
    this.initBusinessUnitForm();
  }

  initBusinessUnitForm() {
    this.fields = [
      {
        key: "name",
        type: "input",
        templateOptions: {
          required: true,
          label: "GENERIC.NAME",
        },
      },
    ];

    const BUId = this.route.snapshot.params["id"];
    if (BUId) {
      this.isEdit = true;
      this.api.businessUnits.get(BUId).subscribe((a) => {
        this.actualData = a;
        this.model = { ...a };
        this.formSystem.updateModel(this.model);
        this.breadcrumbService.set("@businessUnitName", this.actualData.name);
      });
    }
  }

  submitBusinessUnitForm() {
    this.formSubmitted = true;
    this.loadingDone = false;
    if (this.model.name.trim() === "") {
      this.loadingDone = true;
      this.formSystem.loaderVisibility(false);
      this.notification.error("BUSINESS_UNITS.ENTER_BUSINESS_NAME_ERROR");
      return;
    }
    const BUId = this.route.snapshot.params["id"];
    if (BUId) {
      this.api.businessUnits.update(this.model).subscribe((a) => {
        this.loadingDone = true;
        this.formSystem.loaderVisibility(false);
        this.notification.success(
          "BUSINESS_UNITS.SUCCESSFUL_UPDATE_BUSINESS_UNIT"
        );
        this.router.navigate(["business-units"]);
      });
    } else {
      this.api.businessUnits.create(this.model).subscribe((a) => {
        this.loadingDone = true;
        this.formSystem.loaderVisibility(false);
        this.notification.success("BUSINESS_UNITS.SUCCESSFUL_CREATION_BUSINESS_UNIT");
        this.router.navigate(["business-units"]);
      });
    }
  }
}
