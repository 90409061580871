<div class="container" [style.display]="outlet.isActivated ? 'none' : ''">
  <div class="column col-12">
    <app-generic-card>
      <app-actionbar
        title="{{ 'BASIC_ENTITIES_PLURAL.WEBHOOKS' | translate }}"
        [actionBarItems]="actionBarItemsWebhooks"
      ></app-actionbar>
      <app-datatable
        #dtWebhooks
        [data]="dataWebhooks"
        tableIdentifier="webhooks"
        [columns]="columnsWebhooks"
        [actions]="actionsWebhooks"
        (callbackOnUpdate)="webhooksUpdate($event)"
      ></app-datatable>
    </app-generic-card>
  </div>
</div>
<router-outlet
  #outlet="outlet"
  (deactivate)="onDeactivate($event)"
></router-outlet>
<app-modal
  #modalWebhooks
  title="{{ 'MODAL_GENERIC.CONFIRM_DELETE' | translate }}"
  bodyContent="{{ 'MODAL_GENERIC.CONFIRM_DELETE_QUESTION' | translate }}"
  [confirmCallback]="deleteWebhooksConfirm"
></app-modal>
