<div class="container">
  <div class="column col-12 hitl-wrapper">
    <app-actionbar
      title="{{ 'BASIC_ENTITIES_SINGULAR.HITL_TASK' | translate }}"
    ></app-actionbar>
    <div class="filter-list">
      <div class="search">
        <input
          style="border:none; border-bottom:1px solid #999999; margin-right:10px;"
          class="mat-input-element"
          type="text"
          placeholder="{{ 'GENERIC.SEARCH_BY_EMAIL' | translate }}"
          [(ngModel)]="searchText"
        />
        <button
          class="btn btn-primary submit-button"
          (click)="searchWithEmail()"
          #searchButton
        >
          {{ "GENERIC.SEARCH" | translate }}
        </button>
      </div>
      <div class="buffer"></div>
      <button
        style="margin-top:10px;margin-right:15px;"
        (click)="removeFilters()"
        class="btn btn-secondary"
      >
        {{ "GENERIC.CLEAR" | translate }}
      </button>
      <div class="filter">
        <mat-form-field>
          <mat-select
            matInput
            placeholder="{{ 'HITL_TASK.FILTER_USER' | translate }}"
            (selectionChange)="changeUserFilter()"
            [(ngModel)]="userFilter"
          >
            <mat-option *ngFor="let user of users" [value]="user.id"
              >{{ user.emailAddress }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="filter">
        <mat-form-field>
          <mat-select
            matInput
            placeholder="{{ 'HITL_TASK.FILTER_SOURCE' | translate }}"
            (selectionChange)="changeSourceFilter()"
            [(ngModel)]="sourceFilter"
          >
            <mat-option
              *ngFor="let trigger of triggers"
              [value]="trigger.value"
              >{{ trigger.value }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="filter">
        <mat-form-field>
          <mat-select
            matInput
            placeholder="{{ 'HITL_TASK.FILTER_TEMPLATE' | translate }}"
            (selectionChange)="changeTemplateFilter()"
            [(ngModel)]="templateFilter"
          >
            <mat-option
              *ngFor="let template of dataHitlTemplates"
              [value]="template.id"
              >{{ template.shortDescription }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="!dataHitlTasks || !dataHitlTasks.length">
      <h2>
        {{ "HITL_TASK.NO_TASKS_AVAILABLE" | translate }}
      </h2>
    </div>
    <ejs-kanban
      *ngIf="dataHitlTasks && dataHitlTasks.length"
      keyField="Status"
      id="KanbanObj"
      #KanbanObj
      [dataSource]="kanbanData"
      [cardSettings]="cardSettings"
      [dialogSettings]="dialogSettings"
      (dialogOpen)="dialogOpen($event)"
      (dragStop)="onKanbanDragStop($event)"
    >
      <e-columns>
        <e-column
          headerText="{{ 'HITL_TASK.CREATED' | translate }}"
          keyField="created"
        ></e-column>
        <e-column
          headerText="{{ 'HITL_TASK.IN_PROGRESS' | translate }}"
          keyField="in_progress"
        ></e-column>
        <e-column
          headerText="{{ 'HITL_TASK.COMPLETED' | translate }}"
          keyField="completed"
        ></e-column>
        <e-column
          headerText="{{ 'HITL_TASK.DECLINED' | translate }}"
          keyField="declined"
        ></e-column>
        <e-column
          headerText="{{ 'HITL_TASK.ARCHIVED' | translate }}"
          keyField="archived"
        ></e-column>
      </e-columns>
    </ejs-kanban>
    <div style="margin-top:10px; margin-left:10px;">
      {{ "HITL_TASK.DOUBLE_CLICK_EDIT" | translate }}
    </div>
  </div>
</div>
