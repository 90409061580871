import { ResourceSQL } from "../resource-sql/resource-sql.model";

/**
 * Users WITHIN a tenant in 4Comply.  This will be used in both applications
 */

export class ConsentPermission extends ResourceSQL {
  consentId: string;
  email: string;
  businessUnit: string;
  permissionValue: string;
  permissionType: string;
  processingPurpose: string;
  permissionCategory: string;
  expiration: Date;
  regulationName: string;
  regulationId: string;
  standardState: string;
  standardCountry: string;
  consentSource: string;
  createdAt: Date;
  updatedAt: Date;
  activity_date: Date;
  createdAtAux: string;
  expirationAux: string;
  externalSource: string;
  means: string;
  consentLanguage:string;
  privacyNoticeVersion: string;
  customField1: string;
  customField2: string;
  customField3: string;
  customField4: string;
  customField5: string;
  customField6: Date;
  customField7: Date;
  activity_dateAux:string;
}
