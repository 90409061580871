import { Component, OnInit } from "@angular/core";

import { AppTitleService } from "@fourcomply-dashboard/shared";
import { AuthService } from "@fourcomply-dashboard/shared";
import { SideNavItem } from "@fourcomply-dashboard/shared";
import { Router, NavigationEnd } from "@angular/router";
import { BreadcrumbService } from "xng-breadcrumb";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  navOpen = true;
  navItems: SideNavItem[];
  userEmail: string;
  showBreadCrumb = false;

  constructor(
    private appTitleService: AppTitleService,
    public auth: AuthService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    public translate: TranslateService
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang("en");
    translate.addLangs(["en", "fr"]);
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use("en");

    breadcrumbService.breadcrumbs$.subscribe((x) => {
      if (x.length >= 3) {
        this.showBreadCrumb = true;
      } else {
        this.showBreadCrumb = false;
      }
    });
  }

  initTranslation() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang("en");
    this.translate.addLangs(["en", "fr"]);
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use("en");
  }

  ngOnInit() {
    this.initSideNav();
    this.initIntercom();
    this.initTranslation();

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  initIntercom() {
    window.Intercom = window.Intercom || {};
    window.Intercom("boot", {
      app_id: "r2twb3rv",
    });
  }

  initSideNav() {
    this.navItems = [
      {
        divider: "SIDEMENU.CIT_RIGHTS_FULFILLMENT",
        first: true,
      },
      {
        displayName: "SIDEMENU.RIGHTS_DASHBOARD",
        iconClass: "fas fa-tachometer-alt",
        route: "dashboard-rights",
      },
      {
        displayName: "SIDEMENU.RIGHTS_REQUESTS",
        iconClass: "fas fa-lightbulb",
        route: "right-requests",
      },
      {
        displayName: "SIDEMENU.RIGHTS_FLOWS",
        iconClass: "fas fa-lightbulb",
        route: "rights-flows",
      },
      {
        divider: "SIDEMENU.COMPLIANCE_INPUTS",
      },
      {
        displayName: "SIDEMENU.COMPLIANCE_DASHBOARD",
        iconClass: "fas fa-tachometer-alt",
        route: "dashboard-consent",
      },
      {
        displayName: "SIDEMENU.COMPLIANCE_INPUTS",
        iconClass: "fas fa-door-open",
        route: "compliance-inputs",
      },
      {
        displayName: "SIDEMENU.PERMISSIONS",
        iconClass: "fas fa-door-open",
        route: "consent-permissions",
      },
      {
        displayName: "SIDEMENU.CITIZEN_HISTORY",
        iconClass: "fas fa-link",
        route: "citizen-history",
      },

      {
        divider: "SIDEMENU.LEGAL_ACTIVITIES_VAULT",
      },
      {
        displayName: "SIDEMENU.LEGAL_ACTIVITIES_VAULT",
        iconClass: "fas fa-balance-scale-right",
        route: "legal-activities",
      },
      {
        displayName: "SIDEMENU.LEGAL_ACTIVITY_ERRORS",
        iconClass: "fas fa-balance-scale-right",
        route: "legal-activity-errors",
      },
      {
        displayName: "SIDEMENU.FORGOTTEN_VAULT",
        iconClass: "fas fa-chart-line",
        route: "forgotten-users",
      },
      {
        divider: "SIDEMENU.HITL",
      },
      {
        displayName: "SIDEMENU.HITL_TASKS",
        iconClass: "fas fa-link",
        route: "hitl-tasks",
      },
      {
        displayName: "SIDEMENU.HITL_CONFIGURATION",
        iconClass: "fas fa-link",
        route: "hitl-config",
      },
      {
        divider: "SIDEMENU.REPORTING_AND_SEGMENTATION",
      },
      {
        displayName: "4Segments",
        iconClass: "fas fa-chart-pie",
        route: "https://segments.4comply.io/",
      },
      {
        divider: "SIDEMENU.CONFIGURATION",
      },
      {
        displayName: "SIDEMENU.GEO",
        iconClass: "fas fa-globe",
        route: "geo",
      },
      {
        displayName: "SIDEMENU.REGULATIONS",
        iconClass: "fas fa-gavel",
        route: "client-regulations",
      },

      {
        displayName: "SIDEMENU.FORMS",
        iconClass: "far fa-file-alt",
        route: "forms",
      },
      {
        displayName: "SIDEMENU.EMAIL_TEMPLATES",
        iconClass: "far fa-mail-bulk",
        route: "email-templates",
      },
      {
        displayName: "SIDEMENU.LANDING_PAGES",
        iconClass: "far fa-mail-bulk",
        route: "landing-pages",
      },
      {
        displayName: "SIDEMENU.WEBHOOKS",
        iconClass: "fas fa-link",
        route: "webhooks",
      },
      {
        displayName: "SIDEMENU.SYSTEMS",
        iconClass: "fas fa-link",
        route: "systems",
      },
      {
        displayName: "SIDEMENU.BUSINESS_UNITS",
        iconClass: "fas fa-link",
        route: "business-units",
      },
      //Dummy element because the last one is hidden
      {
        displayName: "SIDEMENU.BUSINESS",
        iconClass: "fas fa-link",
        route: "business-units",
      },
    ];
  }
}
