import { Component, OnInit, ViewChild } from "@angular/core";
import { FormlyFieldConfig } from "@ngx-formly/core";
import {
  APIService,
  NotificationService,
  ClientWebhook,
  FormComponent,
} from "@fourcomply-dashboard/shared";
import { Router, ActivatedRoute } from "@angular/router";
import { BreadcrumbService } from "xng-breadcrumb";
import { WebUrlValidator } from "../../app.module";

@Component({
  selector: "app-add-client-webhook",
  templateUrl: "./add-client-webhook.component.html",
  styleUrls: ["./add-client-webhook.component.scss"],
})
export class AddClientWebhookComponent implements OnInit {
  @ViewChild("formWebhook", { static: true })
  formWebhook: FormComponent;
  isEdit = false;
  fields: FormlyFieldConfig[] = [];
  formSubmitted = false;
  triggers: string[];
  actualData: ClientWebhook;
  model: ClientWebhook = {
    triggerActions: [],
    name: "",
    payloadFormat: "",
    createdAt: new Date(),
    responseMapping: "",
    id: undefined,
    signatureType: "",
    type: "",
    updatedAt: new Date(),
    webhookId: "",
    webhookUrl: "",
  };

  triggerActions = [""];
  isDuplicateTriggerSelected = false;
  constructor(
    public api: APIService,
    public router: Router,
    private notification: NotificationService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this.submitWebhooksForm = this.submitWebhooksForm.bind(this);
    this.initWebhooksForm();
    this.getTriggers();
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  getTriggers(): void {
    this.api.clientWebhooks.getTriggers().subscribe((data) => {
      this.triggers = data;
    });
  }

  onSelectionChanged(): void {
    this.isDuplicateTriggerSelected = false;
    let object = {};
    this.triggerActions.forEach(function(item) {
      if (item && !object[item]) object[item] = 0;
      object[item] += 1;
    });

    for (var prop in object) {
      if (object[prop] >= 2) {
        this.isDuplicateTriggerSelected = true;
      }
    }
  }

  initWebhooksForm() {
    this.fields = [
      {
        key: "name",
        type: "input",
        templateOptions: {
          required: true,
          label: "GENERIC.NAME",
        },
      },
      {
        key: "webhookUrl",
        type: "input",
        templateOptions: {
          required: true,
          label: "WEBHOOK.WEBHOOK_URL",
        },
        validators: {
          validation: [WebUrlValidator],
        },
      },
    ];

    const webhookId = this.route.snapshot.params["id"];
    if (webhookId) {
      this.isEdit = true;
      this.api.clientWebhooks.get(webhookId).subscribe((a) => {
        this.actualData = a;
        this.model = { ...a };
        this.triggerActions = this.model.triggerActions;
        this.formWebhook.updateModel(this.model);
        this.breadcrumbService.set("@webhookName", this.actualData.name);
      });
    }
  }

  submitWebhooksForm() {
    if (this.isDuplicateTriggerSelected) {
      this.formWebhook.loaderVisibility(false);
      return;
    }
    this.formSubmitted = true;
    this.model.triggerActions = [];
    this.triggerActions.forEach((x) => {
      if (x.length > 0) {
        this.model.triggerActions.push(x);
      }
    });
    if (this.isEdit) {
      this.api.clientWebhooks.update(this.model).subscribe(
        (a) => {
          this.notification.success("GENERIC.SUCCESSFUL_DATA_UPDATE");
          this.router.navigate(["webhooks"]);
        },
        (err) => {
          this.formWebhook.loaderVisibility(false);
        }
      );
    } else {
      this.api.clientWebhooks.create(this.model).subscribe(
        (a) => {
          this.notification.success("GENERIC.SUCCESSFUL_DATA_ADD");
          this.router.navigate(["webhooks"]);
        },
        (err) => {
          this.formWebhook.loaderVisibility(false);
        }
      );
    }
  }
}
