import { BaseApiService } from "../base/base-api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from "../custom-error-handler.service";
import { ClientWebhook } from '../../../models/client-webhooks/client-webhook.model';
import { ClientWebhookSerializer } from '../../../models/client-webhooks/client-webhook-serializer.class';
import { Observable } from 'rxjs';

/**
 * Service to communicate with API Client Users
 */

@Injectable()
export class ClientWebhooksService extends BaseApiService<ClientWebhook> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      null,
      "webhooks",
      new ClientWebhookSerializer(),
      new CustomErrorHandlerService()
    );
  }

  getTriggers(): Observable<any> {
    return this.httpClient.get(`${this.url}/webhooks/triggers`);
  }
}
