import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {
  APIService,
  DatatableComponent,
  ActionBarItem,
  ModalComponent,
  NotificationService,
} from "@fourcomply-dashboard/shared";
import { ClientUser } from "@fourcomply-dashboard/shared";
import { TranslateService } from "@ngx-translate/core";
import { QueryOptions } from "libs/shared/src/lib/models/query-options/query-options.class";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
  @ViewChild("dtClientUsers", { static: true })
  dtClientUsers: DatatableComponent;
  actionBarItemsUsers: ActionBarItem[];
  dataClientUsers: ClientUser[];
  columnsClientUsers: any[];
  actionsUsers: any[];
  toDeleteUser: string;
  toResetUser: ClientUser;
  translateService: TranslateService;
  @ViewChild("modalDeleteUser", { static: true })
  public modalDeleteUser: ModalComponent;

  @ViewChild("modalResetPassword", { static: true })
  public modalResetPassword: ModalComponent;

  constructor(
    private api: APIService,
    public router: Router,
    private notification: NotificationService,
    private translate: TranslateService
  ) {
    this.translateService = translate;
  }

  ngOnInit() {
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.openResetPasswordModal = this.openResetPasswordModal.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.goToEdit = this.goToEdit.bind(this);
    this.fetchClientUsers();
    this.setActions = this.setActions.bind(this);
    setTimeout(() => {
      this.setActions();
    }, 200);
  }

  setActions() {
    this.actionsUsers = [
      {
        iconClass: "fas fa-edit",
        toolTipText: "GENERIC.UPDATE",
        callback: this.goToEdit,
      },
      {
        iconClass: "fas fa-trash",
        toolTipText: "GENERIC.DELETE",
        callback: this.openDeleteModal,
      },
      {
        iconClass: "fas fa-share-square",
        toolTipText: "GENERIC.SEND_RESET_PASSWORD_EMAIL",
        callback: this.openResetPasswordModal,
      },
    ];
    this.dtClientUsers.actions = this.actionsUsers;
  }

  onDeactivate(event): void {
    this.fetchClientUsers();
  }

  openDeleteModal(ev) {
    this.toDeleteUser = ev;
    this.modalDeleteUser.openModal();
  }

  deleteUser() {
    this.api.clientUsers.delete(this.toDeleteUser).subscribe((a) => {
      this.modalDeleteUser.closeModal();
      this.notification.success("USER.SUCCESSFULLY_DELETED_USER");
      this.fetchClientUsers();
    });
  }

  goToEdit(clientUserId) {
    this.router.navigate(["users/" + clientUserId]);
  }

  openResetPasswordModal(clientUserId) {
    this.toResetUser = this.dataClientUsers.find((x) => x.id == clientUserId);
    console.log(this.toResetUser);
    this.modalResetPassword.openModal();
  }

  resetPassword() {
    this.modalResetPassword.closeModal();
    this.api.clientUsers
      .resetPassword(this.toResetUser.emailAddress)
      .subscribe((a: string) => {
        console.log(a);
        if (a.includes("just sent")) {
          this.notification.success("USER.EMAIL_SENT");
        } else {
          this.notification.error("USER.EMAIL_NOT_SENT_ERROR");
        }
      });
  }

  fetchClientUsers() {
    this.actionBarItemsUsers = [
      {
        btnText: "USER.ADD_NEW",
        iconClass: "fas fa-plus",
        route: "users/add",
      },
    ];

    this.columnsClientUsers = [
      { name: "USER.FIRST_NAME_FIELD", prop: "firstName" },
      { name: "USER.LAST_NAME_FIELD", prop: "lastName" },
      { name: "GENERIC.EMAIL_ADDRESS", prop: "emailAddress" },
      {
        name: "USER.USER_LEVEL",
        prop: "userLevelToTranslate",
        type: "translate",
      },
      { name: "GENERIC.ACTIONS", prop: "actions" },
    ];

    const qo = new QueryOptions();
    qo.pageSize = 300;
    qo.page = 1;

    this.api.clientUsers.list(qo).subscribe((a) => {
      this.dataClientUsers = a?.map((u) => {
        return {
          ...u,
          userLevelToTranslate: "USER." + u.userLevel.toUpperCase() + "_LEVEL",
        };
      });
      this.dtClientUsers.updateRows(this.dataClientUsers);
      this.dtClientUsers.loaderVisibility(false);
    });
  }
}
