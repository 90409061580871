<div class="container">
  <div class="column col-5">
    <app-generic-card>
      <h3 class="section-title">
        {{
          (this.model.id ? "GEO.ADD_NEW_GEO_MAP" : "GEO.EDIT_GEO_MAP")
            | translate
        }}
      </h3>
      <app-form
        #regulationForm
        [fields]="fields"
        [model]="model"
        [callback]="submitGeoMapForm"
        closeRoute="geo"
      ></app-form>
    </app-generic-card>
  </div>
</div>
