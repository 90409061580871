<form [formGroup]="form" novalidate (ngSubmit)="submit()">
  <formly-form
    [model]="modelSelf"
    [fields]="fieldsSelf"
    [options]="options"
    [form]="form"
  ></formly-form>
  <button
    *ngFor="let btn of additionalButtons"
    type="button"
    class="btn btn-default"
    (click)="btn.action()"
  >
    {{ btn.name | translate }}
  </button>
  <button
    type="submit"
    class="btn btn-primary submit-button"
    *ngIf="!disableSubmitButton"
    [class.loading]="loadingIndicator"
  >
    {{ "GENERIC.SUBMIT" | translate }}
  </button>
  <a
    class="btn btn-default submit-button"
    (click)="close()"
    *ngIf="closeRouteThis"
    >{{ "GENERIC.CLOSE" | translate }}</a
  >
</form>
