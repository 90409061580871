import { BaseApiService } from "../base/base-api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from "../custom-error-handler.service";
import { RightRequest } from "../../../models/right-requests/right-request.model";
import { RightRequestSerializer } from "../../../models/right-requests/right-request-serializer.class";
import { KeyValuePair } from "../../../models/key-value.interface";
import { Observable, throwError } from "rxjs";
import { map, tap, catchError } from "rxjs/operators";

/**
 * Service to communicate with API Client Users
 */

@Injectable()
export class RightRequestService extends BaseApiService<RightRequest> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      null,
      "rightrequests",
      new RightRequestSerializer(),
      new CustomErrorHandlerService()
    );
  }

  // EXTEND/OVERRIDE THE SERVICE HERE IF NECESSARY

  getSummary(type: string, regulationId: number): Observable<KeyValuePair[]> {
    let url = `${this.url}/rightrequests/summary/${type}`;
    if (regulationId && regulationId !== 0) {
      url += `/${regulationId}`;
    }
    return this.httpClient.get<KeyValuePair[]>(url);
  }

  createRightsRequestIncognito(
    rightrequest,
    tenant_id,
    geo_type_header?
  ): Observable<RightRequest> {
    // Todo make this an additional field in the form table
    let headers = {};
    if (rightrequest.geo_override) {
      headers = {
        geo_override: rightrequest.geo_override,
        geo_type: geo_type_header ? geo_type_header : "country",
      };
    }
    return this.httpClient
      .post<any>(`${this.url}/rightrequests`, rightrequest, {
        headers: {
          ...headers,
          tenant_id,
        },
      })
      .pipe(
        map((data) => data),
        catchError((error) => throwError(error))
      );
  }

  updateRightRequestWithToken(
    body,
    tenant_id,
    requestId,
    nextState,
    token
  ): Observable<any> {
    // Todo make this an additional field in the form table
    let encodedToken = encodeURIComponent(token);
    return this.httpClient
      .get<any>(
        `${this.url}/rightrequests/withtoken` +
          "?request_id=" +
          requestId +
          "&tenant=" +
          tenant_id +
          "&token=" +
          encodedToken +
          "&next_state=" +
          nextState +
          "&data=" +
          this.toURI(JSON.stringify(body)),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .pipe(
        map((data) => data),
        catchError((error) => throwError(error))
      );
  }

  toURI(body) {
    return encodeURIComponent(JSON.stringify(body));
  }

  endCurrentStep(id: string, nextState: string): Observable<any> {
    let url = `${this.url}/rightrequests?id=${id}&nextState=${nextState}`;
    return this.httpClient.put<any>(url, null);
  }

  getUploadFileUrl(
    id: string,
    stepName: string,
    completionId?: string
  ): string {
    return `${this.url}/rightrequests?id=${id}&nextState=${stepName}${
      completionId ? `&completionRequirementConfigId=${completionId}` : ""
    }`;
  }
}
