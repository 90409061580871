import { ClientSystem } from "./client-system.model";
import { Serializer } from "../resource/serializer.interface";

export class ClientSystemSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): ClientSystem {
    const client = new ClientSystem();
    client.id = json.id;
    client.name = json.name;
    client.type = json.type;
    client.priority = json.priority;

    return client;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(client: ClientSystem): any {
    return {
      id: client.id,
      name: client.name,
      type: client.type,
      priority: client.priority
    };
  }
}
