import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { NotificationService } from "./notification.service";

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(private readonly injector: Injector) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const notificationService: NotificationService = this.injector.get(
      NotificationService
    );
    return next.handle(req).pipe(
      tap((evt) => {
        console.log(evt);
        console.log(req);
        if (req.headers.get("skip")) {
          return next.handle(req);
        }
        if (evt instanceof HttpResponse) {
          if (evt.body && evt.body.success)
            notificationService.success(evt.body.success.message);
        }
      }),
      catchError((err: any) => {
        console.log(err);
        if (err instanceof HttpErrorResponse) {
          try {
            switch (err.status) {
              case 404:
                notificationService.error(
                  "Record Not Found. Please try again or contact support."
                );
                break;
              case 500:
                notificationService.error(err.error.ErrorMessage);
                break;
              case 403:
                notificationService.error(
                  "Your User Level is not Authorized to do this"
                );
                break;
              default:
                console.log(err);
                if (err.error && typeof err.error === "string") {
                  notificationService.error(err.error);
                } else if (
                  err.error &&
                  err.error.message &&
                  typeof err.error.message === "string"
                ) {
                  notificationService.error(err.error.message);
                } else if (
                  err.error &&
                  err.error.errors &&
                  Object.keys(err.error.errors) &&
                  Object.keys(err.error.errors).length
                ) {
                  // specific validation errors
                  const err2 =
                    err.error.errors[Object.keys(err.error.errors)[0]];
                  if (err2 && err2.length > 1) {
                    notificationService.error(err2[0]);
                  } else if (err2) {
                    notificationService.error(err2[0]);
                  }
                } else {
                  notificationService.error(err.statusText);
                }
                break;
            }
          } catch (e) {
            console.log("e", e);
            notificationService.error(
              "Something went wrong while making your request. Please try again."
            );
          }
          //log error
        }
        throw new Error(err);
        //return of(err);
      })
    );
  }
}
