import { Component } from "@angular/core";

@Component({
  selector: "app-analytics",
  templateUrl: "./analytics.component.html",
  styleUrls: []
})
export class AnalyticsComponent{
  constructor() {}
}
