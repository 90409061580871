import { Serializer } from "../resource/serializer.interface";
import { ClientRegulation } from "./client-regulation.model";

export class ClientRegulationSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): ClientRegulation {
    const clientRegulation = new ClientRegulation();
    clientRegulation.id = json.id;
    clientRegulation.name = json.name;
    clientRegulation.longName = json.long_name;
    clientRegulation.priority = json.priority;
    clientRegulation.note = json.note;
    clientRegulation.enabled = json.enabled;
    clientRegulation.createdAt = json.created_at;
    clientRegulation.updatedAt = json.updated_at;

    return clientRegulation;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(clientRegulation: ClientRegulation): any {
    return {
      id: clientRegulation.id,
      name: clientRegulation.name,
      priority: clientRegulation.priority,
      note: clientRegulation.note,
      long_name: clientRegulation.longName,
      enabled: clientRegulation.enabled,
      created_at: clientRegulation.createdAt,
      updated_at: clientRegulation.updatedAt,
      source_duplicate: clientRegulation.sourceDuplicate,
    };
  }
}
