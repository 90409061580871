<div class="container right-request-steps">
  <div
    *ngIf="allRegs && allRegs.length"
    style="display: flex; flex-direction: row"
  >
    <div *ngIf="!editMode" style="padding: 0px" class="column col-3">
      <div class="choice-column">
        <div *ngFor="let reg of allRegs" class="regulation-item">
          <div
            (click)="toggleExpanded(reg.name)"
            style="
              display: flex;
              cursor: pointer;
              flex-direction: row;
              align-items: center;
            "
          >
            <div>{{ reg.name }}</div>
            <div style="flex: 1"></div>
            <div><i class="fas fa-caret-down"></i></div>
          </div>
          <div *ngIf="expanded[reg.name] == true">
            <div
              style="font-weight: 300; font-size: 12px"
              *ngIf="!reg.requestConfigs || reg.requestConfigs.length == 0"
            >
              {{ "RIGHTS_DETAIL_CONFIG.NO_RIGHTS_REGULATION" | translate }}
            </div>
            <div
              class="request-type-item"
              (click)="selectRegulation(reg.id, conf.requestType)"
              *ngFor="let conf of reg.requestConfigs"
              [ngClass]="{
                active:
                  reg.name == this.dataRegulation.name &&
                  conf.requestType == this.requestType
              }"
            >
              {{ conf.requestType }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="editMode" id="palette" style="width: 300px">
      <section>
        <div
          class="plugin-op"
          *ngFor="let item of stepItems"
          [ngFlowchartStep]="{
            template: item.step.template,
            type: item.type,
            data: item.data
          }"
        >
          <span style="font-weight: bold">{{ item.name }}</span>
          <div class="desc">{{ getDescription(item.type) | translate }}</div>
        </div>
      </section>
      <!-- <button (click)="showFlowData()">Show Output</button>-->
    </div>
    <div style="flex: 1; position: relative">
      <div
        id="canvas"
        ngFlowchartCanvas
        [ngFlowchartOptions]="options"
        [ngFlowchartCallbacks]="callbacks"
      ></div>
      <div style="position: absolute; top: 50px; z-index: 10; right: 30px">
        <button
          class="btn btn-primary"
          style="margin-right: 10px"
          (click)="zoom(true)"
        >
          + Zoom In
        </button>
        <button class="btn btn-primary" (click)="zoom(false)">
          - Zoom Out
        </button>
      </div>
      <div
        *ngIf="!editMode"
        style="position: absolute; top: 50px; z-index: 10; left: 20px"
      >
        <button class="btn btn-primary" (click)="toggleMode()">
          <i class="fas fa-edit"></i> Edit
        </button>
      </div>
      <div style="position: absolute; top: 100px; z-index: 10; left: 20px">
        <div
          style="
            margin-bottom: 5px;
            background: #90ee90;
            padding: 6px;
            border-radius: 4px;
            box-shadow: 1px 1px 12px 2px rgba(0, 0, 0, 0.1);
          "
        >
          {{ "RIGHTS_DETAIL_CONFIG.REQUIRED" | translate }}
        </div>
        <div
          style="
            background: #ffffbf;
            padding: 6px;
            border-radius: 4px;
            box-shadow: 1px 1px 12px 2px rgba(0, 0, 0, 0.1);
          "
        >
          {{ "RIGHTS_DETAIL_CONFIG.OPTIONAL" | translate }}
        </div>
      </div>
      <div
        *ngIf="editMode"
        style="position: absolute; top: 50px; z-index: 10; left: 20px"
      >
        <button
          class="btn btn-primary"
          style="margin-right: 10px"
          (click)="saveFlow()"
        >
          <i class="fas fa-check"></i> Save
        </button>
        <button class="btn btn-primary" (click)="toggleMode()">
          <i class="fas fa-times"></i> Cancel
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Step config modal -->
<app-modal
  #modalStepEdit
  (closer)="closeStepEditModal()"
  class="step-edit-modal"
  title="GENERIC.VIEW_EDIT_CONFIGURATION"
  style="min-height: 580px"
>
  <form
    style="
      min-width: 585px;
      max-width: 585px;
      height: 100%;
      display: flex;
      flex-direction: column;
    "
    *ngIf="stepInEdit"
    (ngSubmit)="saveAndCloseStepEditModal()"
    #f="ngForm"
  >
    <div class="form-group">
      <div>
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'GENERIC.NAME' | translate }}"
            name="name"
            [(ngModel)]="stepInEdit.name"
            required
          />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'RIGHTS_DETAIL_CONFIG.SHORT_CODE' | translate }}"
            name="identifier"
            [(ngModel)]="stepInEdit.identifier"
            required
          />
        </mat-form-field>
      </div>
      <div style="margin-bottom: 20px">
        <label
          style="margin-right: 15px; font-weight: bold"
          id="example-radio-group-label"
          >{{ "RIGHTS_DETAIL_CONFIG.OPTIONAL" | translate }}</label
        >
        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="example-radio-group"
          name="optional"
          ngDefaultControl
          fxLayout="column"
          required
          [(ngModel)]="stepInEdit.optional"
        >
          <mat-radio-button [value]="true" style="margin-right: 10px"
            >True</mat-radio-button
          >
          <mat-radio-button [value]="false">False</mat-radio-button>
        </mat-radio-group>
      </div>
      <div *ngIf="stepInEdit.type === 'automatic' && stepInEdit.config">
        <mat-form-field>
          <mat-select
            matInput
            name="action"
            placeholder="{{ 'RIGHTS_DETAIL_CONFIG.ACTION' | translate }}"
            [(ngModel)]="stepInEdit.config.action"
            required
          >
            <mat-option value="email">Send Email</mat-option>
            <mat-option value="CheckUserExistsIn4Comply"
              >CheckUserExistsIn4Comply</mat-option
            >
          </mat-select>
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field
          *ngIf="
            !stepInEdit.config.action || stepInEdit.config.action === 'email'
          "
        >
          <mat-select
            matInput
            name="type"
            placeholder="{{
              'BASIC_ENTITIES_SINGULAR.EMAIL_TEMPLATE' | translate
            }}"
            [(ngModel)]="stepInEdit.config.email_template_id"
            required
          >
            <mat-option *ngFor="let et of emailTemplates" value="{{ et.id }}">{{
              et.type
            }}</mat-option>
          </mat-select>
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
        <div
          *ngIf="
            (!stepInEdit.config.action ||
              stepInEdit.config.action === 'email') &&
            stepInEdit.config.email_template_id
          "
        >
          <h4>Email Template Preview</h4>
          <h5>
            Subject:
            {{ getEmailTemplate(stepInEdit.config.email_template_id).subject }}
          </h5>
          <div
            class="inner-html-wrapper"
            style="max-width: 600px; position: relative"
          >
            <div
              style="max-width: 600px; position: relative"
              [innerHTML]="
                getEmailTemplate(stepInEdit.config.email_template_id).template
                  | safeHtml
              "
            ></div>
          </div>
        </div>
      </div>
      <div *ngIf="stepInEdit.type === 'hitl'">
        <mat-form-field>
          <mat-select
            matInput
            name="type"
            placeholder="{{
              'BASIC_ENTITIES_SINGULAR.HITL_TEMPLATE' | translate
            }}"
            [(ngModel)]="stepInEdit.config.hitl_template_id"
            required
          >
            <mat-option *ngFor="let et of hitlTemplates" value="{{ et.id }}">{{
              et.name
            }}</mat-option>
          </mat-select>
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
        <div *ngIf="stepInEdit.config.hitl_template_id">
          <h4>Email Template Preview</h4>
          <h5>
            HITL Template Description:
            {{
              getHitlTemplate(stepInEdit.config.hitl_template_id).description
            }}
          </h5>
          <h5>
            Email Subject:
            {{
              getEmailTemplate(
                getHitlTemplate(stepInEdit.config.hitl_template_id)
                  .emailTemplateId
              ).subject
            }}
          </h5>
          <div
            class="inner-html-wrapper"
            style="max-width: 600px; position: relative"
          >
            <div
              style="max-width: 600px; position: relative"
              [innerHTML]="
                getEmailTemplate(
                  getHitlTemplate(stepInEdit.config.hitl_template_id)
                    .emailTemplateId
                ).template | safeHtml
              "
            ></div>
          </div>
        </div>
      </div>
      <div
        style="font-weight: bold; font-style: italic"
        *ngIf="stepInEdit.type === 'hitl' && stepInEdit.config"
      >
        <mat-form-field>
          <mat-select
            matInput
            name="blocking"
            placeholder="{{ 'RIGHTS_DETAIL_CONFIG.BLOCKING' | translate }}"
            [(ngModel)]="stepInEdit.config.blocking"
            required
          >
            <mat-option [value]="true">{{
              "RIGHTS_DETAIL_CONFIG.BLOCKING" | translate
            }}</mat-option>
            <mat-option [value]="false">{{
              "RIGHTS_DETAIL_CONFIG.NONBLOCKING" | translate
            }}</mat-option>
          </mat-select>
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="stepInEdit.type === 'indeterminate'">
        <div>
          {{ "RIGHTS_DETAIL_CONFIG.COMPLETION_ENTRIES" | translate }}
        </div>
        <mat-form-field
          *ngFor="let c of stepInEdit.config.completion_requirements"
        >
          <mat-select
            matInput
            name="completion"
            placeholder="{{
              'RIGHTS_DETAIL_CONFIG.COMPLETION_ENTRIES' | translate
            }}"
            [(ngModel)]="c.id"
            required
          >
            <mat-option
              *ngFor="let et of completionConfigs"
              value="{{ et.id }}"
              >{{ et.name }}</mat-option
            >
          </mat-select>
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
        <br />
        <!--<div>
          <button
            class="btn btn-primary"
            (click)="(stepInEdit.config.completion_requirements || []).push({})"
          >
            {{ "GENERIC.ADD" | translate }}
          </button>
          <button
            class="btn btn-primary"
            style="margin-left:15px;"
            (click)="(stepInEdit.config.completion_requirements || []).pop()"
          >
            {{ "GENERIC.DELETE" | translate }}
          </button>
        </div>-->
      </div>
    </div>
    <div style="flex: 1; margin-top: 30px"></div>
    <div class="main-submit">
      <button type="submit" class="btn btn-primary">
        {{ "RIGHTS_DETAIL_CONFIG.UPDATE_LOCAL_VERSION" | translate }}
      </button>
    </div>
  </form>
</app-modal>
