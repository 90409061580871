<div class="container">
  <div class="column col-12">
    <app-generic-card>
      <app-actionbar
        title="{{ 'BASIC_ENTITIES_PLURAL.HITL_TEMPLATES' | translate }}"
        [actionBarItems]="actionBarItemsHitl"
      ></app-actionbar>
      <app-datatable
        #dtHitlTemplates
        [actions]="actionsHitl"
        [data]="dataHitlTemplates"
        tableIdentifier="hitlTemplates"
        [columns]="columnsHitlTemplates"
      ></app-datatable>
    </app-generic-card>
  </div>
</div>
<app-modal
  #modalHitlConfig
  title="{{ 'MODAL_GENERIC.CONFIRM_DELETE' | translate }}"
  bodyContent="{{ 'MODAL_GENERIC.CONFIRM_DELETE_QUESTION' | translate }}"
  [confirmCallback]="deleteHitlConfigConfirm"
></app-modal>
<app-modal
  (closer)="closeModal()"
  #modalCreateTask
  title="HITL_TEMPLATE.CREATE_TASK_FROM_TEMPLATE"
>
  <form #g="ngForm" (ngSubmit)="submitCreateTask()">
    <div class="create-task">
      <div class="form-group">
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'GENERIC.EMAIL_ADDRESS' | translate }}"
            name="citizenEmail"
            [(ngModel)]="modelCreateTask.citizenEmail"
            required
          />
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <input
            matInput
            name="notes"
            placeholder="{{ 'GENERIC.NOTES' | translate }}"
            [(ngModel)]="modelCreateTask.notes"
          />
        </mat-form-field>
      </div>
      <div class="form-group main-submit">
        <button type="submit" class="btn btn-primary">
          {{ "GENERIC.SAVE" | translate }}
        </button>
      </div>
    </div>
  </form>
</app-modal>
