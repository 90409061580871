import { BaseApiService } from "../base/base-api.service";
import { Client } from "../../../models/clients/client.model";
import { ClientSerializer } from "../../../models/clients/client-serializer.class";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from "../custom-error-handler.service";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { KeyValuePair } from "../../../models/key-value.interface";

/**
 * Service to communicate with API Clients/Tenants
 */

@Injectable()
export class ClientsService extends BaseApiService<Client> {
  crntSerializer = new ClientSerializer();
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      null,
      "client",
      new ClientSerializer(),
      new CustomErrorHandlerService()
    );
  }

  createClient(name, tenant_suffix, current_user): Observable<any> {
    return this.httpClient.post(`${this.url}/client`, {
      name,
      tenant_suffix,
      external_id: current_user.sub.replace("auth0|", ""),
      existing_user: true,
    });
  }

  resetClient(id): Observable<any> {
    return this.httpClient.post(
      `${this.url}/client/${id}/reset-workflow-hitl-email`,
      {}
    );
  }

  resetClientSpecific(id, config): Observable<any> {
    return this.httpClient.post(
      `${this.url}/client/${id}/reset-specific`,
      config
    );
  }

  updateCustomFields(clientId, newCustomFields): Observable<any> {
    return this.httpClient.put(
      `${this.url}/client/${clientId}/customfieldnames`,
      {
        custom_field_1_name: newCustomFields.customFieldName1,
        custom_field_2_name: newCustomFields.customFieldName2,
        custom_field_3_name: newCustomFields.customFieldName3,
        custom_field_4_name: newCustomFields.customFieldName4,
        custom_field_5_name: newCustomFields.customFieldName5,
        custom_field_6_name: newCustomFields.customFieldName6,
        custom_field_7_name: newCustomFields.customFieldName7,
      }
    );
  }

  getDomainCNameInfo(id: number, domain: string): Observable<any> {
    return this.httpClient.get(`${this.url}/client/${id}/domain/${domain}`);
  }

  getDefaultEmail(id: string): Observable<any> {
    return this.httpClient.get(`${this.url}/client/${id}/defaultEmail`);
  }

  updateDefaultEmail(id: string, email: string): Observable<any> {
    return this.httpClient.put(`${this.url}/client/${id}/defaultEmail`, {
      default_email_address: email,
    });
  }

  getSecondaryEmails(id: string): Observable<any> {
    return this.httpClient.get(`${this.url}/client/${id}/secondaryEmails`);
  }

  updateSecondaryEmails(id: string, emails: string): Observable<any> {
    return this.httpClient.put(`${this.url}/client/${id}/secondaryEmails`, {
      secondary_email_addresses: emails,
    });
  }

  updateAlias(clientId: string, alias: string) {
    return this.httpClient.put(`${this.url}/client/${clientId}/alias`, {
      alias: alias,
    });
  }

  updatePermissionUpdateLogic(clientId: string, logic: string) {
    return this.httpClient.put(
      `${this.url}/client/${clientId}/permissionUpdateLogic`,
      {
        permission_update_logic: logic,
      }
    );
  }

  uploadLogo(clientId, file): Observable<any> {
    const form: any = {};
    form.files = [file];
    return this.httpClient.post(
      `${this.url}/client/upload-logo/${clientId}`,
      form
    );
  }

  getUploadLogoUrl(clientId, contentType, extension): Observable<any> {
    return this.httpClient.post(
      `${this.url}/client/${clientId}/logo/uploadurl`,
      {
        content_type: contentType,
        extension,
      }
    );
  }

  uploadFavicon(clientId, file): Observable<any> {
    const form: any = {};
    form.files = [file];
    return this.httpClient.post(
      `${this.url}/client/upload-favicon/${clientId}`,
      form
    );
  }

  getUploadFaviconUrl(clientId, contentType, extension): Observable<any> {
    return this.httpClient.post(
      `${this.url}/client/${clientId}/favicon/uploadurl`,
      {
        content_type: contentType,
        extension,
      }
    );
  }

  getLandingPages(clientId): Observable<any> {
    return this.httpClient.get(`${this.url}/landingpages`);
  }

  createLandingPage(landingPage): Observable<any> {
    return this.httpClient.post(`${this.url}/landingpages`, landingPage);
  }

  updateLandingPage(landingPage): Observable<any> {
    return this.httpClient.put(
      `${this.url}/landingpages/${landingPage.id}`,
      landingPage
    );
  }

  getPermissionTypes(clientId): Observable<any> {
    return this.httpClient.get(
      `${this.url}/client/${clientId}/permissiontypes`
    );
  }

  updatePermissionTypes(clientId, newPermissionTypes): Observable<any> {
    return this.httpClient.put(
      `${this.url}/client/${clientId}/permissiontypes`,
      {
        explicit_permission_types: newPermissionTypes,
      }
    );
  }

  getPermissionCategories(clientId): Observable<any> {
    return this.httpClient.get(
      `${this.url}/client/${clientId}/permissioncategories`
    );
  }
  getComplianceInputTypes(clientId): Observable<any> {
    return this.httpClient.get(
      `${this.url}/client/${clientId}/complianceinputtypes`
    );
  }

  updatePermissionCategories(
    clientId,
    newPermissionCategories
  ): Observable<any> {
    return this.httpClient.put(
      `${this.url}/client/${clientId}/permissioncategories`,
      {
        permission_categories: newPermissionCategories,
      }
    );
  }

  createClientRegulations(clientId, newClientRegulations): Observable<any> {
    return this.httpClient.post(
      `${this.url}/client/${clientId}/clientregulations`,
      {
        client_regulations: newClientRegulations,
      }
    );
  }

  getLogo(clientId) {
    return this.httpClient.get(`${this.url}/client/${clientId}/logo`);
  }

  getFavicon(clientId) {
    return this.httpClient.get(`${this.url}/client/${clientId}/favicon`);
  }

  getToken(): Observable<KeyValuePair> {
    return this.httpClient.get<KeyValuePair>(`${this.url}/client/gettoken`);
  }

  updateToken() {
    return this.httpClient.post<KeyValuePair>(
      `${this.url}/client/updatetoken`,
      ""
    );
  }
}
