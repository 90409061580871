import { Serializer } from "../resource/serializer.interface";
import { ConsentSummaryByCountry } from "./consent-summary-by-country.model";

export class ConsentSummaryByCountrySerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): ConsentSummaryByCountry {
    const consentCountryBySummary = new ConsentSummaryByCountry();
    consentCountryBySummary.total_number = json.total_number;
    consentCountryBySummary.data = json.data;

    return consentCountryBySummary;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(consentCountryBySummary: ConsentSummaryByCountry): any {
    return {
      total_number: consentCountryBySummary.total_number,
      data: consentCountryBySummary.data,
    };
  }
}
