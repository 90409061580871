<div class="container">
  <div class="column col-7">
    <app-generic-card>
      <h3 class="section-title">
        {{ (isEdit ? "WEBHOOK.EDIT" : "WEBHOOK.ADD_NEW") | translate }}
      </h3>
      <div class="columns">
        <div class="column col-6">
          <app-form
            #formWebhook
            [fields]="fields"
            [model]="model"
            [callback]="submitWebhooksForm"
            closeRoute="webhooks"
          ></app-form>
        </div>
        <div class="column col-6">
          <h6>{{ "WEBHOOK.ADD_TRIGGER_ACTIONS" | translate }}</h6>
          <div class="form-group">
            <mat-form-field
              class="example-full-width"
              *ngFor="
                let ta of triggerActions;
                let i = index;
                trackBy: trackByFn
              "
            >
              <mat-label>Action {{ i + 1 }}</mat-label>
              <!-- <input matInput placeholder="Action {{i + 1}}" [(ngModel)]="triggerActions[i]"> -->
              <mat-select
                [(ngModel)]="triggerActions[i]"
                (selectionChange)="onSelectionChanged()"
              >
                <mat-option *ngFor="let trigger of triggers" [value]="trigger">
                  {{ trigger }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <label class="error-text" *ngIf="isDuplicateTriggerSelected">{{
              "WEBHOOK.SAME_ACTION_ERROR" | translate
            }}</label>
          </div>
          <button
            type="submit"
            class="btn btn-primary"
            (click)="triggerActions.push('')"
          >
            {{ "WEBHOOK.ADD_ACTION" | translate }}
          </button>
          <button
            style="margin-left: 10px;"
            type="submit"
            class="btn btn-default"
            (click)="
              triggerActions.splice(triggerActions.length - 1, 1);
              onSelectionChanged()
            "
            *ngIf="triggerActions.length > 0"
          >
            {{ "WEBHOOK.REMOVE_LAST" | translate }}
          </button>
        </div>
      </div>
    </app-generic-card>
  </div>
</div>
