<div class="container">
  <div class="column col-7">
    <app-generic-card>
        <h3 class="section-title">{{'GENERIC.EDIT' | translate}} {{model.type}}</h3>

        <div class="form-group">
          <label>{{'GENERIC.TEMPLATE' | translate}} *</label>
          <ckeditor [(ngModel)]="model.template"></ckeditor>
        </div>
        
        <button type="submit" class="btn btn-primary submit-button" [class.loading]="formSubmitted" (click)="submitClientLandingPageTemplate()">Submit</button>
        <a class="btn btn-default submit-button" (click)="close()">{{'GENERIC.CLOSE' | translate}}</a>
    </app-generic-card>
  </div>
</div>