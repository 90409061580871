import {
  Component,
  EventEmitter,
  OnInit,
  Input,
  Output,
  OnChanges,
} from "@angular/core";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit, OnChanges {
  isActiveThis: boolean = false;
  titleThis: string;
  bodyThis: string;

  @Input() title: string;
  @Input() bodyContent: string;
  @Input() confirmCallback: Function;
  @Output() closer: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    this.titleThis = this.title;
    this.bodyThis = this.bodyContent;
  }

  ngOnChanges(newProps) {
    if (newProps && newProps.bodyContent) {
      this.bodyThis = newProps.bodyContent.currentValue;
    }
  }

  openModal() {
    this.isActiveThis = true;
  }

  closeModal() {
    this.isActiveThis = false;
    this.closer.emit(null);
  }

  confirmClick() {
    this.confirmCallback();
  }
}
