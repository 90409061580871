import { Resource } from "../resource/resource.model";

/**
 * Users WITHIN a tenant in 4Comply.  This will be used in both applications
 */

export class BusinessUnit extends Resource {
    name: string;
    createdAt?: Date;
    updatedAt?: Date;
    tenantId: string;
}


