import { Injectable } from '@angular/core';  
import { environment } from "../../../../environments/environment";
import { HttpClient, HttpResponse } from "@angular/common/http";  
import { Observable } from 'rxjs';  
@Injectable() 

export class CitizenHistorySearchService {  
    endPoint: string;
    constructor(private http: HttpClient) {  
        this.endPoint = environment.api.API_ENDPOINT + '/citizenhistory/emails';  
    } 

    search(term: string): Observable<string[]> { 
        return this.http.get<string[]>(this.endPoint + '?searchTerm=' + term);
    } 
}  