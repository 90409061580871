import { BaseApiService } from "../base/base-api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from "../custom-error-handler.service";
import { HitlTemplate } from "../../../models/hitl-templates/hitl-template.model";
import { HitlTemplateSerializer } from "../../../models/hitl-templates/hitl-template-serializer.class";
import { Observable } from "rxjs";

/**
 * Service to communicate with API Client Users
 */

@Injectable()
export class HitlTemplatesService extends BaseApiService<HitlTemplate> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      null,
      "hitltemplate",
      new HitlTemplateSerializer(),
      new CustomErrorHandlerService()
    );
  }

  getTriggers(): Observable<any> {
    return this.httpClient.get(`${this.url}/hitltemplate/triggers`);
  }
}
