import { Resource } from "../resource/resource.model";

/**
 * A Tenant in the 4Comply SaaS System
 * This will really only be used for the 4comply dashboard
 */

export class ClientSystem extends Resource {
  name: string;
  type: string;
  priority: number;
}
