import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormlyFormOptions, FormlyFieldConfig } from "@ngx-formly/core";
import { Router } from "@angular/router";
import {
  LangChangeEvent,
  TranslateService,
  TranslationChangeEvent,
} from "@ngx-translate/core";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormComponent implements OnInit, OnChanges {
  form = new FormGroup({});
  modelSelf: any = {};
  options: FormlyFormOptions = {};
  fieldsSelf: FormlyFieldConfig[] = [];
  loadingIndicator = false;
  closeRouteThis;
  translate: TranslateService;
  translations: any = {};
  translated = false;
  disableSubmitButtonThis = false;

  @Input() additionalButtons: [];
  @Input() fields: [];
  @Input() model: {};
  @Input() closeRoute: string;
  @Input() callback: Function;
  @Input() loadingDone: boolean;
  @Input() disableSubmitButton: boolean;

  constructor(
    private router: Router,
    private translateService: TranslateService
  ) {
    this.translate = this.translateService;
  }
  ngOnInit() {
    this.modelSelf = this.model;
    this.fieldsSelf = this.fields;
    this.closeRouteThis = this.closeRoute;
    this.disableSubmitButtonThis = this.disableSubmitButton;
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.fieldsSelf = this.translateBulk(this.fields);
    });
    this.translateService.onTranslationChange.subscribe((event: any) => {
      this.fieldsSelf = this.translateBulk(this.fields);
    });
  }

  translateBulk(fields: FormlyFieldConfig[]) {
    let f = [];
    fields.map((field: FormlyFieldConfig) => {
      let updated = {
        ...field,
        templateOptions: {
          ...field.templateOptions,
          placeholder:
            field.templateOptions.placeholder &&
            field.templateOptions.placeholder !== ""
              ? this.translate.instant(field.templateOptions.placeholder)
              : "",
          label: field.templateOptions.label
            ? this.translate.instant(field.templateOptions.label)
            : "",
          options: field.templateOptions.options
            ? (field.templateOptions.options as any).map((o) => {
                if (!o.name && !o.value) return;
                return {
                  ...o,
                  name: o.name ? this.translate.instant(o.name) : null,
                  value: o.value ? this.translate.instant(o.value) : null,
                };
              })
            : null,
        },
      };
      f.push(updated);
    });
    return f;
  }

  ngOnChanges(newProps) {
    if (
      newProps &&
      newProps.loadingDone &&
      newProps.loadingDone.currentValue === true
    ) {
      this.loadingIndicator = false;
    }
    if (
      newProps.fields &&
      newProps.fields.currentValue &&
      newProps.fields.currentValue != this.fieldsSelf
    ) {
      setTimeout(() => {
        this.fieldsSelf = this.translateBulk(this.fields);
      }, 0);
    }
  }

  loaderVisibility(value: boolean) {
    this.loadingIndicator = value;
  }

  resetForm() {
    this.form.reset();
  }

  updateModel(data) {
    this.modelSelf = data;
  }

  updateFields(data) {
    this.fieldsSelf = data;
    setTimeout(() => {
      this.fieldsSelf = this.translateBulk(this.fields);
    }, 0);
  }

  close() {
    this.router.navigate([this.closeRouteThis]);
  }

  submit() {
    if (this.form.valid) {
      this.loadingIndicator = true;
      this.callback();
    }
  }
}
