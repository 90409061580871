<div class="container right-request-steps">
  <div class="refresher" *ngIf="refresher">
    <i class="fa fa-sync"></i> Auto Refreshing
  </div>
  <div style="display: flex; flex-direction:row; min-height:900px;">
    <div style="min-width: 440px;padding:25px;margin-top:7px;">
      <app-generic-card>
        <h3 style="margin-bottom: 0px;">
          <app-back-btn></app-back-btn>
          {{ "RIGHTS_REQUESTS.DETAIL" | translate }}
        </h3>
      </app-generic-card>
      <app-generic-card *ngIf="isIndeterminateStep">
        <button
          type="button"
          class="btn btn-primary"
          (click)="openUploadModal()"
        >
          {{ "GENERIC.UPLOAD" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary ml-15"
          (click)="openEndStepModal()"
        >
          {{ "RIGHTS_REQUESTS.END_INDETERMINATE" | translate }}
        </button>
      </app-generic-card>
      <app-generic-card *ngIf="uploadedData && uploadedData.length">
        <label>Uploaded Data</label>
        <ul class="file-list">
          <li *ngFor="let data of uploadedData; index as index">
            <div>
              <b>{{ data.step }}</b>
            </div>
            <div *ngFor="let key of data.keys">
              {{ key }} : {{ data.parsedData[key] }}
            </div>
          </li>
        </ul>
      </app-generic-card>
      <app-generic-card *ngIf="isIndeterminateStep && currentStepData.config">
        <label>{{
          "RIGHTS_DETAIL_CONFIG.REQUIRED_COMPLETION_ENTRIES" | translate
        }}</label>
        <ul class="file-list">
          <li
            *ngFor="
              let completion of currentStepData.config.completion_requirements
            "
          >
            <div>
              <b>{{ completion.name }}</b>
            </div>
          </li>
        </ul>
      </app-generic-card>
      <app-generic-card
        *ngIf="
          requestData &&
          requestData.completionEntries &&
          requestData.completionEntries.length
        "
      >
        <label>{{
          "RIGHTS_DETAIL_CONFIG.COMPLETION_ENTRIES" | translate
        }}</label>
        <ul class="file-list">
          <li *ngFor="let completion of formatCompletionEntries()">
            <div *ngIf="completion && completion.config">
              <b>{{ completion.config.name }} at {{ completion.updated_at }}</b>
            </div>
          </li>
        </ul>
      </app-generic-card>
      <app-generic-card *ngIf="presignedUrls && presignedUrls.length > 0">
        <label *ngIf="presignedUrls.length == 1">{{
          "GENERIC.UPLOADED_FILE" | translate
        }}</label>
        <label *ngIf="presignedUrls.length > 1">{{
          "GENERIC.UPLOADED_FILES" | translate
        }}</label>
        <ul class="file-list">
          <li *ngFor="let url of presignedUrls; index as index">
            <i class="fas fa-external-link-square-alt"></i>
            <a [href]="url.presignedUrl" target="_blank"
              >{{ "GENERIC.UPLOADED_FILE" | translate }} #{{ index + 1 }} -
              {{ url && url.fileName }} - {{ url && url.stepName }}</a
            >
          </li>
        </ul>
      </app-generic-card>
    </div>
    <div
      style="flex-direction:column;flex: 1; display: flex; position: relative;"
    >
      <div
        id="canvas"
        ngFlowchartCanvas
        [ngFlowchartOptions]="flowchartOptions"
        [ngFlowchartCallbacks]="callbacks"
      ></div>
      <div style="position: absolute; top: 50px; z-index: 10;left: 20px;">
        <div
          class="legend current"
          style="margin-bottom:5px;padding:6px;border-radius:4px;box-shadow:1px 1px 12px 2px rgba(0,0,0,.1)"
        >
          {{ "RIGHTS_DETAIL_CONFIG.CURRENT" | translate }}
        </div>
        <div
          class="legend completed"
          style="margin-bottom: 5px;padding:6px;border-radius:4px;box-shadow:1px 1px 12px 2px rgba(0,0,0,.1)"
        >
        {{ "RIGHTS_DETAIL_CONFIG.COMPLETED" | translate }}
        </div>
        <div
          class="legend pending"
          style="margin-bottom: 5px;padding:6px;border-radius:4px;box-shadow:1px 1px 12px 2px rgba(0,0,0,.1)"
        >
        {{ "RIGHTS_DETAIL_CONFIG.PENDING" | translate }}
        </div>
        <div
          class="legend failed"
          style="margin-bottom: 5px;padding:6px;border-radius:4px;box-shadow:1px 1px 12px 2px rgba(0,0,0,.1)"
        >
        {{ "RIGHTS_DETAIL_CONFIG.FAILED" | translate }}
        </div>
      </div>
      <div style="position: absolute; bottom: 40px; z-index: 10;left: 20px;">
        <button
          class="btn btn-primary"
          style="margin-right:10px"
          (click)="zoom(true)"
        >
          + Zoom In
        </button>
        <button class="btn btn-primary" (click)="zoom(false)">
          - Zoom Out
        </button>
      </div>
    </div>
  </div>
</div>

<app-modal
  #modalUpload
  title="{{
    requestData &&
    (requestData.requestType === 'RTA' || requestData.requestType === 'RTU')
      ? 'GENERIC.UPLOAD_JSON_FILE'
      : 'GENERIC.UPLOAD_ANY_FILE_TYPE'
  }}"
>
  <div class="upload-file-section">
    <div
      #fakeUpload
      class="drop-container"
      ngFileDrop
      [options]="options"
      (uploadOutput)="onUploadOutput($event)"
      [uploadInput]="uploadInput"
      [ngClass]="{ 'is-drop-over': uploadFileStatus === 'dragOver' }"
    >
      <h1 *ngIf="uploadFileStatus === ''">
        {{ "GENERIC.DRAG_AND_DROP" | translate }}
      </h1>
      <h1 *ngIf="uploadFileStatus === 'dragOver'">
        {{ "GENERIC.DROP_NOW" | translate }}
      </h1>
      <h1 *ngIf="uploadFileStatus === 'uploading'">
        {{ "GENERIC.UPLOADING" | translate }}
      </h1>
    </div>

    <label
      class="upload-button"
      [ngClass]="{ 'uploading-now': uploadFileStatus === 'uploading' }"
    >
      <input
        type="file"
        #fakeUpload2
        ngFileSelect
        [options]="options"
        (uploadOutput)="onUploadOutput($event)"
        [uploadInput]="uploadInput"
      />
      {{ "GENERIC.OR_CHOOSE_FILE" | translate }}
    </label>
    <div style="margin-top: 20px; text-align:center;">
      <div>
        {{ "RIGHTS_DETAIL_CONFIG.SELECT_WORKFLOW_COMPLETION" | translate }}
      </div>
      <div
        style="margin-top:5px;"
        *ngIf="currentStepData && currentStepData.config"
      >
        <select
          name="selectedCompletion"
          [(ngModel)]="selectionCompletionRequirement"
          required
        >
          <option
            value="{{ requirement.workflow_step_completion_config_id }}"
            *ngFor="
              let requirement of currentStepData.config.completion_requirements
            "
          >
            {{ requirement.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</app-modal>

<app-modal
  #modalEndStep
  title="RIGHTS_DETAIL_CONFIG.CONFIRM_END_STEP"
  bodyContent="{{ 'RIGHTS_DETAIL_CONFIG.ARE_YOU_SURE_STEP' | translate }}"
  [confirmCallback]="confirmEndStep"
></app-modal>
