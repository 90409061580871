import { BaseApiService } from "../base/base-api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from "../custom-error-handler.service";
import { GeoRegulationMap } from "../../../models/geo-regulation-map/geo-regulation-map.model";
import { GeoRegulationMapSerializer } from "../../../models/geo-regulation-map/geo-regulation-map-serializer.class";

/**
 * Service to communicate with API Client Users
 */

@Injectable()
export class GeoRegulationMapsService extends BaseApiService<GeoRegulationMap> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      null,
      "geo/regulationmaps",
      new GeoRegulationMapSerializer(),
      new CustomErrorHandlerService()
    );
  }

  // EXTEND/OVERRIDE THE SERVICE HERE IF NECESSARY
}
