import { BaseApiService } from "../base/base-api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from "../custom-error-handler.service";
import { LegalActivityError } from '../../../models/legal-activity-errors/legal-activity-error.model';
import { LegalActivityErrorSerializer } from '../../../models/legal-activity-errors/legal-activity-error-serializer.class';



@Injectable()
export class LegalActivityErrorsService extends BaseApiService<LegalActivityError> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      null,
      "legalactivityerrors",
      new LegalActivityErrorSerializer(),
      new CustomErrorHandlerService()
    );
  }

  // EXTEND/OVERRIDE THE SERVICE HERE IF NECESSARY
}