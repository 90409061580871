import { Resource } from "../resource/resource.model";
import { ClientConfig } from "../client-config/client-config.model";
import { ClientMetering } from "../client-metering/client-metering.model";

/**
 * A Tenant in the 4Comply SaaS System
 * This will really only be used for the 4comply dashboard
 */

export class Client extends Resource {
  name: string;
  configId: ClientConfig;
  metering: ClientMetering;
  tenantSuffix: string;
  createdAt: Date;
}
