import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';  
import { Observable, Subject, EMPTY, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { CitizenHistorySearchService } from "@fourcomply-dashboard/shared";
import { NgModel } from '@angular/forms';

@Component({  
  selector: 'app-citizen-history-search',  
  templateUrl: './citizen-history-search.component.html',  
})  
export class CitizenHistorySearchComponent implements OnInit {  
  public clients: Observable<string[]>;  
  private searchTerms = new Subject<string>();  
  public searchText = '';  
  @Input() flag: boolean = true;
  @Output() selectedEmail = new EventEmitter<string>();

  constructor(private citizenHistorySearchService: CitizenHistorySearchService) {}  
  
  ngOnInit(): void {  
    this.clients = this.searchTerms.pipe(  
        debounceTime(300),        // wait for 300ms pause in events  
        distinctUntilChanged(),     // ignore if next search term is same as previous
        switchMap(term => term   // switch to new observable each time   
            ? this.citizenHistorySearchService.search(term) // return the http search observable    
            : of<string[]>([])), // or the observable of empty heroes if no search term
            catchError(error => {  
            console.log(error);  
            return of<string[]>([]);  
            })  
        );
    }

    // Push a search term into the observable stream.  
    searchClient(term: string): void {  
        this.flag = true;  
        this.selectedEmail.emit(term); 
        this.searchTerms.next(term);  
    }  

    onselectClient(term) {     
    if (term.length > 0) {  
      this.searchText = term;
      this.selectedEmail.emit(term);       
      this.flag = false;  
    }  
    else {  
      return false;  
    }  
  }  
} 