import { Serializer } from "../resource/serializer.interface";
import { Regulation } from "./regulation.model";

export class RegulationSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): Regulation {
    const regulation = new Regulation();
    regulation.id = json.id;
    regulation.name = json.name;
    regulation.note = json.note;
    regulation.enabled = json.enabled;
    return regulation;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(regulation: Regulation): any {
    return {
      id: regulation.id,
      name: regulation.name,
      note: regulation.note,
      enabled: regulation.enabled
    };
  }
}
