import { Serializer } from "../resource/serializer.interface";
import { ClientEmailTemplate } from './client-email-template.model';

export class ClientEmailTemplateSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): ClientEmailTemplate {
    const clientEmailTemplate = new ClientEmailTemplate();
    clientEmailTemplate.id = json.id;
    clientEmailTemplate.type = json.type;
    clientEmailTemplate.subject = json.subject;
    clientEmailTemplate.template = json.template;
    clientEmailTemplate.hydratedTemplate = json.hydrated_template;
    clientEmailTemplate.footer = json.footer;
    clientEmailTemplate.createdAt = json.created_at;
    clientEmailTemplate.updatedAt = json.updated_at;
  
    return clientEmailTemplate;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(clientEmailTemplate: ClientEmailTemplate): any {
    return {
      id: clientEmailTemplate.id,
      type: clientEmailTemplate.type,
      subject: clientEmailTemplate.subject,
      template: clientEmailTemplate.template,
      footer: clientEmailTemplate.footer,
      created_at: clientEmailTemplate.createdAt,
      updated_at: clientEmailTemplate.updatedAt
    };
  }
}
