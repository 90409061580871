import { BaseApiService } from "../base/base-api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from "../custom-error-handler.service";
import { ClientRegulation } from '../../../models/client-regulations/client-regulation.model';
import { ClientRegulationSerializer } from '../../../models/client-regulations/client-regulation-serializer.class';

/**
 * Service to communicate with API Client Users
 */

@Injectable()
export class ClientRegulationsService extends BaseApiService<ClientRegulation> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      null,
      "regulations",
      new ClientRegulationSerializer(),
      new CustomErrorHandlerService()
    );
  }

  // EXTEND/OVERRIDE THE SERVICE HERE IF NECESSARY
}
