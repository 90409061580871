import { Component, OnInit, ViewChild, OnChanges } from "@angular/core";
import {
  DatatableComponent,
  ClientForm,
  DatalistActionItem,
  APIService,
  ActionBarItem,
  BusinessUnit,
  NotificationService,
} from "@fourcomply-dashboard/shared";
import { Router } from "@angular/router";
import { environment } from "apps/fourcomply-dashboard/src/environments/environment";
import { ClipboardService } from "ngx-clipboard";

@Component({
  selector: "app-business-unit",
  templateUrl: "./business-unit.component.html",
  styleUrls: ["./business-unit.component.scss"],
})
export class BusinessUnitComponent implements OnInit {
  @ViewChild("dtBusinessUnits", { static: true })
  dtBusinessUnits: DatatableComponent;

  dataBusinessUnits: BusinessUnit[];
  columnsBusinessUnits: any[];
  actionsBusinessUnits: DatalistActionItem[];
  actionBarItemsForms: ActionBarItem[];

  constructor(
    private api: APIService,
    private router: Router,
    private notification: NotificationService,
    private _clipboardService: ClipboardService
  ) {}

  ngOnInit() {
    this.routeToEdit = this.routeToEdit.bind(this);
    this.fetchBusinessUnits();
  }

  onDeactivate(event): void {
    this.fetchBusinessUnits();
  }

  fetchBusinessUnits() {
    this.actionBarItemsForms = [
      {
        btnText: "GENERIC.ADD_NEW",
        iconClass: "fas fa-plus",
        route: "business-units/add",
      },
    ];

    this.actionsBusinessUnits = [
      {
        iconClass: "fas fa-pen",
        toolTipText: "GENERIC.EDIT",
        callback: this.routeToEdit,
      },
      {
        iconClass: "fas fa-trash",
        toolTipText: "GENERIC.DELETE",
        callback: (id) => {
          this.api.businessUnits.delete(id).subscribe((a) => {
            this.notification.success(
              "BUSINESS_UNITS.SUCCESSFUL_DELETE_BUSINESS_UNIT"
            );
            this.fetchBusinessUnits();
          });
        },
      },
    ];

    this.columnsBusinessUnits = [
      { name: "GENERIC.NAME", prop: "name" },
      { name: "GENERIC.ACTIONS", prop: "actions" },
    ];

    this.api.businessUnits.list().subscribe((a) => {
      this.dataBusinessUnits = a;
      if (!this.dtBusinessUnits.updateRows) {
        return;
      }
      this.dtBusinessUnits.updateRows(this.dataBusinessUnits);
      this.dtBusinessUnits.loaderVisibility(false);
    });
  }

  updateBusinessUnit(businessUnit: BusinessUnit) {
    this.api.businessUnits.update(businessUnit).subscribe((a) => {
      this.notification.success(
        "BUSINESS_UNITS.SUCCESSFUL_UPDATE_BUSINESS_UNIT"
      );
      this.fetchBusinessUnits();
    });
  }

  routeToEdit(id) {
    this.router.navigate(["/business-units/edit", id]);
  }
}
