import { BaseApiService } from "../base/base-api.service";
import { ClientUser } from "../../../models/client-users/client-user.model";
import { ClientUserSerializer } from "../../../models/client-users/client-user-serializer.class";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from "../custom-error-handler.service";

/**
 * Service to communicate with API Client Users
 */

@Injectable()
export class ClientUsersService extends BaseApiService<ClientUser> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      null,
      "clientuser",
      new ClientUserSerializer(),
      new CustomErrorHandlerService()
    );
  }

  resetPassword(email) {
    return this.httpClient.post(
      `${this.url}/clientuser/resetpassword`,
      {
        email_address : email
      },
    );
  }

  // EXTEND/OVERRIDE THE SERVICE HERE IF NECESSARY
}
