import { PermissionCountry } from "./permission-country.model";
import { Resource } from "../resource/resource.model";

/**
 * Users WITHIN a tenant in 4Comply.  This will be used in both applications
 */

export class PermissionSummaryByCountry extends Resource {
  total_number: string;
  data: PermissionCountry[];
}
