import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { DatalistActionItem } from "../../models/datalist-action-item.interface";
import { Router } from "@angular/router";
import { DatatableComponent, SortDirection } from "@swimlane/ngx-datatable";
import { LocalStorageService } from "../../services/local-storage.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-draggable-table",
  templateUrl: "./draggable-table.component.html",
  styleUrls: ["./draggable-table.component.scss"],
})
export class DraggableTableComponent implements OnInit {
  rows = [];
  columnList = [];
  columnMapping = [];
  actionItems: DatalistActionItem[];
  loadingIndicator = true;
  isEditable = {};
  editingItemCount = 0;
  editedItemOriginalData: any[] = [];
  showTable: boolean = true;
  @ViewChild("datatable")
  public datatable: DatatableComponent;
  @Input()
  columns: [];
  @Input() data: [];
  @Input() tableIdentifier: string = null;
  sorts: any = [];
  @Input() actions: DatalistActionItem[];
  @Output() callbackOnDrop: EventEmitter<any> = new EventEmitter<any>();
  @Output() callbackOnUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() callbackEmit = new EventEmitter();
  @Output() sortEvent = new EventEmitter();

  constructor(
    public router: Router,
    public localStorage: LocalStorageService,
    public translate: TranslateService
  ) {}

  version = "1.24";

  loaderVisibility(value: boolean) {
    this.loadingIndicator = value;
  }

  reset() {
    this.showTable = false;
    setTimeout(() => {
      this.showTable = true;
    });
  }

  onColumnSort(a: any) {
    console.log(a);
    if (!a.sorts || !a.sorts.length) {
      return;
    }
    a.sorts.forEach((b) => {
      if (b.dir.toLowerCase() === "asc") {
        b.dir = SortDirection.asc;
      } else {
        b.dir = SortDirection.desc;
      }
    });
    this.localStorage.set(
      "tablesort_" + this.version + this.tableIdentifier,
      JSON.stringify(a.sorts)
    );
    if (!this.datatable) {
      return;
    }
    this.datatable.onColumnSort(a);
  }

  onEdit(index: number): void {
    this.isEditable[index] = true;
    this.editingItemCount++;
    this.editedItemOriginalData[index] = { ...this.rows[index] };
  }

  onCancelEdit(index: number): void {
    this.isEditable[index] = false;
    this.editingItemCount--;
    this.rows[index] = this.editedItemOriginalData[index];
    this.rows = [...this.rows];
  }

  onSort(event) {
    this.sortEvent.emit(event);
    console.log("SETTING SORT LOCALSTORAGE", event.sorts);
    this.localStorage.set(
      "tablesort_" + this.version + this.tableIdentifier,
      JSON.stringify(event.sorts)
    );
    this.sorts = event.sorts;
  }

  updateRows(data) {
    this.rows = data;
  }

  onDrop(event) {
    //this.rows = event;
    this.callbackOnDrop.emit(event);
  }

  ngOnInit() {
    this.rows = this.data;
    this.columnList = this.columns;
    this.actionItems = this.actions;
    this.setupColumns();
  }

  ngOnChanges(changes) {
    this.setupColumns();
    this.setupSort();
  }

  setupSort() {
    let sort = this.localStorage.get(
      "tablesort_" + this.version + this.tableIdentifier
    );
    console.log(sort);
    if (sort) {
      this.sortEvent.emit({ sorts: JSON.parse(sort) });
      this.sorts = JSON.parse(sort);
    }
  }

  setupColumns() {
    let columnMapping;
    if (!this.tableIdentifier) {
      this.columnList = this.columns;
      this.columnMapping = this.columns;
    }
    let oldColumns: any = this.columns;
    let columnMappingString = this.localStorage.get(
      "tablecolumns_" + this.tableIdentifier
    );
    if (!columnMappingString) {
      columnMapping = this.columns;
      this.localStorage.set(
        "tablecolumns_" + this.tableIdentifier,
        JSON.stringify(columnMapping)
      );
    } else {
      columnMapping = JSON.parse(columnMappingString);
    }
    // Apply new titles if they are there
    columnMapping = columnMapping.map((m) => {
      return {
        ...m,
        name: oldColumns.find((f: any) => f.prop === m.prop).name,
      };
    });
    this.columnList = columnMapping.filter((c) => !c.disabled);
    this.columnMapping = columnMapping;
  }

  toggleColumn(col) {
    let columnMappingString = this.localStorage.get(
      "tablecolumns_" + this.tableIdentifier
    );
    let columnMapping = JSON.parse(columnMappingString);
    columnMapping.forEach((c) => {
      if (c.name == col.name) {
        if (c.disabled) {
          c.disabled = false;
        } else {
          c.disabled = true;
        }
      }
    });
    this.columnMapping = columnMapping;
    this.columnList = columnMapping.filter((c) => !c.disabled);
    this.localStorage.set(
      "tablecolumns_" + this.tableIdentifier,
      JSON.stringify(columnMapping)
    );
  }

  onActionClick(item: DatalistActionItem, id) {
    if (item.route) {
      this.router.navigate([item.route]);
    } else if (item.callback) {
      this.callbackEmit.emit(item.callback(id));
    }
  }

  saveData(row, rowIndex) {
    this.callbackOnUpdate.emit(row);
    this.isEditable[rowIndex] = false;
  }
}
