<div class="container">
  <div class="column col-12 filter-selection">
    <div class="filter-list">
      <div class="filter">
        <mat-form-field>
          <mat-select
            matInput
            placeholder="{{ 'GENERIC.MAP_TYPE' | translate }}"
            (selectionChange)="changeMapType()"
            [(ngModel)]="mapType"
            required
          >
            <mat-option value="consent">{{
              "BASIC_ENTITIES_SINGULAR.CONSENT" | translate
            }}</mat-option>
            <mat-option value="permission">{{
              "BASIC_ENTITIES_SINGULAR.PERMISSION" | translate
            }}</mat-option>
          </mat-select>
          <mat-error>Required</mat-error>
        </mat-form-field>
      </div>
      <div class="filter">
        <mat-form-field>
          <mat-select
            matInput
            placeholder="{{ 'BASIC_ENTITIES_SINGULAR.REGULATION' | translate }}"
            (selectionChange)="changeRegulation()"
            [(ngModel)]="selectedRegulation"
            required
          >
            <mat-option
              *ngFor="let regulation of regulations; index as i"
              [value]="regulation.id"
              >{{ regulation.name }}</mat-option
            >
          </mat-select>
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="filter">
        <mat-form-field>
          <mat-select
            matInput
            placeholder="{{
              'BASIC_ENTITIES_SINGULAR.PERMISSION_TYPE' | translate
            }}"
            (selectionChange)="changePermissionType()"
            [(ngModel)]="selectedPermissionType"
            required
          >
            <mat-option
              *ngFor="let type of explicitPermissionTypes; index as i"
              [value]="type.name"
              >{{ type.name }}</mat-option
            >
          </mat-select>
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="columns">
    <div class="column col-12">
      <app-generic-card>
        <div style="width: 100%; height: 400px;">
          <div
            id="divMap"
            style="width: 100%; height: 400px;position: absolute; top: 0px; left:0px;"
          ></div>
          <div
            *ngIf="loading"
            style="width: 100%; height: 400px;position: absolute; top: 0px; left:0px;text-align:center;-index:20;background: white"
          >
            <h4 style="margin-top:50px;">
              Loading... This may take up to a minute if you are tracking over
              1,000,000 Digital Citizens
            </h4>
          </div>
        </div>
      </app-generic-card>
      <div style="margin-top:20px;">
        <app-generic-card>
          <app-actionbar
            title="{{ 'DASHBOARD.GEO_TITLE' | translate }}"
            [subtitle]="
              total != null && total != undefined
                ? indeterminateAmounts
                  ? 'More than ' + total + ' values across all Geo'
                  : total + ' total values across all Geo'
                : null
            "
          ></app-actionbar>
          <app-datatable
            #dtGeoEntries
            (sortEvent)="onSort($event)"
            [data]="geoEntries"
            [columns]="columnsGeo"
          ></app-datatable>
        </app-generic-card>
      </div>
    </div>
  </div>
</div>
