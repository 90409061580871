import { BaseApiService } from "../base/base-api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from "../custom-error-handler.service";
import { ClientRegulationConfiguration } from "../../../models/client-regulation-configuration/client-regulation-configuration.model";
import { ClientRegulationConfigurationSerializer } from "../../../models/client-regulation-configuration/client-regulation-configuration-serializer.class";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

/**
 * Service to communicate with API Client Users
 */

@Injectable()
export class ClientRegulationConfigurationsService extends BaseApiService<
  ClientRegulationConfiguration
> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      null,
      "regulation/config",
      new ClientRegulationConfigurationSerializer(),
      new CustomErrorHandlerService()
    );
  }

  // EXTEND/OVERRIDE THE SERVICE HERE IF NECESSARY

  getByRegulationId(
    regulationId: number
  ): Observable<ClientRegulationConfiguration> {
    return this.httpClient
      .get(`${this.url}/regulation/${regulationId}/config`)
      .pipe(
        map(
          (data: any) =>
            this.serializer.fromJson(data) as ClientRegulationConfiguration
        )
      );
  }

  updateByRegulationId(regulationId: number, item: ClientRegulationConfiguration): Observable<ClientRegulationConfiguration> {
    return this.httpClient
      .put<ClientRegulationConfiguration>(
        `${this.url}/regulation/${regulationId}/config`,
        this.serializer.toJson(item)
      )
      .pipe(
        map(data => this.serializer.fromJson(data) as ClientRegulationConfiguration)
      );
  }
}
