import { Component, OnInit, ViewChild } from "@angular/core";
import { DatalistActionItem } from "libs/shared/src/lib/models/datalist-action-item.interface";
import {
  APIService,
  NotificationService,
  ActionBarItem,
  ClientWebhook,
  DatatableComponent,
  ModalComponent,
} from "@fourcomply-dashboard/shared";
import { Router } from "@angular/router";

@Component({
  selector: "app-webhooks",
  templateUrl: "./webhooks.component.html",
  styleUrls: ["./webhooks.component.scss"],
})
export class WebhooksComponent implements OnInit {
  @ViewChild("dtWebhooks")
  dtWebhooks: DatatableComponent;

  @ViewChild("modalWebhooks", { static: true })
  public modalWebhooks: ModalComponent;

  actionBarItemsWebhooks: ActionBarItem[];
  dataWebhooks: ClientWebhook[];
  columnsWebhooks: any[];

  actionsWebhooks: DatalistActionItem[];
  idForDeleteWebhooks: number;
  constructor(
    private api: APIService,
    private notification: NotificationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.deleteWebhooks = this.deleteWebhooks.bind(this);
    this.editWebhooks = this.editWebhooks.bind(this);
    this.deleteWebhooksConfirm = this.deleteWebhooksConfirm.bind(this);
    this.fetchWebhooks();
  }

  onDeactivate(event): void {
    this.fetchWebhooks();
  }

  editWebhooks(id) {
    this.router.navigate(["/webhooks", id]);
  }

  fetchWebhooks() {
    this.actionBarItemsWebhooks = [
      {
        btnText: "GENERIC.ADD_NEW",
        iconClass: "fas fa-plus",
        route: "webhooks/add",
      },
    ];

    this.actionsWebhooks = [
      {
        iconClass: "fas fa-pen",
        toolTipText: "GENERIC.CUSTOMIZE",
        callback: this.editWebhooks,
      },
      {
        iconClass: "fas fa-trash-alt",
        toolTipText: "GENERIC.DELETE",
        callback: this.deleteWebhooks,
      },
    ];

    this.columnsWebhooks = [
      { name: "GENERIC.NAME", prop: "name" },
      { name: "WEBHOOK.WEBHOOK_URL", prop: "webhookUrl" },
      { name: "WEBHOOK.TRIGGER_ACTIONS", prop: "triggerActionsMap" },
      { name: "GENERIC.ACTIONS", prop: "actions" },
    ];
    this.api.clientWebhooks.list().subscribe((a) => {
      this.dataWebhooks = a.map((b: any) => {
        let string = "";
        b.triggerActions.map((c, index) => {
          string += c + (index === b.triggerActions.length - 1 ? "" : ", ");
        });
        b.triggerActionsMap = string;
        return b;
      });
      this.dtWebhooks.updateRows(this.dataWebhooks);
      this.dtWebhooks.loaderVisibility(false);
    });
  }

  webhooksUpdate(data) {
    this.dtWebhooks.loaderVisibility(true);
    this.api.clientWebhooks.update(data).subscribe((a) => {
      this.dtWebhooks.loaderVisibility(false);
    });
  }

  deleteWebhooks(id) {
    this.idForDeleteWebhooks = id;
    this.modalWebhooks.openModal();
  }

  deleteWebhooksConfirm() {
    this.api.clientWebhooks.delete(this.idForDeleteWebhooks).subscribe(
      (a) => {
        console.log(a);
        this.fetchWebhooks();
        this.modalWebhooks.closeModal();
        this.notification.success("GENERIC.SUCCESSFUL_DATA_DELETE");
      },
      (error) => {
        console.log(error);
        this.modalWebhooks.closeModal();
      }
    );
  }
}
