<input
  style="border:none; border-bottom:1px solid #999999; margin-right:10px;"
  class="mat-input-element"
  type="text"
  placeholder="{{ 'GENERIC.EMAIL_ADDRESS' | translate }}"
  [(ngModel)]="searchText"
  (input)="searchClient($event.target.value)"
/>
<div class="search-result" *ngIf="flag">
  <ul>
    <li *ngFor="let client of (clients | async)">
      <a (click)="onselectClient(client)">{{ client }}</a>
    </li>
  </ul>
</div>
