<div class="datatable-super-upper-wrap">
  <div class="searchbar-wrapper" *ngIf="enableSearch">
    <div class="input-wrap">
      <input
        matInput
        name="searchtext"
        style="border:none; border-bottom:1px solid #999999; margin-right:10px;"
        class="mat-input-element"
        type="text"
        [placeholder]="placeholder"
        [(ngModel)]="searchText"
      />
    </div>
    <button
      class="btn btn-primary submit-button search-button"
      (click)="searcher(searchText)"
      [disabled]="disabled"
      #searchButton
    >
      {{ buttonTitle }}
    </button>
    <div style="cursor:pointer;">
      <div
        style="height:35px;width:35px;border: 1px solid #ccc; border-radius: 2px;background: #eee;margin-left: 3px;padding-top: 2px; padding-left: 3px;"
        (click)="toggleColumnSettings()"
        class="tooltip tooltip-bottom"
        data-tooltip="Configure Columns"
      >
        <!--<i style="color:grey;" class="fas fa-cog"></i>-->
        <img
          src="https://4comply.io/images/TableSettingsIcon.png"
          style="width:28px; height:28px;"
          alt="Edit Columns"
        />
      </div>
      <div class="column-settings-wrapper" *ngIf="showColumnSettings">
        <div style="display:inline-block; width: 100%;">
          <i
            style="color:grey; float:right;"
            class="fas fa-times"
            (click)="toggleColumnSettings()"
          ></i>
        </div>
        <div cdkDropList (cdkDropListDropped)="drop($event)">
          <div *ngFor="let col of columnMapping" cdkDrag>
            <label class="form-checkbox">
              <input
                (click)="toggleColumn(col)"
                [(ngModel)]="!col.disabled"
                type="checkbox"
              />
              <i class="form-icon"></i> {{ col.name | translate }}
              <i class="fas fa-sort"></i>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="datatable-upper-wrap" style="margin-top:28px;">
    <ngx-datatable
      class="material striped custom-ngx-dt"
      [rows]="rows"
      [loadingIndicator]="loadingIndicator"
      [columnMode]="ColumnMode.force"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="'auto'"
      [messages]="{ emptyMessage: 'TABLE.NO_DATA' | translate }"
      [externalSorting]="enableExternalSorting"
      [scrollbarH]="true"
      [sorts]="sorts"
      (sort)="onSort($event)"
    >
      <!-- [scrollbarV]="false"  [scrollbarH]="true"-->
      <ng-container *ngIf="!enableExternalSorting">
        <ngx-datatable-column
          *ngFor="let col of columnList"
          [prop]="col.prop"
          [name]="col.name | translate"
          [width]="col.width"
        >
          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-value="value"
            let-row="row"
          >
            <div *ngIf="col.prop !== 'actions' && col.prop !== 'customFields'">
              <mat-form-field *ngIf="isEditable[rowIndex]">
                <input matInput [(ngModel)]="row[col.prop]" />
              </mat-form-field>
              <span
                *ngIf="
                  !isEditable[rowIndex] &&
                  col.type !== 'link' &&
                  col.type !== 'localLink' &&
                  col.type !== 'translate'
                "
                >{{ value || "-" }}</span
              >
              <span *ngIf="col.type === 'translate'">{{
                value || "-" | translate
              }}</span>
              <a
                [href]="value"
                target="_blank"
                *ngIf="!isEditable[rowIndex] && col.type === 'link'"
                >{{ value }}</a
              >
              <a
                [routerLink]="row.linkValue"
                *ngIf="!isEditable[rowIndex] && col.type === 'localLink'"
                >{{ value }}</a
              >
            </div>
            <div class="action-list-custom" *ngIf="col.prop === 'customFields'">
              <div class="action-item">
                <button (click)="onCustomFields(row)" class="btn btn-primary">
                  {{ "GENERIC.VIEW_FIELDS" | translate }}
                </button>
              </div>
            </div>
            <div class="action-list-custom" *ngIf="col.prop === 'actions'">
              <div class="action-item" *ngFor="let a of actions">
                <div [ngSwitch]="a.toolTipText">
                  <div
                    *ngSwitchCase="'GENERIC.EDIT'"
                    style="position:absolute;"
                  >
                    <button
                      (click)="onEdit(rowIndex)"
                      class="btn btn-action btn-primary btn-sm s-circle tooltip tooltip-left"
                      [attr.data-tooltip]="a.toolTipText | translate"
                      *ngIf="!isEditable[rowIndex]"
                    >
                      <i class="{{ a.iconClass }}"></i>
                    </button>

                    <button
                      (click)="saveData(row, rowIndex)"
                      class="btn btn-action btn-primary btn-sm s-circle tooltip tooltip-left"
                      [attr.data-tooltip]="'GENERIC.SAVE' | translate"
                      *ngIf="isEditable[rowIndex]"
                      style="position: absolute;right: 30px;top: 2px;"
                    >
                      <i class="fas fa-check"></i>
                    </button>
                    <button
                      (click)="onCancelEdit(rowIndex)"
                      class="btn btn-action btn-primary btn-sm s-circle tooltip tooltip-left"
                      [attr.data-tooltip]="'GENERIC.CANCEL' | translate"
                      *ngIf="isEditable[rowIndex]"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </div>

                  <button
                    *ngSwitchDefault
                    (click)="onActionClick(a, row.id)"
                    class="btn btn-action btn-primary btn-sm s-circle tooltip tooltip-left"
                    [attr.data-tooltip]="a.toolTipText | translate"
                  >
                    <i class="{{ a.iconClass }}"></i>
                  </button>
                </div>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ng-container>

      <ng-container *ngIf="!!enableExternalSorting">
        <ngx-datatable-column
          *ngFor="let col of columnList"
          [prop]="col.prop"
          [comparator]="noopComparator"
          [name]="col.name | translate"
          [width]="col.width"
        >
          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-value="value"
            let-row="row"
          >
            <div *ngIf="col.prop !== 'actions' && col.prop !== 'customFields'">
              <mat-form-field *ngIf="isEditable[rowIndex]">
                <input matInput [(ngModel)]="row[col.prop]" />
              </mat-form-field>
              <span
                *ngIf="
                  !isEditable[rowIndex] &&
                  col.type !== 'link' &&
                  col.type !== 'localLink' &&
                  col.type !== 'translate'
                "
                >{{ value || "-" }}</span
              >
              <span *ngIf="col.type === 'translate'">{{
                value || "-" | translate
              }}</span>
              <a
                [href]="value"
                target="_blank"
                *ngIf="!isEditable[rowIndex] && col.type === 'link'"
                >{{ value }}</a
              >
              <a
                [routerLink]="row.linkValue"
                *ngIf="!isEditable[rowIndex] && col.type === 'localLink'"
                >{{ value }}</a
              >
            </div>
            <div class="action-list-custom" *ngIf="col.prop === 'customFields'">
              <div class="action-item">
                <button (click)="onCustomFields(row)" class="btn btn-primary">
                  {{ "GENERIC.VIEW_FIELDS" | translate }}
                </button>
              </div>
            </div>
            <div class="action-list-custom" *ngIf="col.prop === 'actions'">
              <div class="action-item" *ngFor="let a of actions">
                <div [ngSwitch]="a.toolTipText">
                  <div
                    *ngSwitchCase="'GENERIC.EDIT'"
                    style="position:absolute;"
                  >
                    <button
                      (click)="onEdit(rowIndex)"
                      class="btn btn-action btn-primary btn-sm s-circle tooltip tooltip-left"
                      [attr.data-tooltip]="a.toolTipText | translate"
                      *ngIf="!isEditable[rowIndex]"
                    >
                      <i class="{{ a.iconClass }}"></i>
                    </button>

                    <button
                      (click)="saveData(row, rowIndex)"
                      class="btn btn-action btn-primary btn-sm s-circle tooltip tooltip-left"
                      [attr.data-tooltip]="'GENERIC.SAVE' | translate"
                      *ngIf="isEditable[rowIndex]"
                      style="position: absolute;right: 30px;top: 2px;"
                    >
                      <i class="fas fa-check"></i>
                    </button>
                    <button
                      (click)="onCancelEdit(rowIndex)"
                      class="btn btn-action btn-primary btn-sm s-circle tooltip tooltip-left"
                      [attr.data-tooltip]="'GENERIC.CANCEL' | translate"
                      *ngIf="isEditable[rowIndex]"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </div>

                  <button
                    *ngSwitchDefault
                    (click)="onActionClick(a, row.id)"
                    class="btn btn-action btn-primary btn-sm s-circle tooltip tooltip-left"
                    [attr.data-tooltip]="a.toolTipText | translate"
                  >
                    <i class="{{ a.iconClass }}"></i>
                  </button>
                </div>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ng-container>
    </ngx-datatable>
  </div>
</div>
