import { Serializer } from "../resource/serializer.interface";
import { ClientRegulationConfiguration } from './client-regulation-configuration.model';

export class ClientRegulationConfigurationSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): ClientRegulationConfiguration {
    const clientRegulationConfig = new ClientRegulationConfiguration();
    clientRegulationConfig.id = json.id;
    clientRegulationConfig.regulationId = json.regulationId;
    clientRegulationConfig.config = json.config;
    clientRegulationConfig.createdAt = json.created_at;
    clientRegulationConfig.updatedAt = json.updated_at;
    
    return clientRegulationConfig;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(clientRegulationConfig: ClientRegulationConfiguration): any {
    return {
      id: clientRegulationConfig.id,
      regulationId: clientRegulationConfig.regulationId,
      config: clientRegulationConfig.config,
      created_at: clientRegulationConfig.createdAt,
      updated_at: clientRegulationConfig.updatedAt
    };
  }
}
