import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  NgZone,
} from "@angular/core";
import { FormlyFieldConfig } from "@ngx-formly/core";
import {
  AuthService,
  APIService,
  ModalComponent,
  ComplianceInput,
  KeyValuePair,
  GaugeOptions,
  PieOptions,
  ConsentSummaryByCountry,
} from "@fourcomply-dashboard/shared";
import { ClientUser } from "@fourcomply-dashboard/shared";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldHigh from "@amcharts/amcharts4-geodata/worldHigh";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { ChartOptions, ChartType, ChartDataSets } from "chart.js";
import { Label, Color } from "ng2-charts";
import { Regulation } from "libs/shared/src/lib/models/regulation/regulation.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-dashboard-rights",
  templateUrl: "./dashboard-rights.component.html",
  styleUrls: ["./dashboard-rights.component.scss"],
})
export class DashboardRightsComponent implements OnInit {
  public chartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: (value: any) => {
              if (value % 1 === 0) return value;
            },
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
      },
    },
  };

  public chartLabels: Label[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  public chartType: ChartType = "bar";
  public chartLegend = true;

  public chartRTRData: ChartDataSets[] = [
    { data: [], label: "Right to Review" },
  ];
  chartRTRLabel: Label[] = [];

  public chartRTRColors: Color[] = [
    {
      backgroundColor: "#A5D6A7", //green
    },
  ];

  public chartRTUData: ChartDataSets[] = [
    { data: [], label: "Right to Update" },
  ];
  chartRTULabel: Label[] = [];
  public chartRTUColors: Color[] = [
    {
      backgroundColor: "#4FC3F7", //blue
    },
  ];

  public chartRTPData: ChartDataSets[] = [
    { data: [], label: "Right to Portability" },
  ];
  chartRTPLabel: Label[] = [];
  public chartRTPColors: Color[] = [
    {
      backgroundColor: "#FFF59D", //yellow
    },
  ];

  public chartRTBFData: ChartDataSets[] = [
    { data: [], label: "Right to be Forgotten" },
  ];
  chartRTBFLabel: Label[] = [];
  public chartRTBFColors: Color[] = [
    {
      backgroundColor: "#FF8A65", //red
    },
  ];

  public chartTotalLabels: Label[] = [
    "Jan 19",
    "Feb 19",
    "Mar 19",
    "Apr 19",
    "May 19",
    "Jun 19",
  ];
  public chartTotalData: ChartDataSets[] = [
    { data: [12, 8, 25, 35, 11, 21], label: "Right to Review" },
    { data: [4, 8, 1, 2, 1, 3], label: "Right to Update" },
    { data: [1, 2, 1, 2, 1, 1], label: "Right to Portability" },
    { data: [1, 0, 0, 0, 1, 1], label: "Right to be Forgotten" },
  ];
  public chartTotalColors: Color[] = [
    {
      backgroundColor: "#A5D6A7", //green
    },
    {
      backgroundColor: "#4FC3F7", //blue
    },
    {
      backgroundColor: "#FFF59D", //yellow
    },
    {
      backgroundColor: "#FF8A65", //red
    },
  ];

  gaugeData: any[];
  gaugeOptions: GaugeOptions;
  pieData: any[];
  pieOptions: PieOptions;

  userName = "";
  data = [];
  model: any = {};
  columns = [];
  fields: FormlyFieldConfig[] = [];
  formSubmitted = false;
  submitUserName = "";
  regulations: any[] = [];
  public clientUsers: ClientUser[];
  public consents: ComplianceInput[];
  rtaTotal = 0;
  rtuTotal = 0;
  rtpTotal = 0;
  rtbfTotal = 0;
  selectedRegulationName = "All";
  months = [];
  request = 0;
  translateService: TranslateService;
  @ViewChild(ModalComponent, { static: true })
  public childModal: ModalComponent;

  constructor(
    private auth: AuthService,
    private api: APIService,
    private zone: NgZone,
    private translate: TranslateService
  ) {
    this.translateService = translate;
  }

  ngOnInit() {
    this.columns = [
      { name: "GENERIC.NAME", prop: "name" },
      { name: "GENERIC.ID", prop: "id" },
    ];
    setTimeout(() => {
      this.chartRTRData = [
        { data: [], label: this.translateService.instant("RIGHTS.RTR") },
      ];

      this.chartRTUData = [
        { data: [], label: this.translateService.instant("RIGHTS.RTU") },
      ];
      this.chartRTPData = [
        { data: [], label: this.translateService.instant("RIGHTS.RTP") },
      ];
      this.chartRTBFData = [
        { data: [], label: this.translateService.instant("RIGHTS.RTBF") },
      ];
      this.loadLastTwelveMonth();
      this.getRegulations();
      this.fetChSummaryData(null);
    }, 200);
  }

  loadLastTwelveMonth() {
    let date = new Date();
    date.setMonth(date.getMonth() - 11);
    let monthNames = [
      "MONTHS.SHORT.JANUARY",
      "MONTHS.SHORT.FEBRUARY",
      "MONTHS.SHORT.MARCH",
      "MONTHS.SHORT.APRIL",
      "MONTHS.SHORT.MAY",
      "MONTHS.SHORT.JUNE",
      "MONTHS.SHORT.JULY",
      "MONTHS.SHORT.AUGUST",
      "MONTHS.SHORT.SEPTEMBER",
      "MONTHS.SHORT.OCTOBER",
      "MONTHS.SHORT.NOVEMBER",
      "MONTHS.SHORT.DECEMBER",
    ];

    for (var i = 0; i < 12; i++) {
      this.months.push(
        this.translate.instant(monthNames[date.getMonth()]) +
          " " +
          date.getFullYear()
      );

      // Subtract a month each time
      date.setMonth(date.getMonth() + 1);
    }

    this.chartRTRLabel = this.months.reverse();
    this.chartRTULabel = this.months.reverse();
    this.chartRTPLabel = this.months.reverse();
    this.chartRTBFLabel = this.months.reverse();
  }

  fetChSummaryData(regulationId: number): void {
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    this.request = 4;
    this.api.rightRequests.getSummary("RTA", regulationId).subscribe((data) => {
      this.rtaTotal = 0;
      this.chartRTRData[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let a = [].concat(this.months);
      this.chartRTRLabel = [].concat(a);
      a.forEach((m, index) => {
        // m = monthYear
        var split = m.split(" ");
        let monthName = split[0];
        let monthNumber = monthNames.indexOf(monthName) + 1;
        let year = split[1];
        let combined = monthNumber + "/" + year;
        data.forEach((x) => {
          if (x.key == combined.toString()) {
            this.chartRTRData[0].data[index] = parseInt(x.value);
            this.rtaTotal += parseInt(x.value);
          }
        });
      });
      this.request--;
    });

    this.api.rightRequests.getSummary("RTU", regulationId).subscribe((data) => {
      this.rtuTotal = 0;
      this.chartRTUData[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let a = [].concat(this.months);
      this.chartRTULabel = [].concat(a);
      a.forEach((m, index) => {
        // m = monthYear
        var split = m.split(" ");
        let monthName = split[0];
        let monthNumber = monthNames.indexOf(monthName) + 1;
        let year = split[1];
        let combined = monthNumber + "/" + year;
        data.forEach((x) => {
          if (x.key == combined.toString()) {
            this.chartRTUData[0].data[index] = parseInt(x.value);
            this.rtuTotal += parseInt(x.value);
          }
        });
      });
      this.request--;
    });

    this.api.rightRequests.getSummary("RTP", regulationId).subscribe((data) => {
      this.rtpTotal = 0;
      this.chartRTPData[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let a = [].concat(this.months);
      this.chartRTPLabel = [].concat(a);
      a.forEach((m, index) => {
        // m = monthYear
        var split = m.split(" ");
        let monthName = split[0];
        let monthNumber = monthNames.indexOf(monthName) + 1;
        let year = split[1];
        let combined = monthNumber + "/" + year;
        data.forEach((x) => {
          if (x.key == combined.toString()) {
            this.chartRTPData[0].data[index] = parseInt(x.value);
            this.rtpTotal += parseInt(x.value);
          }
        });
      });
      this.request--;
    });

    this.api.rightRequests
      .getSummary("RTBF", regulationId)
      .subscribe((data) => {
        this.rtbfTotal = 0;
        this.chartRTBFData[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        let a = [].concat(this.months);
        this.chartRTBFLabel = [].concat(a);
        a.forEach((m, index) => {
          // m = monthYear
          var split = m.split(" ");
          let monthName = split[0];
          let monthNumber = monthNames.indexOf(monthName) + 1;
          let year = split[1];
          let combined = monthNumber + "/" + year;
          data.forEach((x) => {
            if (x.key == combined.toString()) {
              this.chartRTBFData[0].data[index] = parseInt(x.value);
              this.rtbfTotal += parseInt(x.value);
            }
          });
        });
        this.request--;
      });
  }

  changeRegulation(index: number): void {
    this.regulations.forEach((r) => {
      if (r.id === this.regulations[index].id) {
        this.fetChSummaryData(r.id);
        r.isActive = true;
        this.selectedRegulationName = r.name;
      } else {
        r.isActive = false;
      }
    });
  }

  getRegulations(): void {
    this.api.regulations.list().subscribe((data) => {
      this.regulations.push({
        name: "All",
        id: 0,
        isActive: true,
      });
      data.forEach((d) => {
        this.regulations.push({
          name: d.name,
          id: d.id,
          isActive: false,
        });
      });
    });
  }
}
