import { Resource } from "../resource/resource.model";

/**
 * Users WITHIN a tenant in 4Comply.  This will be used in both applications
 */

export class ClientEmailTemplate extends Resource {
  type: string;
  subject: string;
  template: string;
  hydratedTemplate: string;
  footer: string;
  createdAt: Date;
  updatedAt: Date;
}
