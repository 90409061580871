import { Serializer } from "../resource/serializer.interface";
import { PermissionSummaryByCountry } from "./permission-summary-by-country.model";

export class PermissionSummaryByCountrySerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): PermissionSummaryByCountry {
    const permissionCountryBySummary = new PermissionSummaryByCountry();
    permissionCountryBySummary.total_number = json.total_number;
    permissionCountryBySummary.data = json.data;

    return permissionCountryBySummary;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(permissionCountryBySummary: PermissionSummaryByCountry): any {
    return {
      total_number: permissionCountryBySummary.total_number,
      data: permissionCountryBySummary.data,
    };
  }
}
