import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';

@Component({
  selector: 'app-callback',
  template: '<div class="loading redirect-loader"></div>',
  styles: []
})
export class CallbackComponent implements OnInit {

   constructor(private auth: AuthService) { }

  ngOnInit() {
     this.auth.handleAuthCallback();
  }
}
