import { Serializer } from "../resource/serializer.interface";
import { RightRequestConfig } from "./right-request-config.model";
import { RightRequestStep } from "../right-request-steps/right-request-step.model";

export class RightRequestConfigSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): RightRequestConfig {
    const rrc = new RightRequestConfig();
    rrc.id = json.id;
    rrc.requestType = json.request_type;
    rrc.sfConfig = json.sf_config;
    rrc.regulationId = json.regulation_id;
    rrc.steps = json.steps;
    rrc.createdAt = json.created_at;
    rrc.updatedAt = json.updated_at;
     rrc.numberOfSteps = json.steps?.length || 0;

    return rrc;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(rrc: RightRequestConfig): any {
    return {
      id: rrc.id,
      request_type: rrc.requestType,
      sf_config: rrc.sfConfig,
      regulation_id: rrc.regulationId,
      Steps: rrc.steps,
      created_at: rrc.createdAt,
      updated_at: rrc.updatedAt
    };
  }
}
