<div class="container">
  <div class="column col-7">
    <app-generic-card>
      <h3 class="section-title">{{ "CLIENT_SYSTEM.ADD_NEW" | translate }}</h3>
      <div class="columns">
        <div class="column col-6">
          <app-form
            #formSystem
            [fields]="fields"
            [model]="model"
            [callback]="submitSystemsForm"
            [loadingDone]="loadingDone"
            closeRoute="systems"
          ></app-form>
        </div>
      </div>
    </app-generic-card>
  </div>
</div>
