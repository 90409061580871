import { Resource } from "../resource/resource.model";
import { RightRequestStep } from "../right-request-steps/right-request-step.model";

export class RightRequestConfig extends Resource {
  requestType: string;
  sfConfig: string;
  regulationId: string;
  steps: RightRequestStep[];
  createdAt?: Date;
  updatedAt?: Date;
  numberOfSteps?: Number;
}
