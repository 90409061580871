import { Resource } from "../resource/resource.model";
import { ClientFormField } from './client-form-fields.model';

/**
 * Users WITHIN a tenant in 4Comply.  This will be used in both applications
 */

export class ClientForm extends Resource {
    type: string;
    title: string;
    description:string;
    subHeader: string;
    submitAction: string;
    createdAt?: Date;
    updatedAt?: Date;
    fields: ClientFormField[];
    tenantId: string;
}


