<div class="container" [style.display]="outlet.isActivated ? 'none' : ''">
  <div class="column col-12">
    <app-generic-card>
      <app-actionbar
        title="{{ 'GEO.GEO_PRIORITIES' | translate }}"
        [actionBarItems]="[]"
      ></app-actionbar>
      <app-draggable-table
        #dtGeoPriorities
        [data]="dataGeoPriorities"
        tableIdentifier="geoPriorities"
        [columns]="columnsGeoPriorities"
        [actions]="actionsGeoPriorities"
        (callbackOnUpdate)="geoPriorityUpdate($event)"
        (callbackOnDrop)="onDropPriority($event)"
      ></app-draggable-table>
    </app-generic-card>
  </div>
  <div class="column col-12">
    <app-generic-card>
      <app-actionbar
        title="{{ 'GEO.GEO_REGULATION_MAP' | translate }}"
        [actionBarItems]="actionBarItemsGeoMaps"
      ></app-actionbar>
      <app-datatable
        #dtGeoRegulationMaps
        [data]="dataGeoRegulationMaps"
        [columns]="columnsGeoRegulationMaps"
        [actions]="actionsGeoRegulationMaps"
        tableIdentifier="geoRegMaps"
        [enableExternalSorting]="false"
        (callbackOnUpdate)="geoRegulationMapUpdate($event)"
      ></app-datatable>
    </app-generic-card>
  </div>
  <div class="column col-12">
    <app-generic-card>
      <app-actionbar
        title="{{ 'GEO.GEO_IP_EXCEPTIONS' | translate }}"
        [actionBarItems]="actionBarItemsGeoIP"
      ></app-actionbar>
      <app-datatable
        #dtGeoIpExceptions
        [data]="dataGeoIpExceptions"
        tableIdentifier="geoIpExceptions"
        [columns]="columnsGeoIpExceptions"
        [actions]="actionsGeoIpException"
        (callbackOnUpdate)="geoIpExceptionUpdate($event)"
      ></app-datatable>
    </app-generic-card>
  </div>
</div>
<router-outlet
  #outlet="outlet"
  (deactivate)="onDeactivate($event)"
></router-outlet>

<app-modal
  #modalGeoIpExcpetions
  title="{{ 'MODAL_GENERIC.CONFIRM_DELETE' | translate }}"
  bodyContent="{{ 'MODAL_GENERIC.CONFIRM_DELETE_QUESTION' | translate }}"
  [confirmCallback]="deleteGeoIpExceptionConfirm"
></app-modal>

<app-modal
  #modalGeoRegulationMap
  title="{{ 'MODAL_GENERIC.CONFIRM_DELETE' | translate }}"
  bodyContent="{{ 'MODAL_GENERIC.CONFIRM_DELETE_QUESTION' | translate }}"
  [confirmCallback]="deleteGeoRegulationMapConfirm"
></app-modal>
