import { Resource } from "../resource/resource.model";
import { GeoSummaryDetails } from "./geo-summary-details.model";

/**
 * Users WITHIN a tenant in 4Comply.  This will be used in both applications
 */

export class GeoSummary extends Resource {
  total_number: string;
  data: GeoSummaryDetails[];
}