import { Resource } from "../resource/resource.model";

/**
 * Priority and level
 */

export class GeoPriority extends Resource {
  priority: number;
  geo_type: string;
  geo_method_name: string;
  geo_key: string;
}
