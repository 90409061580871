import { HitlTemplate } from "./hitl-template.model";
import { Serializer } from "../resource/serializer.interface";

export class HitlTemplateSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): HitlTemplate {
    const client = new HitlTemplate();
    client.id = json.id;
    client.name = json.name;
    client.description = json.description;
    client.shortDescription = json.short_description;
    client.triggeringActions = json.triggering_actions;
    client.emailTemplateId = json.email_template_id;
    client.defaultAssignedUserId = json.default_assigned_user_id;
    client.timeoutBehavior = json.timeout_behavior;
    client.declineBehavior = json.decline_behavior;
    client.createdAt = json.created_at;
    client.updatedAt = json.updated_at;

    return client;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(client: HitlTemplate): any {
    return {
      id: client.id,
      name: client.name,
      description: client.description,
      short_description: client.shortDescription,
      triggering_actions: client.triggeringActions,
      email_template_id: client.emailTemplateId,
      default_assigned_user_id: client.defaultAssignedUserId,
      timeout_behavior: client.timeoutBehavior,
      decline_behavior: client.declineBehavior,
      updated_at: client.updatedAt,
      created_at: client.createdAt,
    };
  }
}
