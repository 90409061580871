import { BaseApiService } from "../base/base-api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from "../custom-error-handler.service";
import { ClientSystem } from "../../../models/client-systems/client-system.model";
import { ClientSystemSerializer } from "../../../models/client-systems/client-system-serializer.class";
import { Observable } from "rxjs";

/**
 * Service to communicate with API Client Users
 */

@Injectable()
export class ClientSystemsService extends BaseApiService<ClientSystem> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      null,
      "systems",
      new ClientSystemSerializer(),
      new CustomErrorHandlerService()
    );
  }

  getTriggers(): Observable<any> {
    return this.httpClient.get(`${this.url}/systems/triggers`);
  }
}
