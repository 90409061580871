import { Serializer } from "../resource/serializer.interface";
import { GeoRegulationMap } from "./geo-regulation-map.model";

export class GeoRegulationMapSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): GeoRegulationMap {
    const geoRegulationMap = new GeoRegulationMap();
    geoRegulationMap.id = json.id;
    geoRegulationMap.type = json.type;
    geoRegulationMap.nickname = json.nickname;
    geoRegulationMap.inputCountry = json.input_country;
    geoRegulationMap.inputState = json.input_state;
    geoRegulationMap.standardCountry = json.standard_country;
    geoRegulationMap.standardState = json.standard_state;
    geoRegulationMap.regulationId = json.regulation_id;
    geoRegulationMap.regulationName = json.regulation_name;
    return geoRegulationMap;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(geoRegulationMap: GeoRegulationMap): any {
    return {
      id: geoRegulationMap.id,
      type: geoRegulationMap.type,
      input_country: geoRegulationMap.inputCountry,
      input_state: geoRegulationMap.inputState,
      standard_country: geoRegulationMap.standardCountry,
      standard_state: geoRegulationMap.standardState,
      nickname: geoRegulationMap.nickname,
      regulation_id: geoRegulationMap.regulationId,
      regulation_name: geoRegulationMap.regulationName
    };
  }
}
