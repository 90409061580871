import { Inject, Injectable } from "@angular/core";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";

@Injectable()
export class LocalStorageService {
  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {}

  public set(key: string, value: string) {
    this.storage.set(key, value);
  }

  public get(key: string) {
    return this.storage.get(key);
  }

  public unset(key: string) {
    this.storage.remove(key);
  }
}
