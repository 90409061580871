import { ResourceSQL } from "../resource-sql/resource-sql.model";

export class ComplianceInput extends ResourceSQL {
  email: string;
  userId: string;
  geo: string;
  consentCode: string;
  ttl: string;
  expiration: Date;
  activityId: string;
  standardState: string;
  standardCountry: string;
  processingPurpose: string;
  permissionCategory: string;
  permissionType: string;
  regulationName: any;
  permissionSource: string;
  regulationId: string;
  createdAt: Date;
  updatedAt: Date;
  activity_date: Date;
  createdAtAux: string;
  externalSource: string;
  businessUnit: string;
  means: string;
  consentLanguage:string;
  privacyNoticeVersion: string;
  customField1: string;
  customField2: string;
  customField3: string;
  customField4: string;
  customField5: string;
  customField6: Date;
  customField7: Date;
  activityDateAux: string;
}
