export const sharedEnvironment = {
  local: {
    auth: {
      CLIENT_ID: "eC0aOQfFpZgb70xYHiD7fZQdP5zC4q5q",
      CLIENT_DOMAIN: "4comply-dev.auth0.com",
      AUDIENCE: "4comply-dev.com",
      admin: {
        REDIRECT: "http://localhost:4300/callback",
        LOGOUT_URL: "http://localhost:4300"
      },
      dashboard: {
        REDIRECT: "http://localhost:4200/callback",
        LOGOUT_URL: "http://localhost:4200"
      },
      forms: {
        URL: "http://localhost:4400"
      }
    },
    api: {
      API_ENDPOINT: "http://localhost:5000/v1"
    }
  },
  dev: {
    auth: {
      CLIENT_ID: "eC0aOQfFpZgb70xYHiD7fZQdP5zC4q5q",
      CLIENT_DOMAIN: "4comply-dev.auth0.com",
      AUDIENCE: "4comply-dev.com",
      admin: {
        REDIRECT: "https://admin-dev.4comply.io/callback",
        LOGOUT_URL: "https://admin-dev.4comply.io"
      },
      dashboard: {
        REDIRECT: "https://app-dev.4comply.io/callback",
        LOGOUT_URL: "https://app-dev.4comply.io"
      },
      forms: {
        URL: "https://forms-dev.4comply.io"
      }
    },
    api: {
      API_ENDPOINT: "https://api-dev.4comply.io/v1"
    }
  },
  staging: {
    auth: {
      CLIENT_ID: "Ycn6lP4LuyiyZps8SiRe1wjLvJCksSKO",
      CLIENT_DOMAIN: "4comply-staging.auth0.com",
      AUDIENCE: "4comply-staging.com",
      admin: {
        REDIRECT: "https://admin-staging.4comply.io/callback",
        LOGOUT_URL: "https://admin-staging.4comply.io"
      },
      dashboard: {
        REDIRECT: "https://dashboard-staging.4comply.io/callback",
        LOGOUT_URL: "https://dashboard-staging.4comply.io"
      },
      forms: {
        URL: "https://forms-staging.4comply.io"
      }
    },
    api: {
      API_ENDPOINT: "https://api-staging.4comply.io/v1"
    }
  },
  demo: {
    auth: {
      CLIENT_ID: "kvbjMASWfWl2LcxmAkdIUPAr0i4rS4GG",
      CLIENT_DOMAIN: "4comply-demo.auth0.com",
      AUDIENCE: "4comply-demo.com",
      admin: {
        REDIRECT: "https://admin-demo.4comply.io/callback",
        LOGOUT_URL: "https://admin-demo.4comply.io"
      },
      dashboard: {
        REDIRECT: "https://dashboard-demo.4comply.io/callback",
        LOGOUT_URL: "https://dashboard-demo.4comply.io"
      },
      forms: {
        URL: "https://forms-demo.4comply.io"
      }
    },
    api: {
      API_ENDPOINT: "https://api-demo.4comply.io/v1"
    }
  },
  eu: {
    auth: {
      CLIENT_ID: "RXDNAtMm5cGBy8TVpfPIKpsSUH38MgwW",
      CLIENT_DOMAIN: "4comply.eu.auth0.com",
      AUDIENCE: "4comply-eu.com",
      admin: {
        REDIRECT: "https://admin-eu.4comply.io/callback",
        LOGOUT_URL: "https://admin-eu.4comply.io"
      },
      dashboard: {
        REDIRECT: "https://dashboard-eu.4comply.io/callback",
        LOGOUT_URL: "https://dashboard-eu.4comply.io"
      },
      forms: {
        URL: "https://forms-eu.4comply.io"
      }
    },
    api: {
      API_ENDPOINT: "https://eu.4comply.io/v1"
    }
  },
  production: {
    auth: {
      CLIENT_ID: "ZyjsW1nesGdEmX7s9uy2AilgDTzmtlgB",
      CLIENT_DOMAIN: "4comply-prod.auth0.com",
      AUDIENCE: "4comply.com",
      admin: {
        REDIRECT: "https://admin.4comply.io/callback",
        LOGOUT_URL: "https://admin.4comply.io"
      },
      dashboard: {
        REDIRECT: "https://dashboard.4comply.io/callback",
        LOGOUT_URL: "https://dashboard.4comply.io"
      },
      forms: {
        URL: "https://forms.4comply.io"
      }
    },
    api: {
      API_ENDPOINT: "https://api.4comply.io/v1"
    }
  }
};
