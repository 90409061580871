import { Resource } from "../resource/resource.model";

/**
 * A Tenant in the 4Comply SaaS System
 * This will really only be used for the 4comply dashboard
 */

export class ClientLawConfiguration extends Resource {
  derived_permission_types: ClientLawConfigurationType[];
  processing_purposes: ClientLawConfigurationPurpose[];
  consent_override_behavior: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export class ClientLawConfigurationType {
  name: string;
  explicit_permission_types: string[];
  expiry_behavior: string;
  business_unit:string;
  name_aux:string;
}

export class ClientLawConfigurationPurpose {
  name: string;
  ttl_months: number;
  permission_category: string;
  returned_contact: string;
  privacy_policy_justification: string;
}
