import { Component, OnInit, ViewChild } from "@angular/core";
import { FormlyFieldConfig } from "@ngx-formly/core";
import {
  APIService,
  NotificationService,
  FormComponent,
  ClientEmailTemplate,
} from "@fourcomply-dashboard/shared";
import { Router, ActivatedRoute } from "@angular/router";
import { BreadcrumbService } from "xng-breadcrumb";

@Component({
  selector: "app-add-hitl-template",
  templateUrl: "./add-hitl-template.component.html",
  styleUrls: ["./add-hitl-template.component.scss"],
})
export class AddHitlTemplateComponent implements OnInit {
  @ViewChild("hitlTemplateForm", { static: true })
  hitlTemplateForm: FormComponent;
  isEdit = false;
  fields: FormlyFieldConfig[] = [];
  formSubmitted = false;
  triggers: string[];
  emailTemplates: ClientEmailTemplate[];
  actualData: any;
  model: any = {
    trigger: null,
    emailTemplateId: null,
    templateName: null,
    emailResendPeriod: null,
    timeoutBehavior: null,
    declineBehavior: null,
  };

  triggeringActions = [""];
  isDuplicateTriggerSelected = false;
  constructor(
    public api: APIService,
    public router: Router,
    private notification: NotificationService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService
  ) {
    this.initHitlTemplateForm = this.initHitlTemplateForm.bind(this);
  }

  ngOnInit() {
    this.submitHitlTemplateForm = this.submitHitlTemplateForm.bind(this);
    this.initHitlTemplateForm();
    this.getEmailTemplates();
    this.getUsers();
    this.getTriggers();
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  getTriggers() {
    this.api.hitlTemplates.getTriggers().subscribe((a) => {
      console.log(a);
      this.triggers = a.map((a) => {
        return { value: a, label: a };
      });
    });
  }

  getUsers() {
    this.api.clientUsers.list().subscribe((users) => {
      this.fields[4].templateOptions.options = users.map((a) => {
        return {
          key: a.id,
          name: a.emailAddress,
        };
      });
    });
  }

  getEmailTemplates(): void {
    this.api.clientEmailTemplates.list().subscribe((emailTemps) => {
      this.fields[3].templateOptions.options = emailTemps.map((a) => {
        return {
          key: a.id,
          name: a.type,
        };
      });
    });
  }

  onSelectionChanged(): void {
    this.isDuplicateTriggerSelected = false;
    let object = {};
    this.triggeringActions.forEach(function(item) {
      if (item && !object[item]) object[item] = 0;
      object[item] += 1;
    });

    for (var prop in object) {
      if (object[prop] >= 2) {
        this.isDuplicateTriggerSelected = true;
      }
    }
  }

  initHitlTemplateForm() {
    this.fields = [
      {
        key: "name",
        type: "input",
        templateOptions: {
          required: true,
          label: "GENERIC.NAME",
        },
      },
      {
        key: "shortDescription",
        type: "input",
        templateOptions: {
          required: true,
          label: "GENERIC.SHORT_DESCRIPTION",
        },
      },
      {
        key: "description",
        type: "input",
        templateOptions: {
          required: true,
          label: "GENERIC.DESCRIPTION",
        },
      },
      {
        key: "emailTemplateId",
        type: "select",
        templateOptions: {
          valueProp: "key",
          labelProp: "name",
          required: true,
          placeholder: "GENERIC.SELECT_ONE",
          label: "BASIC_ENTITIES_SINGULAR.EMAIL_TEMPLATE",
        },
      },
      {
        key: "defaultAssignedUserId",
        type: "select",
        templateOptions: {
          valueProp: "key",
          labelProp: "name",
          required: false,
          placeholder: "GENERIC.SELECT_ONE",
          label: "HITL_TEMPLATE.AUTOMATICALLY_ASSIGNED_USER",
        },
      },
    ];

    const hitlTemplateId = this.route.snapshot.params["id"];
    if (hitlTemplateId) {
      this.isEdit = true;
      this.api.hitlTemplates.get(hitlTemplateId).subscribe((a) => {
        this.actualData = a;
        console.log(a);
        this.model = { ...a };
        this.triggeringActions = this.model.triggeringActions.map(
          (x) => x.type
        );
        this.hitlTemplateForm.updateModel(this.model);
      });
    }
  }

  submitHitlTemplateForm() {
    if (this.isDuplicateTriggerSelected) {
      this.hitlTemplateForm.loaderVisibility(false);
      return;
    }
    this.formSubmitted = true;
    this.model.triggeringActions = [];
    this.triggeringActions.forEach((x) => {
      if (x.length > 0) {
        this.model.triggeringActions.push({ name: x, type: x });
      }
    });
    if (this.isEdit) {
      this.api.hitlTemplates.update(this.model).subscribe(
        (a) => {
          this.notification.success("GENERIC.SUCCESSFUL_DATA_UPDATE");
          this.router.navigate(["hitl-config"]);
        },
        (err) => {
          this.hitlTemplateForm.loaderVisibility(false);
        }
      );
    } else {
      this.api.hitlTemplates.create(this.model).subscribe(
        (a) => {
          this.notification.success("GENERIC.SUCCESSFUL_DATA_ADD");;
          this.router.navigate(["hitl-config"]);
        },
        (err) => {
          this.hitlTemplateForm.loaderVisibility(false);
        }
      );
    }
  }
}
