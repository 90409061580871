import { Serializer } from "../resource/serializer.interface";
import { LegalActivity } from "./legal-activity.model";

export class LegalActivitySerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): LegalActivity {
    const legalActivity = new LegalActivity();
    legalActivity.id = json.id;
    legalActivity.consentId = json.consent_id;
    legalActivity.emailAddress = json.email_address;
    legalActivity.regulationId = json.regulation_id;
    legalActivity.createdAt = new Date(json.created_at);
    legalActivity.updatedAt = new Date(json.updated_at);
    legalActivity.activityAt = new Date(json.activity_at);
    legalActivity.activityType = json.activity_type;
    legalActivity.standardState = json.standard_state;
    legalActivity.standardCountry = json.standard_country;
    legalActivity.inputState = json.input_state;
    legalActivity.inputCountry = json.input_country;
    legalActivity.consentCode = json.consent_code;
    legalActivity.regulationName = json.regulation_name;
    legalActivity.rightWorkflowId = json.right_workflow_id;
    legalActivity.rightWorkflowStepId = json.right_workflow_step_id;
    legalActivity.rightWorkflowStepName = json.right_workflow_step_name;
    legalActivity.systemId = json.system_id;
    legalActivity.systemType = json.system_type;
    legalActivity.system = json.system;
    legalActivity.systemRecordId = json.system_record_id;
    legalActivity.systemRecordType = json.system_record_type;
    legalActivity.inputEntity = json.input_entity;

    legalActivity.system=json.system;
    legalActivity.permission_type=json.permission_type;
    legalActivity.business_unit=json.business_unit;
    legalActivity.external_source=json.external_source;
    legalActivity.processing_purpose=json.processing_purpose;
    return legalActivity;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(legalActivity: LegalActivity): any {
    return {
      id: legalActivity.id,
      consent_id: legalActivity.consentId,
      email_address: legalActivity.emailAddress,
      regulation_id: legalActivity.regulationId,
      created_at: legalActivity.createdAt,
      updated_at: legalActivity.updatedAt,
      activity_at: legalActivity.activityAt,
      activity_type: legalActivity.activityType,
      consent_code: legalActivity.consentCode,
      right_workflow_id: legalActivity.rightWorkflowId,
      right_workflow_step_id: legalActivity.rightWorkflowStepId,
      right_workflow_step_name: legalActivity.rightWorkflowStepName,
      system_id: legalActivity.systemId,
      system_type: legalActivity.systemType,
      system: legalActivity.system,
      system_record_id: legalActivity.systemRecordId,
      system_record_type: legalActivity.systemRecordType,
      input_entity: legalActivity.inputEntity,
    };
  }
}
