import { Injectable } from "@angular/core";
import { ClientsService } from "./clients/clients.service";
import { ClientUsersService } from "./client-users/client-users.service";
import { AdminUsersService } from "./admin-users/admin-users.service";
import { HttpClient } from "@angular/common/http";
import { GeoPrioritiesService } from "./geo-priorities/geo-priorities.service";
import { GeoIpExceptionsService } from "./geo-ip-exceptions/geo-ip-exceptions.service";
import { RegulationsService } from "./regulations/regulations.service";
import { GeoRegulationMapsService } from "./geo-regulation-maps/geo-regulation-maps.service";
import { ConsentPermissionsService } from "./consent-permissions/consent-permissions.service";
import { ComplianceInputService } from "./compliance-inputs/compliance-input.service";
import { ClientWebhooksService } from "./client-webhooks/client-webhooks.service";
import { ClientSystemsService } from "./client-systems/client-systems.service";
import { ClientFormsService } from "./client-forms/client-forms.service";
import { LegalActivityErrorsService } from "./legal-activity-errors/legal-activity-errors.service";
import { LegalActivitiesService } from "./legal-activities/legal-activities.service";
import { ClientRegulationsService } from "./client-regulations/client-regulations.service";
import { ClientRegulationConfigurationsService } from "./client-regulation-configurations/client-regulation-configurations.service";
import { RightRequestConfigsService } from "./right-request-configs/right-request-configs.service";
import { ClientEmailTemplatesService } from "./client-email-templates/client-email-templates.service";
import { ForgottenUsersService } from "./forgotten-users/forgotten-users.service";
import { RightRequestService } from "./right-requests/right-requests.service";
import { HitlTemplatesService } from "./hitl-templates/hitl-templates.service";
import { HitlTasksService } from "./hitl-tasks/hitl-tasks.service";
import { BusinessUnitsService } from "./business-units/business-units.service";
import { GeoSummaryService } from "./geo-summary/geo-summary.service";

/**
 * The API service is a consumable that is an SDK that gives any 4Thought Application an easy wrapper around the 4Comply API
 * If you want to just inject one of the services, you can also do that instead.
 *
 * For instance, in order to access the client users, you could run:
 * let apiService = new APIService();
 *
 * apiService.clientUsers.get(1255)...etc
 *
 *
 */

@Injectable()
export class APIService {
  // Resources Available to the API Service
  public clients: ClientsService;
  public clientUsers: ClientUsersService;
  public adminUsers: AdminUsersService;
  public geoPriorities: GeoPrioritiesService;
  public geoIpExceptions: GeoIpExceptionsService;
  public regulations: RegulationsService;
  public geoRegulationMaps: GeoRegulationMapsService;
  public consentPermissions: ConsentPermissionsService;
  public complianceInputs: ComplianceInputService;
  public clientWebhooks: ClientWebhooksService;
  public businessUnits: BusinessUnitsService; 
  public clientSystems: ClientSystemsService;
  public clientForms: ClientFormsService;
  public legalActivityErrors: LegalActivityErrorsService;
  public legalActivities: LegalActivitiesService;
  public clientRegulations: ClientRegulationsService;
  public clientRegulationConfigurations: ClientRegulationConfigurationsService;
  public rightRequestConfigs: RightRequestConfigsService;
  public clientEmailTemplates: ClientEmailTemplatesService;
  public forgottenUsers: ForgottenUsersService;
  public rightRequests: RightRequestService;
  public hitlTemplates: HitlTemplatesService;
  public hitlTasks: HitlTasksService;
  public geoSummaries : GeoSummaryService;

  /**
   * Instantiate every service so it is easily available
   * @param httpClient
   */
  constructor(httpClient: HttpClient) {
    this.clients = new ClientsService(httpClient);
    this.clientUsers = new ClientUsersService(httpClient);
    this.adminUsers = new AdminUsersService(httpClient);
    this.geoPriorities = new GeoPrioritiesService(httpClient);
    this.geoIpExceptions = new GeoIpExceptionsService(httpClient);
    this.regulations = new RegulationsService(httpClient);
    this.geoRegulationMaps = new GeoRegulationMapsService(httpClient);
    this.consentPermissions = new ConsentPermissionsService(httpClient);
    this.complianceInputs = new ComplianceInputService(httpClient);
    this.businessUnits= new BusinessUnitsService(httpClient);
    this.clientWebhooks = new ClientWebhooksService(httpClient);
    this.clientSystems = new ClientSystemsService(httpClient);
    this.clientForms = new ClientFormsService(httpClient);
    this.legalActivityErrors = new LegalActivityErrorsService(httpClient);
    this.legalActivities = new LegalActivitiesService(httpClient);
    this.clientRegulations = new ClientRegulationsService(httpClient);
    this.clientRegulationConfigurations = new ClientRegulationConfigurationsService(
      httpClient
    );
    this.rightRequestConfigs = new RightRequestConfigsService(httpClient);
    this.clientEmailTemplates = new ClientEmailTemplatesService(httpClient);
    this.forgottenUsers = new ForgottenUsersService(httpClient);
    this.rightRequests = new RightRequestService(httpClient);
    this.hitlTemplates = new HitlTemplatesService(httpClient);
    this.hitlTasks = new HitlTasksService(httpClient);
    this.geoSummaries = new GeoSummaryService(httpClient);
  }
}
