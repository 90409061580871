import { BaseApiService } from "../base/base-api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from "../custom-error-handler.service";
import { GeoPriority } from "../../../models/geo-priority/geo-priority.model";
import { GeoPrioritySerializer } from "../../../models/geo-priority/geo-priority-serializer.class";

/**
 * Service to communicate with API Client Users
 */

@Injectable()
export class GeoPrioritiesService extends BaseApiService<GeoPriority> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      null,
      "geo/priorities",
      new GeoPrioritySerializer(),
      new CustomErrorHandlerService()
    );
  }

  // EXTEND/OVERRIDE THE SERVICE HERE IF NECESSARY
}
