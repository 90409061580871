import { Component, OnInit, ViewChild } from "@angular/core";
import {
  APIService,
  GeoIpException,
  DatatableComponent,
  GeoPriority,
  DraggableTableComponent,
  ActionBarItem,
  ModalComponent,
  NotificationService,
} from "@fourcomply-dashboard/shared";
import { DatalistActionItem } from "libs/shared/src/lib/models/datalist-action-item.interface";
import { Router } from "@angular/router";

@Component({
  selector: "app-geo",
  templateUrl: "./geo.component.html",
  styleUrls: ["./geo.component.scss"],
})
export class GeoComponent implements OnInit {
  @ViewChild("dtGeoIpExceptions")
  dtGeoIpExceptions: DatatableComponent;
  @ViewChild("dtGeoPriorities")
  dtGeoPriorities: DraggableTableComponent;
  @ViewChild("dtGeoRegulationMaps")
  dtGeoRegulationMaps: DraggableTableComponent;
  @ViewChild("modalGeoIpExcpetions", { static: true })
  public modalGeoIpExcpetions: ModalComponent;

  @ViewChild("modalGeoRegulationMap", { static: true })
  public modalGeoRegulationMap: ModalComponent;

  dataGeoIpExceptions: GeoIpException[];
  columnsGeoIpExceptions: any[];

  actionBarItemsGeoIP: ActionBarItem[];
  actionsGeoIpException: DatalistActionItem[];
  idForDeleteGeoIpException: number;

  dataGeoPriorities: any[];
  columnsGeoPriorities: any[];
  actionsGeoPriorities: DatalistActionItem[];

  actionBarItemsGeoMaps: ActionBarItem[];
  dataGeoRegulationMaps: any[];
  columnsGeoRegulationMaps: any[];
  actionsGeoRegulationMaps: DatalistActionItem[];
  idForDeleteGeoRegulationMap: number;

  constructor(
    private api: APIService,
    private notification: NotificationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.deleteGeoIpException = this.deleteGeoIpException.bind(this);
    this.deleteGeoIpExceptionConfirm = this.deleteGeoIpExceptionConfirm.bind(
      this
    );
    this.deleteGeoRegulationMap = this.deleteGeoRegulationMap.bind(this);
    this.deleteGeoRegulationMapConfirm = this.deleteGeoRegulationMapConfirm.bind(
      this
    );

    this.routeToEditGeoIpException = this.routeToEditGeoIpException.bind(this);
    this.routeToEditGeoRegulationMap = this.routeToEditGeoRegulationMap.bind(
      this
    );

    this.fetchGeoIpExceptions();
    this.fetchGeoPriorities();
    this.fetchGeoRegulationMaps();
  }

  onDeactivate(event): void {
    this.fetchGeoIpExceptions();
    this.fetchGeoPriorities();
    this.fetchGeoRegulationMaps();
  }

  fetchGeoIpExceptions() {
    this.actionBarItemsGeoIP = [
      {
        btnText: "GENERIC.ADD_NEW",
        iconClass: "fas fa-plus",
        route: "geo/add-ip-exception",
      },
    ];

    this.actionsGeoIpException = [
      {
        iconClass: "fas fa-pen",
        toolTipText: "GENERIC.CUSTOMIZE",
        callback: this.routeToEditGeoIpException,
      },
      {
        iconClass: "fas fa-trash-alt",
        toolTipText: "GENERIC.DELETE",
        callback: this.deleteGeoIpException,
      },
    ];

    this.columnsGeoIpExceptions = [
      { name: "GEO.IP_RANGE", prop: "ip_range" },
      { name: "GEO.GEO_TYPE", prop: "geo_type" },
      { name: "GENERIC.VALUE", prop: "value" },
      { name: "GENERIC.ACTIONS", prop: "actions" },
    ];
    this.api.geoIpExceptions.list().subscribe((a) => {
      this.dataGeoIpExceptions = a;
      this.dtGeoIpExceptions.updateRows(this.dataGeoIpExceptions);
      this.dtGeoIpExceptions.loaderVisibility(false);
    });
  }

  routeToEditGeoIpException(id) {
    this.router.navigate(["geo/ip-exception", id]);
  }

  geoIpExceptionUpdate(data) {
    this.dtGeoIpExceptions.loaderVisibility(true);
    this.api.geoIpExceptions.update(data).subscribe((a) => {
      this.dtGeoIpExceptions.loaderVisibility(false);
      this.notification.success("GENERIC.SUCCESSFUL_DATA_UPDATE");
    });
  }

  deleteGeoIpException(id) {
    this.idForDeleteGeoIpException = id;
    this.modalGeoIpExcpetions.openModal();
  }

  deleteGeoIpExceptionConfirm() {
    this.api.geoIpExceptions.delete(this.idForDeleteGeoIpException).subscribe(
      (a) => {
        this.fetchGeoIpExceptions();
        this.modalGeoIpExcpetions.closeModal();
        this.notification.success("GENERIC.SUCCESSFUL_DATA_DELETE");
      },
      (error) => this.modalGeoIpExcpetions.closeModal()
    );
  }

  onDropPriority(draggable) {
    this.dtGeoPriorities.loaderVisibility(true);
    let toUpdate = this.dataGeoPriorities[draggable.dropIndex];
    this.api.geoPriorities
      .update({ ...toUpdate, priority: draggable.dropIndex + 1 })
      .subscribe((a) => {
        let oldPriority = this.dataGeoPriorities[draggable.dropIndex].priority;
        let newPriority = draggable.dropIndex + 1;
        if (oldPriority > newPriority) {
          for (let i = newPriority; i < oldPriority; i++) {
            this.dataGeoPriorities[i].priority = i + 1;
          }
        } else if (oldPriority < newPriority) {
          for (let i = oldPriority - 1; i < newPriority; i++) {
            this.dataGeoPriorities[i].priority = i + 1;
          }
        }
        this.dataGeoPriorities[draggable.dropIndex].priority =
          draggable.dropIndex + 1;
        this.notification.success("GEO.SUCCESSFUL_UPDATE_GEO_PRIORITY");
        this.dtGeoPriorities.loaderVisibility(false);
      });
  }

  fetchGeoPriorities() {
    this.actionsGeoPriorities = [
      {
        iconClass: "fas fa-pen",
        toolTipText: "GENERIC.EDIT",
      },
    ];

    this.columnsGeoPriorities = [
      { name: "GEO.GEO_METHOD_NAME", prop: "geo_method_name", sortable: true },
      { name: "GEO.GEO_TYPE", prop: "geo_type", sortable: true },
      { name: "GEO.GEO_KEY", prop: "geo_key", sortable: true },
      { name: "GEO.PRIORITY", prop: "priority", sortable: true },
      { name: "GENERIC.ACTIONS", prop: "actions", sortable: false },
    ];

    this.api.geoPriorities.list().subscribe((a) => {
      this.dataGeoPriorities = a;
      // this.dataGeoPriorities = a.sort((a, b) => {
      //   if (a.priority < b.priority) {
      //     return -1;
      //   } else return 1;
      // });
      this.dtGeoPriorities.updateRows(this.dataGeoPriorities);
      this.dtGeoPriorities.loaderVisibility(false);
    });
  }

  geoPriorityUpdate(data) {
    console.log(data);
    this.dtGeoPriorities.loaderVisibility(true);
    this.api.geoPriorities.update(data).subscribe((a) => {
      this.notification.success("GEO.SUCCESSFUL_UPDATE_GEO_PRIORITY");
      this.dtGeoPriorities.loaderVisibility(false);
    });
  }

  fetchGeoRegulationMaps() {
    this.actionBarItemsGeoMaps = [
      {
        btnText: "GENERIC.ADD_NEW",
        iconClass: "fas fa-plus",
        route: "geo/add-map",
      },
    ];

    this.actionsGeoRegulationMaps = [
      {
        iconClass: "fas fa-pen",
        toolTipText: "GENERIC.CUSTOMIZE",
        callback: this.routeToEditGeoRegulationMap,
      },
      {
        iconClass: "fas fa-trash-alt",
        toolTipText: "GENERIC.DELETE",
        callback: this.deleteGeoRegulationMap,
      },
    ];

    this.columnsGeoRegulationMaps = [
      { name: "GENERIC.NAME", prop: "nickname", sortable: true },
      {
        name: "BASIC_ENTITIES_SINGULAR.REGULATION",
        prop: "regulationName",
        sortable: true,
      },
      { name: "GEO.INPUT_COUNTRY", prop: "inputCountry", sortable: true },
      { name: "GEO.STANDARD_COUNTRY", prop: "standardCountry", sortable: true },
      { name: "GEO.INPUT_STATE", prop: "inputState", sortable: true },
      { name: "GEO.STANDARD_STATE", prop: "standardState", sortable: true },
      { name: "GENERIC.ACTIONS", prop: "actions", sortable: false },
    ];

    this.api.geoRegulationMaps.list().subscribe((a) => {
      this.dataGeoRegulationMaps = a;
      this.dtGeoRegulationMaps.updateRows(this.dataGeoRegulationMaps);
      this.dtGeoRegulationMaps.loaderVisibility(false);
    });
  }

  deleteGeoRegulationMap(id) {
    this.idForDeleteGeoRegulationMap = id;
    this.modalGeoRegulationMap.openModal();
  }

  deleteGeoRegulationMapConfirm() {
    this.api.geoRegulationMaps
      .delete(this.idForDeleteGeoRegulationMap)
      .subscribe(
        (a) => {
          this.fetchGeoRegulationMaps();
          this.modalGeoRegulationMap.closeModal();
          this.notification.success("GENERIC.SUCCESSFUL_DATA_DELETE");
        },
        (error) => this.modalGeoRegulationMap.closeModal()
      );
  }

  routeToEditGeoRegulationMap(id) {
    this.router.navigate(["/geo", id]);
  }

  geoRegulationMapUpdate(data) {
    this.dtGeoRegulationMaps.loaderVisibility(true);
    this.api.geoRegulationMaps.update(data).subscribe((a) => {
      this.dtGeoRegulationMaps.loaderVisibility(false);
      this.notification.success("GEO.SUCCESSFUL_UPDATE_GEO_REGULATION_MAP");
    });
  }
}
