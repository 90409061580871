import { Component, OnInit, ViewChild, EventEmitter } from "@angular/core";
import {
  DatatableComponent,
  ConsentPermission,
  APIService,
  NotificationService,
  ActionBarItem,
  ModalComponent,
  LocalStorageService,
  AuthService,
} from "@fourcomply-dashboard/shared";
import {
  UploaderOptions,
  UploadFile,
  UploadInput,
  humanizeBytes,
  UploadOutput,
  UploadStatus,
} from "ngx-uploader";
import { QueryOptions } from "libs/shared/src/lib/models/query-options/query-options.class";
import { DatePipe } from "@angular/common";
import { pipe } from "rxjs";

@Component({
  selector: "app-consent-permissions",
  templateUrl: "./consent-permissions.component.html",
  styleUrls: ["./consent-permissions.component.scss"],
})
export class ConsentPermissionsComponent implements OnInit {
  @ViewChild("dtPermissions")
  dtPermissions: DatatableComponent;

  @ViewChild("modalUpload")
  modalUpload: ModalComponent;

  @ViewChild("modalCustomFields")
  modalCustomFields: ModalComponent;

  disabled: boolean = false;
  searchText: string;
  dataPermissions: ConsentPermission[];
  columnsPermissions: any[];
  actionBarItemsPermission: ActionBarItem[];
  pages: number;
  sort: string;
  currentPage: number = 1;
  total: number;
  isEstimate: boolean = false;
  pageSize: number = 10;
  options: UploaderOptions;
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  uploadFileStatus: string = "";
  userid: string;
  client: any;
  selectedPermission: any = null;
  customFieldNames: any = {
    customFieldName1: "",
    customFieldName2: "",
    customFieldName3: "",
    customFieldName4: "",
    customFieldName5: "",
    customFieldName6: "",
    customFieldName7: "",
  };

  constructor(
    private api: APIService,
    private notification: NotificationService,
    private localStorage: LocalStorageService,
    private auth: AuthService
  ) {
    this.options = { concurrency: 1, maxUploads: 1 };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    this.openUploadModal = this.openUploadModal.bind(this);
    this.openCustomFieldsModal = this.openCustomFieldsModal.bind(this);
    this.export = this.export.bind(this);
    this.getStoredPageSize();
    this.fetchPermissions();
    this.auth.userProfile$.subscribe(
      pipe((res: any) => {
        this.userid = res.currentTenant.tenant_id;
        this.getClient();
      })
    );
  }

  getClient() {
    this.api.clients.get(this.userid).subscribe((a) => {
      this.client = a;
      if (a.configId.custom_field_config) {
        this.customFieldNames = {
          customFieldName1:
            a.configId.custom_field_config.custom_field_1_name || "",
          customFieldName2:
            a.configId.custom_field_config.custom_field_2_name || "",
          customFieldName3:
            a.configId.custom_field_config.custom_field_3_name || "",
          customFieldName4:
            a.configId.custom_field_config.custom_field_4_name || "",
          customFieldName5:
            a.configId.custom_field_config.custom_field_5_name || "",
          customFieldName6:
            a.configId.custom_field_config.custom_field_6_name || "",
          customFieldName7:
            a.configId.custom_field_config.custom_field_7_name || "",
        };
      }
    });
  }

  getStoredPageSize() {
    this.pageSize = this.localStorage.get("LIST_PAGE_SIZE")
      ? Number(this.localStorage.get("LIST_PAGE_SIZE"))
      : 10;
  }

  setCurrentPage(ev) {
    this.dtPermissions.loaderVisibility(true);
    this.currentPage = ev;
    this.fetchPermissions();
  }

  onOpenCustomFields(ev) {
    this.selectedPermission = ev;
    this.openCustomFieldsModal();
  }

  setPageSize(ev) {
    this.dtPermissions.loaderVisibility(true);
    this.pageSize = ev;
    this.localStorage.set("LIST_PAGE_SIZE", this.pageSize.toString());
    this.currentPage = 1;
    this.fetchPermissions();
  }

  sortMapper(field) {
    switch (field) {
      case "createdAtAux":
        return "created_at";
      case "email":
        return "email_address";
      case "permissionCategory":
        return "permission_category";
      case "processingPurpose":
        return "processing_purpose";
      case "permissionType":
        return "permission_type";
      case "regulationName":
        return "regulation_name";
      case "expirationAux":
        return "expiration";
      case "businessUnit":
        return "business_unit";
      case "activity_dateAux":
        return "activity_date";
      case "permissionSource":
        return "permission_source";
      case "consentSource":
        return "consent_source";
      case "standardState":
        return "standard_country";
      case "standardCountry":
        return "standard_country";
      case "createdAtAux":
        return "created_at";
      case "externalSource":
        return "external_source";
      case "consentLanguage":
        return "consent_language";
      case "privacyNoticeVersion":
        return "privacy_notice_version";
      default:
        return field;
    }
  }

  onSort(ev) {
    console.log(ev);
    if (!ev.sorts || !ev.sorts.length) {
      return;
    }
    this.sort =
      (ev.sorts[0].dir == "desc" ? "-" : "") +
      this.sortMapper(ev.sorts[0].prop);
    this.fetchPermissions();
  }

  openCustomFieldsModal(): void {
    this.modalCustomFields.openModal();
  }

  fetchPermissions() {
    this.actionBarItemsPermission = [
      {
        btnText: "PERMISSIONS.EXPORT_ALL",
        iconClass: "fas fa-file-export",
        callback: this.export,
      },
      {
        btnText: "PERMISSIONS.IMPORT_ALL",
        iconClass: "fas fa-file-upload",
        callback: this.openUploadModal,
      },
    ];

    this.columnsPermissions = [
      { name: "GENERIC.ID", prop: "id", width: 120 },
      { name: "GENERIC.EMAIL_ADDRESS", prop: "email", width: 250 },
      { name: "GENERIC.EXPIRATION", prop: "expirationAux", width: 120 },
      { name: "GENERIC.ACTIVITY_DATE", prop: "activity_dateAux", width: 120 },
      {
        name: "BASIC_ENTITIES_SINGULAR.PERMISSION_CATEGORY",
        prop: "permissionCategory",
        width: 180,
      },
      {
        name: "BASIC_ENTITIES_SINGULAR.PROCESSING_PURPOSE",
        prop: "processingPurpose",
        width: 180,
      },
      {
        name: "BASIC_ENTITIES_SINGULAR.PERMISSION_TYPE",
        prop: "permissionType",
        width: 180,
      },
      {
        name: "BASIC_ENTITIES_SINGULAR.PERMISSION_SOURCE",
        prop: "permissionSource",
        width: 150,
      },
      {
        name: "BASIC_ENTITIES_SINGULAR.BUSINESS_UNIT",
        prop: "businessUnit",
        width: 150,
      },
      { name: "GEO.STATE", prop: "standardState", width: 120 },
      { name: "GEO.COUNTRY", prop: "standardCountry", width: 120 },
      {
        name: "BASIC_ENTITIES_SINGULAR.REGULATION",
        prop: "regulationName",
        width: 120,
      },
      { name: "BASIC_ENTITIES_SINGULAR.SYSTEM", prop: "system", width: 120 },
      { name: "GENERIC.CREATED_AT", prop: "createdAtAux", width: 120 },
      { name: "SETTINGS.CUSTOM_FIELDS", prop: "customFields", width: 120 },
      { name: "GENERIC.EXTERNAL_SOURCE", prop: "externalSource", width: 120 },
      { name: "GENERIC.MEANS", prop: "means", width: 120 },
      { name: "GENERIC.CONSENT_LANGUAGE", prop: "consentLanguage", width: 120 },
      {
        name: "GENERIC.PRIVACY_NOTICE_VERSION",
        prop: "privacyNoticeVersion",
        width: 120,
      },
    ];

    let qo = new QueryOptions();
    qo.page = this.currentPage;
    qo.pageSize = this.pageSize;
    qo.sorts = this.sort;

    if (this.searchText) {
      qo.filter = "email_address@=" + encodeURIComponent(this.searchText) + "";
    }

    this.api.consentPermissions.listWithPaging(qo).subscribe((data) => {
      this.dataPermissions = data.list;
      this.pages = data.pages;
      this.currentPage = data.currentpage;
      this.total =
        this.searchText || Number(data.total) < 1000
          ? Number(data.total)
          : data.totalunfiltered
          ? Number(data.totalunfiltered)
          : Number(data.total);
      this.isEstimate =
        this.searchText || Number(data.total) < 1000 ? false : true;
      this.pageSize = data.pagesize;
      this.dataPermissions.forEach(function (value) {
        value.createdAtAux = new DatePipe("en-US").transform(
          value.createdAt,
          "MM/dd/yyyy hh:mm zzz"
        );
        value.expirationAux = new DatePipe("en-US").transform(
          value.expiration,
          "MM/dd/yyyy hh:mm zzz"
        );
        value.activity_dateAux = new DatePipe("en-US").transform(
          value.activity_date,
          "MM/dd/yyyy hh:mm zzz"
        );
      });
      this.dtPermissions.updateRows(this.dataPermissions);
      this.dtPermissions.loaderVisibility(false);
    });
  }

  export(): void {
    this.api.consentPermissions.exportPermissions().subscribe((x) => {
      this.notification.success(x.message);
    });
  }

  openUploadModal(): void {
    this.modalUpload.openModal();
  }

  onUploadOutput(output: UploadOutput): void {
    console.log("onUploadOutput", output);
    switch (output.type) {
      case "allAddedToQueue":
        this.uploadFileStatus = "uploading";
        this.api.consentPermissions.uploadConsents().subscribe((data) => {
          const event: UploadInput = {
            type: "uploadFile",
            url: data.presignedurl,
            headers: {
              "Content-Type": "application/json",
            },
            includeWebKitFormBoundary: false,
            method: "PUT",
            file: this.files[0],
          };
          this.uploadInput.emit(event);
        });
        break;
      case "addedToQueue":
        if (typeof output.file !== "undefined") {
          this.files.push(output.file);
        }
        break;
      case "uploading":
        if (typeof output.file !== "undefined") {
          // update current data in files array for uploading file
          const index = this.files.findIndex(
            (file) =>
              typeof output.file !== "undefined" && file.id === output.file.id
          );
          this.files[index] = output.file;
        }
        break;
      case "removed":
        // remove file from array when removed
        this.files = this.files.filter(
          (file: UploadFile) => file !== output.file
        );
        break;
      case "dragOver":
        this.uploadFileStatus = "dragOver";
        break;
      case "dragOut":
      case "drop":
        this.uploadFileStatus = "";
        break;
      case "done":
        if (output.file.responseStatus == 200) {
          this.modalUpload.closeModal();
          this.uploadFileStatus = "";
          this.uploadInput.emit({ type: "removeAll" });

          this.notification.success(
            "COMPLIANCE_INPUTS.SUCCESSFUL_UPLOAD_COMPLIANCE_INPUTS"
          );
        } else {
          this.notification.error("COMPLIANCE_INPUTS.ERROR_UPLOAD");
        }
        this.uploadFileStatus = "";
        break;
    }
    this.files = this.files.filter(
      (file) => file.progress.status !== UploadStatus.Done
    );
  }

  searchWithEmail(ev) {
    this.searchText = ev;
    this.currentPage = 1;
    this.dtPermissions.updateRows([]);
    this.fetchPermissions();
  }
}
