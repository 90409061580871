import { Component, OnInit, ViewChild } from "@angular/core";
import {
  DatatableComponent,
  RightRequest,
  APIService,
  DatalistActionItem,
  LocalStorageService,
} from "@fourcomply-dashboard/shared";
import { environment } from "apps/fourcomply-dashboard/src/environments/environment";
import { Router } from "@angular/router";
import { QueryOptions } from "libs/shared/src/lib/models/query-options/query-options.class";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-right-request",
  templateUrl: "./right-request.component.html",
  styleUrls: ["./right-request.component.scss"],
})
export class RightRequestComponent implements OnInit {
  @ViewChild("dtRightRequest")
  dtRightRequest: DatatableComponent;
  pages: number;
  currentPage: number = 1;
  total: number;
  searchText: string;
  isEstimate: boolean = false;
  disabled: boolean = false;
  pageSize: number = 10;
  dataRightRequest: RightRequest[];
  columnsRightRequest: any[];
  actionsRightRequest: DatalistActionItem[];
  sort: string;

  constructor(
    private api: APIService,
    private router: Router,
    private localStorage: LocalStorageService
  ) {}

  ngOnInit() {
    this.viewDetails = this.viewDetails.bind(this);
    this.getStoredPageSize();
    this.fetchRightRequests();
  }

  getStoredPageSize() {
    this.pageSize = this.localStorage.get("LIST_PAGE_SIZE")
      ? Number(this.localStorage.get("LIST_PAGE_SIZE"))
      : 10;
  }

  setCurrentPage(ev) {
    this.dtRightRequest.loaderVisibility(true);
    this.currentPage = ev;
    this.fetchRightRequests();
  }

  setPageSize(ev) {
    this.dtRightRequest.loaderVisibility(true);
    this.pageSize = ev;
    this.localStorage.set("LIST_PAGE_SIZE", this.pageSize.toString());
    this.currentPage = 1;
    this.fetchRightRequests();
  }

  sortMapper(field) {
    switch (field) {
      case "createdAtAux":
        return "created_at";
      case "email":
        return "email_address";
      case "permissionCategory":
        return "permission_category";
      case "processingPurpose":
        return "processing_purpose";
      case "permissionType":
        return "permission_type";
      case "regulationName":
        return "regulation_name";
      case "expirationAux":
        return "expiration";
      case "activity_dateAux":
        return "activity_date";
      case "consentSource":
        return "consent_source";
      case "standardState":
        return "standard_country";
      case "standardCountry":
        return "standard_country";
      case "createdAtAux":
        return "created_at";
      case "externalSource":
        return "external_source";
      case "consentLanguage":
        return "consent_language";
      case "privacyNoticeVersion":
        return "privacy_notice_version";
      case "requestType":
        return "request_type";
      case "regulationName":
        return "regulation_name";
      case "currentState":
        return "current_state";
      default:
        return field;
    }
  }

  onSort(ev) {
    if (!ev.sorts || !ev.sorts.length) {
      return;
    }
    this.sort =
      (ev.sorts[0].dir == "desc" ? "-" : "") +
      this.sortMapper(ev.sorts[0].prop);
    this.fetchRightRequests();
  }

  fetchRightRequests() {
    this.actionsRightRequest = [
      {
        iconClass: "fas fa-eye",
        toolTipText: "GENERIC.VIEW_DETAILS",
        callback: this.viewDetails,
      },
    ];

    this.columnsRightRequest = [
      { name: "GENERIC.ID", prop: "id", type: "localLink" },
      { name: "GENERIC.EMAIL_ADDRESS", prop: "email" },
      { name: "GENERIC.CREATED_AT", prop: "createdAtAux" },
      { name: "GENERIC.STATUS", prop: "status" },
      { name: "RIGHTS_REQUESTS.CURRENT_RIGHTS_STATE", prop: "currentState" },
      { name: "RIGHTS_REQUESTS.REQUEST_TYPE", prop: "requestType" },
      { name: "RIGHTS_REQUESTS.REGULATION_NAME", prop: "regulationName" },
      { name: "GENERIC.COUNTRY_STATE", prop: "standardState" },
      { name: "GENERIC.COUNTRY", prop: "standardCountry" },
      { name: "GENERIC.ACTIONS", prop: "actions" },
      //{ name: 'Config ID', prop: 'configId' },
      //{ name: 'Error', prop: 'error' }
    ];

    let qo = new QueryOptions();
    qo.page = this.currentPage;
    qo.pageSize = this.pageSize;
    qo.sorts = this.sort;

    if (this.searchText) {
      qo.filter = "email==" + this.searchText + "";
    }

    this.api.rightRequests.listWithPaging(qo).subscribe((data) => {
      this.dataRightRequest = data.list.map((rightRequest: any) => {
        rightRequest.linkValue = "/right-requests/" + rightRequest.id;
        return rightRequest;
      });
      this.pages = data.pages;
      this.currentPage = data.currentpage;
      this.total =
        this.searchText || Number(data.total) < 1000
          ? Number(data.total)
          : data.totalunfiltered
          ? Number(data.totalunfiltered)
          : Number(data.total);
      this.isEstimate =
        this.searchText || Number(data.total) < 1000 ? false : true;
      this.pageSize = data.pagesize;
      this.dataRightRequest.forEach(function (value) {
        value.createdAtAux = new DatePipe("en-US").transform(
          value.createdAt,
          "MM/dd/yyyy hh:mm zzz"
        );
      });
      this.dtRightRequest.updateRows(this.dataRightRequest);
      console.log("Updating Right Requests Table");
      this.dtRightRequest.loaderVisibility(false);
    });
  }

  searchWithEmail(ev) {
    this.searchText = ev;
    this.currentPage = 1;
    this.dtRightRequest.updateRows([]);
    this.fetchRightRequests();
  }

  viewDetails(id): void {
    this.router.navigate(["/right-requests", id]);
  }
}
