import { Resource } from "../resource/resource.model";

/**
 * A Tenant in the 4Comply SaaS System
 * This will really only be used for the 4comply dashboard
 */

export class HitlTemplate extends Resource {
  name: string;
  triggeringActions: any;
  description: string;
  shortDescription: string;
  emailTemplateId: string;
  emailResendPeriod: string;
  timeoutBehavior: string;
  declineBehavior: string;
  defaultAssignedUserId: string;
  createdAt: Date;
  updatedAt: Date;
}
