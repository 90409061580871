import { Resource } from "../resource/resource.model";
import { Regulation } from "../regulation/regulation.model";

/**
 * Priority and level
 */

export class GeoRegulationMap extends Resource {
  //regulation: Regulation;
  regulationId: string;
  regulationName: string;
  type: string;
  nickname: string;
  inputCountry: string;
  inputState: string;
  standardCountry: string;
  standardState: string;
}
