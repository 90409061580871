import { Component, OnInit, ViewChild } from "@angular/core";
import { FormlyFieldConfig } from "@ngx-formly/core";
import {
  APIService,
  NotificationService,
  DraggableTableComponent,
  ActionBarItem,
  ModalComponent,
  FormComponent,
} from "@fourcomply-dashboard/shared";
import { Router, ActivatedRoute } from "@angular/router";
@Component({
  selector: "fourcomply-dashboard-add-geo-map",
  templateUrl: "./add-geo-map.component.html",
  styleUrls: ["./add-geo-map.component.scss"],
})
export class AddGeoMapComponent implements OnInit {
  @ViewChild("regulationForm", { static: true }) regulationForm: FormComponent;

  fields: FormlyFieldConfig[] = [];
  formSubmitted = false;
  model: any = {};
  regulations: any[];

  constructor(
    public api: APIService,
    public router: Router,
    private notification: NotificationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.submitGeoMapForm = this.submitGeoMapForm.bind(this);
    this.initGeoMapForm();
    this.fetchGeoRegulationMaps();
  }

  initGeoMapForm() {
    const id = this.route.snapshot.params["id"];
    if (id) {
      this.api.geoRegulationMaps.get(id).subscribe((a) => {
        //this.actualData = a;
        this.model = { ...a };
        this.regulationForm.updateModel(this.model);
      });
    } else {
      this.model = {};
    }

    this.fields = [];

    this.api.regulations.list().subscribe((data) => {
      console.log(data);
      this.regulations = data;
      let options = data.map((a) => {
        return {
          id: a.id,
          value: a.name,
          name: a.name,
        };
      });
      this.fields = [
        {
          key: "inputState",
          type: "input",
          defaultValue: "",
          templateOptions: {
            label: "ADD_GEO_MAP.INPUT_STATE_FIELD",
          },
        },
        {
          key: "standardState",
          type: "input",
          defaultValue: "",
          templateOptions: {
            label: "ADD_GEO_MAP.STANDARD_STATE_FIELD",
          },
        },
        {
          key: "inputCountry",
          type: "input",
          defaultValue: "",
          templateOptions: {
            label: "ADD_GEO_MAP.INPUT_COUNTRY_FIELD",
          },
        },
        {
          key: "standardCountry",
          type: "input",
          defaultValue: "",
          templateOptions: {
            label: "ADD_GEO_MAP.STANDARD_COUNTRY_FIELD",
          },
        },
        {
          key: "nickname",
          type: "input",
          templateOptions: {
            required: true,
            label: "ADD_GEO_MAP.NICKNAME",
          },
        },
        {
          key: "regulationId",
          type: "select",
          templateOptions: {
            options,
            label: "BASIC_ENTITIES_SINGULAR.REGULATION",
            valueProp: "id",
            labelProp: "value",
            required: true,
            placeholder: "GENERIC.SELECT_ONE",
          },
        },
      ];
    });
  }

  submitGeoMapForm() {
    this.formSubmitted = true;
    this.regulations.forEach((val) => {
      if (val.id == this.model.regulationId) {
        this.model.regulationName = val.name;
      }
    });
    if (this.model.id) {
      this.api.geoRegulationMaps.update(this.model).subscribe(
        (a) => {
          this.notification.success("GENERIC.SUCCESSFUL_DATA_UPDATE");
          this.router.navigate(["geo"]);
        },
        (err) => {
          this.formSubmitted = false;
          this.regulationForm.loadingIndicator = false;
        }
      );
    } else {
      var validgeoRegMap = true;
      var geoExceptionReason = "";
      this.dataGeoRegulationMaps.forEach((element) => {
        if (
          element.standard_country == this.model.standard_country &&
          element.input_country == this.model.input_country
        ) {
          if (element.input_state && element.standard_state) {
          } else if (!element.input_state && !element.standard_state) {
          } else {
            validgeoRegMap = false;
            geoExceptionReason = "ADD_GEO_MAP.DUPLICATE_ERROR";
          }
        }

        if (
          this.model.standardState != null &&
          this.model.standardState != ""
        ) {
          if (
            this.model.standardState == element.standardState &&
            this.model.nickname != element.nickname
          ) {
            validgeoRegMap = false;
            geoExceptionReason = "ADD_GEO_MAP.NICKNAME_EXISTING_MATCH_STATE";
          }
        }

        if (
          !this.model.standardState &&
          this.model.standardCountry != null &&
          this.model.standardCountry != ""
        ) {
          if (
            this.model.standardCountry == element.standardCountry &&
            this.model.nickname != element.nickname
          ) {
            validgeoRegMap = false;
            geoExceptionReason = "ADD_GEO_MAP.NICKNAME_EXISTING_MATCH_COUNTRY";
          }
        }
      });

      if (validgeoRegMap) {
        console.log(this.model);
        this.api.geoRegulationMaps.create(this.model).subscribe(
          (a) => {
            this.notification.success("GENERIC.SUCCESSFUL_DATA_ADD");
            this.router.navigate(["geo"]);
          },
          (err) => {
            this.formSubmitted = false;
            this.regulationForm.loadingIndicator = false;
          }
        );
      } else {
        this.notification.error(geoExceptionReason);
        this.formSubmitted = false;
        this.regulationForm.loadingIndicator = false;
      }
    }
  }

  dataGeoRegulationMaps: any[];

  fetchGeoRegulationMaps() {
    this.api.geoRegulationMaps.list().subscribe((a) => {
      this.dataGeoRegulationMaps = a;
    });
  }
}
