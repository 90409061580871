import { Resource } from "../resource/resource.model";

/**
 * Users WITHIN a tenant in 4Comply.  This will be used in both applications
 */

export class AdminUser extends Resource {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  createdAt: string;
  permissions: Array<any>; // Once we have the permissions model, make this better
}
