import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Error } from '../../models/error.interface';

@Injectable()
export class CustomErrorHandlerService {
  constructor() { }


  tryParseError(error: HttpErrorResponse): any {
    try {
      const response = new Error(error.status, error.statusText, error.message);
      this.showErrorMessage(response);

      return response;
    } catch (ex) {

      const response = new Error(400, "Unknown Error", "Unknow error occured");
      return response;
    }
  }

  showErrorMessage(error: Error){
    //implement notification plugin
  }
}