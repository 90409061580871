<div class="container" [style.display]="outlet.isActivated ? 'none' : ''">
  <div class="column col-12">
    <app-generic-card>
      <app-actionbar
        title="{{ 'BASIC_ENTITIES_PLURAL.REGULATIONS' | translate }}"
        [actionBarItems]="actionBarItemsRegulations"
      >
      </app-actionbar>
      <app-datatable
        #dtClientRegulations
        [enableExternalSorting]="false"
        tableIdentifier="regulationsused"
        [data]="dataClientRegulations"
        [columns]="columnsClientRegulations"
        [actions]="actionsClientRegulations"
        (callbackOnUpdate)="updateRegulation($event)"
      ></app-datatable>
    </app-generic-card>
    <div style="margin-top: 20px;">
      <app-generic-card>
        <app-actionbar
          subtitle="{{ 'CLIENT_REGULATIONS.NO_GEO_REG' | translate }}"
          title="{{ 'BASIC_ENTITIES_PLURAL.REGULATIONS_UNUSED' | translate }}"
          [actionBarItems]="actionBarItemsRegulationsUnused"
        >
        </app-actionbar>
        <app-datatable
          #dtClientRegulationsUnused
          [enableExternalSorting]="false"
          tableIdentifier="regulationsunused"
          [data]="dataClientRegulationsUnused"
          [columns]="columnsClientRegulationsUnused"
          [actions]="actionsClientRegulationsUnused"
          (callbackOnUpdate)="updateRegulation($event)"
        ></app-datatable>
      </app-generic-card>
    </div>
    <div style="margin-top:20px;">
      <app-generic-card>
        <app-actionbar
          title="{{
            'BASIC_ENTITIES_PLURAL.MASTER_PERMISSION_TYPES' | translate
          }}"
          [actionBarItems]="actionBarItemsMasterPermission"
        >
        </app-actionbar>
        <app-datatable
          #dtClientPermissionTypes
          [enableExternalSorting]="false"
          tableIdentifier="permissionTypes"
          [data]="explicitPermissionTypes"
          [actions]="actionsPermissionTypes"
          [columns]="explicitPermissionTypeColumns"
        ></app-datatable>
      </app-generic-card>
    </div>
    <div style="margin-top:20px;">
      <app-generic-card>
        <app-actionbar
          title="{{
            'BASIC_ENTITIES_PLURAL.PERMISSION_CATEGORIES' | translate
          }}"
          [actionBarItems]="actionBarItemsPermission"
        ></app-actionbar>
        <app-datatable
          #dtClientPermissionCategories
          [data]="dataPermissionCategories"
          [enableExternalSorting]="false"
          tableIdentifier="permissionCategories"
          [actions]="actionsPermissionCategories"
          [columns]="columnsPermissionCategories"
          (callbackOnUpdate)="editCategory($event)"
        ></app-datatable>
      </app-generic-card>
    </div>
  </div>
</div>
<router-outlet #outlet="outlet"></router-outlet>

<app-modal
  #modalCategoryEdit
  (closer)="closeAddEditModal()"
  title="BASIC_ENTITIES_SINGULAR.PERMISSION_CATEGORY"
>
  <form #f="ngForm" (ngSubmit)="submitPermissionCategory()">
    <div class="purposes-edit-section">
      <div class="form-group">
        <mat-form-field>
          <input
            matInput
            name="type"
            placeholder="{{
              'BASIC_ENTITIES_SINGULAR.PERMISSION_CATEGORY' | translate
            }}"
            [(ngModel)]="toEditCategory"
            required
          />
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <select
            matInput
            matNativeControl
            name="compliance_input_type"
            placeholder="{{
              'BASIC_ENTITIES_SINGULAR.COMPLIANCE_INPUT_TYPE' | translate
            }}"
            [(ngModel)]="toEditCategoryType"
            required
          >
            <option [ngValue]="null"></option>

            <option
              *ngFor="let cit of complianceInputTypes"
              value="{{ cit.name }}"
              >{{ cit.name }}</option
            >
          </select>
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group main-submit">
        <button type="submit" class="btn btn-primary">
          {{ "GENERIC.SAVE" | translate }}
        </button>
      </div>
    </div>
  </form>
</app-modal>

<app-modal
  #modalAddRegulation
  (closer)="closeAddEditModal()"
  title="CLIENT_REGULATIONS.ADD_REGULATION"
>
  <form #f="ngForm" (ngSubmit)="submitRegulation()">
    <div class="purposes-edit-section">
      <div class="form-group">
        <mat-form-field>
          <input
            matInput
            name="regulationName"
            placeholder="{{ 'BASIC_ENTITIES_SINGULAR.REGULATION' | translate }}"
            [(ngModel)]="toEditRegulationName"
            required
          />
          <mat-error>{{
            "GENERIC.REQUIRED" | translate
          }}</mat-error> </mat-form-field
        ><br />
        <mat-form-field>
          <input
            matInput
            name="notes"
            placeholder="{{ 'GENERIC.NOTES' | translate }}"
            [(ngModel)]="toEditRegulationNotes"
            required
          />
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group main-submit">
        <button type="submit" class="btn btn-primary">
          {{ "GENERIC.SAVE" | translate }}
        </button>
      </div>
    </div>
  </form>
</app-modal>

<app-modal
  #modalDuplicateRegulation
  (closer)="closeDuplicateRegModal()"
  title="CLIENT_REGULATIONS.ADD_REGULATION"
>
  <div style="margin-bottom: 20px; font-size:17px;">
    {{ "GENERIC.DUPLICATE" | translate }}
    {{ toEditRegulationSourceDuplicateName }}
  </div>
  <form #f="ngForm" (ngSubmit)="duplicateReg()">
    <div class="purposes-edit-section">
      <div class="form-group">
        <mat-form-field>
          <input
            matInput
            name="regulationName"
            placeholder="{{ 'BASIC_ENTITIES_SINGULAR.REGULATION' | translate }}"
            [(ngModel)]="toEditRegulationName"
            required
          />
          <mat-error>{{
            "GENERIC.REQUIRED" | translate
          }}</mat-error> </mat-form-field
        ><br />
        <mat-form-field>
          <input
            matInput
            name="notes"
            placeholder="{{ 'GENERIC.NOTES' | translate }}"
            [(ngModel)]="toEditRegulationNotes"
            required
          />
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group main-submit">
        <button type="submit" class="btn btn-primary">
          {{ "GENERIC.SAVE" | translate }}
        </button>
      </div>
    </div>
  </form>
</app-modal>

<app-modal
  #modalMasterPermissionEdit
  (closer)="closeAddEditModal()"
  title="BASIC_ENTITIES_PLURAL.MASTER_PERMISSION_TYPES"
>
  <form #f="ngForm" (ngSubmit)="submitMasterPermission()">
    <div class="purposes-edit-section">
      <div class="form-group">
        <mat-form-field>
          <input
            matInput
            name="name"
            placeholder="{{ 'GENERIC.NAME' | translate }}"
            [(ngModel)]="toEditMasterPermissionName"
            required
          />
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <select
            matNativeControl
            required
            matInput
            name="default_permission_value"
            placeholder="{{
              'BASIC_ENTITIES_SINGULAR.DEFAULT_PERMISSION_VALUE' | translate
            }}"
            [(ngModel)]="toEditMasterPermissionDefault"
            required
          >
            <option value="yes">{{ "GENERIC.YES" | translate }}</option>
            <option value="no">{{ "GENERIC.NO" | translate }}</option>
          </select>
          <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <select
            matInput
            matNativeControl
            name="businesUnit"
            placeholder="{{
              'BASIC_ENTITIES_SINGULAR.BUSINESS_UNIT' | translate
            }}"
            [(ngModel)]="toEditMasterPermissionBusinessUnit"
          >
            <option [ngValue]="null"></option>

            <option *ngFor="let bu of businessUnits" value="{{ bu.name }}">{{
              bu.name
            }}</option>
          </select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <input
            matInput
            name="description"
            placeholder="{{ 'GENERIC.DESCRIPTION' | translate }}"
            [(ngModel)]="toEditMasterPermissionDescription"
          />
        </mat-form-field>
      </div>
      <div class="form-group main-submit">
        <button type="submit" class="btn btn-primary">
          {{ "GENERIC.SAVE" | translate }}
        </button>
      </div>
    </div>
  </form>
</app-modal>
