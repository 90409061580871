import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { SideNavItem } from '../../models/sidenav-item.interface';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})

export class SideNavComponent implements OnInit {
    navItemsThis: SideNavItem[];

    @Input() navItems: SideNavItem[];
    map: Map<string, SideNavItem[]>;

    ngOnInit() {
        this.navItemsThis = this.navItems;

        this.map = new Map<string, SideNavItem[]>();     

        console.log(this.navItems);

        let children = new Array<SideNavItem>();
        let lastDivider : string;
  
        this.navItemsThis.forEach((item, index) => {
          
          if(lastDivider && ((item.divider && item.divider != lastDivider) || index == this.navItemsThis.length-1))
          {
              this.map.set(lastDivider, children);
              children = new Array<SideNavItem>();
          }
  
          if(item.divider){
            lastDivider = item.divider;
          }else{
            children.push(item)
          }
        });
  
        console.log(this.map);
        //console.log(Array.from(this.map.keys()).length);

        if(Array.from(this.map.keys()).length == 0){
          this.map = null;
        }
        //console.log(this.map);
    }

    unOrderedPipe(){
      return 0;
    }
}
