<div style="padding-top: 25px" class="container">
  <div class="column col-12 regulation-selection">
    <h5>{{ "SIDEMENU.REGULATIONS" | translate }}</h5>
    <ul>
      <li
        *ngFor="let regulation of regulations; index as i"
        [ngClass]="{ 'active-regulation': regulation.isActive }"
        (click)="changeRegulation(i)"
      >
        {{ regulation.name }}
      </li>
    </ul>
    <span *ngIf="request > 0"><i class="fas fa-spinner fa-pulse"></i></span>
  </div>
  <div class="column col-12 rights-section">
    <div class="columns">
      <div class="column col-2">
        <app-generic-card class="single-summary green-bg">
          <label>{{ "RIGHTS.RTA" | translate }}</label>
          <h2>{{ rtaTotal === 0 ? "N/A" : rtaTotal }}</h2>
        </app-generic-card>
      </div>
      <div class="column col-4">
        <app-generic-card class="charts-section">
          <label class="chart-labels"
            >{{ "RIGHTS_DASHBOARD.CONTACTS_REVIEWED" | translate }}
            {{ selectedRegulationName }}
            {{ "RIGHTS_DASHBOARD.DATA_LAST_YEAR" | translate }}</label
          >
          <canvas
            baseChart
            [datasets]="chartRTRData"
            [labels]="chartRTRLabel"
            [options]="chartOptions"
            [legend]="chartLegend"
            [chartType]="chartType"
            [colors]="chartRTRColors"
          ></canvas>
        </app-generic-card>
      </div>

      <div class="column col-2">
        <app-generic-card class="single-summary blue-bg">
          <label>{{ "RIGHTS.RTU" | translate }}</label>
          <h2>{{ rtuTotal === 0 ? "N/A" : rtuTotal }}</h2>
        </app-generic-card>
      </div>
      <div class="column col-4">
        <app-generic-card class="charts-section">
          <label class="chart-labels"
            >{{ "RIGHTS_DASHBOARD.CONTACTS_UPDATED" | translate }}
            {{ selectedRegulationName }}
            {{ "RIGHTS_DASHBOARD.DATA_LAST_YEAR" | translate }}</label
          >
          <canvas
            baseChart
            [datasets]="chartRTUData"
            [labels]="chartRTULabel"
            [options]="chartOptions"
            [legend]="chartLegend"
            [chartType]="chartType"
            [colors]="chartRTUColors"
          ></canvas>
        </app-generic-card>
      </div>

      <div class="column col-2">
        <app-generic-card class="single-summary yellow-bg">
          <label>{{ "RIGHTS.RTP" | translate }}</label>
          <h2>{{ rtpTotal === 0 ? "N/A" : rtpTotal }}</h2>
        </app-generic-card>
      </div>
      <div class="column col-4">
        <app-generic-card class="charts-section">
          <label class="chart-labels"
            >{{ "RIGHTS_DASHBOARD.CONTACTS_DOWNLOADED" | translate }}
            {{ selectedRegulationName }}
            {{ "RIGHTS_DASHBOARD.DATA_LAST_YEAR" | translate }}</label
          >
          <canvas
            baseChart
            [datasets]="chartRTPData"
            [labels]="chartRTPLabel"
            [options]="chartOptions"
            [legend]="chartLegend"
            [chartType]="chartType"
            [colors]="chartRTPColors"
          ></canvas>
        </app-generic-card>
      </div>

      <div class="column col-2">
        <app-generic-card class="single-summary red-bg">
          <label>{{ "RIGHTS.RTBF" | translate }}</label>
          <h2>{{ rtbfTotal === 0 ? "N/A" : rtbfTotal }}</h2>
        </app-generic-card>
      </div>
      <div class="column col-4">
        <app-generic-card class="charts-section">
          <label class="chart-labels"
            >{{ "RIGHTS_DASHBOARD.CONTACTS_REQUESTED_FORGOTTEN" | translate }}
            {{ "RIGHTS_DASHBOARD.LAST_YEAR" | translate }}</label
          >
          <canvas
            baseChart
            [datasets]="chartRTBFData"
            [labels]="chartRTBFLabel"
            [options]="chartOptions"
            [legend]="chartLegend"
            [chartType]="chartType"
            [colors]="chartRTBFColors"
          ></canvas>
        </app-generic-card>
      </div>
    </div>
  </div>
</div>
