import { BaseApiService } from "../base/base-api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from "../custom-error-handler.service";
import { Regulation } from "../../../models/regulation/regulation.model";
import { RegulationSerializer } from "../../../models/regulation/regulation-serializer.class";

/**
 * Service to communicate with API Client Users
 */

@Injectable()
export class RegulationsService extends BaseApiService<Regulation> {
  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      null,
      "regulations",
      new RegulationSerializer(),
      new CustomErrorHandlerService()
    );
  }

  // EXTEND/OVERRIDE THE SERVICE HERE IF NECESSARY
}
