import { Component, OnInit } from "@angular/core";
import {
  NotificationService,
  APIService,
  AuthService,
} from "@fourcomply-dashboard/shared";
import { Router, ActivatedRoute } from "@angular/router";
import { BreadcrumbService } from "xng-breadcrumb";
import { pipe } from "rxjs";
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: "app-edit-client-landing-page-template",
  templateUrl: "./edit-client-landing-page-template.component.html",
  styleUrls: ["./edit-client-landing-page-template.component.scss"],
})
export class EditClientLandingPageTemplateComponent implements OnInit {
  // public ckeditor = ClassicEditor;
  formSubmitted = false;
  model: any = {};
  userid: string;

  constructor(
    public api: APIService,
    public router: Router,
    private auth: AuthService,
    private route: ActivatedRoute,
    private notification: NotificationService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this.submitClientLandingPageTemplate = this.submitClientLandingPageTemplate.bind(
      this
    );
    this.initClientLandingPageTemplateForm();

    this.auth.userProfile$.subscribe(
      pipe((res: any) => {
        this.userid = res.currentTenant.tenant_id;
        this.initClientLandingPageTemplateForm();
      })
    );
  }

  initClientLandingPageTemplateForm() {
    const templateType = this.route.snapshot.params["type"];
    this.api.clients.getLandingPages(this.userid).subscribe((a) => {
      console.log(templateType, a);
      this.model = a.data?.pages?.find((x) => x.type === templateType);
      this.breadcrumbService.set("@emailType", this.model.type);
    });
  }

  close() {
    this.router.navigate(["/landing-pages"]);
    //this.router.navigate(["settings"]);
  }

  submitClientLandingPageTemplate() {
    this.formSubmitted = true;
    if (this.model.template.length > 0) {
      if (this.model.id) {
        this.api.clients.updateLandingPage(this.model).subscribe(
          (a) => {
            this.formSubmitted = false;
            this.notification.success(
              "ADD_EMAIL_TEMPLATE.SUCCESSFUL_UPDATE_TEMPLATE"
            );
            this.router.navigate(["/landing-pages"]);
            //this.router.navigate(["settings"]);
          },
          (err) => {
            this.formSubmitted = false;
          }
        );
      } else {
        this.api.clients.createLandingPage(this.model).subscribe(
          (a) => {
            this.formSubmitted = false;
            this.notification.success(
              "ADD_EMAIL_TEMPLATE.SUCCESSFUL_UPDATE_TEMPLATE"
            );
            this.router.navigate(["/landing-pages"]);
            //this.router.navigate(["settings"]);
          },
          (err) => {
            this.formSubmitted = false;
          }
        );
      }
    } else {
      this.formSubmitted = false;
      this.notification.error("ADD_EMAIL_TEMPLATE.EMPTY_ERROR");
    }
  }
}
