import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
  } from "@angular/core";
  import { DatalistActionItem } from "../../models/datalist-action-item.interface";
  import { Router } from "@angular/router";
  import { DatatableComponent, SortDirection } from "@swimlane/ngx-datatable";
  
  @Component({
    selector: "app-draggable-table-regulations",
    templateUrl: "./draggable-table-regulations.component.html",
    styleUrls: ["./draggable-table-regulations.component.scss"],
  })
  export class DraggableTableRegulationsComponent implements OnInit {
    rows = [];
    public columnList = [];
    actionItems: DatalistActionItem[];
    loadingIndicator = true;
    isEditable = {};
    editingItemCount = 0;
    editedItemOriginalData: any[] = [];
    showTable: boolean = true;
    @ViewChild("datatable")
    public datatable: DatatableComponent;
    @Input()
    columns: [];
    @Input() data: [];
    @Input() sorts: any;
    @Input() actions: DatalistActionItem[];
    @Output() callbackOnDrop: EventEmitter<any> = new EventEmitter<any>();
    @Output() callbackOnUpdate: EventEmitter<any> = new EventEmitter<any>();
    @Output() callbackEmit = new EventEmitter();
    @Output() sortEvent = new EventEmitter();
  
    constructor(public router: Router) {}
  
    loaderVisibility(value: boolean) {
      this.loadingIndicator = value;
    }
  
    
    reset() {
      this.showTable = false;
      setTimeout(() => {
        this.showTable = true;
      });
    }
  
    onColumnSort(a: any) {
      console.log(a);
      if (!a.sorts || !a.sorts.length) {
        return;
      }
      a.sorts.forEach((b) => {
        if (b.dir.toLowerCase() === "asc") {
          b.dir = SortDirection.asc;
        } else {
          b.dir = SortDirection.desc;
        }
      });
      if (!this.datatable) {
        return;
      }
      this.datatable.onColumnSort(a);
    }
  
    onEdit(index: number): void {
      this.isEditable[index] = true;
      this.editingItemCount++;
      this.editedItemOriginalData[index] = { ...this.rows[index] };
    }
  
    onCancelEdit(index: number): void {
      this.isEditable[index] = false;
      this.editingItemCount--;
      this.rows[index] = this.editedItemOriginalData[index];
      this.rows = [...this.rows];
    }
  
    onSort(event) {
      console.log(event);
      this.sortEvent.emit(event);
    }
  
    updateRows(data) {
      this.rows = data;
    }
  
    onDrop(event) {
      //this.rows = event;
      this.callbackOnDrop.emit(event);
    }
  
    ngOnInit() {
      this.rows = this.data;
      this.columnList = this.columns;
      this.actionItems = this.actions;
    }
  
    onActionClick(item: DatalistActionItem, id) {
      if (item.route) {
        this.router.navigate([item.route]);
      } else if (item.callback) {
        this.callbackEmit.emit(item.callback(id));
      }
    }
  
    saveData(row, rowIndex) {
      this.callbackOnUpdate.emit(row);
      this.isEditable[rowIndex] = false;
    }
  }
  