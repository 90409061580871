import { Serializer } from "../resource/serializer.interface";
import { RightRequest } from "./right-request.model";

export class RightRequestSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): RightRequest {
    const rightRequest = new RightRequest();
    rightRequest.id = json.id;
    rightRequest.status = json.status;
    rightRequest.requestType = json.request_type;
    rightRequest.configId = json.config_id;
    rightRequest.error = json.error;
    rightRequest.history = json.history;
    rightRequest.executionArn = json.execution_arn;
    rightRequest.email = json.email;
    rightRequest.nextState = json.next_state;
    rightRequest.currentState = json.current_state;
    rightRequest.presignedUrls = json.presignedUrls;
    rightRequest.regulationId = json.regulation_id;
    rightRequest.regulationName = json.regulation_name;
    rightRequest.standardCountry = json.standard_country;
    rightRequest.standardState = json.standard_state;
    rightRequest.completionEntries = json.completion_entries;
    rightRequest.systemId = json.system_id;
    rightRequest.systemType = json.system_type;
    rightRequest.system = json.system;
    rightRequest.systemRecordId = json.system_record_id;
    rightRequest.systemRecordType = json.system_record_type;
    rightRequest.createdAt = new Date(json.created_at);
    rightRequest.updatedAt = new Date(json.updated_at);
    return rightRequest;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(rightRequest: RightRequest): any {
    return {
      id: rightRequest.id,
      status: rightRequest.status,
      request_type: rightRequest.requestType,
      config_id: rightRequest.configId,
      error: rightRequest.error,
      history: rightRequest.history,
      execution_arn: rightRequest.executionArn,
      email: rightRequest.email,
      next_state: rightRequest.nextState,
      current_state: rightRequest.currentState,
      presignedUrls: rightRequest.presignedUrls,
      system_id: rightRequest.systemId,
      system_type: rightRequest.systemType,
      system: rightRequest.system,
      system_record_id: rightRequest.systemRecordId,
      system_record_type: rightRequest.systemRecordType,
    };
  }
}
