import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { NgFlowchartStepComponent } from "@fourcomply-dashboard/shared";
import { FormStepService } from "./form-step.service";

@Component({
  templateUrl: "./form-step.component.html",
  styleUrls: ["./form-step.component.scss"],
})
export class FormStepComponent extends NgFlowchartStepComponent
  implements OnInit, OnChanges {
  constructor(private formStepService: FormStepService) {
    super();
    this.formStepService = formStepService;
  }

  ngOnInit() {
    console.log(this.data);
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.data, changes);
  }

  disableStep() {
    this.formStepService.stepIdSelectedForDisabling.emit(this.data.order);
  }

  editStep() {
    this.formStepService.stepIdSelectedForEditing.emit(this.data.order);
  }

  getDescription(type) {
    switch (type) {
      case "automatic":
        return "RIGHTS_REQUESTS.TYPE_AUTOMATIC_DESC";
      case "end":
        return "RIGHTS_REQUESTS.TYPE_END_DESC";
      case "hitl":
        return "RIGHTS_REQUESTS.TYPE_HITL_DESC";
      case "indeterminate":
        return "RIGHTS_REQUESTS.TYPE_INDETERMINATE_DESC";
      case "webhook":
        return "RIGHTS_REQUESTS.TYPE_WEBHOOK_DESC";
      case "regular":
        return "RIGHTS_REQUESTS.TYPE_REGULAR_DESC";
      default:
        break;
    }
  }
}
