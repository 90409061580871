import { sharedEnvironment } from "@fourcomply-dashboard/environments";

export const environment = {
  production: true,
  auth: {
    CLIENT_ID: sharedEnvironment.production.auth.CLIENT_ID,
    CLIENT_DOMAIN: sharedEnvironment.production.auth.CLIENT_DOMAIN,
    AUDIENCE: sharedEnvironment.production.auth.AUDIENCE,
    REDIRECT: sharedEnvironment.production.auth.dashboard.REDIRECT,
    LOGOUT_URL: sharedEnvironment.production.auth.dashboard.LOGOUT_URL,
    FORMS_URL: sharedEnvironment.production.auth.forms.URL
  },
  api: {
    API_ENDPOINT: sharedEnvironment.production.api.API_ENDPOINT
  }
};
