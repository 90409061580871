import {
  Component,
  OnInit,
  Input,
  ViewChild,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  AuthService,
  ClientsService,
  LocalStorageService,
  ModalComponent,
  NotificationService,
  Utilities,
} from "@fourcomply-dashboard/shared";
import { environment } from "apps/fourcomply-dashboard/src/environments/environment";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { pipe } from "rxjs";
import { APIService } from "@fourcomply-dashboard/shared";
import { TranslateService } from "@ngx-translate/core";
import { PanelRef } from "@verizon/ngx-panels";
import {
  UploadOutput,
  UploaderOptions,
  UploadFile,
  UploadInput,
  UploadStatus,
  humanizeBytes,
} from "ngx-uploader";
import * as jwt_decode from "jwt-decode";

@Component({
  selector: "workflow-detail-panel",
  templateUrl: "./workflow-detail.component.html",
})
export class WorkflowDetailPanelComponent implements OnInit {
  @ViewChild("modalUpload")
  public modalUpload: ModalComponent;

  @ViewChild("modalEndStep", { static: true })
  public modalEndStep: ModalComponent;

  @ViewChild("fakeUpload", { static: true })
  public fakeUploadInput;

  @ViewChild("fakeUpload2", { static: true })
  public fakeUploadInput2;

  public workflowInstanceId: any;
  public users: any;

  public nextState: string;
  public currentState: string;
  isChoiceStep = false;
  currentStepData: any = null;
  requestId: string;
  dataRightRequest: any;
  columnsRightRequestHistory: any;
  dataAllPossibleConfigsSteps: any[];
  selectionCompletionRequirement: string = null;
  completionRequirementMaps: any[] = [];
  completionEntryMaps: any;
  isIndeterminateStep: boolean;
  presignedUrls: any[] = [];
  uploadedData: any[] = [];
  options: UploaderOptions;
  public requestData: any;
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  uploadFileStatus: string = "";
  headers: any;
  refresher: any;

  constructor(
    public api: APIService,
    private cd: ChangeDetectorRef,
    public translate: TranslateService,
    private readonly panelRef: PanelRef<any>,
    private auth: AuthService,
    private notification: NotificationService
  ) {
    this.options = { concurrency: 1, maxUploads: 100 };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    if (this.panelRef && this.panelRef.data) {
      this.workflowInstanceId = this.panelRef.data.workflowInstanceId;
      this.requestId = this.workflowInstanceId;
      this.fetchRightRequest();
      this.confirmEndStep = this.confirmEndStep.bind(this);
      this.columnsRightRequestHistory = [
        { name: "RIGHTS_DETAIL_CONFIG.STEP_NAME", prop: "step_name" },
        { name: "RIGHTS_DETAIL_CONFIG.TIMESTAMP", prop: "timestamp" },
        { name: "RIGHTS_DETAIL_CONFIG.OUTPUT_DATA", prop: "output_data" },
      ];
      this.fetchRightRequest();
      //this.startAutoRefresh();
      this.auth.token$.subscribe((token) => {
        const decodedToken = jwt_decode(token);
        let tenantId =
          decodedToken["https://" + environment.auth.AUDIENCE + "/tenant_id"] ||
          "";
        if (!tenantId) {
          for (const [key, value] of Object.entries(decodedToken)) {
            if (key.includes("tenant.name")) {
              tenantId = key.split("tenant.name/")[1];
            }
          }
        }
        this.headers = {
          Authorization: `Bearer ${token}`,
          tenant_id: tenantId,
        };
      });
    }
  }

  closeUploadModal() {
    this.modalUpload.closeModal();
    this.uploadFileStatus = "";
    this.files = [];
    this.ngOnInit();
  }

  isLastStepLeftCompletedOrFailed() {
    // Get Slice
    let final2 = this.dataAllPossibleConfigsSteps.slice(
      this.dataAllPossibleConfigsSteps.length - 2,
      this.dataAllPossibleConfigsSteps.length
    );
    if (
      (final2[0] && (final2[0].isCompleted || final2[0].isFailedStep)) ||
      (final2[1] && (final2[1].isCompleted || final2[1].isFailedStep))
    ) {
      if (this.dataAllPossibleConfigsSteps.length % 2 === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isLastStepRightCompletedOrFailed() {
    // Get Slice
    let final2 = this.dataAllPossibleConfigsSteps.slice(
      this.dataAllPossibleConfigsSteps.length - 2,
      this.dataAllPossibleConfigsSteps.length
    );
    if (
      (final2[0] && (final2[0].isCompleted || final2[0].isFailedStep)) ||
      (final2[1] && (final2[1].isCompleted || final2[1].isFailedStep))
    ) {
      if (this.dataAllPossibleConfigsSteps.length % 2 !== 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  onSelectionChange(deviceValue) {
    console.log(deviceValue);
    this.selectionCompletionRequirement = deviceValue;
  }

  openEndStepModal() {
    this.modalEndStep.openModal();
  }

  openUploadModal(): void {
    this.modalUpload.openModal();
  }

  onUploadOutput(output: UploadOutput): void {
    console.log(this.selectionCompletionRequirement);
    if (output.type === "allAddedToQueue") {
      let url = this.api.rightRequests.getUploadFileUrl(
        this.requestId,
        this.currentState,
        this.selectionCompletionRequirement
      );
      console.log(url, this.headers);
      const event: UploadInput = {
        type: "uploadAll",
        url: url,
        headers: this.headers,
        method: "PUT",
      };
      this.uploadFileStatus = "uploading";
      this.uploadInput.emit(event);
    } else if (
      output.type === "addedToQueue" &&
      typeof output.file !== "undefined"
    ) {
      this.files.push(output.file);
    } else if (
      output.type === "uploading" &&
      typeof output.file !== "undefined"
    ) {
      const index = this.files.findIndex(
        (file) =>
          typeof output.file !== "undefined" && file.id === output.file.id
      );
      this.files[index] = output.file;
    } else if (output.type === "cancelled" || output.type === "removed") {
      this.files = this.files.filter(
        (file: UploadFile) => file !== output.file
      );
    } else if (output.type === "dragOver") {
      this.uploadFileStatus = "dragOver";
    } else if (output.type === "dragOut") {
      this.uploadFileStatus = "dragOver";
    } else if (output.type === "drop") {
      this.uploadFileStatus = "dragOver";
    } else if (output.type == "removedAll") {
      this.uploadFileStatus = "";
      this.files = [];
      this.formData = null;
      this.closeUploadModal();
    } else if (
      output.type === "rejected" &&
      typeof output.file !== "undefined"
    ) {
      //console.log(output.file.name + " rejected");
    } else if (output.type === "done") {
      this.notification.success("RIGHTS_REQUESTS.SUCCESSFUL_UPLOAD");
      this.uploadInput.emit({ type: "removeAll" });
      this.fetchRightRequest();
    }
  }

  isRightBeforeChoice(index) {
    let steps = this.dataAllPossibleConfigsSteps;
    let choiceIndexes = [];
    steps.forEach((s, ind) => {
      if (s.type == "choice") {
        choiceIndexes.push(ind);
      }
    });
    if (choiceIndexes.includes(index + 1)) {
      return true;
    } else {
      return false;
    }
  }

  confirmEndStep(): void {
    this.modalEndStep.closeModal();
    this.api.rightRequests
      .endCurrentStep(this.requestId, this.nextState)
      .subscribe((a) => {
        this.notification.success(
          "RIGHTS_REQUESTS.STEP_INDETERMINATE_ENDED_SUCCESS"
        );
        this.isIndeterminateStep = false;
        this.fetchRightRequest();
      });
  }

  formatCompletionEntries() {
    if (!this.requestData || !this.requestData.completionEntries) return [];
    if (!this.currentStepData || !this.currentStepData.config)
      return this.requestData.completionEntries;
    let returner = this.requestData.completionEntries.map((e) => {
      this.completionRequirementMaps[0].forEach((c) => {
        if (
          e.workflow_step_completion_config_id ==
          c.workflow_step_completion_config_id
        ) {
          e.config = c;
        }
      });
      return {
        ...e,
        updated_at: Utilities.printShortDate(new Date(e.updated_at)),
      };
    });
    console.log(returner);
    return returner;
  }

  getDescription(type) {
    switch (type) {
      case "automatic":
        return "RIGHTS_REQUESTS.TYPE_AUTOMATIC_DESC";
      case "end":
        return "RIGHTS_REQUESTS.TYPE_END_DESC";
      case "hitl":
        return "RIGHTS_REQUESTS.TYPE_HITL_DESC";
      case "indeterminate":
        return "RIGHTS_REQUESTS.TYPE_INDETERMINATE_DESC";
      case "webhook":
        return "RIGHTS_REQUESTS.TYPE_WEBHOOK_DESC";
      case "regular":
        return "RIGHTS_REQUESTS.TYPE_REGULAR_DESC";
      default:
        break;
    }
  }

  fetchRightRequest() {
    this.api.rightRequests
      .get(this.workflowInstanceId)
      .subscribe((data: any) => {
        this.requestData = data;
        console.log(this.requestData);
        this.nextState = data.nextState;
        this.currentState = data.currentState;
        data.parsedHistory = data.history; //JSON.parse(data.history);
        this.presignedUrls =
          data.presignedUrls &&
          data.presignedUrls.map((url) => {
            let urlArray = url.name.split("#"); // split step name
            let stepName = urlArray[0];
            let fileName = urlArray[1];
            return {
              stepName,
              fileName,
              ...url,
            };
          });
        // This should compare to the regulation name in regulation table, do not assume GDPRc
        this.api.rightRequestConfigs.getAllPossible().subscribe((a) => {
          let allSteps = a
            .filter(
              (a) => a.name === "GDPR" && a.requestType === data.requestType
            )[0]
            .steps.filter((s: any) => {
              return (
                s.type === "indeterminate" ||
                s.type === "regular" ||
                s.type === "automatic" ||
                s.type === "hitl" ||
                s.type == "fulfillment"
              );
            });
          this.dataAllPossibleConfigsSteps = allSteps;
          this.api.rightRequestConfigs
            .get(data.configId)
            .subscribe((currentConfigData: any) => {
              this.uploadedData = [];
              this.dataAllPossibleConfigsSteps
                .filter((s: any) => {
                  return (
                    s.type === "indeterminate" ||
                    s.type === "regular" ||
                    s.type === "automatic" ||
                    s.type === "hitl" ||
                    s.type == "fulfillment"
                  );
                })
                .forEach((step: any) => {
                  step.isCompleted = false;
                  step.message = "RIGHTS_REQUESTS.STEP_PENDING";
                  if (step.type == "indeterminate") {
                    console.log(step.config.completion_requirements);
                    this.completionRequirementMaps.push(
                      currentConfigData.steps.find(
                        (s) => s.identifier === step.identifier
                      ).config.completion_requirements
                    );
                  }
                  // Check if it's the current state
                  if (step.identifier === this.currentState) {
                    step.isCurrentStep = true;
                    this.currentStepData = currentConfigData.steps.find(
                      (s) => s.identifier === step.identifier
                    );
                  }
                  // Check if indeterminate
                  if (
                    step.identifier === data.currentState &&
                    step.type === "indeterminate"
                  ) {
                    this.isIndeterminateStep = true;
                  } else if (step.identifier === data.currentState) {
                    this.isIndeterminateStep = false;
                  }
                  if (step.type === "choice") {
                    step.isChoiceStep = true;
                    if (
                      data.currentState.indexOf("Completed") != -1 ||
                      data.currentState.indexOf("Failed") != -1
                    ) {
                      step.isCompleted = true;
                      step.message = "RIGHTS_REQUESTS.STEP_COMPLETED";
                    }
                  }
                  if (data.parsedHistory) {
                    // Check History
                    data.parsedHistory.forEach((history) => {
                      if (history.step_name === step.identifier) {
                        if (history.error) {
                          step.isFailedStep = true;
                        } else {
                          step.isCompleted = true;
                        }
                        step.isCurrentStep = false;
                        if (history.output_data) {
                          step.message = history.output_data.message;
                          if (history.output_data.data) {
                            step.data = history.output_data.data;
                            step.parsedData = JSON.parse(step.data);
                            this.uploadedData.push({
                              step: history.step_name,
                              data: step.data,
                              parsedData: step.parsedData,
                              keys: Object.keys(step.parsedData),
                            });
                          }
                        }
                      }
                    });
                  }
                });
              this.cd.detectChanges();
            });
        });
      });
  }
}
