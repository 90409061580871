import { Serializer } from "../resource/serializer.interface";
import { BusinessUnit } from "./business-unit.model";

export class BusinessUnitSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): BusinessUnit {
    const businessUnit = new BusinessUnit();
    businessUnit.id = json.id;
    businessUnit.name = json.name;
    businessUnit.tenantId = json.tenant_id;
    businessUnit.createdAt = json.create_at;
    businessUnit.updatedAt = json.updated_at;
    return businessUnit;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(businessUnit: BusinessUnit): any {
    return {
      id: businessUnit.id,
      name: businessUnit.name,
      create_at: businessUnit.createdAt,
      update_at: businessUnit.updatedAt,
      tenant_id: businessUnit.tenantId
    };
  }
}
