import { BaseApiService } from "../base/base-api.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomErrorHandlerService } from "../custom-error-handler.service";
import { ComplianceInput } from "../../../models/compliance-input/compliance-input.model";
import { ComplianceInputSerializer } from "../../../models/compliance-input/compliance-input.serializer.class";
import { ConsentSummaryByCountrySerializer } from "../../../models/consent-summary-by-country/consent-summary-by-country-serializer.class";
import { ConsentSummaryByCountry } from "../../../models/consent-summary-by-country/consent-summary-by-country.model";
import { Observable, throwError } from "rxjs";
import { map, tap, catchError } from "rxjs/operators";
/**
 * Service to communicate with API Client Users
 */

@Injectable()
export class ComplianceInputService extends BaseApiService<ComplianceInput> {
  consentSummarySerializer = new ConsentSummaryByCountrySerializer();

  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      null,
      "citizens/complianceinput",
      new ComplianceInputSerializer(),
      new CustomErrorHandlerService()
    );
  }

  exportConsents(): Observable<any> {
    return this.httpClient.post<any>(
      `${this.url}/bulkrequest/citizens/complianceinput/download`,
      {}
    );
  }

  createExcelCitizenHistory(emailAddress: string): Observable<any> {
    return this.httpClient.get(
      `${this.url}/citizenhistory/excel?emailAddress=${emailAddress}`,
      {
        responseType: "blob",
        headers: {
          skip: "true",
          Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      }
    );
  }

  // EXTEND/OVERRIDE THE SERVICE HERE IF NECESSARY

  createConsentIncognito(
    consent,
    tenant_id,
    geo_type_header?,
    currentConsent?
  ): Observable<ComplianceInput> {
    // Todo make this an additional field in the form table
    let headers = {};
    if (currentConsent) {
      consent = {
        ...currentConsent,
        ...consent,
      };
      if (currentConsent.standard_state) {
        headers = {
          geo_override: currentConsent.standard_state,
          geo_type: "state",
        };
      } else {
        headers = {
          geo_override: currentConsent.standard_country,
          geo_type: "country",
        };
      }
    } else if (consent.geo_override) {
      headers = {
        geo_override: consent.geo_override,
        geo_type: geo_type_header ? geo_type_header : "country",
      };
    }
    return this.httpClient
      .post<ComplianceInput>(`${this.url}/citizens/complianceinput`, consent, {
        headers: {
          ...headers,
          tenant_id,
        },
      })
      .pipe(
        map((data) => data),
        catchError((error) => throwError(error))
      );
  }

  getConsentCountCountryWise(
    id: number,
    permissionType: string
  ): Observable<ConsentSummaryByCountry> {
    let url = `${this.url}/citizens/complianceinput/bycountry`;
    if (id !== 0) {
      url += `/${id}`;
    }
    url += permissionType ? "?permission_type=" + permissionType : "";
    return this.httpClient
      .get(url)
      .pipe(
        map(
          (data: any) =>
            this.consentSummarySerializer.fromJson(
              data
            ) as ConsentSummaryByCountry
        )
      );
  }
}
