<div class="container" [style.display]="outlet.isActivated ? 'none' : ''">
  <div class="column col-12">
    <app-generic-card>
      <app-actionbar
        title="{{ 'BASIC_ENTITIES_PLURAL.SYSTEMS' | translate }}"
        [actionBarItems]="actionBarItemsSystems"
      ></app-actionbar>
      <app-datatable
        #dtSystems
        [data]="dataSystems"
        tableIdentifier="systems"
        [columns]="columnsSystems"
        [actions]="actionsSystems"
      ></app-datatable>
    </app-generic-card>
  </div>
</div>
<router-outlet
  #outlet="outlet"
  (deactivate)="onDeactivate($event)"
></router-outlet>
<app-modal
  #modalSystems
  title="MODAL_GENERIC.CONFIRM_DELETE"
  bodyContent="{{ 'SYSTEMS.MODAL_DELETE_QUESTION' | translate }}"
  [confirmCallback]="deleteSystemsConfirm"
></app-modal>
