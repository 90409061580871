import { ResourceSQL } from "../resource-sql/resource-sql.model";

export class RightRequest extends ResourceSQL {
  status: string;
  requestType: string;
  configId: string;
  regulationId: string;
  regulationName: string;
  error: string;
  standardState: string;
  standardCountry: string;
  history: any[];
  executionArn: string;
  completionEntries: any[];
  email: string;
  nextState: string;
  currentState: string;
  presignedUrls: any[];
  createdAtAux: string;
}
