import { Component, OnInit, ViewChild, EventEmitter } from "@angular/core";
import {
  APIService,
  AuthService,
  Client,
  DatatableComponent,
  //ClientEmailTemplate,
  DatalistActionItem,
  KeyValuePair,
  NotificationService,
  ModalComponent,
} from "@fourcomply-dashboard/shared";
import { pipe } from "rxjs";
import { map, catchError, filter, mergeMap } from "rxjs/operators";
import { environment } from "apps/fourcomply-dashboard/src/environments/environment";
import { Router } from "@angular/router";
import * as jwt_decode from "jwt-decode";
import {
  UploaderOptions,
  UploadFile,
  UploadInput,
  humanizeBytes,
  UploadOutput,
} from "ngx-uploader";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  /*@ViewChild("dtEmailTemplates", { static: false })
  dtEmailTemplates: DatatableComponent;*/

  @ViewChild("modalUpdateToken", { static: true })
  public modalUpdateToken: ModalComponent;

  @ViewChild("modalUploadLogo", { static: true })
  public modalUploadLogo: ModalComponent;

  @ViewChild("modalUploadFavicon", { static: true })
  public modalUploadFavicon: ModalComponent;

  /*dataEmailTemplates: ClientEmailTemplate[];
  columnsEmailTemplates: any[];
  actionsEmailTemplates: DatalistActionItem[];*/

  userid: number;
  client: Client;
  alias: string;
  permissionUpdateLogic: string = "regular";
  domainEditMood = false;
  customFieldNames: any = {
    customFieldName1: "",
    customFieldName2: "",
    customFieldName3: "",
    customFieldName4: "",
    customFieldName5: "",
    customFieldName6: "",
    customFieldName7: "",
  };
  customDomain: string = null;
  clientName: string;
  cNameInfo: any;
  defaultEmailAddress: string;
  secondaryEmailAddresses: string;
  clientLogo: any;
  clientFavicon: any;
  uploadFileStatus: string = "";
  uploadInput: EventEmitter<UploadInput>;
  files: any = [];
  options: UploaderOptions;
  humanizeBytes: Function;
  userLevel: string;
  isNonProd: boolean =
    environment.auth.AUDIENCE.includes("dev") ||
    environment.auth.AUDIENCE.includes("staging") ||
    environment.auth.AUDIENCE.includes("demo");

  token: string;
  domainErrorMsg = "";
  constructor(
    private api: APIService,
    private auth: AuthService,
    private router: Router,
    private notification: NotificationService
  ) {
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    this.updateToken = this.updateToken.bind(this);
    //this.goToEmailTemplateEdit = this.goToEmailTemplateEdit.bind(this);
    this.options = {
      concurrency: 1,
      maxUploads: 1,
      allowedContentTypes: ["image/jpeg", "image/png"],
    };
    this.auth.userProfile$.subscribe(
      pipe((res: any) => {
        this.userid = res.currentTenant.tenant_id;
        this.getClient();
        let roles = res.currentTenant.roles;
        if (roles.includes("admin") || roles.includes("superadmin")) this.userLevel = "admin";
        else {
          this.userLevel = roles[0];
        }
        if (this.userLevel == "admin") {
          this.getToken();
        }
      })
    );

    //this.fetchEmailTemplates();
  }

  resetClient() {
    this.api.clients.resetClient(this.userid).subscribe((a) => {
      console.log("Successfully reset client");
    });
  }

  getClient() {
    this.api.clients.get(this.userid).subscribe((a) => {
      this.client = a;
      this.getLogo();
      this.getFavicon();
      console.log(a);
      if (a.configId.alias) {
        this.alias = a.configId.alias;
      }
      if (a.configId.permission_update_logic) {
        this.permissionUpdateLogic = a.configId.permission_update_logic;
      } else {
        this.permissionUpdateLogic = "regular";
      }
      if (a.configId.default_email_address) {
        this.defaultEmailAddress = a.configId.default_email_address;
      }
      if (a.configId.secondary_email_addresses) {
        this.secondaryEmailAddresses = a.configId.secondary_email_addresses;
      }
      if (a.configId.custom_field_config) {
        this.customFieldNames = {
          customFieldName1:
            a.configId.custom_field_config.custom_field_1_name || "",
          customFieldName2:
            a.configId.custom_field_config.custom_field_2_name || "",
          customFieldName3:
            a.configId.custom_field_config.custom_field_3_name || "",
          customFieldName4:
            a.configId.custom_field_config.custom_field_4_name || "",
          customFieldName5:
            a.configId.custom_field_config.custom_field_5_name || "",
          customFieldName6:
            a.configId.custom_field_config.custom_field_6_name || "",
          customFieldName7:
            a.configId.custom_field_config.custom_field_7_name || "",
        };
      }
      if (this.userLevel == "admin") {
        if (
          this.client &&
          this.client.configId &&
          this.client.configId.custom_email_domain
        )
          this.customDomain = this.client.configId.custom_email_domain;
      }
    });
  }

  getLogo() {
    this.api.clients.getLogo(this.client.id).subscribe((a: any) => {
      console.log(a);
      this.clientLogo = a.url;
    });
  }

  getFavicon() {
    this.api.clients.getFavicon(this.client.id).subscribe((a: any) => {
      console.log(a);
      this.clientFavicon = a.url;
    });
  }

  getToken() {
    this.api.clients.getToken().subscribe((a: any) => {
      this.token = a && a.value;
    });
  }

  uploadLogo(file) {
    this.api.clients.uploadLogo(this.client.id, file).subscribe((a: any) => {
      console.log(a);
    });
  }

  updateToken() {
    this.api.clients.updateToken().subscribe((a: any) => {
      this.token = a && a.value;
      this.notification.success("SETTINGS.SUCCESSFUL_UPDATE_KEY");
      this.modalUpdateToken.closeModal();
    });
  }

  updatePermissionUpdateLogic() {
    this.api.clients
      .updatePermissionUpdateLogic(this.client.id, this.permissionUpdateLogic)
      .subscribe((a) => {
        this.notification.success("GENERIC.SUCCESSFUL_DATA_UPDATE");
      });
  }

  updateDefaultEmailAddress() {
    this.api.clients
      .updateDefaultEmail(this.client.id, this.defaultEmailAddress)
      .subscribe((a) => {
        this.notification.success("GENERIC.SUCCESSFUL_DATA_UPDATE");
      });
  }

  updateSecondaryEmailAddresses() {
    this.api.clients
      .updateSecondaryEmails(this.client.id, this.secondaryEmailAddresses)
      .subscribe((a) => {
        this.notification.success("GENERIC.SUCCESSFUL_DATA_UPDATE");
      });
  }

  updateAlias() {
    this.api.clients.updateAlias(this.client.id, this.alias).subscribe(
      (a) => {
        this.notification.success("SETTINGS.SUCCESSFUL_UPDATE_ALIAS");
      },
      (e) => {
        //this.notification.error(e.message);
      }
    );
  }

  saveCustomFields() {
    this.api.clients
      .updateCustomFields(this.client.id, this.customFieldNames)
      .subscribe(
        (a) => {
          this.notification.success("SETTINGS.SUCCESSFUL_UPDATE_CUSTOM_FIELDS");
        },
        (e) => {
          //this.notification.error(e.message);
        }
      );
  }

  saveCustomDomain() {
    this.domainErrorMsg = "";
    if (!this.customDomain || this.customDomain.trim().length == 0) {
      this.domainErrorMsg = "Domain cannot be empty";
    } else if (this.customDomain.trim().length > 0) {
      this.api.clients
        .getDomainCNameInfo(this.userid, this.customDomain)
        .subscribe((a) => {
          this.cNameInfo = a;
          this.domainEditMood = false;
          this.client.configId.custom_email_domain = this.customDomain;
        });
    }
  }

  /*fetchEmailTemplates() {
    this.actionsEmailTemplates = [
      {
        iconClass: "fas fa-pen",
        toolTipText: "Customize",
        callback: this.goToEmailTemplateEdit,
      },
    ];

    this.columnsEmailTemplates = [
      { name: "Type", prop: "type", sortable: false },
      { name: "Email Subject", prop: "subject", sortable: false },
      { name: "Actions", prop: "actions", sortable: false },
    ];

    this.api.clientEmailTemplates.list().subscribe((a) => {
      this.dataEmailTemplates = a;
      this.dtEmailTemplates.updateRows(this.dataEmailTemplates);
      this.dtEmailTemplates.loaderVisibility(false);
    });
  }*/

  /*goToEmailTemplateEdit(id) {
    this.router.navigate(["settings/edit-email-template", id]);
  }*/

  onUploadOutput(output: UploadOutput, logoOrFavicon): void {
    switch (output.type) {
      case "allAddedToQueue":
        let file: UploadFile = this.files[0];
        this.uploadFileStatus = "uploading";
        let extension;
        if (!file) {
          this.uploadFileStatus = "";
          this.files = [];
          break;
        }
        if (file.type == "image/png") {
          extension = ".png";
        } else if (file.type == "image/jpeg") {
          extension = ".jpg";
        }
        if (Math.round(file.size / 1024) > 2000) {
          this.notification.error("SETTINGS.FILE_SIZE_ERROR");
          this.uploadFileStatus = "";
          this.files = [];
          break;
        }
        if (logoOrFavicon === "logo") {
          this.api.clients
            .getUploadLogoUrl(this.client.id, file.type, extension)
            .subscribe((url) => {
              const event: UploadInput = {
                type: "uploadFile",
                url: url.url,
                headers: {
                  "Content-Type": file.type,
                },
                includeWebKitFormBoundary: false,
                method: "PUT",
                file: this.files[0],
              };
              this.uploadInput.emit(event);
            });
        } else {
          this.api.clients
            .getUploadFaviconUrl(this.client.id, file.type, extension)
            .subscribe((url) => {
              const event: UploadInput = {
                type: "uploadFile",
                url: url.url,
                headers: {
                  "Content-Type": file.type,
                },
                includeWebKitFormBoundary: false,
                method: "PUT",
                file: this.files[0],
              };
              this.uploadInput.emit(event);
            });
        }
        break;
      case "rejected":
        if (typeof output.file !== "undefined") {
          this.notification.error("SETTINGS.ONLY_PNG_JPG");
          this.uploadFileStatus = "";
          this.files = [];
        }
        break;
      case "addedToQueue":
        if (typeof output.file !== "undefined") {
          this.files.push(output.file);
        }
        break;
      case "uploading":
        if (typeof output.file !== "undefined") {
          // update current data in files array for uploading file
          const index = this.files.findIndex(
            (file) =>
              typeof output.file !== "undefined" && file.id === output.file.id
          );
          this.files[index] = output.file;
        }
        break;
      case "removedAll":
        this.uploadFileStatus = "";
        this.files = [];
        this.modalUploadLogo.closeModal();
      case "removed":
      case "cancelled":
        // remove file from array when removed
        this.files = this.files.filter(
          (file: UploadFile) => file !== output.file
        );
        break;
      case "dragOver":
        this.uploadFileStatus = "dragOver";
        break;
      case "dragOut":
      case "drop":
        this.uploadFileStatus = "";
        break;
      case "done":
        if (output.file.responseStatus == 200) {
          this.uploadInput.emit({ type: "removeAll" });
          this.notification.success("SETTINGS.SUCCESSFUL_UPLOAD_FILE");
          this.modalUploadLogo.closeModal();
          this.modalUploadFavicon.closeModal();
          this.getClient();
          this.getLogo();
          this.getFavicon();
        } else {
          this.notification.error("SETTINGS.SOMETHING_WENT_WRONG_UPLOAD");
        }
        this.uploadFileStatus = "";
        break;
    }
  }
}
