import { Serializer } from "../resource/serializer.interface";
import { GeoIpException } from "./geo-ip-exception.model";

export class GeoIpExceptionSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): GeoIpException {
    const geoIpException = new GeoIpException();
    geoIpException.id = json.id;
    geoIpException.regulation_id = json.regulation_id;
    geoIpException.ip_range = json.ip_range;
    geoIpException.geo_type = json.geo_type;
    geoIpException.value = json.value;
    return geoIpException;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(geoIpException: GeoIpException): any {
    return {
      id: geoIpException.id,
      regulation_id: geoIpException.regulation_id,
      ip_range: geoIpException.ip_range,
      geo_type: geoIpException.geo_type,
      value: geoIpException.value
    };
  }
}
