<div *ngIf="showTable">
  <ngx-datatable
    #datatable
    class="material custom-ngx-dt"
    [rows]="rows"
    [loadingIndicator]="loadingIndicator"
    [columnMode]="'force'"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="'auto'"
    (directiveDrop)="onDrop($event)"
    [trackByProp]="id"
    [classSelector]="'fas fa-grip-horizontal'"
    [dragulaName]="'bag'"
    [dragulaModel]="rows"
    [sorts]="sorts"
    (sort)="onSort($event)"
    [messages]="{ emptyMessage: 'TABLE.NO_DATA' | translate }"
  >
    <ngx-datatable-column
      *ngFor="let col of columns"
      [name]="col.name | translate"
      [prop]="col.prop"
      [width]="col.width"
      [sortable]="col.sortable"
    >
      <ng-template
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
        let-value="value"
        let-row="row"
      >
        <div *ngIf="col.prop !== 'actions'">
          <mat-form-field
            *ngIf="
              isEditable[rowIndex] &&
              col.prop !== 'priority' &&
              col.prop !== 'name'
            "
          >
            <input matInput [(ngModel)]="row[col.prop]" />
          </mat-form-field>
          <span
            *ngIf="
              !isEditable[rowIndex] ||
              col.prop === 'priority' ||
              (isEditable[rowIndex] &&
                col.prop !== 'priority' &&
                col.prop == 'name')
            "
            >{{ value }}</span
          >
        </div>
        <div class="action-list-custom" *ngIf="col.prop === 'actions'">
          <div class="action-item" *ngFor="let a of actions">
            <div [ngSwitch]="a.toolTipText">
              <div
                *ngSwitchCase="('GENERIC.EDIT' | translate)"
                style="position:absolute;"
              >
                <button
                  (click)="onEdit(rowIndex)"
                  class="btn btn-action btn-primary btn-sm s-circle tooltip tooltip-left"
                  [attr.data-tooltip]="a.toolTipText | translate"
                  *ngIf="!isEditable[rowIndex]"
                >
                  <em class="{{ a.iconClass }}"></em>
                </button>

                <button
                  (click)="
                    saveData(row, rowIndex);
                    editingItemCount = editingItemCount - 1
                  "
                  class="btn btn-action btn-primary btn-sm s-circle tooltip tooltip-left"
                  [attr.data-tooltip]="'GENERIC.SAVE' | translate"
                  *ngIf="isEditable[rowIndex]"
                  style="position: absolute;right: 30px;top: 2px;"
                >
                  <em class="fas fa-check"></em>
                </button>
                <button
                  (click)="onCancelEdit(rowIndex)"
                  class="btn btn-action btn-primary btn-sm s-circle tooltip tooltip-left"
                  [attr.data-tooltip]="'GENERIC.CANCEL' | translate"
                  *ngIf="isEditable[rowIndex]"
                >
                  <em class="fas fa-times"></em>
                </button>
              </div>

              <button
                *ngSwitchDefault
                (click)="onActionClick(a, row.id)"
                class="btn btn-action btn-primary btn-sm s-circle tooltip tooltip-left"
                [attr.data-tooltip]="a.toolTipText | translate"
              >
                <em class="{{ a.iconClass }}"></em>
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
