<div class="container" [style.display]="outlet.isActivated ? 'none' : ''">
  <div class="columns">
    <div class="column col-12">
      <app-generic-card>
        <app-actionbar
          title="{{ 'BASIC_ENTITIES_PLURAL.EMAIL_TEMPLATES' | translate }}"
        ></app-actionbar>
        <app-datatable
          #dtEmailTemplates
          tableIdentifier="emailTemplates"
          [data]="dataEmailTemplates"
          [columns]="columnsEmailTemplates"
          [actions]="actionsEmailTemplates"
        ></app-datatable>
      </app-generic-card>
    </div>
  </div>
</div>
<router-outlet #outlet="outlet"></router-outlet>

<app-modal #modalEmailTemplatePreview title="BASIC_ENTITIES_SINGULAR.EMAIL_TEMPLATE">
  <div *ngIf="templateForPreview">
    <div
      style="max-width:600px;position: relative;"
      [innerHTML]="templateForPreview.hydratedTemplate | safeHtml"
    ></div>
  </div>
</app-modal>
