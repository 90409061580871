import { Resource } from "../resource/resource.model";

/**
 * A Tenant in the 4Comply SaaS System
 * This will really only be used for the 4comply dashboard
 */

export class ClientWebhook extends Resource {
    name: string;
    type: string;
    webhookId: string;
    webhookUrl: string;
    payloadFormat: string;
    responseMapping: string;
    triggerActions: string[];
    signatureType: string;
    createdAt: Date;
    updatedAt: Date;
}
