<div
  #canvasContent
  style="width: 400px"
  class="step {{
    data.activeMode ? (data.isCompleted ? 'completed' : 'pending') : ''
  }} {{ data.isFailedStep && 'failed' }} {{
    data.isCurrentStep && 'current'
  }} {{ !data.activeMode ? (data.optional ? 'optional' : 'mandatory') : '' }}"
>
  <div
    *ngIf="
      data.editMode &&
      data.type != 'start' &&
      data.type != 'choice' &&
      data.type != 'end'
    "
    (click)="disableStep()"
    class="remove-button"
  >
    <i class="fas fa-trash"></i>
  </div>
  <div class="title">
    {{ data.name }}
  </div>
  <div *ngIf="data.scale && data.scale >= 0.9" class="short-code">
    <label>{{ "RIGHTS_DETAIL_CONFIG.SHORT_CODE" | translate }}:</label>
    {{ data.identifier }}
  </div>
  <div *ngIf="!data.identifier" style="color: red; font-weight:bold;">
    <label>{{ "RIGHTS_DETAIL_CONFIG.MISSING_SHORT_CODE" | translate }}</label>
  </div>
  <div
    *ngIf="data.type === 'hitl' && !data.config.hitl_template_id"
    style="color: red; font-weight:bold;"
  >
    <label>{{
      "RIGHTS_DETAIL_CONFIG.MISSING_HITL_TEMPLATE" | translate
    }}</label>
  </div>
  <button
    *ngIf="
      data.editMode &&
      data.type != 'start' &&
      data.type != 'choice' &&
      data.type != 'end'
    "
    (click)="editStep()"
    style="border: 1px solid #333; color: #333;border-radius:5px; display: inline; width: auto;margin-bottom: 10px;"
    class="btn btn-link"
  >
    <i class="fas fa-edit"></i> Change Configuration
  </button>
  <div class="type">
    <div>
      <i *ngIf="data.type === 'start'" class="fas fa-list"></i>
      <i *ngIf="data.type === 'automatic'" class="fas fa-recycle"></i>
      <i *ngIf="data.type === 'webhook'" class="fas fa-undo"></i>
      <i *ngIf="data.type === 'regular'" class="fas fa-arrow-right"></i>
      <i
        *ngIf="data.type === 'indeterminate'"
        class="fas fa-clipboard-list"
      ></i>
      <i *ngIf="data.type === 'fulfillment'" class="fas fa-check-double"></i>
      <i *ngIf="data.type === 'choice'" class="fas fa-random"></i>
      <i *ngIf="data.type === 'hitl'" class="fas fa-user-clock"></i>
      <i *ngIf="data.type === 'end'" class="fas fa-check-circle"></i>
      {{ "WORKFLOW_STEP_TYPES." + data.type.toUpperCase() | translate }}
    </div>
    <div *ngIf="data.scale && data.scale >= 0.9">
      <div class="desc">{{ getDescription(data.type) | translate }}</div>
      <div
        *ngIf="
          data.type === 'automatic' &&
          data.config &&
          data.config.email_template_name
        "
        class="subdesc"
      >
        {{ "BASIC_ENTITIES_SINGULAR.EMAIL_TEMPLATE" | translate }}:
        {{ data.config.email_template_name }}
      </div>
      <div
        *ngIf="data.type === 'automatic'"
        class="subdesc"
        style="text-transform:capitalize"
      >
        {{ "RIGHTS_DETAIL_CONFIG.ACTION" | translate }}:
        {{ data.config && data.config.action ? data.config.action : "email" }}
      </div>
      <div
        class="subdesc"
        *ngIf="
          data.type === 'automatic' &&
          data.config &&
          data.config.action &&
          data.config.action === 'CheckUserExistsIn4Comply'
        "
      >
        {{ "RIGHTS_DETAIL_CONFIG.CHECK_USER_EXISTS_EXPLANATION" | translate }}
      </div>
      <div
        *ngIf="data.config && data.config.hitl_template_name"
        class="subdesc"
      >
        {{ "BASIC_ENTITIES_SINGULAR.HITL_TEMPLATE" | translate }}:
        {{ data.config.hitl_template_name }}
      </div>
      <div *ngIf="data.type === 'webhook'" class="subdesc">
        {{ "WORKFLOW_STEP_TYPES.WEBHOOK" | translate }}: {{ data.name }}
      </div>
      <div
        class="subdesc"
        style="font-weight:bold; font-style:italic;"
        *ngIf="data.type === 'hitl' && data.config"
      >
        {{
          data.config.blocking
            ? ("RIGHTS_DETAIL_CONFIG.BLOCKING" | translate)
            : ("RIGHTS_DETAIL_CONFIG.NONBLOCKING" | translate)
        }}
      </div>
      <div
        *ngIf="
          data.config &&
          data.config.completion_requirements &&
          data.config.completion_requirements.length
        "
        class="subdesc"
      >
        {{ "RIGHTS_DETAIL_CONFIG.REQUIRED_COMPLETION_ENTRIES" | translate }}:
        <br />
        <ul>
          <li *ngFor="let cr of data.config.completion_requirements">
            {{ cr.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
