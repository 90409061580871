import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  DatatableComponent,
  APIService,
  ClientRegulationConfiguration,
  RightRequestConfig,
  RightRequestConfigMap,
  ClientRegulation,
  NotificationService,
  ModalComponent,
  NgFlowchart,
  NgFlowchartCanvasDirective,
  NgFlowchartStepRegistry,
  AuthService,
} from "@fourcomply-dashboard/shared";
import { ActivatedRoute } from "@angular/router";
import { FormStepComponent } from "./form-step/form-step.component";
import { FormStepService } from "./form-step/form-step.service";
import { pipe } from "rxjs";

@Component({
  selector: "app-right-request-configurations",
  templateUrl: "./right-request-configurations.component.html",
  styleUrls: ["./right-request-configurations.component.scss"],
})
export class RightRequestConfigurationComponent implements OnInit {
  dataRightRequestConfigs: RightRequestConfig[];
  dataRightRequestConfig: RightRequestConfig;

  dataRightRequestSteps: any[];
  columnsRightRequestSteps: any[];
  allRegs: any[];
  regulationId: string;
  requestType: string;
  dataRegulation: ClientRegulation;
  allConfigs: any;
  emailTemplates: any;
  hitlTemplates: any;
  completionConfigs: any;
  editMode: boolean = false;
  clientid: string;
  clientLogo: string;
  expanded: any = {};

  // Step we are editing
  stepInEdit: any = null;

  @ViewChild("modalStepEdit")
  modalStepEdit: ModalComponent;

  @ViewChild(NgFlowchartCanvasDirective)
  canvas: NgFlowchartCanvasDirective;

  callbacks: NgFlowchart.Callbacks = {};
  initialZoom: number = 1;
  options: NgFlowchart.Options = {
    stepGap: 100,
    rootPosition: "TOP_CENTER",
    centerOnResize: false,
    zoom: {
      mode: "MANUAL",
      defaultStep: 0.05,
    },
  };

  @ViewChild("normalStep")
  normalStepTemplate: TemplateRef<any>;

  stepItems = [
    {
      name: "Automatic Step",
      type: "automatic",
      step: {
        template: FormStepComponent,
        type: "automatic",
      },
      data: {
        optional: false,
        type: "automatic",
        name: "Automatic Step",
        icon: { name: "log-icon", color: "blue" },
        config: {
          completion_requirements: [],
          email_template_id: null,
          hitl_template_id: null,
        },
      },
    },
    {
      name: "Regular Step",
      type: "regular",
      step: {
        template: FormStepComponent,
        type: "regular",
      },
      data: {
        optional: false,
        type: "regular",
        name: "Regular Step",
        icon: { name: "log-icon", color: "blue" },
        config: {
          completion_requirements: [],
          email_template_id: null,
          hitl_template_id: null,
        },
      },
    },
    {
      name: "HITL Step",
      type: "hitl",
      step: {
        template: FormStepComponent,
        type: "hitl",
      },
      data: {
        optional: false,
        type: "hitl",
        name: "HITL Step",
        icon: { name: "log-icon", color: "blue" },
        config: {
          completion_requirements: [],
          email_template_id: null,
          hitl_template_id: null,
        },
      },
    },
    {
      name: "Webhook Step",
      type: "webhook",
      step: {
        template: FormStepComponent,
        type: "webhook",
      },
      data: {
        optional: false,
        name: "Webhook Step",
        type: "webhook",
        icon: { name: "log-icon", color: "blue" },
        config: {
          completion_requirements: [],
          email_template_id: null,
          hitl_template_id: null,
        },
      },
    },
    {
      name: "Indeterminate Step",
      type: "indeterminate",
      step: {
        template: FormStepComponent,
        type: "indeterminate",
      },
      data: {
        optional: false,
        type: "indeterminate",
        name: "Indeterminate Step",
        icon: { name: "log-icon", color: "blue" },
        config: {
          completion_requirements: [],
          email_template_id: null,
          hitl_template_id: null,
        },
      },
    },
  ];

  constructor(
    private api: APIService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private notification: NotificationService,
    private stepRegistry: NgFlowchartStepRegistry,
    private formStepService: FormStepService
  ) {
    this.callbacks.onDropError = this.onDropError;
    this.callbacks.onDropStep = this.onDropStep.bind(this);
    this.callbacks.onMoveError = this.onMoveError;
    this.callbacks.afterScale = this.afterScale.bind(this);
    this.openStepEditModal = this.openStepEditModal.bind(this);
    this.disableStep = this.disableStep.bind(this);
  }

  ngOnInit() {
    let id = this.route.snapshot.params["id"];
    let type = this.route.snapshot.params["type"];
    if (this.regulationId) {
      this.fetchAllRegulationsAndSelect(id, type);
    } else {
      this.fetchAllRegulationsAndSelect(null, null);
    }
    this.formStepService.stepIdSelectedForDisabling.subscribe((id) => {
      this.disableStep(id);
    });
    this.formStepService.stepIdSelectedForEditing.subscribe((id) => {
      this.openStepEditModal(id);
    });
    this.auth.userProfile$.subscribe(
      pipe((res: any) => {
        this.clientid = res.currentTenant.tenant_id;
        this.getLogo();
      })
    );
  }

  getEmailTemplate(id) {
    let temp = this.emailTemplates.find((e) => e.id === id);
    if (!temp) return null;
    temp.template = temp.template.replace(
      "{{companyLogoUrl}}",
      this.clientLogo
    );
    return temp;
  }

  getLogo() {
    this.api.clients.getLogo(this.clientid).subscribe((a: any) => {
      console.log(a);
      this.clientLogo = a.url;
    });
  }

  getHitlTemplate(id) {
    return this.hitlTemplates.find((e) => e.id === id);
  }

  getEmailTemplates() {
    this.api.clientEmailTemplates.list().subscribe((data) => {
      console.log(data);
      this.emailTemplates = data;
      this.dataRightRequestSteps = this.dataRightRequestSteps?.map((d) => {
        return {
          ...d,
          config: d.config
            ? {
                ...d.config,
                email_template_name: d.config.email_template_id
                  ? this.emailTemplates.find(
                      (a) => a.id === d.config.email_template_id
                    ).type
                  : null,
              }
            : {},
        };
      });
      this.getHitlTemplates();
    });
  }

  getCompletionConfigs() {
    this.api.rightRequestConfigs.getCompletionConfigs().subscribe((data) => {
      console.log(data);
      this.completionConfigs = data;
      this.dataRightRequestSteps = this.dataRightRequestSteps?.map((d) => {
        return {
          ...d,
          editMode: this.editMode,
          config: d.config
            ? {
                ...d.config,
                completion_requirements: d.config.completion_requirements
                  ? d.config.completion_requirements.map((cr) => {
                      return this.completionConfigs.find(
                        (cc) => cc.id === cr.workflow_step_completion_config_id
                      );
                    })
                  : [],
              }
            : {},
        };
      });
      console.log(this.dataRightRequestSteps);
      this.loadIntoWorkflow();
    });
  }

  getHitlTemplates() {
    this.api.hitlTemplates.list().subscribe((data) => {
      console.log(data);
      this.hitlTemplates = data;
      this.dataRightRequestSteps = this.dataRightRequestSteps?.map((d) => {
        return {
          ...d,
          config: d.config
            ? {
                ...d.config,
                hitl_template_name: d.config.hitl_template_id
                  ? this.hitlTemplates.find(
                      (a) => a.id === d.config.hitl_template_id
                    ).name
                  : null,
              }
            : {},
        };
      });
      this.getCompletionConfigs();
    });
  }

  ngAfterViewInit() {
    this.stepRegistry.registerStep("start", FormStepComponent as any);
    this.stepRegistry.registerStep("automatic", FormStepComponent as any);
    this.stepRegistry.registerStep("hitl", FormStepComponent as any);
    this.stepRegistry.registerStep("regular", FormStepComponent as any);
    this.stepRegistry.registerStep("indeterminate", FormStepComponent as any);
    this.stepRegistry.registerStep("fulfillment", FormStepComponent as any);
    this.stepRegistry.registerStep("choice", FormStepComponent as any);
    this.stepRegistry.registerStep("end", FormStepComponent as any);
    this.stepRegistry.registerStep("webhook", FormStepComponent as any);
  }

  closeStepEditModal() {
    this.stepInEdit = null;
    this.modalStepEdit.closeModal();
  }

  saveAndCloseStepEditModal() {
    // swap with old one step
    this.dataRightRequestSteps[
      this.dataRightRequestSteps.findIndex(
        (s) => s.order === this.stepInEdit.order
      )
    ] = this.stepInEdit;
    this.stepInEdit = null;
    this.modalStepEdit.closeModal();
    this.getEmailTemplates();
  }

  openStepEditModal(id) {
    console.log(id, this.dataRightRequestSteps);
    this.stepInEdit = this.dataRightRequestSteps.find((s) => s.order === id);
    this.modalStepEdit.openModal();
  }

  toggleMode() {
    this.editMode = !this.editMode;
    this.fetchRightRequestConfigs(this.regulationId);
  }

  toggleExpanded(name) {
    if (this.expanded[name]) {
      this.expanded[name] = false;
    } else {
      this.expanded[name] = true;
    }
  }

  afterScale(newScale) {
    this.initialZoom = newScale;
    if (this.initialZoom < 0.5) {
      this.options = {
        ...this.options,
        stepGap: 70,
      };
    } else if (this.initialZoom >= 0.5) {
      this.options = {
        ...this.options,
        stepGap: 100,
      };
    }
    this.loadIntoWorkflow();
  }

  zoom(zoomIn: boolean) {
    if (zoomIn) {
      this.canvas.scaleUp();
    } else {
      if (this.initialZoom <= 0.5) return;
      this.canvas.scaleDown();
    }
  }

  loadIntoWorkflow() {
    if (!this.dataRightRequestSteps?.length) return;
    let flow = {
      root: {
        id: 1,
        type: this.dataRightRequestSteps[0].type,
        data: {
          ...this.dataRightRequestSteps[0],
          name: this.dataRightRequestSteps[0].name,
        },
        children: [],
      },
    };
    console.log(this.dataRightRequestSteps);
    let a = this.dataRightRequestSteps
      .map((step) => {
        let directParentNoChoice = this.dataRightRequestSteps.find((a) => {
          return a.next === step.order;
        });
        let choiceParent = null;
        if (!directParentNoChoice) {
          // check for choice before
          let choiceParentList = this.dataRightRequestSteps.filter(
            (a, index) => {
              //TEMP FIX a.type === "choice" &&
              return a.order < step.order;
            }
          );
          // need the one with the highest order
          choiceParent = choiceParentList[choiceParentList.length - 1];
        }
        return {
          ...step,
          id: step.order,
          parent_id:
            step.order === 1 || step.order === 0
              ? null
              : choiceParent
              ? choiceParent.order
              : directParentNoChoice?.order,
        };
      })
      .slice(1, this.dataRightRequestSteps.length);

    const nest = (items, id = null, link = "parent_id") => {
      return items
        .filter((item) => {
          return item[link] === id;
        })
        .map((item) => {
          return {
            ...item,
            id: item.id,
            type: item.type,
            data: {
              ...item,
              name: item.name,
              scale: this.initialZoom,
            },
            children: nest(items, item.id),
          };
        });
    };
    console.log("A", a);
    flow.root.children = nest(a, 1);
    this.canvas.getFlow().upload(flow);
  }

  onDropError(error: NgFlowchart.DropError) {
    console.log(error);
  }

  onMoveError(error: NgFlowchart.MoveError) {
    console.log(error);
  }
  showFlowData() {
    let json = this.canvas.getFlow().toJSON(4);

    var x = window.open();
    x.document.open();
    x.document.write(
      "<html><head><title>Flowchart Json</title></head><body><pre>" +
        json +
        "</pre></body></html>"
    );
    x.document.close();
  }

  clearData() {
    this.canvas.getFlow().clear();
  }

  onGapChanged(event) {
    this.options = {
      ...this.options,
      stepGap: parseInt(event.target.value),
    };
  }

  onSequentialChange(event) {
    this.options = {
      ...this.options,
      isSequential: event.target.checked,
    };
  }

  onDelete(id) {
    this.canvas.getFlow().getStep(id).destroy(true);
  }

  getDescription(type) {
    switch (type) {
      case "automatic":
        return "RIGHTS_REQUESTS.TYPE_AUTOMATIC_DESC";
      case "end":
        return "RIGHTS_REQUESTS.TYPE_END_DESC";
      case "hitl":
        return "RIGHTS_REQUESTS.TYPE_HITL_DESC";
      case "indeterminate":
        return "RIGHTS_REQUESTS.TYPE_INDETERMINATE_DESC";
      case "webhook":
        return "RIGHTS_REQUESTS.TYPE_WEBHOOK_DESC";
      case "regular":
        return "RIGHTS_REQUESTS.TYPE_REGULAR_DESC";
      default:
        break;
    }
  }

  isEnabled(step) {
    return true;
  }

  isRightBeforeChoice(index) {
    let steps = this.dataRightRequestSteps;
    let choiceIndexes = [];
    steps.forEach((s, ind) => {
      if (s.type == "choice") {
        choiceIndexes.push(ind);
      }
    });
    if (choiceIndexes.includes(index + 1)) {
      return true;
    } else {
      return false;
    }
  }

  onDropStep(event) {
    if (this.editMode === false) return;
    if (event.isMove) {
      this.notification.error("RIGHTS_DETAIL_CONFIG.ERROR_MOVE_STEP");
      return;
    }
    console.log("ON DROP STEP", event);
    // add to data model
    let steps = this.dataRightRequestSteps;
    console.log("ALL STEPS", steps);
    let insertedStepParentId = event.parent.data.order;
    let insertedStepParentIndex = steps.findIndex(
      (s) => s.order == insertedStepParentId
    );
    let beforeInsert = steps.slice(0, insertedStepParentIndex + 1);
    beforeInsert.push({
      ...event.step.data,
      order: insertedStepParentIndex + 2,
      next: insertedStepParentIndex + 3,
      id: insertedStepParentIndex + 2,
      parent_id: insertedStepParentIndex + 1,
    });
    let afterInsert = steps.slice(insertedStepParentIndex + 1, steps.length);
    afterInsert.forEach((s) => {
      s.order++;
      s.next === 0 ? 0 : s.next++;
      s.id = s.order;
      s.parent_id = s.order - 1;
    });
    beforeInsert = beforeInsert.concat(afterInsert);
    beforeInsert = beforeInsert.map((d) => {
      return { ...d, editMode: true };
    });
    console.log("NEW LIST", beforeInsert);
    this.dataRightRequestSteps = beforeInsert;
    this.loadIntoWorkflow();
  }

  saveFlow() {
    let steps = this.dataRightRequestSteps;
    this.dataRightRequestConfig.steps = steps;
    this.dataRightRequestSteps = steps;
    this.toggleMode();
    this.api.rightRequestConfigs
      .update(this.dataRightRequestConfig)
      .subscribe((a) => {
        this.notification.success("GENERIC.SUCCESSFUL_DATA_UPDATE");
        this.getEmailTemplates();
      });
  }

  disableStep(order) {
    let steps = this.dataRightRequestSteps;
    console.log("ALL STEPS", steps);
    console.log("REMOVING STEP", order);
    let removedStepId = order;
    let removedStepIndex = steps.findIndex((s) => s.order == removedStepId);
    let beforeInsert = steps.slice(0, removedStepIndex);
    console.log("BEFORE", removedStepIndex, beforeInsert);
    let afterInsert = steps.slice(removedStepIndex + 1, steps.length);
    afterInsert.forEach((s) => {
      s.order--;
      s.next === 0 ? 0 : s.next--;
      s.id = s.order;
      s.parent_id = s.order - 1;
    });
    beforeInsert = beforeInsert.concat(afterInsert);
    beforeInsert = beforeInsert.map((d) => {
      return { ...d, editMode: true };
    });
    console.log("NEW LIST", beforeInsert);
    this.dataRightRequestSteps = beforeInsert;
    this.loadIntoWorkflow();
    // Don't save until the user clicks save
  }

  reorderStepsAfterRemoval(steps, removedStep) {
    let stepsToReorder = [];
    steps.forEach((s, ind) => {
      if (removedStep && s.next != 0 && s.next === removedStep.order) {
        stepsToReorder.push({
          ...s,
          next: removedStep.next,
        });
      } else {
        stepsToReorder.push({
          ...s,
        });
      }
    });
    return steps;
  }

  switchRegulation(id) {
    this.regulationId = id;
    this.fetchRightRequestConfigs(this.regulationId);
  }

  selectRegulation(id, type) {
    this.requestType = type || "RTP";
    this.regulationId = id;
    this.fetchRightRequestConfigs(this.regulationId);
  }

  fetchAllRegulationsAndSelect(id, type) {
    this.requestType = type || "RTP";
    this.api.clientRegulations.list().subscribe((allRegs) => {
      this.allRegs = allRegs;
      this.switchRegulation(id || allRegs[0].id);
    });
  }

  fetchRightRequestConfigs(id: string) {
    this.columnsRightRequestSteps = [
      { name: "GENERIC.NAME", prop: "name" },
      { name: "GENERIC.IDENTIFIER", prop: "identifier" },
      { name: "GENERIC.TYPE", prop: "type" },
    ];
    // Get Regulation
    this.api.clientRegulations.get(id).subscribe((a) => {
      this.dataRegulation = a;

      // Get All Request Configs
      this.api.rightRequestConfigs.list().subscribe((b) => {
        this.allRegs = this.allRegs.map((a) => {
          return {
            ...a,
            requestConfigs: b.filter((b) => b.regulationId === a.id),
          };
        });
      });

      // Get All Possible Configs
      this.api.rightRequestConfigs.getByRegulationId(id).subscribe((a) => {
        let conf = a.filter((a) => a.requestType === this.requestType)[0];
        if (conf) {
          console.log("adding config", conf, conf.steps);
          this.dataRightRequestConfig = conf;
          this.dataRightRequestSteps = conf.steps || [];
        }
        setTimeout(() => {
          this.getEmailTemplates();
        }, 100);
      });
    });
  }
}
