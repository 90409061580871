<div style="width:600px;height:100vh;">
  <form
    *ngIf="selectedTask"
    #g="ngForm"
    (ngSubmit)="submitEditTask(selectedTask)"
  >
    <ngx-panel-header>
      {{ "BASIC_ENTITIES_SINGULAR.HITL_TASK" | translate }}
    </ngx-panel-header>
    <ngx-panel-body>
      <div
        style="padding: 20px;"
        *ngIf="selectedTask != null && selectedTask.template != null"
      >
        <h2>{{ selectedTask.template.shortDescription }}</h2>
        <h4 style="margin-bottom:20px;">
          {{ selectedTask.citizenEmail }}
        </h4>
        <div *ngIf="selectedTask != null && selectedTask.workflowInstanceId">
          <a (click)="goToWorkflow(selectedTask)">{{
            "HITL_DETAIL.LINK_TO_ACTIVE_WORKFLOW" | translate
          }}</a>
        </div>

        <div class="create-task">
          <div class="form-group">
            <mat-form-field>
              <input
                matInput
                placeholder="{{ 'GENERIC.NOTES' | translate }}"
                name="notes"
                [(ngModel)]="selectedTask.notes"
              />
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field>
              <mat-select
                matInput
                name="assignedToId"
                placeholder="{{ 'HITL_DETAIL.ASSIGNED_TO' | translate }}"
                [(ngModel)]="selectedTask.assignedToId"
                ><mat-option *ngFor="let user of users" [value]="user.id"
                  >{{ user.emailAddress }}
                </mat-option></mat-select
              >
              <mat-error>{{ "GENERIC.REQUIRED" | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </ngx-panel-body>
    <ngx-panel-footer
      ><div class="form-group main-submit">
        <button
          style="margin-right:10px;"
          type="submit"
          class="btn btn-primary"
        >
          {{ "GENERIC.SAVE" | translate }}
        </button>
        <button
          style="margin-right:10px;"
          (click)="submitDeleteTask()"
          type="button"
          class="btn btn-error"
        >
          {{ "HITL_DETAIL.DELETE_TASK" | translate }}
        </button>
        <button
          (click)="submitArchiveTask()"
          type="button"
          class="btn btn-error"
        >
          {{ "HITL_DETAIL.ARCHIVE" | translate }}
        </button>
      </div></ngx-panel-footer
    >
  </form>
</div>
