import { ClientWebhook } from "./client-webhook.model";
import { Serializer } from "../resource/serializer.interface";

export class ClientWebhookSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): ClientWebhook {
    const client = new ClientWebhook();
    client.id = json.id;
    client.name = json.name;
    client.type = json.type;
    client.webhookId = json.webhook_id;
    client.webhookUrl = json.webhook_url;
    client.payloadFormat = json.payload_format;
    client.responseMapping = json.response_mapping;
    client.triggerActions = json.trigger_actions;
    client.signatureType = json.signature_type;
    client.createdAt = json.created_at;
    client.updatedAt = json.updated_at;
  
    return client;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(client: ClientWebhook): any {
    return {
      id: client.id,
      name: client.name,
      type: client.type,
      webhook_id: client.webhookId,
      webhook_url: client.webhookUrl,
      payload_format: client.payloadFormat,
      response_mapping: client.responseMapping,
      trigger_actions: client.triggerActions,
      signature_type: client.signatureType,
      updated_at: client.updatedAt,      
      created_at: client.createdAt      
    };
  }
}
