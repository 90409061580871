import { Component, OnInit, ViewChild } from "@angular/core";
import { DatalistActionItem } from "libs/shared/src/lib/models/datalist-action-item.interface";
import {
  APIService,
  NotificationService,
  ActionBarItem,
  ClientSystem,
  DatatableComponent,
  ModalComponent,
} from "@fourcomply-dashboard/shared";
import { Router } from "@angular/router";

@Component({
  selector: "app-systems",
  templateUrl: "./systems.component.html",
  styleUrls: ["./systems.component.scss"],
})
export class SystemsComponent implements OnInit {
  @ViewChild("dtSystems")
  dtSystems: DatatableComponent;

  @ViewChild("modalSystems", { static: true })
  public modalSystems: ModalComponent;

  actionBarItemsSystems: ActionBarItem[];
  dataSystems: ClientSystem[];
  columnsSystems: any[];

  actionsSystems: DatalistActionItem[];
  idForDeleteSystems: number;
  constructor(
    private api: APIService,
    private notification: NotificationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.deleteSystems = this.deleteSystems.bind(this);
    this.routeToEditSystem = this.routeToEditSystem.bind(this);
    this.deleteSystemsConfirm = this.deleteSystemsConfirm.bind(this);
    this.fetchSystems();
  }

  onDeactivate(event): void {
    this.fetchSystems();
  }

  fetchSystems() {
    this.actionBarItemsSystems = [
      {
        btnText: "GENERIC.ADD_NEW",
        iconClass: "fas fa-plus",
        route: "systems/add",
      },
    ];

    this.actionsSystems = [
      {
        iconClass: "fas fa-pen",
        toolTipText: "GENERIC.CUSTOMIZE",
        callback: this.routeToEditSystem,
      },
      {
        iconClass: "fas fa-trash-alt",
        toolTipText: "GENERIC.DELETE",
        callback: this.deleteSystems,
      },
    ];

    this.columnsSystems = [
      { name: "GENERIC.NAME", prop: "name" },
      { name: "GENERIC.TYPE", prop: "type" },
      { name: "GENERIC.PRIORITY", prop: "priority" },
      { name: "GENERIC.ACTIONS", prop: "actions" },
    ];
    this.api.clientSystems.list().subscribe((a) => {
      this.dataSystems = a;
      this.dtSystems.updateRows(this.dataSystems);
      this.dtSystems.loaderVisibility(false);
    });
  }

  routeToEditSystem(id) {
    this.router.navigate(["systems/edit/" + id]);
  }

  deleteSystems(id) {
    this.idForDeleteSystems = id;
    this.modalSystems.openModal();
  }

  deleteSystemsConfirm() {
    this.api.clientSystems.delete(this.idForDeleteSystems).subscribe(
      (a) => {
        this.fetchSystems();
        this.modalSystems.closeModal();
        this.notification.success("GENERIC.SUCCESSFUL_DATA_DELETE");
      },
      (error) => {
        console.log(error);
        this.modalSystems.closeModal();
      }
    );
  }
}
