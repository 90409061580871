<div class="container">
  <div class="column col-12">
    <div class="columns">
      <div class="column col-4">
        <app-generic-card>
          <h3 class="section-title">
            {{
              (model.id ? "CLIENT_FORM.EDIT" : "CLIENT_FORM.ADD_NEW")
                | translate
            }}
          </h3>
          <app-form
            #formClient
            [fields]="fields"
            [model]="model"
            [callback]="submitForms"
            [loadingDone]="loadingDone"
            closeRoute="forms"
          >
          </app-form>
        </app-generic-card>
      </div>
      <div class="column col-8" [style.display]="!isEdit ? 'none' : ''">
        <app-generic-card>
          <h3 class="section-title">{{ "GENERIC.FORM_FIELDS" | translate }}</h3>
          <app-draggable-table
            #dtClientFormFields
            tableIdentifier="form_fields"
            [data]="dataClientFormFields"
            [columns]="columnsClientFormFields"
            [actions]="actionsClientFormFields"
            (callbackOnDrop)="onDropFields($event)"
          >
          </app-draggable-table>

          <button
            class="btn btn-primary"
            type="input"
            (click)="addNewFormField()"
          >
            {{ "GENERIC.ADD_NEW_FORM_FIELD" | translate }}
          </button>
        </app-generic-card>
      </div>
    </div>
  </div>
</div>
<app-modal (closer)="closeModal()" #modalClient [title]="modalTitle">
  <app-form
    #formFieldClient
    [fields]="fieldsFormFields"
    [model]="modelFormFields"
    [loadingDone]="loadingDone"
    [callback]="submitFormFields"
    [additionalButtons]="additionalButtons"
  >
  </app-form>
</app-modal>
<app-modal
  #modalDeleteFormField
  title="{{ 'MODAL_GENERIC.CONFIRM_DELETE' | translate }}"
  bodyContent="{{ 'MODAL_GENERIC.CONFIRM_DELETE_QUESTION' | translate }}"
  [confirmCallback]="deleteClientFormField"
></app-modal>
