import { Serializer } from "../resource/serializer.interface";
import { ConsentPermission } from "./consent-permission.model";

export class ConsentPermissionSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): ConsentPermission {
    const consentPermission = new ConsentPermission();
    consentPermission.id = json.id;
    consentPermission.consentId = json.consent_id;
    consentPermission.email = json.email_address;
    consentPermission.businessUnit = json.business_unit;
    consentPermission.permissionValue = json.permission_value;
    consentPermission.permissionType = json.permission_type;
    consentPermission.expiration = new Date(json.expiration);
    consentPermission.activity_date= new Date(json.activity_date);
    consentPermission.consentSource = json.permission_source;
    consentPermission.processingPurpose = json.processing_purpose;
    consentPermission.permissionCategory = json.permission_category;
    consentPermission.regulationId = json.regulation_id;
    consentPermission.regulationName = json.regulation_name;
    consentPermission.createdAt = new Date(json.created_at);
    consentPermission.updatedAt = new Date(json.updated_at);
    consentPermission.systemId = json.system_id;
    consentPermission.standardState = json.standard_state;
    consentPermission.standardCountry = json.standard_country;
    consentPermission.systemType = json.system_type;
    consentPermission.system = json.system;
    consentPermission.systemRecordId = json.system_record_id;
    consentPermission.systemRecordType = json.system_record_type;
    consentPermission.externalSource = json.external_source;
    consentPermission.means = json.means;
    consentPermission.consentLanguage = json.consent_language;
    consentPermission.privacyNoticeVersion = json.privacy_notice_version;
    consentPermission.customField1 = json.custom_field_1;
    consentPermission.customField2 = json.custom_field_2;
    consentPermission.customField3 = json.custom_field_3;
    consentPermission.customField4 = json.custom_field_4;
    consentPermission.customField5 = json.custom_field_5;
    consentPermission.customField6 = json.custom_field_6;
    consentPermission.customField7 = json.custom_field_7;
    return consentPermission;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(consentPermission: ConsentPermission): any {
    return {
      id: consentPermission.id,
      consent_id: consentPermission.consentId,
      email_address: consentPermission.email,
      business_unit: consentPermission.businessUnit,
      permission_value: consentPermission.permissionValue,
      permission_type: consentPermission.permissionType,
      expiration: consentPermission.expiration,
      activity_date: consentPermission.activity_date,
      standard_country: consentPermission.standardCountry,
      standard_state: consentPermission.standardState,
      processing_purpose: consentPermission.processingPurpose,
      permission_category: consentPermission.permissionCategory,
      system_id: consentPermission.systemId,
      system_type: consentPermission.systemType,
      system: consentPermission.system,
      system_record_id: consentPermission.systemRecordId,
      system_record_type: consentPermission.systemRecordType,
    };
  }
}
