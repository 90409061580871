<div class="container page">
  <div class="column col-12">
    <app-generic-card>
      <app-actionbar
        title="{{ 'BASIC_ENTITIES_PLURAL.COMPLIANCE_INPUTS' | translate }}"
        [actionBarItems]="actionBarItemsConsent"
        [subtitle]="
          total != null && total != undefined
            ? isEstimate
              ? 'around ' + total + ' items'
              : total + ' total items'
            : null
        "
      ></app-actionbar>
      <app-datatable
        #dtConsent
        tableIdentifier="consent"
        (sortEvent)="onSort($event)"
        (openCustomFieldsCallback)="onOpenCustomFields($event)"
        [data]="dataConsent"
        [columns]="columnsConsent"
        placeholder="{{ 'GENERIC.ENTER_VALUE_SEARCH' | translate }}"
        buttonTitle="{{ 'GENERIC.SEARCH' | translate }}"
        [disabled]="disabled"
        enableSearch="true"
        (search)="searchWithEmail($event)"
      ></app-datatable>
    </app-generic-card>
    <app-pagination
      *ngIf="pages"
      [pages]="pages"
      [currentPageSize]="pageSize"
      [currentPage]="currentPage"
      (setPageSize)="setPageSize($event)"
      (setCurrentPage)="setCurrentPage($event)"
    ></app-pagination>
  </div>
</div>

<app-modal #modalUpload title="COMPLIANCE_INPUTS.UPLOAD_MODAL_TITLE">
  <div class="upload-file-section">
    <div
      class="drop-container"
      ngFileDrop
      [options]="options"
      (uploadOutput)="onUploadOutput($event)"
      [uploadInput]="uploadInput"
      [ngClass]="{ 'is-drop-over': uploadFileStatus === 'dragOver' }"
    >
      <h1 *ngIf="uploadFileStatus === ''">
        {{ "GENERIC.DRAG_AND_DROP" | translate }}
      </h1>
      <h1 *ngIf="uploadFileStatus === 'dragOver'">
        {{ "GENERIC.DROP_NOW" | translate }}
      </h1>
      <h1 *ngIf="uploadFileStatus === 'uploading'">
        {{ "GENERIC.UPLOADING" | translate }}
      </h1>
    </div>

    <label
      class="upload-button"
      [ngClass]="{ 'uploading-now': uploadFileStatus === 'uploading' }"
    >
      <input
        type="file"
        ngFileSelect
        [options]="options"
        (uploadOutput)="onUploadOutput($event)"
        [uploadInput]="uploadInput"
      />
      {{ "GENERIC.OR_CHOOSE_FILE" | translate }}
    </label>
  </div>
</app-modal>

<app-modal #modalCustomFields title="COMPLIANCE_INPUTS.CUSTOM_FIELDS_MODAL">
  <div *ngIf="selectedConsent !== null">
    <b>{{ selectedConsent.email }}</b>
    <div>
      {{ "GENERIC.FIELD" | translate }} 1 -
      {{ customFieldNames.customFieldName1 }} :
      {{ selectedConsent.customField1 || "-" }}
    </div>
    <div>
      {{ "GENERIC.FIELD" | translate }} 2 -
      {{ customFieldNames.customFieldName2 }} :
      {{ selectedConsent.customField2 || "-" }}
    </div>
    <div>
      {{ "GENERIC.FIELD" | translate }} 3 -
      {{ customFieldNames.customFieldName3 }} :
      {{ selectedConsent.customField3 || "-" }}
    </div>
    <div>
      {{ "GENERIC.FIELD" | translate }} 4 -
      {{ customFieldNames.customFieldName4 }} :
      {{ selectedConsent.customField4 || "-" }}
    </div>
    <div>
      {{ "GENERIC.FIELD" | translate }} 5 -
      {{ customFieldNames.customFieldName5 }} :
      {{ selectedConsent.customField5 || "-" }}
    </div>
    <div>
      {{ "GENERIC.FIELD" | translate }} 6 -
      {{ customFieldNames.customFieldName6 }} :
      {{ selectedConsent.customField6 || "-" }}
    </div>
    <div>
      {{ "GENERIC.FIELD" | translate }} 7 -
      {{ customFieldNames.customFieldName7 }} :
      {{ selectedConsent.customField7 || "-" }}
    </div>
  </div>
</app-modal>
