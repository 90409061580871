import { Component, OnInit } from "@angular/core";
import { NotificationService, APIService } from "@fourcomply-dashboard/shared";
import { Router, ActivatedRoute } from "@angular/router";
import { BreadcrumbService } from "xng-breadcrumb";
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: "app-edit-client-email-template",
  templateUrl: "./edit-client-email-template.component.html",
  styleUrls: ["./edit-client-email-template.component.scss"],
})
export class EditClientEmailTemplateComponent implements OnInit {
  // public ckeditor = ClassicEditor;
  formSubmitted = false;
  model: any = {};

  constructor(
    public api: APIService,
    public router: Router,
    private route: ActivatedRoute,
    private notification: NotificationService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this.submitClientEmailTemplate = this.submitClientEmailTemplate.bind(this);
    this.initClientEmailTemplateForm();
  }

  initClientEmailTemplateForm() {
    const templateId = this.route.snapshot.params["id"];
    this.api.clientEmailTemplates.get(templateId).subscribe((a) => {
      this.model = a;
      this.breadcrumbService.set("@emailType", this.model.type);
    });
  }

  close() {
    this.router.navigate(["/email-templates"]);
    //this.router.navigate(["settings"]);
  }

  submitClientEmailTemplate() {
    this.formSubmitted = true;
    if (this.model.subject.length > 0 && this.model.template.length > 0) {
      this.api.clientEmailTemplates.update(this.model).subscribe(
        (a) => {
          this.formSubmitted = false;
          this.notification.success(
            "ADD_EMAIL_TEMPLATE.SUCCESSFUL_UPDATE_TEMPLATE"
          );
          this.router.navigate(["/email-templates"]);
          //this.router.navigate(["settings"]);
        },
        (err) => {
          this.formSubmitted = false;
        }
      );
    } else {
      this.formSubmitted = false;
      this.notification.error("ADD_EMAIL_TEMPLATE.EMPTY_ERROR");
    }
  }
}
