import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {
  AuthService,
  ClientsService,
  LocalStorageService,
} from "@fourcomply-dashboard/shared";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { pipe } from "rxjs";
import {
  NotificationService,
  APIService,
  FormComponent,
  ModalComponent,
  DatalistActionItem,
  ClientForm,
  DraggableTableComponent,
} from "@fourcomply-dashboard/shared";
import { BreadcrumbService } from "xng-breadcrumb";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @ViewChild("formClient", { static: true })
  formClient: FormComponent;
  @ViewChild("modalClient", { static: true })
  public modalClient: ModalComponent;
  @Input() appTitle: string;
  navOpen = true;
  fieldsFormFields: FormlyFieldConfig[] = [];
  modelFormFields: any = {};
  currentTenant: any;
  selectLang: string = "en";
  selectLangLabel: string = "English";
  selectLangImage: string = "/assets/flagUS.png";
  TransLang = [];
  userTooltipString: string = "";
  settingsTooltipString: string = "";
  docsTooltipString: string = "";
  logoutTooltipString: string = "";

  langs: any = [
    { label: "English", value: "en", image: "/assets/flagUS.png" },
    { label: "Español", value: "es", image: "/assets/flagMX.png" },
    { label: "हिंद", value: "in", image: "/assets/flagIN.png" },
    { label: "Italiano", value: "it", image: "/assets/flagIT.png" },
    { label: "Français", value: "fr", image: "/assets/flagFR.png" },
    { label: "Português", value: "pt", image: "/assets/flagPT.png" },
    { label: "Deutsch", value: "de", image: "/assets/flagDE.png" },
    { label: "Nederlands", value: "nl", image: "/assets/flagNL.png" },
    { label: "日本語", value: "jp", image: "/assets/flagJP.png" },
    { label: "简体中文", value: "ch", image: "/assets/flagCH.png" },
    { label: "繁体中文", value: "tw", image: "/assets/flagTW.png" },
  ];

  constructor(
    public auth: AuthService,
    public api: APIService,
    public translate: TranslateService,
    private localStorage: LocalStorageService,
    private router: Router
  ) {
    this.submitFormFields = this.submitFormFields.bind(this);
    this.getTransLanguage();
    this.translate = translate;
    this.selectLang = this.localStorage.get("lang")
      ? this.localStorage.get("lang")
      : "en";
    this.processLanguage();
  }

  processLanguage() {
    this.translate.use(this.selectLang);
    this.selectLangLabel = this.langs.find(
      (i) => i.value === this.selectLang
    ).label;
    this.selectLangImage = this.langs.find(
      (i) => i.value === this.selectLang
    ).image;
  }

  ngOnInit() {
    this.auth.localAuthSetup();
  }

  translateTooltips() {
    this.userTooltipString = this.translate.instant(
      "BASIC_ENTITIES_PLURAL.USERS"
    );
  }

  getTransLanguage() {
    this.TransLang = this.langs;
  }

  setTransLanguage($event) {
    this.selectLang = $event.value;
    this.localStorage.set("lang", $event.value);
    this.processLanguage();
  }

  submitFormFields() {
    this.auth.userProfile$.subscribe(
      pipe((res: any) => {
        this.api.clients
          .createClient(
            this.modelFormFields.name,
            this.modelFormFields.environment,
            res
          )
          .subscribe((newClient) => {
            console.log(newClient);
            let intermediate = location.href.split("?tenant=")[0];
            console.log(intermediate);
            location.href = intermediate + "?tenant=" + newClient.client.id;
          });
      })
    );
  }

  initFormFieldModalForm() {
    this.fieldsFormFields = [
      {
        key: "name",
        type: "input",
        templateOptions: {
          required: true,
          label: "GENERIC.NAME",
        },
      },
      {
        key: "environment",
        type: "input",
        templateOptions: {
          required: true,
          label: "HEADER.COMPANY_IDENTIFIER",
        },
      },
    ];
    this.formClient.updateFields(this.fieldsFormFields);
  }

  startClientModal() {
    this.initFormFieldModalForm();
    this.modalClient.openModal();
  }

  closeModal() {
    this.formClient.options.resetModel();
    this.initFormFieldModalForm();
  }

  changeTenant(tenant) {
    if (tenant === "0000000000") {
      this.startClientModal();
    } else {
      this.auth.changeTenant(tenant[0]).subscribe();
    }
  }
}
