import { Serializer } from "../resource/serializer.interface";
import { RightRequestConfigMap } from "./right-request-config-map.model";
import { RightRequestStep } from "../right-request-steps/right-request-step.model";

export class RightRequestConfigMapSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): RightRequestConfigMap {
    const rrc = new RightRequestConfigMap();
    rrc.id = json.id;
    rrc.requestType = json.request_type;
    rrc.sfConfig = json.sf_config;
    rrc.regulationId = json.regulation_id;
    rrc.steps = json.steps;
    rrc.createdAt = json.created_at;
    rrc.updatedAt = json.updated_at;
    rrc.numberOfSteps = json.steps.length;
    rrc.name = json.name;
    return rrc;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(consent: RightRequestConfigMap): any {
    return {
      id: consent.id,
      request_type: consent.requestType,
      sf_config: consent.sfConfig,
      regulation_id: consent.regulationId,
      steps: consent.steps,
      created_at: consent.createdAt,
      updated_at: consent.updatedAt,
      name: consent.name
    };
  }
}
