<div
  class="container citizen-history-page"
  [style.display]="outlet.isActivated ? 'none' : ''"
>
  <div class="column col-12">
    <app-generic-card>
      <app-actionbar [title]="'SIDEMENU.CITIZEN_HISTORY' | translate">
      </app-actionbar>
      <!--<input
        style="border:none; border-bottom:1px solid #999999; margin-right:10px;"
        class="mat-input-element"
        type="text"
        placeholder="Email Address"
        [(ngModel)]="searchText"
      />-->

      <app-citizen-history-search
        (selectedEmail)="setSearchText($event)"
        [flag]="parentFlagValue"
      >
      </app-citizen-history-search>

      <button
        class="btn btn-primary submit-button"
        (click)="searchWithEmail()"
        #searchButton
      >
        {{ "CITIZEN_HISTORY.SEARCH" | translate }}
      </button>
      <div class="download-wrap">
        <button
          *ngIf="dataPermissions && dataPermissions.length"
          (click)="downloadPermissionCsv()"
          class="btn btn-primary submit-button"
        >
          {{ "CITIZEN_HISTORY.DOWNLOAD_PERMISSIONS" | translate }}
        </button>
        <button
          *ngIf="dataConsent && dataConsent.length"
          (click)="downloadConsentCsv()"
          class="btn btn-primary submit-button"
        >
          {{ "CITIZEN_HISTORY.DOWNLOAD_COMPLIANCE_INPUT" | translate }}
        </button>
        <button
          *ngIf="dataLegalActivities && dataLegalActivities.length"
          (click)="downloadLegalActivityCsv()"
          class="btn btn-primary submit-button"
        >
          {{ "CITIZEN_HISTORY.DOWNLOAD_LEGAL_ACTIVITIES" | translate }}
        </button>
        <button
          *ngIf="dataRightRequest && dataRightRequest.length"
          (click)="downloadRightRequestCsv()"
          class="btn btn-primary submit-button"
        >
          {{ "CITIZEN_HISTORY.DOWNLOAD_RIGHT_REQUESTS" | translate }}
        </button>
        <button (click)="downloadExcel()" class="btn btn-primary submit-button">
          Download Excel of all data
        </button>
      </div>
    </app-generic-card>
  </div>
  <div class="column col-12">
    <app-generic-card>
      <app-actionbar
        title="{{ 'BASIC_ENTITIES_PLURAL.LEGAL_ACTIVITIES' | translate }}"
      ></app-actionbar>
      <app-datatable
        #dtLegalActivities
        (sortEvent)="onSortLegal($event)"
        [data]="dataLegalActivities"
        tableIdentifier="legalActivitiesReport"
        [columns]="columnsLegalActivities"
      ></app-datatable>
    </app-generic-card>
    <app-pagination
      *ngIf="legalPages"
      [pages]="legalPages"
      [currentPageSize]="legalPageSize"
      [currentPage]="legalCurrentPage"
      (setPageSize)="setLegalPageSize($event)"
      (setCurrentPage)="setLegalCurrentPage($event)"
    ></app-pagination>
  </div>
  <div class="column col-12">
    <app-generic-card>
      <app-actionbar
        title="{{ 'BASIC_ENTITIES_PLURAL.RIGHT_REQUESTS' | translate }}"
      ></app-actionbar>
      <app-datatable
        #dtRightRequest
        [data]="dataRightRequest"
        (sortEvent)="onSortRight($event)"
        tableIdentifier="rightRequestsReport"
        [columns]="columnsRightRequests"
      ></app-datatable>
    </app-generic-card>
    <app-pagination
      *ngIf="rightPages"
      [pages]="rightPages"
      [currentPageSize]="rightPageSize"
      [currentPage]="rightCurrentPage"
      (setPageSize)="setRightPageSize($event)"
      (setCurrentPage)="setRightCurrentPage($event)"
    ></app-pagination>
  </div>
  <div class="column col-12">
    <app-generic-card>
      <app-actionbar
        title="{{ 'BASIC_ENTITIES_PLURAL.PERMISSIONS' | translate }}"
      ></app-actionbar>
      <app-datatable
        #dtPermissions
        [data]="dataPermissions"
        (sortEvent)="onSortPerm($event)"
        tableIdentifier="permissionsReport"
        [columns]="columnsPermissions"
        (openCustomFieldsCallback)="onOpenCustomFields($event)"
      ></app-datatable>
    </app-generic-card>
    <app-pagination
      *ngIf="permPages"
      [pages]="permPages"
      [currentPageSize]="permPageSize"
      [currentPage]="permCurrentPage"
      (setPageSize)="setPermPageSize($event)"
      (setCurrentPage)="setPermCurrentPage($event)"
    ></app-pagination>
  </div>
  <div class="column col-12">
    <app-generic-card>
      <app-actionbar
        title="{{ 'BASIC_ENTITIES_PLURAL.COMPLIANCE_INPUTS' | translate }}"
      ></app-actionbar>
      <app-datatable
        #dtConsent
        [data]="dataConsent"
        (sortEvent)="onSortComp($event)"
        tableIdentifier="consentReport"
        [columns]="columnsConsent"
        (openCustomFieldsCallback)="onOpenCustomFields2($event)"
      ></app-datatable>
    </app-generic-card>
    <app-pagination
      *ngIf="consPages"
      [pages]="consPages"
      [currentPageSize]="consPageSize"
      [currentPage]="consCurrentPage"
      (setPageSize)="setConsPageSize($event)"
      (setCurrentPage)="setConsCurrentPage($event)"
    ></app-pagination>
  </div>
</div>
<router-outlet
  #outlet="outlet"
  (deactivate)="onDeactivate($event)"
></router-outlet>

<app-modal
  #modalCustomFields
  title="{{ 'CITIZEN_HISTORY.COMPLIANCE_INPUT_TABLE_ENTRY' | translate }}"
>
  <div *ngIf="selectedPermission !== null">
    <b>{{ selectedPermission.email }}</b>
    <div>
      {{ "GENERIC.FIELD" | translate }} 1 -
      {{ customFieldNames.customFieldName1 }} :
      {{ selectedPermission.customField1 || "-" }}
    </div>
    <div>
      {{ "GENERIC.FIELD" | translate }} 2 -
      {{ customFieldNames.customFieldName2 }} :
      {{ selectedPermission.customField2 || "-" }}
    </div>
    <div>
      {{ "GENERIC.FIELD" | translate }} 3 -
      {{ customFieldNames.customFieldName3 }} :
      {{ selectedPermission.customField3 || "-" }}
    </div>
    <div>
      {{ "GENERIC.FIELD" | translate }} 4 -
      {{ customFieldNames.customFieldName4 }} :
      {{ selectedPermission.customField4 || "-" }}
    </div>
    <div>
      {{ "GENERIC.FIELD" | translate }} 5 -
      {{ customFieldNames.customFieldName5 }} :
      {{ selectedPermission.customField5 || "-" }}
    </div>
    <div>
      {{ "GENERIC.FIELD" | translate }} 6 -
      {{ customFieldNames.customFieldName6 }} :
      {{ selectedPermission.customField6 || "-" }}
    </div>
    <div>
      {{ "GENERIC.FIELD" | translate }} 7 -
      {{ customFieldNames.customFieldName7 }} :
      {{ selectedPermission.customField7 || "-" }}
    </div>
  </div>
</app-modal>

<app-modal
  #modalCustomFields2
  title="{{ 'CITIZEN_HISTORY.COMPLIANCE_INPUT_TABLE_ENTRY' | translate }}"
>
  <div *ngIf="selectedConsent !== null">
    <b>{{ selectedConsent.email }}</b>
    <div>
      {{ "GENERIC.FIELD" | translate }} 1 -
      {{ customFieldNames.customFieldName1 }} :
      {{ selectedConsent.customField1 || "-" }}
    </div>
    <div>
      {{ "GENERIC.FIELD" | translate }} 2 -
      {{ customFieldNames.customFieldName2 }} :
      {{ selectedConsent.customField2 || "-" }}
    </div>
    <div>
      {{ "GENERIC.FIELD" | translate }} 3 -
      {{ customFieldNames.customFieldName3 }} :
      {{ selectedConsent.customField3 || "-" }}
    </div>
    <div>
      {{ "GENERIC.FIELD" | translate }} 4 -
      {{ customFieldNames.customFieldName4 }} :
      {{ selectedConsent.customField4 || "-" }}
    </div>
    <div>
      {{ "GENERIC.FIELD" | translate }} 5 -
      {{ customFieldNames.customFieldName5 }} :
      {{ selectedConsent.customField5 || "-" }}
    </div>
    <div>
      {{ "GENERIC.FIELD" | translate }} 6 -
      {{ customFieldNames.customFieldName6 }} :
      {{ selectedConsent.customField6 || "-" }}
    </div>
    <div>
      {{ "GENERIC.FIELD" | translate }} 7 -
      {{ customFieldNames.customFieldName7 }} :
      {{ selectedConsent.customField7 || "-" }}
    </div>
  </div>
</app-modal>
