<div class="container">
  <div class="column col-12">
    <app-generic-card>
      <app-actionbar
        title="{{ 'FORGOTTEN_VAULT.TITLE' | translate }}"
        [actionBarItems]="actionBarItemsForgottenUsers"
        [subtitle]="
          total != null && total != undefined
            ? isEstimate
              ? 'around ' + total + ' items'
              : total + ' total items'
            : null
        "
      ></app-actionbar>
      <app-datatable
        #dtForgottenUsers
        [data]="dataForgottenUsers"
        tableIdentifier="forgottenUsers"
        [columns]="columnsForgottenUsers"
        (sortEvent)="onSort($event)"
      ></app-datatable> </app-generic-card
    ><app-pagination
      *ngIf="pages"
      [pages]="pages"
      [currentPageSize]="pageSize"
      [currentPage]="currentPage"
      (setPageSize)="setPageSize($event)"
      (setCurrentPage)="setCurrentPage($event)"
    ></app-pagination>
  </div>
</div>
