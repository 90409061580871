import { HitlTask } from "./hitl-task.model";
import { Serializer } from "../resource/serializer.interface";

export class HitlTaskSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): HitlTask {
    const client = new HitlTask();
    client.id = json.id;
    client.status = json.status;
    client.assignedToId = json.assigned_to_id;
    client.citizenEmail = json.citizen_email;
    client.templateId = json.template_id;
    client.notes = json.notes;
    client.source = json.source;
    client.createdAt = json.created_at;
    client.updatedAt = json.updated_at;
    client.workflowInstanceId = json.workflow_instance_id;

    return client;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(client: HitlTask): any {
    return {
      id: client.id,
      status: client.status,
      assigned_to_id: client.assignedToId,
      citizen_email: client.citizenEmail,
      template_id: client.templateId,
      source: client.source,
      notes: client.notes,
      updated_at: client.updatedAt,
      created_at: client.createdAt,
      workflow_instance_id: client.workflowInstanceId,
    };
  }
}
