<div class="container" [style.display]="outlet.isActivated ? 'none' : ''">
  <div class="column col-12">
    <app-generic-card>
      <app-actionbar
        title="{{ 'BASIC_ENTITIES_PLURAL.FORMS' | translate }}"
        [actionBarItems]="actionBarItemsForms"
      ></app-actionbar>
      <app-datatable
        #dtClientForms
        [data]="dataClientForms"
        tableIdentifier="forms"
        [columns]="columnsClientForms"
        [actions]="actionsClientForms"
      ></app-datatable>
    </app-generic-card>
  </div>
</div>
<router-outlet
  #outlet="outlet"
  (deactivate)="onDeactivate($event)"
></router-outlet>
