import {
    Directive,
    OnChanges,
    AfterViewInit,
    EventEmitter,
    OnInit,
    Output,
    Input,
    OnDestroy,
    ElementRef,
    SimpleChange
} from "@angular/core";
import { dragula, DragulaService } from "ng2-dragula";
import { Subscription } from 'rxjs';
import { Draggable } from '../models/draggable.model';

@Directive({ selector: "ngx-datatable[dragulaName]" })
export class NgxDradDropDirective
    implements OnChanges, OnInit, AfterViewInit, OnDestroy {
    @Input() public dragulaName: string;
    @Input() public dragulaModel: any;
    @Input() public classSelector: string = "null";
    @Output() public directiveDrop: EventEmitter<Draggable> = new EventEmitter<Draggable>();

    protected container: any;
    private drake: any;
    private options: any;
    private el: ElementRef;
    private dragulaService: DragulaService;
    subscriptionDrag: any = null;
    subscriptionDrop: any = null;
    subs = new Subscription();

    public constructor(el: ElementRef, dragulaService: DragulaService) {
        this.el = el;
        this.dragulaService = dragulaService;
    }
    ngOnInit() { }

    ngAfterViewInit() {
        if (this.el) {
            let container = this.el;

            // Check for the row's parent node: datatable-scroller
            // This is what you want to bind Dragula to, in order to drag sort
            if (container.nativeElement.querySelector("datatable-scroller")) {
                let rowParent = container.nativeElement.querySelector(
                    "datatable-scroller"
                );                

                // Check if this Dragula already exists
                if (!this.dragulaService.find(this.dragulaName)) {
                    // Must assign the new rowParent as the container you want to pass to Dragula
                    this.container = rowParent;
                    this.initializeDragula();
                }
            }
        }
    }

    ngOnDestroy() {
        // Clear this Dragula always
        // comment out if you want to keep it
        if (this.dragulaService.find(this.dragulaName)) {
            this.dragulaService.destroy(this.dragulaName);
        }

        if (this.subscriptionDrop) {
            this.subscriptionDrop.unsubscribe();
            this.subscriptionDrop = null;
        }
        this.subs.unsubscribe();
    }

    public initializeDragula() {
        let dragIndex: number, dropIndex: number;
        // Create new Dragula container
        let bag = this.dragulaService.find(this.dragulaName);
        if (bag) {
            this.drake = bag.drake;
            this.checkModel();
            this.drake.containers.push(this.container);
        } else {
            if (this.classSelector != "null") {
                let classSelector = this.classSelector;
                let options = {
                    moves: function (el, container, handle) {
                        return handle.className === classSelector;
                    }

                };
                this.drake = dragula([this.container], options);

                let optionsForServ = (this.drake, {
                    containers: [this.container],
                    copy: true,
                    moves: function (el, container, handle) {
                        return handle.className === classSelector;
                    }
                })
                this.dragulaService.createGroup(this.dragulaName, optionsForServ);
            } else {
                this.drake = dragula([this.container]);
                let optionsForServ = (this.drake, {
                    containers: [this.container],
                    copy: true
                })
                this.dragulaService.createGroup(this.dragulaName, optionsForServ);
            }
            this.checkModel();
        }

        this.dragulaService.drag(this.dragulaName).subscribe((value) => {
            dragIndex = [].slice.call(value.el.parentElement.children).indexOf(value.el);
        });

        this.subscriptionDrop = this.subs.add(this.dragulaService.drop(this.dragulaName)
            .subscribe((value) => {
                var childNodes = [].slice.call(value.source.children);
                for (let index = 0; index < childNodes.length; index++) {
                    if(childNodes[index].textContent == value.el.textContent){
                        dropIndex = index;
                    }
                }

                if (dragIndex >= 0 && dropIndex >= 0 && dragIndex !== dropIndex) {
                    let temp: any = this.dragulaModel[dragIndex];
                    this.dragulaModel.splice(dragIndex, 1);
                    this.dragulaModel.splice(dropIndex, 0, temp);
                }
                var draggable = new Draggable(dragIndex, dropIndex);
                this.onDropModel(draggable);
            })
        );
    }

    private checkModel() {
        if (this.dragulaModel) {
            if (this.drake.models) {
                this.drake.models.push(this.dragulaModel);
            } else {
                this.drake.models = [this.dragulaModel];
            }
        }
    }

    private onDropModel(draggable) {
        this.directiveDrop.emit(draggable);
    }

    public ngOnChanges(changes: { dragulaModel?: SimpleChange }): void {
        if (changes && changes.dragulaModel) {
            if(changes.dragulaModel.currentValue && !changes.dragulaModel.previousValue){
                setTimeout(() => {
                    this.ngAfterViewInit();    
                }, 2000);
            }
            else if (this.drake) {
                if (this.drake.models) {
                    let modelIndex = this.drake.models.indexOf(
                        changes.dragulaModel.previousValue
                    );
                    this.drake.models.splice(
                        modelIndex,
                        1,
                        changes.dragulaModel.currentValue
                    );
                } else {
                    this.drake.models = [changes.dragulaModel.currentValue];
                }
            }
        }
    }
}
