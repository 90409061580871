export class Error {
    statusCode: number;
    statusText: string;
    message: string;

    constructor(theStatusCode: number, theStatusText: string, theMessage: string) { 
        this.statusCode = theStatusCode; 
        this.statusText = theStatusText; 
        this.message = theMessage; 
    }
}
