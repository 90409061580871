import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";
import { DatalistActionItem } from "../../models/datalist-action-item.interface";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() pages;
  @Input() currentPage;
  @Input() currentPageSize = 10;
  @Output() setCurrentPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() setPageSize: EventEmitter<any> = new EventEmitter<any>();
  pageList: any[] = [];
  options: any[] = [10, 20, 30, 50, 100, 200];
  morePage: any = null;
  lessPage: any = null;

  constructor(public router: Router, public translate: TranslateService) {}

  ngOnInit() {
    this.setup();
  }

  ngOnChanges() {
    this.setup();
  }

  setup() {
    console.log("PAGESSSSSS:", this.pages, this.currentPage);
    let a = [];
    for (let i = Number(this.currentPage) - 1; i < Number(this.pages); i++) {
      a.push(i + 1);
    }
    if (this.pages == 0) {
      a = [1]
    }
    this.pageList = a;
    let current = Number(this.currentPage);
    this.morePage = current + 1;
    this.lessPage = current - 1 < 1 ? null : current - 1;
  }

  increment() {
    this.setCurrentPager(Number(this.currentPage) + 1);
  }

  decrement() {
    this.setCurrentPager(Number(this.currentPage) - 1);
  }

  decrementAll() {
    this.setCurrentPager(1);
  }

  incrementAll() {
    this.setCurrentPager(this.pages);
  }

  setPagerSize(size) {
    this.setPageSize.emit(size);
  }

  setCurrentPager(page) {
    this.setCurrentPage.emit(page);
  }
}
