import { Resource } from "../resource/resource.model";
import { ClientLawConfiguration } from '../client-law-configuration/client-law-configuration.model';

/**
 * A Tenant in the 4Comply SaaS System
 * This will really only be used for the 4comply dashboard
 */

export class ClientRegulationConfiguration extends Resource {
    regulationId: string;
    config: ClientLawConfiguration;    
    createdAt?: Date;
    updatedAt?: Date;
}
