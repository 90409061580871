import { Serializer } from "../resource/serializer.interface";
import { AdminUser } from './admin-user.model';

export class AdminUserSerializer implements Serializer {
  /**
   * If necessary, more significant transforms could be done here
   * @param json
   */
  fromJson(json: any): AdminUser {
    const adminUser = new AdminUser();
    adminUser.id = json.id;
    adminUser.createdAt = json.created_at;
    adminUser.firstName = json.first_name;
    adminUser.lastName = json.last_name;
    adminUser.email = json.email_address;
    adminUser.phone = json.phone;

    return adminUser;
  }

  /**
   * For now this will do, we could generalize this in Serializer if we wanted
   * */
  toJson(adminUser: AdminUser): any {
    return {
      id: adminUser.id,
      first_name: adminUser.firstName,
      last_name: adminUser.lastName,
      email_address: adminUser.email,
      phone: adminUser.phone
    };
  }
}
