import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import {
  ReactiveFormsModule,
  FormsModule,
  FormControl,
  ValidationErrors,
} from "@angular/forms";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyMaterialModule } from "@ngx-formly/material";
import { StorageServiceModule } from "ngx-webstorage-service";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { MatSidenavModule } from "@angular/material/sidenav";
import { CKEditorModule } from "ckeditor4-angular";
import { AppRoutingModule } from "./app-routing.module";
import {
  AppTitleService,
  APIService,
  AuthService,
  ModalComponent,
  CustomErrorHandlerService,
  GenericCardComponent,
  DraggableTableComponent,
  DraggableTableRegulationsComponent,
  NgxDradDropDirective,
  ActionBarComponent,
  AppHttpInterceptor,
  NotificationService,
  EmailValidation,
  BackBtnComponent,
  LocalStorageService,
  CitizenHistorySearchService,
  NgFlowchartModule,
} from "@fourcomply-dashboard/shared";
import { AppComponent } from "./app.component";
import { ChartsModule } from "ng2-charts";
import { ClipboardModule } from "ngx-clipboard";
import { MatListModule } from "@angular/material/list";
import { MatRadioModule } from "@angular/material/radio";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatExpansionModule } from "@angular/material/expansion";
import {
  CallbackComponent,
  DatatableComponent,
  FormComponent,
  SideNavComponent,
  SideNavItemComponent,
  //GaugeChartComponent,
  PaginationComponent,
} from "@fourcomply-dashboard/shared";
import { NgxUploaderModule } from "ngx-uploader";
import { BreadcrumbModule } from "xng-breadcrumb";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { DragulaService, DragulaModule } from "ng2-dragula";
import { KanbanModule } from "@syncfusion/ej2-angular-kanban";
import { PanelModule, PanelSide } from "@verizon/ngx-panels";
import { LoginComponent } from "./components/login/login.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { AnalyticsComponent } from "./components/analytics/analytics.component";
import { UsersComponent } from "./components/users/users.component";
import { GeoComponent } from "./components/geo/geo.component";
import { HeaderComponent } from "./components/header/header.component";
import { AddEditGeoIpExceptionComponent } from "./components/add-edit-geo-ip-exception/add-edit-geo-ip-exception.component";
import { ToastrModule } from "ngx-toastr";
import { ConsentPermissionsComponent } from "./components/consent-permissions/consent-permissions.component";
import { ComplianceInputComponent } from "./components/compliance-inputs/compliance-inputs.component";
import { WebhooksComponent } from "./components/webhooks/webhooks.component";
import { SystemsComponent } from "./components/systems/systems.component";
import { CitizenHistoryComponent } from "./components/citizen-history/citizen-history.component";
import { CitizenHistorySearchComponent } from "./components/citizen-history-search/citizen-history-search.component";
import { AddClientWebhookComponent } from "./components/add-client-webhook/add-client-webhook.component";
import { AddClientSystemComponent } from "./components/add-client-system/add-client-system.component";
import { LegalActivityErrorsComponent } from "./components/legal-activity-errors/legal-activity-errors.component";
import { LegalActivitiesComponent } from "./components/legal-activities/legal-activities.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { ClientRegulationsComponent } from "./components/client-regulations/client-regulations.component";
import { ClientRegulationConfigurationsComponent } from "./components/client-regulation-configurations/client-regulation-configurations.component";
import { EditClientEmailTemplateComponent } from "./components/edit-client-email-template/edit-client-email-template.component";
import { RightRequestConfigurationComponent } from "./components/right-request-configurations/right-request-configurations.component";
import { ClientFormEditComponent } from "./components/client-form-edit/client-form-edit.component";
import { ClientFormsComponent } from "./components/client-forms/client-forms.component";
import { AddUserComponent } from "./components/add-user/add-user.component";
import { AddGeoMapComponent } from "./components/add-geo-map/add-geo-map.component";
import { ForgottenUsersComponent } from "./components/forgotten-users/forgotten-users.component";
import { RightRequestComponent } from "./components/right-request/right-request.component";
import { RightRequestDetailComponent } from "./components/right-request-detail/right-request-detail.component";
import { DashboardRightsComponent } from "./components/dashboard-rights/dashboard-rights.component";
import { HitlTasksComponent } from "./components/hitl-tasks/hitl-tasks.component";
import { HitlConfigurationComponent } from "./components/hitl-configuration/hitl-configuration.component";
import { AddHitlTemplateComponent } from "./components/add-hitl-template/add-hitl-template.component";
import { BusinessUnitComponent } from "./components/business-unit/business-unit.component";
import { AddBusinessUnitComponent } from "./components/add-business-unit/add-business-unit.component";
import { ClientEmailTemplateComponent } from "./components/client-email-template/client-email-template.component";
import { HitlDetailPanelComponent } from "./components/hitl-tasks/hitl-detail-panel.component";
import { WorkflowDetailPanelComponent } from "./components/right-request-detail/workflow-detail-panel.component";
import { FormStepComponent } from "./components/right-request-configurations/form-step/form-step.component";
import { FormStepService } from "./components/right-request-configurations/form-step/form-step.service";
import { SafeHtmlPipe } from "./safe-html.pipe";
import { LandingPagesComponent } from "./components/landing-pages/landing-pages.component";
import { EditClientLandingPageTemplateComponent } from "./components/edit-client-landing-page-template/edit-client-landing-page-template.component";

export function WebUrlValidator(control: FormControl): ValidationErrors {
  return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi.test(
    control.value
  )
    ? null
    : { url: true };
}

export function IpValidator(control: FormControl): ValidationErrors {
  return /(\d{1,3}\.){3}\d{1,3}/.test(control.value) ? null : { ip: true };
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json?v=2.5.0");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    AnalyticsComponent,
    UsersComponent,
    CallbackComponent,
    HeaderComponent,
    DatatableComponent,
    FormComponent,
    SideNavComponent,
    SideNavItemComponent,
    ModalComponent,
    GeoComponent,
    GenericCardComponent,
    DraggableTableComponent,
    DraggableTableRegulationsComponent,
    NgxDradDropDirective,
    ActionBarComponent,
    AddEditGeoIpExceptionComponent,
    ConsentPermissionsComponent,
    ComplianceInputComponent,
    WebhooksComponent,
    SystemsComponent,
    BusinessUnitComponent,
    AddBusinessUnitComponent,
    AddClientSystemComponent,
    CitizenHistoryComponent,
    CitizenHistorySearchComponent,
    AddClientWebhookComponent,
    LegalActivityErrorsComponent,
    LegalActivitiesComponent,
    HitlTasksComponent,
    HitlConfigurationComponent,
    AddHitlTemplateComponent,
    EditClientLandingPageTemplateComponent,
    //PieChartComponent,
    DashboardRightsComponent,
    LandingPagesComponent,
    SettingsComponent,
    ClientRegulationsComponent,
    ClientRegulationConfigurationsComponent,
    RightRequestConfigurationComponent,
    EditClientEmailTemplateComponent,
    ClientFormsComponent,
    RightRequestDetailComponent,
    ClientFormEditComponent,
    AddUserComponent,
    AddGeoMapComponent,
    ForgottenUsersComponent,
    HitlDetailPanelComponent,
    WorkflowDetailPanelComponent,
    RightRequestComponent,
    BackBtnComponent,
    PaginationComponent,
    ClientEmailTemplateComponent,
    FormStepComponent,
    SafeHtmlPipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    HttpClientModule,
    NgxDatatableModule,
    KanbanModule,
    NgFlowchartModule,
    ClipboardModule,
    ReactiveFormsModule,
    StorageServiceModule,
    PanelModule.forRoot({ side: PanelSide.RIGHT, size: 600 }),
    FormlyModule.forRoot({
      extensions: [
        { name: "email", extension: { prePopulate: EmailValidation } },
      ],
      validators: [
        { name: "url", validation: WebUrlValidator },
        { name: "ip", validation: IpValidator },
      ],
      validationMessages: [
        { name: "url", message: "Invalid URL format" },
        { name: "ip", message: "Invalid IP address format" },
        { name: "required", message: "Required" },
      ],
    }),
    FormlyMaterialModule,
    MatSidenavModule,
    MatRadioModule,
    DragDropModule,
    MatListModule,
    DragulaModule.forRoot(),
    ToastrModule.forRoot(),
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatRadioModule,
    FormsModule,
    CKEditorModule,
    ChartsModule,
    NgxUploaderModule,
    BreadcrumbModule,
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  entryComponents: [HitlDetailPanelComponent, WorkflowDetailPanelComponent],
  providers: [
    AuthService,
    APIService,
    AppTitleService,
    FormStepService,
    CustomErrorHandlerService,
    LocalStorageService,
    NotificationService,
    SafeHtmlPipe,
    DragulaService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
    },
    CitizenHistorySearchService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
