<header class="navbar header-area" *ngIf="auth.loggedIn">
  <section class="navbar-section" style="height:80px">
    <div style="height: 80px;">
      <div style="height: 80px;">
        <img
          src="https://4comply.io/images/4Comply-logo-nt.png"
          alt="4Comply"
          style="width: 150px;height: auto;margin-top: -35px;"
        />
      </div>
      <div
        style="margin-top: -18px;font-size: 12px;background: #fcfcfc;border: 1px solid #eee;border-bottom: none;"
      >
        <a
          (click)="auth.navOpen = !auth.navOpen"
          class="btn btn-link"
          *ngIf="!auth.navOpen"
          ><i class="fas fa-bars"></i
        ></a>
        <a
          (click)="auth.navOpen = !auth.navOpen"
          class="btn btn-link"
          *ngIf="auth.navOpen"
          ><i class="fas fa-bars"></i
        ></a>
        <span *ngIf="auth.navOpen">Close Menu</span>
        <span *ngIf="!auth.navOpen">Open Menu</span>
      </div>
    </div>
  </section>
  <section class="navbar-section" *ngIf="auth.userProfile$ | async as profile">
    <div class="language">
      <mat-form-field appearance="fill">
        <mat-label>{{ "HEADER.LANGUAGE_SELECTOR" | translate }}</mat-label>
        <mat-select
          (selectionChange)="setTransLanguage($event)"
          [value]="selectLang"
        >
          <mat-select-trigger>
            <img style="height:12px" [src]="selectLangImage" />&nbsp;{{
              selectLangLabel
            }}
          </mat-select-trigger>
          <mat-option *ngFor="let lang of TransLang" value="{{ lang.value }}"
            ><img style="height:12px" [src]="lang.image" />&nbsp;{{
              lang.label
            }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="header-user">
      <mat-form-field appearance="fill">
        <mat-label>{{ "HEADER.COMPANY_SELECTOR" | translate }}</mat-label>
        <mat-select [value]="profile.currentTenant">
          <mat-option
            (click)="changeTenant([tenant])"
            *ngFor="let tenant of profile.tenants"
            [value]="tenant"
            >{{ tenant.name }}</mat-option
          >
          <mat-option (click)="changeTenant('0000000000')" value="0000000000">
            {{ "HEADER.ADD_COMPANY" | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="header-user">
      <div>
        <i class="fas fa-user-circle"></i> <label>{{ profile.email }}</label>
      </div>
    </div>
    <a
      [routerLink]="'/settings'"
      class="btn btn-link"
      [matTooltip]="'BASIC_ENTITIES_PLURAL.SETTINGS' | translate"
    >
      <i class="fas fa-cog"></i
    ></a>
    <a
      [routerLink]="'/users'"
      class="btn btn-link"
      [matTooltip]="'BASIC_ENTITIES_PLURAL.USERS' | translate"
    >
      <i class="fas fa-users"></i
    ></a>
    <a
      href="http://docs.4comply.io"
      target="_blank"
      class="btn btn-link"
      [matTooltip]="'BASIC_ENTITIES_SINGULAR.DOCUMENTATION' | translate"
    >
      <i class="fas fa-book"></i
    ></a>

    <a
      (click)="auth.logout()"
      class="btn btn-link"
      [matTooltip]="'GENERIC.LOGOUT' | translate"
      ><i class="fas fa-sign-out-alt"></i
    ></a>
  </section>
</header>

<app-modal (closer)="closeModal()" #modalClient title="HEADER.ADD_COMPANY">
  <app-form
    #formClient
    [fields]="fieldsFormFields"
    [model]="modelFormFields"
    [callback]="submitFormFields"
  >
  </app-form>
</app-modal>
