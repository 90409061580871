import { ResourceSQL } from "../resource-sql/resource-sql.model";

/**
 * A Tenant in the 4Comply SaaS System
 * This will really only be used for the 4comply dashboard
 */

export class LegalActivityError extends ResourceSQL {
  consentId: string;
  emailAddress: string;
  regulationId: string;
  regulationName: string;
  createdAt: Date;
  updatedAt: Date;
  activityAt: Date;
  activityType: string;
  standardState: string;
  standardCountry: string;
  inputState: string;
  inputCountry: string;
  consentCode: string;
  rightWorkflowId: string;
  rightWorkflowStepName: string;
  rejectCode: string;
  rejectReason: string;
  createdAtAux: string;
  activityAtAux: string;
  system: string;
  permission_type:string;
  business_unit:string;
  external_source:string;
  processing_purpose:string;
}
